import React from "react";
import GridDuck from "gridduck";
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	DemandListCell,
	EditableList,
	GdAutocomplete,
	Input,
	StandardListCell,
	StateListCell,
	Toast
} from "gd-react";
import cookie from "react-cookies";
import history from "./../../meta/history";

class ManageHubs extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			hubs: [],
			availableGws: [],
			hubSerial: '',
			hubCheck: null,
		}
		let self = this;
		this.searchEmail = this.searchEmail.bind(this);
		this.onHubChange = this.onHubChange.bind(this);
		this.resetOwners = this.resetOwners.bind(this);
		this.selectEmail = this.selectEmail.bind(this);
		this.getHubsOwnedByOrg = this.getHubsOwnedByOrg.bind(this);
		this.getAvailableHubs = this.getAvailableHubs.bind(this);
		this.hubCheck = this.hubCheck.bind(this)
		this.save = this.save.bind(this);
	}

	getHubsOwnedByOrg(retrievalParams) {
		retrievalParams.filters = retrievalParams.filters ? retrievalParams.filters : [];
		retrievalParams.filters.push(
			{
				field: 'organisation_id',
				value: this.state.selectedOrgId
			}
		);
		return GridDuck.getAvailableGateways(retrievalParams);
	}

	getAvailableHubs(retrievalParams) {
		return GridDuck.getAvailableGateways(retrievalParams);
	}

	selectEmail(item, other) {
		this.setState({
			selectedOrg: other.subTitle,
			selectedOrgId: other.id
		});
	}

	onHubChange(val) {
		
		this.setState({
			hubSerial: val.target.value
		})
	}

	hubCheck() {
		
	}

	resetOwners() {
		let self = this;
		let serial = this.state.hubSerial.replace(/\s/g, '');
		
		let hub = {
			serialNumber: serial,
			resetOwners: true
		}
		return GridDuck.createPhysicalGateway(hub)
			.then((res) => {
				
				self.setState({hubSerial: ''})
				return Promise.resolve(true)
			})
			.catch((err) => {
				
				return Promise.reject(err)
			})
	}

	searchEmail(string) {
		return GridDuck.getOrganisationSearchs({
			filters: [{
				field: 'query',
				value: string
			}]
		}).then(function (results) {
			let fixedResults = results.list.map(function (r) {
				return {
					id: r.orgId,
					title: r.username,
					subTitle: r.orgName,
					icon: 'GoPerson'
				}
			});
			return Promise.resolve(fixedResults)
		});
	}

	save(newHubs) {
		let promises = [];
		let self = this;
		promises.push(
			GridDuck.getAvailableGateways({
				filters: [
					{
						field: 'organisation_id',
						value: self.state.selectedOrgId
					}
				]
			}).then(function (currentHubs) {
				let innerPromises = [];
				currentHubs.list.forEach(function (currentHub) {
					if (!newHubs.find(nH => nH.serialNumber === currentHub.serialNumber)) {
						if (currentHub.owners.indexOf(self.state.selectedOrgId) !== -1) {
							currentHub.owners.splice(currentHub.owners.indexOf(self.state.selectedOrgId), 1);
							innerPromises.push(
								currentHub.set({
									owners: currentHub.owners
								})
							);
						}
					}
				});
				return Promise.all(innerPromises);
			})
		);
		newHubs.forEach(function (availableGateway) {
			if (availableGateway.owners.indexOf(self.state.selectedOrgId) === -1) {
				availableGateway.owners.push(self.state.selectedOrgId);
			}
			promises.push(
				availableGateway.set({
					owners: availableGateway.owners
				})
			)
		});
		return Promise.all(promises);
	}

	render() {
		let self = this;
		return (
			<div className='page narrow Account not-flex grey'>
				<div className={'column top'}>
					<div className={'detail-content'}>
						<Card>
							<CardHeader>Manage Organisation Hubs</CardHeader>
							<CardBody>
								{this.state.selectedOrg ?
									<Input
										top
										label={'Organisation'}
										disabled={true}
										value={this.state.selectedOrg}
									/>
									:
									<GdAutocomplete
										autoFocus
										async
										label={'Member\'s email address'}
										onChange={this.selectEmail}
										getList={this.searchEmail}
										placeholder={'example@gridduck.com'}
									/>
								}
								<br/>
								{this.state.selectedOrg ?
									<EditableList
										columns={[
											{
												component: StandardListCell,
												bold: true,
												sortable: true,
												title: 'Serial Number',
												field: 'serialNumber'
											},
										]}
										getActiveList={this.getHubsOwnedByOrg}
										getAvailableList={this.getAvailableHubs}
										save={this.save}
										filters={this.state.filters}
										editingFieldName={'Serial Number'}
										editingField={'serialNumber'}
									/>
									:
									''
								}
							</CardBody>
						</Card>

					</div>
				</div>
				<div className={'column top'}>
					<div className={'detail-content'}>
						<Card>
							<CardHeader>Reset Hub Owners</CardHeader>
							<CardBody>
								<Input
									label={'Hub Serial Number'}
									onChange={this.onHubChange}
									placeholder={'0200 0001 3000 XXXX'}
									value={this.state.hubSerial}/>
								<br/>
								<div style={{width: "200px"}}>
									<Button
										progressRes
										color={'gd-green'}
										label={'Reset Owners'}
										onClick={this.resetOwners}
									/>
								</div>
								<br/>
								<p>
									Caution: This will remove all owner organisations from the hub with the exception of
									GridDuck. For hub resale / reassignment.
								</p>
							</CardBody>
						</Card>
					</div>
				</div>
				<div className={'column top'}>
					<div className={'detail-content'}>
						<Card>
							<CardHeader>Existing Hub Check</CardHeader>
							<CardBody>
								<Input
									label={'Hub Serial Number'}
									onChange={(input) => self.setState({hubCheck: input})}
									placeholder={'0200 0001 3000 XXXX'}
									value={this.state.hubCheck}/>
								<br/>
								<div style={{width: "200px"}}>
									<Button
										progressRes
										color={'gd-green'}
										label={'Check Hub'}
										onClick={this.checkHub}
									/>
								</div>
								<br/>
								{/*<p>*/}
								{/*	Caution: This will remove all owner organisations from the hub with the exception of*/}
								{/*	GridDuck. For hub resale / reassignment.*/}
								{/*</p>*/}
							</CardBody>
						</Card>
					</div>
				</div>
			</div>
		)
	}
}

export default ManageHubs;

import React from "react";
import {Input, Icon, Loader} from "gd-react";
import GridDuck from "gridduck";
import TabbedPage from "../../components/TabbedPage/TabbedPage";
import SaveBar from "../../components/SaveBar/SaveBar";
import "./MainOrganisationPage.scss";
import OrganisationMembers from "./OrganisationMembersPage";
import OrganisationPartners from "./OrganisationPartnersPage";
import OrganisationInvites from "./OrganisationInvitesPage";
import OrganisationSecurity from "./OrganisationSecurityPage";
import {v4 as uuidv4} from "uuid";
import GenericLoader from "../../components/GenericLoader";

class MainOrganisationPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            updateRef: 0,
            uploadProgress: 0,
            uploadComplete: false,
            uploadingImage: false,
            orgPermission: null,
            orgId: null,
            name: null,
            websiteUrl: null,
            contactEmail: null,
            contactPhone: null,
            address: {
                address_line_1: null,
                address_line_2: null,
                locality: null,
                postal_code: null
            },
            logoImage: null,
            logoImageThumbnail: null

        };
        this.imageUploaded = this.imageUploaded.bind(this);
        // this.hasChanged = this.hasChanged.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.onFormChangeAddress = this.onFormChangeAddress.bind(this);
        this.setOrgDetails = this.setOrgDetails.bind(this);
        this.externallyUpdated = this.externallyUpdated.bind(this);
        this.setOrgDetails();
    }

    componentWillUnmount() {
        if (this.organisation && this.organisation.off) {
            this.organisation.off('updated', this.externallyUpdated);
        }
        if (this.account && this.account.off) {
            this.account.off('updated', this.externallyUpdated);
        }
    }

    externallyUpdated(field, val) {
        switch (field) {
            case 'organisationName':
                field = 'name';
                break;
            case 'orgWebsite':
                field = 'websiteUrl';
                break;
            case 'orgEmail':
                field = 'contactEmail';
                break;
            case 'orgPhone':
                field = 'contactPhone';
                break;
            case 'orgAddress':
                field = 'address';
                break;
        }
        this.setState({[field]: val});
    }

    setOrgDetails() {
        let self = this;
        this.hasChanged = false;
        if (self.organisation) {
            self.organisation.off('updated', self.externallyUpdated);
        }
        GridDuck.getAccount({id: GridDuck.userId}).then(function (account) {

            self.account = account;
            self.setState({updateRef: self.state.updateRef++});
            // self.account.on('updated', self.externallyUpdated);
            self.setState({
                orgPermission: account.orgPermission,
                orgId: account.organisationId,
                name: account.organisationName,
                websiteUrl: account.orgWebsite,
                contactEmail: account.orgEmail,
                contactPhone: account.orgPhone,
                address: account.orgAddress ? {
                    address_line_1: account.orgAddress['address_line_1'],
                    address_line_2: account.orgAddress['address_line_2'],
                    locality: account.orgAddress['locality'],
                    postal_code: account.orgAddress['postal_code']
                } : {
                    address_line_1: '',
                    address_line_2: '',
                    locality: '',
                    postal_code: ''
                },
                logoImage: account.logoImage,
                logoImageThumbnail: account.logoImageThumbnail,
                defaultOccupancySchedule: account.defaultOccupancySchedule,
                loaded: true
            })
            GridDuck.getOrganisation({id: account.organisationId}).then(function (organisation) {
                self.organisation = organisation;
                self.setState({updateRef: self.state.updateRef++, loadedOrg: true});
                self.organisation.on('updated', self.externallyUpdated);
            });
        })
    }

    onFormChangeAddress(val) {
        this.hasChanged = true;
        this.state.address[val.target.name] = val.target.value;
        this.setState(this.state);
    }

    onFormChange(val) {
        this.state[val.target.name] = val.target.value;
        this.hasChanged = true;
        this.setState(this.state);
    }


    // hasChanged() {
    //     const compareObject = function (objectA, objectB) {
    //         let res = false;
    //         Object.keys(objectA).forEach(function (key) {
    //             if (typeof objectA[key] === 'object') {
    //                 if (compareObject(objectA[key], objectB[key])) {
    //                     res = true;
    //                 }
    //             } else if (objectA && objectB && objectA[key] !== objectB[key]) {
    //                 
    //                 res = true;
    //             }
    //         })
    //         return res;
    //     }
    //
    //
    //     let self = this;
    //     let hasChanged = false;
    //     if (self.organisation && self.state.loadedOrg) {
    //         Object.entries(self.organisation).map(([key, value]) => {
    //             let updatedKey = key.replace(/^_+/i, '');
    //             if ((updatedKey in self.state) && self.state[updatedKey] !== value) {
    //                 if (typeof self.state[updatedKey] === 'object') {
    //                     
    //                     
    //                     if (compareObject(self.state[updatedKey], value)) {
    //                         hasChanged = true;
    //                     }
    //                 } else {
    //                     hasChanged = true;
    //                 }
    //             }
    //         })
    //     }
    //     return hasChanged;
    // }

    saveChanges() {
        let self = this;

        return this.organisation.set(this.state).then(function () {
            self.setOrgDetails();
        })
    }


    cancelChanges() {
        this.setOrgDetails();
    }

    imageUploaded(selectorFiles) {
        let self = this;
        let file = selectorFiles[0];
        let newFile = {
            itemType: 'organisation',
            itemId: this.state.orgId,
            name: file.name,
            type: file.type,
            size: file.size,
            file: file,
            ref: uuidv4(),
        };

        this.setState({uploadingImage: true, uploadProgress: 0, uploadComplete: false});

        GridDuck.createFile(newFile, function uploadProgress(progressEvt) {
            self.setState({uploadProgress: Math.round((progressEvt.loaded / newFile.size) * 100)});

        }).then(function () {
            self.setState({uploadComplete: true, uploadingImage: false})

        });
    }

    render() {
        let headerBarComp = this.hasChanged && (this.account && !window.$exampleAccount) ?
            <SaveBar onSaveClick={this.saveChanges} onCancelClick={this.cancelChanges}/> : ''

        let tabs = [
            {
                default: true,
                url: null,
                title: 'Members',
                component: OrganisationMembers
            }
        ];
        if (this.state.orgPermission === 'admin' || this.state.orgPermission === 'owner') {
            tabs.push(
                {
                    url: 'partners',
                    title: 'Partners',
                    component: OrganisationPartners
                }
            );
            tabs.push(
                {
                    url: 'invites',
                    title: 'Pending Invites',
                    component: OrganisationInvites
                }
            );
        }
        let address;
        let imageStyle = {};
        if (this.state.logoImage) {
            imageStyle = {
                backgroundImage: `url(${this.state.logoImage})`
            }
        }
        if (this.state.address) {
            address = (
                <div className={'input-row'}>
                    <input id={"image-upload"} accept={'image/*'} ref={input => this.imageUploadRef = input}
                           type={"file"}
                           disabled={this.state.orgPermission !== 'admin' && this.state.orgPermission !== 'owner'}
                           onChange={(e) => this.imageUploaded(e.target.files)} hidden/>
                    {(this.state.orgPermission === 'admin' || this.state.orgPermission === 'owner') || (this.state.address.address_line_1) ?
                        <div className={'smaller-input'}>
                            <Input name={'address_line_1'} onChange={this.onFormChangeAddress} placeholder={'GridDuck'}
                                   disabled={this.state.orgPermission !== 'admin' && this.state.orgPermission !== 'owner'}
                                   label={'Office Address'} value={this.state.address.address_line_1}/>
                        </div> : ''}
                    {(this.state.orgPermission === 'admin' || this.state.orgPermission === 'owner') || (this.state.address.address_line_2) ?
                        <div className={'smaller-input'}>
                            <Input name={'address_line_2'} onChange={this.onFormChangeAddress} nolabel='true'
                                   disabled={this.state.orgPermission !== 'admin' && this.state.orgPermission !== 'owner'}
                                   placeholder={'Duckworth Lane'} value={this.state.address.address_line_2}/>
                        </div> : ''}
                    {(this.state.orgPermission === 'admin' || this.state.orgPermission === 'owner') || (this.state.address.locality) ?
                        <div className={'smaller-input'}>
                            <Input name={'locality'} onChange={this.onFormChangeAddress} nolabel='true'
                                   disabled={this.state.orgPermission !== 'admin' && this.state.orgPermission !== 'owner'}
                                   placeholder={'London'}
                                   value={this.state.address.locality}/>
                        </div> : ''}
                    {(this.state.orgPermission === 'admin' || this.state.orgPermission === 'owner') || (this.state.address.postal_code) ?
                        <div className={'smaller-input'}>
                            <Input name={'postal_code'} onChange={this.onFormChangeAddress} nolabel='true'
                                   disabled={this.state.orgPermission !== 'admin' && this.state.orgPermission !== 'owner'}
                                   placeholder={'N19 4NF'}
                                   value={this.state.address.postal_code}/>
                        </div> : ''}

                </div>
            );
        }


        return (
            <TabbedPage dataLoaded={this.state.loaded} className={"organisation-page"} tabs={tabs}
                        item={this.organisation} {...this.props}/>
        )
    }
}

export default MainOrganisationPage;

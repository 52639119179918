import React from "react";
import Popover from '@material-ui/core/Popover';

class DetailsPopover extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null
        }
        // this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClick(e) {
        
        this.setState({anchorEl: e.currentTarget});
    }

    handleClose() {
        this.setState({anchorEl: null});
        if (this.props.onClose) this.props.onClose();
    }


    render() {
        let open = Boolean(this.state.anchorEl || this.props.anchorEl);
        const id = open ? 'simple-popover' : undefined;
        return (
            <Popover
                id={id}
                disablePortal
                open={open}
                anchorEl={this.state.anchorEl || this.props.anchorEl}
                onClose={() => this.handleClose()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                {this.props.details}
            </Popover>
        )
    }
}

export default DetailsPopover;

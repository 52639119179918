import React from "react";
import GridDuck from "gridduck";
import "./DemoBar.scss";
import {Button, GdCheckbox, Input} from "gd-react";
import Modal from 'react-modal';
import history from "../../meta/history";
import GenericLoader from "../GenericLoader";
import TermsOfSale from "../TermsOfSale";


class DemoBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            inDemo: false,
            page: 1,
            openIntroModal: false,
            address: {},
            account: {},
            billingEmail: '',
            acceptedTerms: false
        };

        this.errors = {};

        this.closeIntroModal = this.closeIntroModal.bind(this);
        this.checkBillingInfo = this.checkBillingInfo.bind(this);
        this.billingEmailChange = this.billingEmailChange.bind(this);
        this.saveAddressChanges = this.saveAddressChanges.bind(this);
        this.addressFieldChanged = this.addressFieldChanged.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.addBillingInfo = this.addBillingInfo.bind(this);
        this.acceptTerms = this.acceptTerms.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.moveForward = this.moveForward.bind(this);
    }

    onCheckboxChange(val) {
        this.setState({acceptedTerms: val.target.checked});
    }

    async acceptTerms() {
        let self = this;
        if (this.state.acceptedTerms) {
            return self.organisation.set({acceptedTerms: true}).then(function () {
                history.push('/siteGroup/all')
                self.closeIntroModal();
            })
        } else {
            return Promise.reject();
        }
    }

    saveAddressChanges() {
        let self = this;
        this.setState({triedToSave: true})
        if (Object.keys(this.errors).length > 0) return new Promise(function (resolve, reject) {
            return reject();
        }).catch(function () {
            return Promise.reject();
        });
        return self.account.set({
            billingEmail: self.state.billingEmail,
            orgAddress: {
                line1: self.state.address.line1,
                line2: self.state.address.line2,
                city: self.state.address.city,
                postal_code: self.state.address.postal_code,
                country: self.state.address.country
            }
        }).then(function () {
            self.moveForward();
        })
    }

    addressFieldChanged(val) {
        this.state.address[val.target.name] = val.target.value;
        this.state.edited = true;
        this.setState(this.state);
    }

    billingEmailChange(val) {
        this.setState({
            billingEmail: val.target.value,
            edited: true
        });
    }

    checkErrors() {
        let errors = {};
        if (!this.state.address.line1 || !this.state.address.line1.length) errors['line1'] = true;
        if (!this.state.address.city || !this.state.address.city.length) errors['city'] = true;
        if (!this.state.address.postal_code || !this.state.address.postal_code.length) errors['postal_code'] = true;
        if (!this.state.address.country || !this.state.address.country.length) errors['country'] = true;
        if (!this.state.billingEmail || !this.state.billingEmail.length) errors['billingEmail'] = true;
        this.errors = errors;
    }

    checkBillingInfo() {
        let self = this;
        GridDuck.getPaymentCards({getAll: true, filters: []}).then(function (res) {
            self.setState({cards: res.list, loaded: true});
            if (!res.list.length && !self.state.organisation.free) {
                
                self.setState({page: 2})
            } else {
                if ((self.state.organisation.address && self.state.organisation.address.line1 && self.state.organisation.address.postal_code) || self.isCustomer) {
                    if (self.state.organisation.acceptedTerms) {
                        self.setState({toursSeen: true, openIntroModal: false});
                    } else {
                        self.setState({page: 4});
                    }
                } else {
                    self.setState({page: 3})
                }
            }
        })
    }

    addBillingInfo() {
        GridDuck.createStripeSession({url: ''}).then(function (session) {
            window.location.replace(session.url);
        })
    }

    async componentDidMount() {
        let self = this;
        let page = 1;
        let openIM;
        self.onProductPage = (history.location.pathname.indexOf('order-hardware') !== -1);
        let user = await GridDuck.getAccount({id: GridDuck.userId});
        let userAdditionalInfo = await Promise.all([GridDuck.getOrganisation({id: user.organisationId}), GridDuck.getPaymentCards({getAll: true, filters: []}), GridDuck.getOrgPartners({getAll: true, filters: []})]);
        let organisation = userAdditionalInfo[0];
        let res = userAdditionalInfo[1];
        let orgPartners = userAdditionalInfo[2];
        let address = user.orgAddress || organisation.address || {};

        let isCustomer = orgPartners.list && orgPartners.list.length && orgPartners.list.find(op => op.permType === 'customer' && op.orgToId === organisation.id);

        self.isCustomer = isCustomer;
        self.account = user;
        self.organisation = organisation;
        // if (!user.god) {
        //     if (res.list.length) {
        //         window.plerdy_send_user_group = 1;
        //     } else {
        //         window.plerdy_send_user_group = 2;
        //     }
        // } else {
        //     window.plerdy_send_user_group = 3;
        // }
        //TMP FOR RESETTING
        // self.account.set({tour: {}}).then(()=>{});
        // self.organisation.set({tour: {}}).then(()=>{});
        //TMP FOR RESETTING

        if (self.account.tour && (self.account.tour.seen_welcome || self.account.tour.seenWelcome)) {
            if (!res.list.length && !organisation.free) {
                openIM = true;
                page = 2
            } else {

                if ((!address || (!address.line1 && !address.address_line_1) || !address.country) && !isCustomer) {
                    openIM = true;
                    page = 3
                } else {
                    if (!organisation.acceptedTerms) {
                        openIM = true;
                        page = 4;
                    } else {
                        openIM = false;
                    }
                }
            }
        } else {
            openIM = true;
        }

        if (user.noAssets && (!user.invoices || !user.invoices.data.length)) {
            self.unlisten = history.listen((location, action) => {

                self.onProductPage = (location.pathname.indexOf('order-hardware') !== -1);
                self.setState({inDemo: !self.onProductPage && (user.noAssets && (!user.invoices || !user.invoices.data.length))});
            });
        }
        if (self.account.god) {
            openIM = false;
        }

        self.setState({
            inDemo: !self.onProductPage && (user.noAssets && (!user.invoices || !user.invoices.data.length)),
            openIntroModal: openIM,
            account: user,
            billingEmail: user.billingEmail,
            organisation: organisation,
            page: page,
            address: address,
            acceptedTerms: organisation.acceptedTerms,
            loaded: true
        });
    }

    closeIntroModal() {
        //Update that they've clicked one of the buttons in the org db
        this.setState({openIntroModal: false})
    }

    moveForward(decision) {
        let self = this;

        let orgTour = this.state.organisation.tour || {};
        let new_state = {};
        let newTourObj = this.account.tour || {};
        return new Promise((resolve) => {
            if (this.state.page === 1) {
                newTourObj.seen_welcome = true;
                this.account.set({tour: newTourObj}).then(function () {
                    self.checkBillingInfo();
                    resolve();
                });
            } else if (this.state.page === 2) {
                orgTour.initial_decision = decision;
                this.organisation.set({tour: orgTour}).then(function () {
                    new_state.toursSeen = true;
                    new_state.openIntroModal = false;
                    self.setState(new_state);
                    resolve();
                });
            } else if (this.state.page === 3) {
                if (!self.state.organisation.acceptedTerms) {
                    self.setState({page: 4});
                    resolve();
                } else {
                    history.push('/siteGroup/all');
                    self.closeIntroModal();
                    resolve();
                }
            } else if (this.state.page === 4) {
                history.push('/siteGroup/all');
                self.closeIntroModal();
                resolve();
            }
        })
    }

    render() {
        this.checkErrors();
        if (this.state.account) {
            return (
                <div>
                    {this.state.loaded && !this.state.toursSeen ? <Modal isOpen={this.state.openIntroModal}
                                                                         onAfterOpen={() => {
                                                                         }}
                                                                         onRequestClose={() => {
                                                                         }}
                                                                         id={'DemoModal'}
                                                                         contentLabel="Example Modal">
                        <div className={'modal-content'}>
                            <div className={'outer-header'}>
                                <a onClick={() => GridDuck.logout()}>Logout</a>
                            </div>
                            <div className={'modal-header'}>
                                {this.state.page === 1 &&
                                    <p className={'welcome'}>Welcome <span className={'to-the'}>to the</span></p>}
                                {this.state.page === 1 && <p className={'gd-dash'}>GridDuck Dashboard</p>}
                                {this.state.page === 2 &&
                                    <p className={'welcome'}>GridDuck</p>}
                                {this.state.page === 2 && <p className={'gd-dash'}>Billing Information</p>}
                                {this.state.page === 3 && <p className={'welcome'}>GridDuck</p>}
                                {this.state.page === 3 &&
                                    <p className={'gd-dash'}>{this.state.account.organisationName} Details</p>}
                                {this.state.page === 4 && <p className={'welcome'}>GridDuck</p>}
                                {this.state.page === 4 && <p className={'gd-dash'}>Terms of Sale / Ts & Cs</p>}
                            </div>
                            {this.state.page === 1 &&
                                <video style={{borderRadius: '5px'}} id="dashboardVideo" autoPlay loop muted
                                       width={'100%'}>
                                    <source
                                        src={"https://s3-eu-west-1.amazonaws.com/gridduck.com/videos/monitoring/monitoring.mp4?v=2"}/>
                                    <source
                                        src={"https://s3-eu-west-1.amazonaws.com/gridduck.com/videos/monitoring/monitoring.ogv?v=2"}
                                        type={"video/ogg"}/>
                                    <source
                                        src={"https://s3-eu-west-1.amazonaws.com/gridduck.com/videos/monitoring/monitoring.webm?v=2"}
                                        type={"video/webm"}/>
                                </video>}
                            {this.state.page === 1 && <div className={'button-row'}>
                                {!this.state.account.org_tour &&
                                    <Button progressRes additionalclasses={'md'} label={'Continue'}
                                            onClick={this.moveForward}/>}
                                {this.state.account.org_tour && this.state.account.org_tour.initial_decision &&
                                    <Button progressRes additionalclasses={'md'} label={'Continue'}
                                            onClick={this.moveForward}/>}
                            </div>}

                            {this.state.page === 2 && <div className={'choose-wrapper'}>
                                <div className={'column'} style={{maxWidth: '600px'}}>
                                    <p style={{fontWeight: '700'}}>Before continuing you need to set up your billing
                                        information.</p>
                                    <p style={{marginBottom: '40px'}}>This is the information that will be used to
                                        pay
                                        for both hardware orders and the
                                        monthly software fees associated.<br/><br/>The payment types accepted are
                                        either
                                        Debit Card or Bacs Direct Debit.</p>
                                    <div className={'row'}
                                         style={{padding: '15px', paddingBottom: '30px', justifyContent: 'center'}}>
                                        <Button progressRes additionalclasses={'md'} label={'Add Payment Method'}
                                                onClick={this.addBillingInfo}/>
                                    </div>
                                </div>
                            </div>}

                            {this.state.page === 3 && <div className={'choose-wrapper'}>
                                {this.state.account ? <div className={'row'} style={{marginBottom: '5px'}}>
                                    <Input error={this.errors['billingEmail'] && this.state.triedToSave} required
                                           label={'Billing Email'} name={'billingEmail'}
                                           top="true"
                                           onChange={this.billingEmailChange}
                                           value={this.state.billingEmail}/>
                                </div> : null}
                                <div className={'divider'}/>
                                <div className={'row'}>
                                    <Input required error={this.errors['line1'] && this.state.triedToSave}
                                           top="true"
                                           label={'Address Line 1'} name={'line1'}
                                           onChange={this.addressFieldChanged}
                                           value={this.state.address.line1}/>
                                </div>
                                <div className={'row'}>
                                    <Input label={'Address Line 2'}
                                           name={'line2'} onChange={this.addressFieldChanged}
                                           value={this.state.address.line2}/>
                                </div>
                                <div className={'row'}>
                                    <Input required t error={this.errors['city'] && this.state.triedToSave}
                                           label={'City'}
                                           name={'city'}
                                           onChange={this.addressFieldChanged} value={this.state.address.city}/>
                                </div>
                                <div className={'row'}>
                                    <Input required error={this.errors['postal_code'] && this.state.triedToSave}
                                           label={'Post Code'}
                                           name={'postal_code'} onChange={this.addressFieldChanged}
                                           value={this.state.address.postal_code}/>
                                </div>
                                <div className={'row'}>
                                    <Input error={this.errors['country'] && this.state.triedToSave} required
                                           label={'Country'} name={'country'}
                                           onChange={this.addressFieldChanged}
                                           value={this.state.address.country}/>
                                </div>

                            </div>}

                            {this.state.page === 4 && <div className={'choose-wrapper'}>
                                <div className={'column'} style={{maxWidth: '600px'}}>
                                    <div className={'tos-wrapper'}>
                                        <TermsOfSale/>
                                    </div>
                                    <GdCheckbox checked={this.state.acceptTerms} name={'alertEmail'}
                                                onChange={this.onCheckboxChange} label={<span>I agree to the <a
                                        href={'https://www.gridduck.com/terms-of-sale'}
                                        target={'_blank'}>Terms of Sale</a> and <a
                                        href={'https://www.gridduck.com/terms-and-conditions'} target={'_blank'}>Terms and Conditions</a></span>}/>
                                    <div className={'row'}
                                         style={{
                                             padding: '15px',
                                             paddingBottom: '30px',
                                             justifyContent: 'center',
                                             marginTop: '20px'
                                         }}>
                                        <Button disabled={!this.state.acceptedTerms} progressRes
                                                additionalclasses={'md'} label={'Enter Dashboard'}
                                                onClick={this.acceptTerms}/>
                                    </div>
                                </div>
                            </div>}

                            {this.state.page === 3 && <div className={'button-row'}>
                                <Button label={'Next'} additionalclasses={'sm'}
                                        onClick={this.saveAddressChanges}
                                        progressRes/>
                            </div>}
                        </div>
                    </Modal> : null}
                    {/*{!this.state.openIntroModal && this.state.inDemo && <div className={"demo-bar"}>*/}
                    {/*    This is a demo account. <a className={'link'} href={'/order-hardware'}>Click Here</a> to get*/}
                    {/*    started for real.*/}
                    {/*    /!*<Button label={'Click Here'}/>*!/*/}
                    {/*</div>}*/}
                </div>
            )
        } else {
            return null;
        }
    }

}

export default DemoBar;
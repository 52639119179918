import {Dropdown, GdAutocomplete, GdCheckbox, Icon, Input, Toast, Tooltip} from "gd-react";
import React from "react";
import './TimeBar.scss'
import TimeBar from "./TimeBar";
import TimeBarSegment from "./TimeBarSegment";
import TariffTimePicker from "./TariffTimePicker";

class Rate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            rate: this.props.rate.rate || 0
        };
        this.isAllTheTime = this.isAllTheTime.bind(this);
    }

    componentDidMount() {

    }

    isAllTheTime() {
        return (this.props.rate.startHour === this.props.rate.endHour && this.props.rate.startMinute === this.props.rate.endMinute);
    }

    render() {
        let id = this.props.id;

        let allCheckBox = (
            <div className={'row ver-center'} style={{marginTop: '20px'}}>
                <div style={{
                    width: '5%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '5px'
                }}>
                    <GdCheckbox
                        checked={this.isAllTheTime()}
                        name={'All the time'}
                        disabled={this.props.disabled}
                        onChange={() => {
                            this.props.setAll()
                        }}
                    />
                </div>
                <p>All the time</p>
            </div>
        )

        return (
            <div style={{padding: '10px', marginTop: '10px'}}>
                <span className={'rate-title'}>Rate {this.props.index + 1}</span>
                <div className={'divider'}/>
                <TimeBar
                    {...this.props}
                    increments={48}
                />
                {this.props.index === 0 ? allCheckBox : null}
                <div className={'row'} style={{alignItems: 'flex-end'}}>
                    {!this.isAllTheTime() ? <div style={{maxWidth: '300px'}}>
                        <TariffTimePicker
                            label={'Start'}
                            disabled={this.props.disabled}
                            key={this.props.id + 'start'}
                            hourError={this.props.errors['startHour'] + this.props.id && this.props.triedToSave}
                            minuteError={this.props.errors['startMinute'] + this.props.id}
                            hour={this.props.rate.startHour}
                            minute={this.props.rate.startMinute}
                            onHourChange={(val) => this.props.handleTimeChange(val, 'startHour', this.props.rate)}
                            onMinuteChange={(val) => this.props.handleTimeChange(val, 'startMinute', this.props.rate)}
                            index={id}
                        />
                    </div> : null}
                    {!this.isAllTheTime() ? <div style={{maxWidth: '300px', paddingLeft: '20px'}}>
                        <TariffTimePicker
                            disabled={this.props.disabled}
                            label={'End'}
                            key={this.props.id + 'end'}
                            hourError={this.props.errors['endHour'] + this.props.id && this.props.triedToSave}
                            minuteError={this.props.errors['endMinute'] + this.props.id}
                            hour={this.props.rate.endHour}
                            minute={this.props.rate.endMinute}
                            onHourChange={(val) => this.props.handleTimeChange(val, 'endHour', this.props.rate)}
                            onMinuteChange={(val) => this.props.handleTimeChange(val, 'endMinute', this.props.rate)}
                            index={id}
                        />
                    </div> : null}
                    <div style={{maxWidth: '300px', paddingLeft: '10px', marginBottom: '14px'}}>
                        <Input
                            label={this.props.tariffType === 'spend' ? 'Cost per ' + this.props.unit.comparatorDataUnit + ' in ' + this.props.currency.lowestDenom + ' (exc. VAT)' : `grams of CO2 per ${this.props.unit.comparatorDataUnit}`}
                            error={this.props.errors['rate' + this.props.id] && this.props.triedToSave}
                            disabled={this.props.disabled}
                            type={'number'}
                            inputProps={{min: 0}}
                            onBlur={(e) => {
                                let value = e.target.value;
                                if (!value) value = 0;
                                this.setState({rate: value});
                                this.props.handleCostChange(this.props.rate, value);
                            }}
                            onChange={(e) => {
                                let value = e.target.value;
                                if (value < 0) value = 0;

                                this.setState({rate: value});
                                this.props.handleCostChange(this.props.rate, value);
                            }}
                            value={this.state.rate}/>
                    </div>
                </div>
            </div>

        )
    }
}

export default Rate;









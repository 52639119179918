import React from "react";
import {
    List, ProcessStateListCell, DataRequestDetailListCell, ButtonListCell, Button, StandardListCell
} from "gd-react";
import GridDuck from 'gridduck';
import './../dataDownload/DataArchive.scss'


class TelemetryOperationsLog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.getList = this.getList.bind(this);
    }

    getList(params) {
        return GridDuck.getTelemetryOperations(params);
    }


    render() {
        let self = this, columns;

        columns = [
            {component: StandardListCell, title: 'Type', field: 'operationType'},
            {component: StandardListCell, title: 'Status', field: 'status'},
            {
                component: StandardListCell, title: 'Start Time', field: 'startTs', custom: function (object) {
                    
                    return new Date(object.startTs).toLocaleString();
                }
            },
            {
                component: StandardListCell, title: 'End Time', field: 'endTs', custom: function (object) {
                    
                    return new Date(object.endTs).toLocaleString();
                }
            },
            {
                component: StandardListCell,
                title: 'Parameters',
                field: 'parameters',
                maxWidth: '800px',
                custom: function (object) {
                    return JSON.stringify(object.parameters);
                }
            },
        ]
        return (

            <div className={"page data-archive"}>
                <List
                    columns={columns}
                    getlist={this.getList}
                    filters={[]}/>
            </div>
        )
    }
}

export default TelemetryOperationsLog;
import React, {Component} from "react";
import "./AddEditCustomField.scss";
import '../../styles/_layout.scss';
import GridDuck from "gridduck";
import {Button, CardBody, Input, Tabs, Toast, Icon, Dropdown} from "gd-react";
import IconSearch from "../../components/IconSearch/IconSearch";
import GdModal from '../../components/GdModal/GdModal'
import GenericLoader from "../../components/GenericLoader";
import AddEditCustomFieldSideContent from "./AddEditCustomFieldSideContent";

class AddEditCustomFieldTypeModal extends Component {
    f_type = {
        number: 'valueNumber',
        text: 'valueText',
        // boolean: 'valueBoolean'
    };

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.moveToTab = this.moveToTab.bind(this);
        this.onTabClick1 = this.onTabClick1.bind(this);
        this.saveCustomFieldTypeChanges = this.saveCustomFieldTypeChanges.bind(this)
        this.deleteCustomFieldType = this.deleteCustomFieldType.bind(this);
        this.errors = {};
        this.changedCustomField = false;
        this.changedCustomFieldType = false;
        this.types = [
            {id: 1, title: 'Number', value: 'number'},
            {id: 0, title: 'Text', value: 'text'},
            // {id: 2, title: 'True / False', value: 'boolean'}
        ];
        this.perm_levels = ['edit', 'admin'];

        this.state = {
            customField: null,
            deleteConfirmation: '',
            prevValue: null,
            newValue: null,
            loaded: false,
            name: this.props.item ? this.props.item.name : '',
            icon: this.props.item ? this.props.item.icon : '',
            colour: this.props.item ? this.props.item.colour : '',
            fieldType: this.props.item ? this.props.item.fieldType : 'number',
            anchorEl: null,
            page1Tabs: [[
                {
                    id: 1,
                    title: 'Details',
                    onTabClick: this.onTabClick1,
                    selected: (this.props.openOnTab === 1 || !this.props.openOnTab)
                }
            ]]
        }

        if (this.props.item && this.props.item._permission !== 'view') {
            this.state.page1Tabs[0].push({
                id: 3,
                title: 'Delete',
                onTabClick: this.onTabClick1,
                selected: (this.props.openOnTab === 3)
            })
        }

        this.modalClosed = false;
    }

    async deleteCustomFieldType() {
        await this.props.item.delete();
        this.setState({showToast: 'Custom Field ' + this.props.item.name + ' Deleted'});
        if (this.props.onDelete) this.props.onDelete();
        this.closeModal();
        return Promise.resolve();
    }

    async saveCustomFieldTypeChanges() {
        
        let self = this;
        this.setState({triedToSave: true})
        if (Object.keys(this.errors).length > 0) return Promise.reject()
        if (!this.props.item) {
            let customFieldType = {
                name: this.state.name,
                icon: this.state.icon,
                colour: this.state.colour,
                fieldType: this.state.fieldType
            }
            
            let res = await GridDuck.createCustomFieldType(customFieldType);
            self.setState({changedCustomFieldType: false, saved: true, loadingNewField: true});
            
            if (self.props.loadNewItem) await self.props.loadNewItem(res.id);
            if (self.props.item && self.props.item._permission !== 'view') {
                this.state.page1Tabs[0].push({
                    id: 3,
                    title: 'Delete',
                    onTabClick: this.onTabClick1
                })
            }
            self.setState({loadingNewField: false});
            return Promise.resolve();
        } else {
            
            let obj = {
                name: this.state.name,
                icon: this.state.icon,
                colour: this.state.colour
            }
            
            return this.props.item.set(obj).then(function () {
                self.setState({changedCustomFieldType: false, saved: true});
            });
        }
    }

    checkErrors() {
        let errors = {};
        if (!this.state.name) errors['name'] = true;
        if (!this.state.icon) errors['icon'] = true;
        this.errors = errors;
    }

    onTabClick1(ev, tab) {
        this.state.page1Tabs.forEach(function (tabArr) {
            tabArr.forEach(function (t) {
                t.selected = (tab.id === t.id);
            })
        })
        this.setState(this.state);
    }

    closeModal(e) {
        this.modalClosed = true;
        this.props.onClose(e, this.state.saved);
    }

    moveToTab() {
        this.setState({triedToNext: true})
        if (Object.keys(this.errors).length > 0) return;
        this.onTabClick(null, {id: 2});
    }

    render() {
        let self = this;
        this.checkErrors()
        let viewOnly = (this.props.item && this.props.item._permission === 'view');

        let title;
        if (this.props.item) {
            title = this.props.item.name;
        } else {
            title = 'Create Custom Field';
        }
        let footer;
        if ((this.state.changedCustomFieldType || !this.props.item) && this.state.page1Tabs[0][0] && this.state.page1Tabs[0][0].selected) footer =
            <Button progressRes disabled={viewOnly}
                    additionalclasses={'sm'}
                    label={!this.props.item ? 'Create' : 'Save'}
                    color={'gd-green'}
                    onClick={this.saveCustomFieldTypeChanges}/>
        if (this.state.page1Tabs[0][1] && this.state.page1Tabs[0][1].selected) footer =
            <Button progressRes disabled={(this.state.deleteConfirmation !== 'DELETE') || viewOnly}
                    additionalclasses={'md'}
                    label={'Permanently Delete'} color={'gd-red'}
                    onClick={this.deleteCustomFieldType}/>


        let r = self.state.customField


        return (<GdModal
                title={title}
                open={this.props.open}
                contentLabel={'Edit Location'}
                footer={footer}
                sideContent={this.props.item ? <AddEditCustomFieldSideContent item={this.props.item}/> : null}
                onClose={this.closeModal}>

                {this.state.page1Tabs ? <Tabs tabData={this.state.page1Tabs}/> : null}

                {this.state.page1Tabs[0][0] && this.state.page1Tabs[0][0].selected && !this.state.loadingNewField ?
                    <CardBody>
                        <div
                            className={'Add-Edit-Custom-Field ' + (this.errors['icon'] && this.state.triedToSave ? 'error' : '')}>
                            {!this.props.item ? <p style={{
                                fontSize: '12px',
                                padding: '10px',
                                paddingTop: 0
                            }}>Custom Fields are used to add custom data to a site.<br/><br/>For example creating a
                                Number Type
                                field called Floor Area (m2) would then enable you to add this value to all of your
                                sites. Which you can then use on the data page to gather deeper insights.
                            </p> : null}
                            <div className={'row'} style={{marginBottom: '20px', alignItems: 'end'}}>
                                <div
                                    className={viewOnly ? 'chosen-icon-wrapper-view' : 'chosen-icon-wrapper ' + (this.errors['icon'] ? 'error' : '')}
                                    onClick={viewOnly ? null : (e) => this.setState({anchorEl: e.currentTarget})}>
                                    <Icon color={this.state.colour} icon={this.state.icon || 'IoMdAddCircleOutline'}
                                          size={this.state.icon ? '50' : '20'}/>
                                    {!this.state.icon ? <p>Choose Icon</p> : null}
                                    {this.state.icon ?
                                        <div className={'remove-icon'}>
                                            <Icon color={'gd-red'} icon={'MdCancel'}
                                                  onIconClick={viewOnly ? null : (e) => {
                                                      e.preventDefault();
                                                      e.stopPropagation();
                                                      this.setState({icon: null})
                                                  }}/>
                                        </div> : null}
                                </div>
                            </div>

                            {this.state.anchorEl ? <IconSearch
                                colour={this.state.colour}
                                onColourChange={(c) => this.setState({colour: c, changedCustomFieldType: true})}
                                onIconChange={(i) => this.setState({icon: i, changedCustomFieldType: true})}
                                onClose={() => this.setState({anchorEl: null})}
                                anchorEl={this.state.anchorEl}/> : null}
                            <div className={'row'} style={{marginBottom: '15px'}}>
                                <Input required error={this.errors['name'] && this.state.triedToSave} top='true'
                                       disabled={viewOnly}
                                       onChange={(e) => this.setState({
                                           name: e.target.value,
                                           changedCustomFieldType: true
                                       })}
                                       name={'name'} value={this.state.name} label={'Name'}/>
                            </div>
                            {this.state.fieldType === 'number' && !this.props.item ?
                                <p style={{
                                    fontSize: '12px',
                                    padding: '10px',
                                    paddingTop: 0
                                }}>E.g. Floor Area (m2)
                                </p> : null}

                            {this.state.fieldType === 'text' && !this.props.item ?
                                <p style={{
                                    fontSize: '12px',
                                    padding: '10px',
                                    paddingTop: 0
                                }}>E.g. Region
                                </p> : null}

                            {/*{this.state.fieldType === 'boolean' && !this.props.item ?*/}
                            {/*    <p style={{*/}
                            {/*        fontSize: '12px',*/}
                            {/*        padding: '10px',*/}
                            {/*        paddingTop: 0*/}
                            {/*    }}>E.g. Double Glazed</p> : null}*/}
                            <Dropdown
                                required
                                disabled={!!(self.props.item) || viewOnly}
                                style={{minWidth: '100px'}}
                                value={this.state.fieldType}
                                label={'Type'}
                                fixeditems={self.types}
                                onChange={(val) => this.setState({
                                    fieldType: val.target.value,
                                    changedCustomFieldType: true
                                })}/>
                            {this.state.fieldType === 'number' && !this.props.item ?
                                <p style={{
                                    fontSize: '12px',
                                    padding: '10px'
                                }}>The Number type is used to add numeric data to a site, used to break down usage.
                                </p> : null}

                            {this.state.fieldType === 'text' && !this.props.item ?
                                <p style={{
                                    fontSize: '12px',
                                    padding: '10px'
                                }}>The Text type is used to create categories for sites to be grouped
                                    together by.
                                </p> : null}

                            {/*{this.state.fieldType === 'boolean' && !this.props.item ?*/}
                            {/*    <p style={{*/}
                            {/*        fontSize: '12px',*/}
                            {/*        padding: '10px'*/}
                            {/*    }}>The True/False type is used to create two groups a site can fall into,*/}
                            {/*        True or False.</p> : null}*/}
                        </div>
                    </CardBody>
                    : ''}
                {this.state.loadingNewField ? <CardBody><GenericLoader/></CardBody> : null}
                {this.state.page1Tabs[0][1] && this.state.page1Tabs[0][1].selected ?
                    <CardBody>
                        <div className={'row'}>
                            <Input required error={this.errors['delete'] && this.state.triedToDelete} top='true'
                                   onChange={(e) => this.setState({
                                       deleteConfirmation: e.target.value,
                                       changedCustomFieldType: true
                                   })}
                                   placeholder={'DELETE'}
                                   name={'delete'} value={this.state.deleteConfirmation}
                                   label={'Enter DELETE (case sensitive) in the input and then click the button bellow'}/>
                        </div>
                    </CardBody>
                : ''}
                <Toast onClose={() => this.setState({saved: null})} message={'Saved Successfully'}
                       open={this.state.saved}
                       severity="success"
                       anchorOrigin={{
                           vertical: 'bottom',
                           horizontal: 'left',
                       }}/>
            </GdModal>
        )
    }

}

export default AddEditCustomFieldTypeModal;
import React from "react";
// import '../styles/_theme.scss'
import DataArchive from "./dataDownload/DataArchive";
import GridDuck from "gridduck";

class InvoiceListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    openTariffModal(tariff) {
        this.setState({showTariffModal: tariff})
    }

    componentDidMount() {
        let self = this;
        GridDuck.getAccount({id: GridDuck.userId})
            .then(function (user) {
                
                self.setState({hasEditPermission: !!(user.orgPermission === 'edit' || user.orgPermission === 'admin' || user.orgPermission === 'owner')});
            });
    }

    render() {
        return (
            <div className={'page white'}>
                <DataArchive hasEditPermission={this.state.hasEditPermission} invoices/>
            </div>
        )
    }
}

export default InvoiceListPage;
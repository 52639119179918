import React from "react";
import GridDuck from "gridduck";
import {
    Button,
    Card, EditableList, GdAutocomplete,
    Input, StandardListCell, CardBody, DateTimeRangePicker
} from "gd-react";

import {StateOptions} from "gd-react";

import Toggle from "../../components/Toggle/Toggle";
import {confirmAlert} from "react-confirm-alert";
import getFormat from "../../services/formatter";

class RemoveData extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.getIndividualList = this.getIndividualList.bind(this);
        this.getAvailableIndividualList = this.getAvailableIndividualList.bind(this);
        this.saveIndividualList = this.saveIndividualList.bind(this);
        this.removeDataUpdated = this.removeDataUpdated.bind(this);
        this.removeData = this.removeData.bind(this);
    }

    removeData() {
        let self = this;
        confirmAlert({
            customUI: ({onClose}) => {
                return (
                    <div className='dialog'>
                        <div className={"top-content"}>
                            <h2>Delete Data</h2>
                            <p>Are you sure you want to
                                delete data
                                between {getFormat('date')(this.state.removeDateStart)} and {getFormat('date')(this.state.removeDataEnd)}?</p>
                        </div>
                        <div className={"bottom-menu"}>
                            <Button label={"No"} color={'gd-grey'} onClick={onClose}/>
                            <Button color={'gd-red'}
                                    onClick={async function () {
                                        try {
                                            let assetIds = self.state.selectedAssets.map(a => a.id).join(',');
                                            await GridDuck.createAssetRemoveDataRequest({
                                                assetId: assetIds,
                                                start: self.state.removeDateStart,
                                                end: self.state.removeDataEnd
                                            });
                                            
                                            onClose();
                                        } catch (e) {
                                            
                                            onClose();
                                        }
                                    }}
                                    label={"Yes, delete"}
                            />
                        </div>
                    </div>
                );
            }
        });
    }

    getIndividualList(retrievalParams) {
        let activeList = this.props.ddState && this.props.ddState.selectedAssets ? this.props.ddState.selectedAssets : [];
        
        
        return Promise.resolve({list: [], total: 0});
    }

    getAvailableIndividualList(retrievalParams) {
        return GridDuck.getAssets(retrievalParams);
    }

    removeDataUpdated(dates) {
        let startUnix = new Date(dates.start).getTime() / 1000;
        let endUnix = new Date(dates.end).getTime() / 1000;
        this.setState({
            removeDateStart: startUnix,
            removeDataEnd: endUnix
        });
    }

    saveIndividualList(list) {
        
        this.setState({
            selectedAssets: list,
            isEditing: false
        }, () => {
            if (this.props.inPage) this.props.onUpdate(list, this.state, this.filteredAssetList)
        });
        return Promise.resolve();
    }

    render() {
        let self = this;
        return (
            <div className='page narrow Account not-flex grey'>
                <div className={'column top'}>
                    <div className={'detail-content'}>
                        <Card>
                            <div className={'row'} style={{marginTop: '20px', maxHeight: '350px'}}>
                                <EditableList
                                    isEditing={true}
                                    noCancel={true}
                                    noSave={true}
                                    columns={[
                                        {
                                            component: StandardListCell,
                                            bold: true,
                                            sortable: true,
                                            title: 'Name',
                                            field: 'name'
                                        },
                                        {
                                            component: StandardListCell,
                                            sortable: false,
                                            title: 'Site Name',
                                            field: 'siteName'
                                        },
                                    ]}
                                    editingColumns={[
                                        {component: StandardListCell, title: 'Site Name', field: 'siteName'},
                                    ]}
                                    getActiveList={this.getIndividualList}
                                    getAvailableList={this.getAvailableIndividualList}
                                    onChange={this.saveIndividualList}
                                />
                            </div>
                            <CardBody>
                                <DateTimeRangePicker nolabel='true' onChange={this.removeDataUpdated}/>
                                <br/>
                                {this.state.selectedAssets && this.state.selectedAssets.length > 0 && this.state.removeDataEnd && this.state.removeDateStart ? (
                                    <Button additionalclasses={'sm'} onClick={self.removeData} color={'gd-red'}
                                            label={'Remove Data'}/>) : ''}
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

export default RemoveData;
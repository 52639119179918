import React from "react";
import {Button, Dropdown, EditableList, Input, StandardListCell} from "gd-react";
import Modal from "react-modal";
import GridDuck from "gridduck";
import GetNames from "../../services/GetNames";

class RuleAction extends React.Component {
    constructor(props) {
        super(props);

        this.updateViaProps = this.updateViaProps.bind(this);
        this.getActiveList = this.getActiveList.bind(this);
        this.getAvailableList = this.getAvailableList.bind(this);
        this.setAssets = this.setAssets.bind(this);
        this.closeAssetsModal = this.closeAssetsModal.bind(this);

        this.updateState = this.updateState.bind(this);

        this.state = this.updateState();
    }

    updateState() {
        return {
            name: this.props.action ? this.props.action.name : null,
            type: this.props.action.type ? this.props.action.type : 'state',
            assetNames: this.props.action.assetNames ? this.props.action.assetNames : 'Select devices',
            assets: this.props.action.assets ? this.props.action.assets : [],
            recipientNames: this.props.action.recipientNames ? this.props.action.recipientNames : 'Select recipients',
            recipients: this.props.action.recipients ? this.props.action.recipients : [],
            stateType: this.props.action.stateType ? this.props.action.stateType : 'ON',
            duration: this.props.action.duration ? this.props.action.duration : 3600,
            nextAction: this.props.action.nextAction,
        };
    }

    updateViaProps() {
        let dataCondition = {
            name: this.state.name,
            type: this.state.type,
            color: this.props.action.color,
            id: this.props.action.id,
            stateType: this.state.stateType,
            isAlert: true,
            assets: this.state.assets,
            assetNames: this.state.assetNames,
            recipients: this.state.recipients,
            nextAction: this.state.nextAction,
            recipientNames: this.state.recipientNames,
            duration: this.state.duration,
            creating: this.props.action.creating,
        };
        if (this.props.onChange) {
            this.props.onChange(dataCondition);
        }
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.action.id !== prevProps.action.id) {
            this.setState(this.updateState());
        }
    }

    closeAssetsModal() {
        this.setState({selectingAssets: false, selectingRecipients: false});
    }

    getAvailableList(params) {
        if (this.state.selectingRecipients) {
            return GridDuck.getOrganisationMembers(params);
        } else {
            return GridDuck.getAssets(params)
        }
    }

    getActiveList(props) {
        if (this.state.selectingRecipients) {
            let self = this;
            return GridDuck.getOrganisationMembers({}).then(function (memberList) {
                let actualList = memberList.list.filter(m => self.state.recipients && self.state.recipients.indexOf(m.username) !== -1);
                return Promise.resolve({list: actualList, total: actualList.length})
            })
        } else {
            let assets = this.props.action.assets || [];
            let promises = assets.map(a => GridDuck.getAsset({id: a}))
            return Promise.all(promises).then(function (results) {
                return Promise.resolve({list: results, total: results.length});
            })
        }
    }

    async setAssets(newItems, itemsToAddRemove) {
        
        if (this.state.selectingRecipients) {
            let newItemIds = this.state.recipients.filter(itemId => !itemsToAddRemove.itemsToRemove.find(i => i.id === itemId)).concat(itemsToAddRemove.itemsToAdd.map(i => i.id));
            
            
            let names = await GetNames('getAsset', newItemIds);
            this.setState({
                recipients: newItemIds,
                recipientNames: names.join(", ")
            }, this.updateViaProps);
            return Promise.resolve();
        } else {
            let newItemIds = this.state.assets.filter(itemId => !itemsToAddRemove.itemsToRemove.find(i => i.id === itemId)).concat(itemsToAddRemove.itemsToAdd.map(i => i.id));
            
            let names = await GetNames('getAsset', newItemIds);
            this.setState({
                assets: newItemIds,
                assetNames: names.join(", ")
            }, this.updateViaProps);
            return Promise.resolve();
        }
    }

    render() {
        let self = this;
        let stateTypes = [
            {
                value: 'ON',
                title: 'On'
            },
            {
                value: 'OFF',
                title: 'Off',
            }
        ]
        let actions = this.props.actions.map((a, id) => ({value: a.id, title: a.name}));
        actions.push({
            value: undefined,
            title: 'No action'
        });
        return (
            <div className={'details-inner'}>
                <Modal
                    isOpen={this.state.selectingAssets || this.state.selectingRecipients}
                    onRequestClose={this.closeAssetsModal}
                    contentLabel={this.state.selectingRecipients ? "Select Devices" : "Select Recipients"}>
                    <EditableList
                        isEditing={true}
                        key={1}
                        noCancel={false}
                        onCancelOnlyCallback={this.closeAssetsModal}
                        editingColumns={
                            this.state.selectingRecipients ? [] : [{
                                component: StandardListCell,
                                title: 'Site Name',
                                field: 'siteName'
                            }]}
                        availableFilters={[]}    //TODO: filter by stateType
                        getActiveList={this.getActiveList}
                        getAvailableList={this.getAvailableList}
                        save={this.setAssets}
                    />
                </Modal>
                <div className={'condition-row'}>
                    <Input label={'Name'}
                           value={this.state.name}
                           onChange={function (e) {
                               self.setState({name: e.target.value}, self.updateViaProps);
                           }}/>
                </div>
                <div className={'condition-row'}>
                    <Dropdown label={'Action type'}
                              onChange={(e) => this.setState({type: e.target.value}, this.updateViaProps)}
                              value={this.state.type}
                              fixeditems={[
                                  {
                                      value: 'state',
                                      title: 'State'
                                  },
                                  {
                                      value: 'boost',
                                      title: 'Boost'
                                  },
                                  {
                                      value: 'snooze',
                                      title: 'Snooze'
                                  },
                                  {
                                      value: 'email',
                                      title: 'Email Notification'
                                  },
                                  {
                                      value: 'end_rule',
                                      title: 'End Rule'
                                  }
                              ]}/>
                </div>
                {this.state.type === 'state' ? (<div className={'condition-row'}>
                    <Dropdown label={'Device state'}
                              onChange={(e) => self.setState({stateType: e.target.value}, self.updateViaProps)}
                              fixeditems={stateTypes}
                              value={this.state.stateType}/>
                </div>) : ''}
                {this.state.type === 'state' || this.state.type === 'boost' || this.state.type === 'snooze' ? (
                    <div className={'condition-row assets'}>
                        <Button label={this.state.assetNames} additionalclasses={'fake-input'} nonBold={true}
                                color={"gd-grey"} disabled={!this.state.stateType}
                                onClick={() => this.setState({selectingAssets: true})}/>
                    </div>) : this.state.type === 'email' ? (
                    <div className={'condition-row assets recip'}><Button label={this.state.recipientNames}
                                                                          additionalclasses={'fake-input'}
                                                                          nonBold={true} color={"gd-grey"}
                                                                          onClick={() => this.setState({selectingRecipients: true})}/>
                    </div>) : ''}
                {this.state.type === 'boost' || this.state.type === 'snooze' ? (<div className={'condition-row'}>
                    <Input label={'Duration (s)'}
                           value={this.state.duration}
                           onChange={function (e) {
                               self.setState({duration: e.target.value}, self.updateViaProps);
                           }}/>
                </div>) : ''}
                <div className={'condition-row'}>
                    <Dropdown
                        label={'Next action'}
                        onChange={(e) => self.setState({nextAction: e.target.value}, self.updateViaProps)}
                        value={this.state.nextAction}
                        fixeditems={actions}/>
                </div>
            </div>
        );
    }

}

export default RuleAction;

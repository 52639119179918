import React, {Component} from "react";
import '../../styles/_layout.scss';
import './ModbusModelModal.scss'
import {
    Button,
    CardBody,
    Dropdown,
    Input, StateOptions,
    Tabs
} from "gd-react";
import GridDuck from "gridduck";
import Toggle from "../../components/Toggle/Toggle";
import GdModal from "../../components/GdModal/GdModal";
import {v4 as uuidv4} from "uuid";
import DeleteModal from "../Delete/DeleteModal";

const parityList = [
    {
        id: 1,
        title: 'None',
        value: 'none'
    }, {
        id: 2,
        title: 'Odd',
        value: 'odd'
    }, {
        id: 3,
        title: 'Even',
        value: 'even'
    }, {
        id: 4,
        title: 'Mark',
        value: 'mark'
    }, {
        id: 5,
        title: 'Space',
        value: 'space'
    }
]
const registerDataTypes = [
    {
        id: 1,
        title: 'INT16',
        value: 'INT16'
    },
    {
        id: 2,
        title: 'UNIT16',
        value: 'UNIT16'
    },
    {
        id: 4,
        title: 'INT32',
        value: 'INT32'
    },
    {
        id: 5,
        title: 'UNIT32',
        value: 'UNIT32'
    },
    {
        id: 6,
        title: 'INT64',
        value: 'INT64'
    },
    {
        id: 7,
        title: 'UNIT64',
        value: 'UNIT64'
    },
    {
        id: 8,
        title: 'FLOAT',
        value: 'FLOAT32'
    },
    {
        id: 9,
        title: 'ASCII',
        value: 'ASCII'
    },
    {
        id: 10,
        title: 'BITMAP',
        value: 'BITMAP'
    },
    {
        id: 11,
        title: 'DATETIME',
        value: 'DATETIME'
    },
    {
        id: 12,
        title: 'FLOAT_REVERSE_WORD',
        value: 'FLOAT_REVERSE_WORD'
    },
]

const registerTypes = [
    {
        id: 1,
        title: 'Read',
        value: 'read'
    },
    {
        id: 2,
        title: 'Write (Coming Soon)',
        value: 'write',
        disabled: true
    }
]

let s_o = StateOptions;
let gridDuckDataTypes = [];

s_o.forEach((so) => {

    so.dataTypes.forEach((dt) => {
        if (!gridDuckDataTypes.find(_dt => _dt.value === dt.type) && !dt.downloadOnly) {
            gridDuckDataTypes.push({
                id: uuidv4(),
                title: (dt.label || dt.type) + ' - ' + dt.unit + ' (' + dt.type + ')',
                value: dt.type
            })
        }
    });
});

const templateRegister = {
    gdDataType: null,
    registerType: 'read',
    modbusDataType: 'INT16',
    addressStart: 0,
    weight: 1,
    length: 6
};

class ModbusModelModal extends Component {

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.onTabClick = this.onTabClick.bind(this);
        this.getActiveList = this.getActiveList.bind(this);
        this.getAvailableList = this.getAvailableList.bind(this);
        this.moveToTab = this.moveToTab.bind(this);
        this.recipientsChanged = this.recipientsChanged.bind(this);
        this.deleteModbusModel = this.deleteModbusModel.bind(this);
        this.onRegisterChange = this.onRegisterChange.bind(this);
        this.addRegister = this.addRegister.bind(this);
        this.removeRegister = this.removeRegister.bind(this);
        this.errors = {};
        this.changed = false;
        const item = this.props.item;
        this.state = {
            wide: false,
            deleteConfirmation: '',
            update: 1,
            modbusModel: {
                displayName: item ? item.displayName : '',
                modbusDeviceType: item ? item.modbusDeviceType : 'meter',
                modbusManufacturerName: item ? item.modbusManufacturerName : '',
                baudRate: item ? item.baudRate : 9600,
                parity: item ? item.parity : 'none',
                stopBits: item ? item.stopBits : 2,
                dataBits: item ? item.dataBits : 8,
                rxTimeout: item ? item.rxTimeout : 10,
                registers: item ? item.registers : [],
                global: item ? !item.organisationId : false
            },
            tabs: [[
                {
                    id: 1,
                    title: 'General',
                    selected: (this.props.openOnTab === 1 || !this.props.openOnTab),
                    onTabClick: this.onTabClick

                },
                {
                    id: 2,
                    title: 'Registers',
                    onTabClick: this.onTabClick,
                    selected: (this.props.openOnTab === 2)
                }
            ]]
        }
        if (this.props.item && this.props.item._permission !== 'view') {
            this.state.tabs[0][2] = {
                id: 3,
                title: 'Delete',
                onTabClick: this.onTabClick,
                selected: (this.props.openOnTab === 3)
            }
        }

        this.modalClosed = false;
    }

    componentDidMount() {
        this.checkErrors();
        if (!this.props.item) {
            this.addRegister();
        }
    }

    deleteModbusModel() {
        let self = this;
        return this.props.item.delete().then(function () {
            if (self.props.onDelete) self.props.onDelete();
            self.closeModal();
        })
    }

    onTabClick(ev, tab) {
        this.state.tabs.forEach(function (tabArr) {
            tabArr.forEach(function (t) {
                t.selected = (tab.id === t.id);
            })
        })
        this.setState(this.state);
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    onFormChange(val) {
        this.state.modbusModel[val.target.name] = val.target.value;
        this.setState({modbusModel: this.state.modbusModel, hasChanged: true});
    }

    async saveChanges() {
        let self = this;
        let modbusModel = this.state.modbusModel;

        // return;

        if (Object.keys(this.errors).length > 0) return this.setState({update: this.state.update++})
        if (this.props.item) await this.props.item.set(modbusModel);
        else await GridDuck.createModbusModel(modbusModel);
        if (self.props.onSave) self.props.onSave();
        self.closeModal();
    }

    checkErrors() {
        let errors = {};
        let modbusModel = this.state.modbusModel;
        if (!modbusModel) return errors;
        if (!modbusModel.displayName.length) errors['displayName'] = true;

        this.errors = errors;
    }

    onDeleteChange(val) {
        this.setState({deleteConfirmation: val.target.value});
    }

    recipientsChanged(newRecipientList) {
        let self = this;
        let modbusModel = this.state.modbusModel;
        modbusModel.recipients = newRecipientList.map(a => a.username);
        this.setState(prevState => ({
            modbusModel: modbusModel
        }));
    }

    getActiveList() {
        let self = this;
        return GridDuck.getOrganisationMembers({}).then(function (memberList) {
            let actualList = memberList.list.filter(m => self.props.item.recipients && self.props.item.recipients.indexOf(m.username) !== -1);
            return Promise.resolve({list: actualList, total: actualList.length})
        })
    }

    getAvailableList(params) {
        return GridDuck.getOrganisationMembers(params);
    }

    moveToTab() {
        this.setState({triedToNext: true})
        if (Object.keys(this.errors).length > 0) return;
        this.onTabClick(null, {id: 2});
    }

    onRegisterChange(data) {


        data.register[data.name] = data.value;

        this.setState({update: this.state.update++})
    }

    addRegister() {

        let newRegister = Object.assign({}, templateRegister);
        newRegister.id = uuidv4();

        this.state.modbusModel.registers.push(newRegister);
        this.setState({update: this.state.update++});
    }

    removeRegister(index) {
        this.state.modbusModel.registers.splice(index, 1);
        this.setState({update: this.state.update++});
    }

    render() {
        this.checkErrors();

        // if (this.state.tabs[0][0].selected) footer = <Button label={'Next'} onClick={() => this.moveToTab(1)}/>
        let footer;
        if (!this.props.item || this.props.item._permission !== 'view') {
            footer = <Button progressRes disabled={Object.keys(this.errors).length > 0} additionalclasses={'sm'}
                             label={this.props.item ? 'Save' : 'Create'}
                             color={'gd-green'}
                             onClick={this.saveChanges}/>
        }



        return (
            <GdModal
                title={(!this.props.item ? 'Create ' : '') + this.state.modbusModel.displayName + ' Modbus Model'}
                open={this.props.open}
                contentLabel={'Edit Modbus Model'}
                footer={footer}
                onClose={this.closeModal}>
                {this.state.tabs ? <Tabs tabData={this.state.tabs}/> : null}
                {this.state.tabs[0][0].selected ?
                    <CardBody>
                        <div>
                            <div className={'row'} style={{marginBottom: '15px'}}>
                                {this.props.isGod ? <Toggle
                                    onClick={(val) => this.onFormChange({
                                        target: {
                                            name: 'global',
                                            value: val === 'true'
                                        }
                                    })}
                                    inactivestatename={'Global'} activestatename={'Global'}
                                    active={this.state.modbusModel.global.toString()}/> : null}
                            </div>
                            <div className={'row'}>
                                <Input disabled={this.props.item && this.props.item._permission === 'view'} required
                                       error={this.errors['displayName'] && this.state.triedToSave} top='true'
                                       onChange={this.onFormChange}
                                       name={'displayName'} value={this.state.modbusModel.displayName}
                                       label={'Model Name'}/>
                            </div>
                            <div className={'row'} style={{marginTop: '15px'}}>
                                <Dropdown disabled={this.props.item && this.props.item._permission === 'view'}
                                          style={{minWidth: '100px'}}
                                          required
                                          value={this.state.modbusModel.modbusDeviceType}
                                          label={'Device Type'} fixeditems={[{
                                    id: 1,
                                    title: 'Meter',
                                    value: 'meter'
                                }]}
                                          onChange={(val) => this.onFormChange({
                                              target: {
                                                  name: 'modbusDeviceType',
                                                  value: val.target.value
                                              }
                                          })}/>
                            </div>
                            <div className={'row'}>
                                <Input disabled={this.props.item && this.props.item._permission === 'view'} required
                                       error={this.errors['modbusManufacturerName'] && this.state.triedToSave}
                                       onChange={this.onFormChange}
                                       name={'modbusManufacturerName'}
                                       value={this.state.modbusModel.modbusManufacturerName}
                                       label={'Manufacturer Name'}/>
                            </div>
                            <div className={'row'}>
                                <Input disabled={this.props.item && this.props.item._permission === 'view'} required
                                       error={this.errors['baudRate'] && this.state.triedToSave}
                                       onChange={this.onFormChange}
                                       type={'number'}
                                       InputProps={{step: 1}}
                                       name={'baudRate'} value={this.state.modbusModel.baudRate} label={'Baud Rate'}/>
                            </div>
                            <div className={'row'} style={{marginTop: '15px'}}>
                                <Dropdown disabled={this.props.item && this.props.item._permission === 'view'}
                                          style={{minWidth: '100px'}}
                                          required value={this.state.modbusModel.parity}
                                          label={'Parity'} fixeditems={parityList}
                                          onChange={(val) => this.onFormChange({
                                              target: {
                                                  name: 'parity',
                                                  value: val.target.value
                                              }
                                          })}/>
                            </div>
                            <div className={'row'}>
                                <Input disabled={this.props.item && this.props.item._permission === 'view'} required
                                       error={this.errors['stopBits'] && this.state.triedToSave}
                                       onChange={this.onFormChange}
                                       type={'number'}
                                       InputProps={{step: 1}}
                                       name={'stopBits'} value={this.state.modbusModel.stopBits} label={'Stop Bits'}/>
                            </div>
                            <div className={'row'}>
                                <Input disabled={this.props.item && this.props.item._permission === 'view'} required
                                       error={this.errors['dataBits'] && this.state.triedToSave}
                                       onChange={this.onFormChange}
                                       type={'number'}
                                       InputProps={{step: 1}}
                                       name={'dataBits'} value={this.state.modbusModel.dataBits} label={'Data Bits'}/>
                            </div>
                            <div className={'row'}>
                                <Input disabled={this.props.item && this.props.item._permission === 'view'} required
                                       error={this.errors['rxTimeout'] && this.state.triedToSave}
                                       onChange={this.onFormChange}
                                       type={'number'}
                                       InputProps={{step: 1}}
                                       name={'rxTimeout'} value={this.state.modbusModel.rxTimeout}
                                       label={'RX Timeout'}/>
                            </div>
                        </div>
                    </CardBody> : ''}
                {this.state.tabs[0][1].selected ?
                    <CardBody>
                        {this.state.modbusModel.registers.map((register, index) => {
                            return <div className={'register'} key={register.id}>
                                <div className={'row'}>
                                    <Dropdown disabled={this.props.item && this.props.item._permission === 'view'}
                                              style={{minWidth: '100px'}} required value={register.gdDataType}
                                              label={'Sensor Data Type'} fixeditems={gridDuckDataTypes}
                                              onChange={(val) => this.onRegisterChange({
                                                  name: 'gdDataType',
                                                  value: val.target.value,
                                                  register: register
                                              })}/>
                                </div>
                                <div className={'row'} style={{marginTop: '15px'}}>
                                    <Dropdown disabled={this.props.item && this.props.item._permission === 'view'}
                                              style={{minWidth: '100px'}} required value={register.registerType}
                                              label={'Register Type'} fixeditems={registerTypes}
                                              onChange={(val) => this.onRegisterChange({
                                                  name: 'registerType',
                                                  value: val.target.value,
                                                  register: register
                                              })}/>
                                </div>
                                <div className={'row'} style={{marginTop: '15px'}}>
                                    <Dropdown disabled={this.props.item && this.props.item._permission === 'view'}
                                              style={{minWidth: '100px'}} required value={register.modbusDataType}
                                              label={'Data Type'} fixeditems={registerDataTypes}
                                              onChange={(val) => this.onRegisterChange({
                                                  name: 'modbusDataType',
                                                  value: val.target.value,
                                                  register: register
                                              })}/>
                                </div>

                                <div className={'row'} style={{marginTop: '15px'}}>
                                    <Input disabled={this.props.item && this.props.item._permission === 'view'} required
                                           onChange={(val) => this.onRegisterChange({
                                               name: 'weight',
                                               value: val.target.value,
                                               register: register
                                           })}
                                           type={'number'}
                                           name={'weight'} value={register.weight} label={'Weight'}/>
                                </div>

                                <div className={'row'}>
                                    <Input required
                                           disabled={this.props.item && this.props.item._permission === 'view'}
                                           onChange={(val) => this.onRegisterChange({
                                               name: 'addressStart',
                                               value: val.target.value,
                                               register: register
                                           })}
                                           type={'number'}
                                           InputProps={{step: 1}}
                                           name={'addressStart'} value={register.addressStart}
                                           label={'Address Start'}/>
                                </div>

                                <div className={'row'}>
                                    <Input required
                                           disabled={this.props.item && this.props.item._permission === 'view'}
                                           onChange={(val) => this.onRegisterChange({
                                               name: 'length',
                                               value: val.target.value,
                                               register: register
                                           })}
                                           type={'number'}
                                           InputProps={{step: 1}}
                                           name={'length'} value={register.length} label={'Length'}/>
                                </div>
                                <div className={'row reverse'} style={{marginTop: '15px'}}>
                                    {this.state.modbusModel.registers.length > 1 && (!this.props.item || this.props.item._permission !== 'view') ?
                                        <Button color={'gd-red'} label={'Remove'}
                                                onClick={() => this.removeRegister(index)}/> : null}
                                </div>
                            </div>
                        })}
                        {!this.props.item || this.props.item._permission !== 'view' ?
                            <div className={'row reverse'}>
                                <Button color={'gd-green'} label={'+ Add Another Register'} onClick={this.addRegister}/>
                            </div> : null}
                    </CardBody>
                    : ''}
                {this.state.tabs[0][2] && this.state.tabs[0][2].selected ? <CardBody>
                    <DeleteModal itemType={'modbusModel'}
                                 item={this.props.item}
                                 open={this.state.tabs[0][2].selected}
                                 deleteRes={this.closeModal}
                                 onClose={(e) => {
                                     this.onTabClick(e, {id: 1})
                                 }}/>
                </CardBody> : null}
            </GdModal>
        )
    }

}

export default ModbusModelModal;

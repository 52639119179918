import React from "react";
import {Tabs, Button, Icon, Input, List, StandardListCell} from "gd-react";
import './Disconnected.scss'
import NoItems from "../noItems";
import GridDuck from "gridduck";
import ListPopover from "../ListPopover/ListPopover";
import TariffForm from "../TariffForm";
import GenericLoader from "../GenericLoader";
import moment from "moment";
import history from "../../meta/history";

class DisconnectedPopover extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
        }
        this.getFakeRecentsList = this.getFakeRecentsList.bind(this)
        this.getFakeCurrentList = this.getFakeCurrentList.bind(this)
        this.handleClose = this.handleClose.bind(this);
        this.onTabClick = this.onTabClick.bind(this)
        this.navigateToClickedItem = this.navigateToClickedItem.bind(this)
        this.tabs = [[
            {
                id: 1,
                title: 'Disconnected Devices',
                onTabClick: this.onTabClick,
                selected: (this.props.openOnTab === 1 || !this.props.openOnTab)
            }
            // {
            //     id: 2,
            //     title: 'Disconnection Events',
            //     onTabClick: this.onTabClick,
            //     selected: (this.props.openOnTab === 2)
            // }
        ]]
        
    }

    async getFakeRecentsList() {
        let list = {
            list: [
                {
                    id: 0,
                    name: 'Kitchen 3 Gas Meter',
                    subtitle: 'Device',
                    date: '11-Jul-2023 11:19:09 (an hour ago)',
                    icon: {
                        name: 'FaWifi',
                        color: 'orange'
                    }
                },
                {
                    id: 1,
                    name: 'Kitchen 3 Gas Meter',
                    subtitle: 'Device',
                    date: '11-Jul-2023 11:19:09 (an hour ago)',
                    icon: {
                        name: 'FaWifi',
                        color: 'orange'
                    }
                },
                {
                    id: 2,
                    name: 'Rear Pod Hub',
                    subtitle: 'Hub',
                    date: '11-Jul-2023 11:19:09 (an hour ago)',
                    icon: {
                        name: 'FaWifi',
                        color: 'orange'
                    }
                },
                {
                    id: 3,
                    name: 'Smart Plug 2',
                    subtitle: 'Device',
                    date: '11-Jul-2023 11:19:09 (an hour ago)',
                    icon: {
                        name: 'FaEthernet',
                        color: 'orange'
                    }
                },
                {
                    id: 4,
                    name: 'Smart Plug 4',
                    subtitle: 'Device',
                    date: '11-Jul-2023 11:19:09 (an hour ago)',
                    icon: {
                        name: 'FaExclamationCircle',
                        color: 'orange'
                    }
                },
                {
                    id: 5,
                    name: 'Smart Plug 2',
                    subtitle: 'Device',
                    date: '11-Jul-2023 11:19:09 (an hour ago)',
                    icon: {
                        name: 'FaEthernet',
                        color: 'orange'
                    }
                },
                {
                    id: 6,
                    name: 'Smart Plug 4',
                    subtitle: 'Device',
                    date: '11-Jul-2023 11:19:09 (an hour ago)',
                    icon: {
                        name: 'FaExclamationCircle',
                        color: 'orange'
                    }
                },
                {
                    id: 7,
                    name: 'Smart Plug 2',
                    subtitle: 'Device',
                    date: '11-Jul-2023 11:19:09 (an hour ago)',
                    icon: {
                        name: 'FaEthernet',
                        color: 'orange'
                    }
                },
                {
                    id: 8,
                    name: 'Smart Plug 4',
                    subtitle: 'Device',
                    date: '11-Jul-2023 11:19:09 (an hour ago)',
                    icon: {
                        name: 'FaExclamationCircle',
                        color: 'orange'
                    }
                },


            ]
        }

        return Promise.resolve(list)
    }

    async getFakeCurrentList(e) {
        
        // 
        // 
        // .filter((incident, i) => i >= e.offset && i < (e.offset + e.items))
        let list = {
            list: this.props.incidents,
            total: this.props.incidents.length
        }

        return Promise.resolve(list)
    }

    navigateToClickedItem(item) {
        
        this.handleClose();
        if (item.type === 'asset') {
            history.push('/device/' + item.objectId + '/details')
        } else {
            history.push('/hub/' + item.objectId + '/details')
        }
    }

    handleClose() {
        if (this.props.onClose) this.props.onClose();
    }

    onTabClick(ev, tab) {
        this.tabs.forEach(function (tabArr) {
            tabArr.forEach(function (t) {
                t.selected = (tab.id === t.id);
            })
        })
        this.setState(this.state);
    }


    render() {
        let footer = (<div
            className={'footer'}
        >
                        <span onClick={() => {
                            this.handleClose()
                            this.props.openDisconnectedDrawer()
                        }}>View Disconnected Devices</span>
            <div className={'icon'}>
                <Icon size={'12'} icon={'FaChevronRight'} color={'#49b9c4'}/>
            </div>
        </div>);

        return (
            <ListPopover
                id="disconnected-popover"
                anchorEl={this.props.anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}
            >
                <div className={'column'} style={{overflow: 'hidden'}}>
                    <div className={'row disconnected-title'}>
                        <div>Disconnected Devices</div>
                    </div>
                    <div className={'row list-container list-container-popover'}>
                        <div className={'row list-container list-container-popover'}>
                            <List
                                removeHeaders
                                lazyload
                                rowHeight={'35px'}
                                noitemelem={<NoItems text={'No current disconnected items to show'}
                                />}
                                columns={[
                                    {
                                        component: StandardListCell,
                                        maxWidth: '15px',
                                        custom: function (item) {
                                            return <div><Icon size={item.type === 'asset' ? '14' : '16'}
                                                              icon={item.type === 'asset' ? 'FaPlug' : 'MdRouter'}
                                                              color={'gd-red'}/>
                                            </div>
                                        }
                                    },
                                    {
                                        component: StandardListCell,
                                        maxWidth: 'none',
                                        custom: function (item) {
                                            return <div className={'column'}>
                                                <div className={'row'}>
                                                    <p className={'disconnected-name'}>{item.objectName}
                                                        {item.siteName ?
                                                            <span
                                                                className={'disconnected-subtitle'}>{' • ' + item.siteName}</span> : null}
                                                    </p>
                                                </div>
                                                <div className={'disconnected-date'}>Last
                                                    Contacted {item.timeStart ? ': ' + moment.unix(item.timeStart).fromNow() + ' (' + moment.unix(item.timeStart).format('MMMM Do YYYY, hh:mm:ss') + ')' : ': Never'}</div>
                                            </div>
                                        }
                                    },
                                    {
                                        component: StandardListCell,
                                        maxWidth: '30px',
                                        custom: function () {
                                            return <div><Icon size={'14'} icon={'FaArrowRight'}
                                                              color={'grey'}/>
                                            </div>
                                        }
                                    },
                                ]}
                                getlist={this.getFakeCurrentList}
                                rowOnClick={this.navigateToClickedItem}
                            />
                        </div>
                    </div>
                    {footer}
                </div>
            </ListPopover>
        )
    }
}

export default DisconnectedPopover
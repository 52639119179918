import React, {Component} from "react";
import '../../src/modals/AddDevice/AddDeviceModal.scss'
import '../styles/_layout.scss'
import {
    Button,
    Dropdown,
    Input,
} from "gd-react";
import GridDuck from "gridduck";
import Toggle from "./Toggle/Toggle";
import AddEditDeviceCategoryModal from "../modals/AddEditDeviceCategory/AddEditDeviceCategoryModal";
import history from "../meta/history";

class DeviceForm extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.errors = {}
        this.state = {
            triedToSave: false,
            showDeviceCategoryModal: false,
            fixedCategorys: [],
            newApplianceType: this.props.type === 'electralink' ? 'Electricity meter' : null,
            newAsset: {
                name: '',
                clampSize: null,
                deviceCategory: null,
                applianceType: this.props.type === 'electralink' ? 'Electricity meter' : null,
                generation: 'false',
                impkWh: '',
            },
            deviceCategories: [],
            channels: [],
            channelOptions: [
                {
                    value: 0,
                    title: '1'
                },
                {
                    value: 1,
                    title: '2'
                },
                {
                    value: 2,
                    title: '3'
                },
            ]
        };
        this.clearFields = this.clearFields.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.increaseChannels = this.increaseChannels.bind(this);
        this.decreaseChannels = this.decreaseChannels.bind(this);
        this.handleChannelChange = this.handleChannelChange.bind(this)
        this.handleAssetChange = this.handleAssetChange.bind(this)
        this.fetchApplianceTypes = this.fetchApplianceTypes.bind(this)
        this.submitAsset = this.submitAsset.bind(this);
        this.closeForm = this.closeForm.bind(this);
        this.getDeviceCategory = this.getDeviceCategory.bind(this)
        this.modalClosed = false;
    }

    handleChannelChange(input, index, item) {
        if (item === 'assetIndex' && this.state.channels.map((c) => c.assetIndex).indexOf(input) > -1) {
            return
        }
        let self = this;
        let channels = this.state.channels;
        let channel = channels.find((c) => c.assetIndex === index)
        channel[item] = input;
        self.setState({channels: channels})
    }

    handleAssetChange(input, item) {
        if (item === 'deviceCategory' && input === 'add_new') {
            return this.setState({showDeviceCategoryModal: true})
        }
        let self = this;
        let asset = this.state.newAsset;
        asset[item] = input;
        self.setState({newAsset: asset})
    }

    clearFields() {
        let self = this;
        self.setState({
            showScanningInstructions: false,
            newAsset: {
                name: '',
                clampSize: null,
                applianceType: '',
                generation: 'false',
                impkWh: '',
            },
            triedToSave: false,
            channels: [],
        });
    }

    closeForm() {
        this.clearFields()
        this.props.closeForm()
    }

    increaseChannels() {
        let self = this
        let channels = this.state.channels;
        let filteredChannelOptions = this.state.channelOptions
            .filter((o) => self.props.existingList?.map((e) => e.assetIndex).indexOf(o.value) < 0)
            .filter((o) => self.state.channels?.map((c) => c.assetIndex).indexOf(o.value) < 0)
        let initialAssetIndex = filteredChannelOptions[0].value
        let channel = {
            name: '',
            impKwh: '',
            assetIndex: initialAssetIndex,
            generation: 'false',
            measurementType: 'DELIVERED'
        }
        channels.push(channel)
        this.setState({channels: channels, triedToSave: false})
    }

    decreaseChannels() {
        let self = this;
        let channels = this.state.channels;
        channels.pop()
        self.setState({channels: channels, triedToSave: false})
    }

    fetchApplianceTypes() {
        return GridDuck.getApplianceTypes({
            sortBy: {
                field: 'name',
                type: 'asc'
            },
            filters: [
                {
                    field: 'search',
                    value: ''
                }
            ]
        }).then((catList) => {
            this.setState({fixedCategorys: catList.list})
            return Promise.resolve(
                catList.list
                    .map(
                        c => ({
                            id: c.id,
                            title: c.name
                        })
                    )
            )
        })
    }

    componentDidMount() {
        this.fetchApplianceTypes().then((res) => {
            this.setState({fixedCategorys: res})
        })
        this.getDeviceCategory().then((res) => this.setState({deviceCategories: res}))
    }

    async submitAsset(e) {

        let self = this;
        let propAsset = this.props.asset
        this.setState({triedToSave: true})
        if (Object.keys(this.errors).length > 0) {

            return Promise.reject()
        }
        // if (e) {
        //     e.preventDefault();
        // }

        let pulse = (this.props.asset && (this.props.asset.sku === 'BLE_PULSE' || this.props.asset.sku === 'NB-IOT-PULSE'));
        let channels = this.state.channels;
        let channelObjects = []
        channels.forEach((c, i) => {
            let meter, sku;
            if (this.props.asset.sku === 'BLE_PULSE') {
                switch (c.measurementType) {
                    case 'DELIVERED':
                        sku = 'BLE_DELIVERED'
                        meter = 'Electricity meter'
                        break;
                    case 'DELIVERED_GAS':
                        sku = 'BLE_GAS'
                        meter = 'Gas meter'
                        break
                    case 'DELIVERED_WATER':
                        sku = 'BLE_WATER'
                        meter = 'Water meter'
                        break
                    case 'DELIVERED_WATER_HOT':
                        sku = 'BLE_WATER_HOT'
                        meter = 'Hot water meter'
                        break;
                    default:
                }
            } else if (this.props.asset.sku === 'NB-IOT-PULSE') {
                sku = 'NB-IOT-PULSE'
                switch (c.measurementType) {
                    case 'DELIVERED':
                        meter = 'Electricity meter'
                        break;
                    case 'DELIVERED_GAS':
                        meter = 'Gas meter'
                        break
                    case 'DELIVERED_WATER':
                        meter = 'Water meter'
                        break
                    case 'DELIVERED_WATER_HOT':
                        meter = 'Hot water meter'
                        break;
                    default:
                }
            }

            let channel = {
                name: c.name,
                code: propAsset.assetCode,
                applianceType: meter,
                generation: c.generation,
                impKwh: c.impKwh,
                dataType: c.measurementType,
                sku: sku,
                gatewayId: propAsset.gatewayId,
                siteId: this.props.site.id,
                groupIds: [this.props.site.id],
                ble: true,
                assetIndex: c.assetIndex
            }
            channelObjects.push(channel)
        })
        let asset;
        if (this.props.type === 'electralink') {
            asset = {
                name: this.state.newAsset.name,
                code: propAsset.assetCode,
                gatewayId: '0c8f4112-f837-4371-9768-d092be315cdf',
                applianceType: this.state.newAsset.applianceType ? this.state.newAsset.applianceType : 'Other',
                sku: 'ELECTRALINK',
                siteId: this.props.site.id,
                groupIds: [this.props.site.id]
            }
        } else {
            asset = {
                name: this.state.newAsset.name,
                code: propAsset.sku === 'VIRTUAL-PARENT' ? propAsset.sku : propAsset.assetCode,
                applianceType: this.state.newAsset.applianceType ? this.state.newAsset.applianceType : 'Other',
                generation: this.state.newAsset.generation ? this.state.newAsset.generation : 'false',
                impKwh: this.state.newAsset.impKwh,
                sku: propAsset.sku,
                gatewayId: propAsset.gatewayId,
                // children: propAsset.children,
                siteId: this.props.site.id,
                deviceCategory: this.state.newAsset.deviceCategory,
                groupIds: [this.props.site.id]
            }
        }

        let assetList, promises = [];

        if (pulse) {
            assetList = channelObjects
        } else {
            assetList = [asset]
        }

        function process(asset) {
            try {
                return GridDuck.createAsset(asset)
            }
            catch (e) {

                if (e.message === 'Request failed with status code 409') {
                    self.setState({error: 'Device already exists'});
                    setTimeout(function () {
                        self.setState({error: null});
                    }, 10000);
                    return Promise.reject("AlreadyExists");
                } else {
                    return Promise.reject(e);
                }
            }
        }

        for (const asset of assetList) {
            promises.push(process(asset))
        }
        let res = await Promise.all(promises)
        if (res && this.props.asset.children && this.props.asset.sku === 'VIRTUAL-PARENT') {
            let id = res[0].id;
            let promises = [];
            for (const child of this.props.asset.children) {
                promises.push(child.setParentAsset(id))
            }
            await Promise.all(promises).then(() => 'parent asset added to children')
        }
        setTimeout(() => {
            self.closeForm()
            history.push('/device/' + res[0].id)
        }, 1000)
        return Promise.resolve();
    }

    checkErrors() {
        let self = this;
        let errors = {};
        let asset = this.props.asset
        let pulse = (this.props.asset && (this.props.asset.sku === 'BLE_PULSE' || this.props.asset.sku === 'NB-IOT-PULSE'));
        if (pulse) {
            let channels = self.state.channels;
            channels.forEach((c, i) => {
                if (!c.name) {
                    errors[`ChannelName${c.assetIndex + 1}`] = true
                }
                if (!c.impKwh) {
                    errors[`ChannelImp${c.assetIndex + 1}`] = true
                }
            })
        } else {
            if (!this.state.newAsset.name) {
                errors['Name'] = true;
            }
            if ((asset) && (asset.sku === 'MET-REA') && (!this.state.newAsset.impKwh)) {
                errors['Imp'] = true
            }
        }
        this.errors = errors;
    }

    getSites(searchTerm) {
        return GridDuck.getSites({
            filters: [{
                field: 'search',
                value: searchTerm
            }]
        }).then(function (siteList) {
            return Promise.resolve(
                siteList.list
                    .filter(s => s._permission === 'admin')
                    .map(
                        s => ({
                            id: s.id,
                            title: s.name
                        })
                    )
            )
        })
    }

    async getDeviceCategory(searchTerm) {
        let filters = []

        let res = await GridDuck.getDeviceCategorys({
            filters: filters
        })
        let list = res.list.map(
            s => ({
                id: s.id,
                title: s.name,
                color: s.colour,
                icon: s.icon
            })
        )
        let addNewElement =
            {
                id: 'add_new',
                value: 'add_new',
                title: 'Create New',
                color: '#49b9c4',
                icon: 'FaPlus'
            }
        list.unshift(addNewElement)
        return Promise.resolve(list)
    }

    render() {
        let pulse = (this.props.asset && (this.props.asset.sku === 'BLE_PULSE' || this.props.asset.sku === 'NB-IOT-PULSE'));
        this.checkErrors();
        let self = this;
        let singleAssetForm;
        let channelsForm = [];
        let errorText;
        let addChannelButton;
        let removeChannelButton;
        let asset = this.props.asset
        if (asset) {
            let displayCode = ''

            if (this.props.type === 'electralink') {
                displayCode = asset.assetCode;
            } else if (asset.assetCode) {
                displayCode = asset.assetCode.substr(-4)
            }

            if (pulse) {
                channelsForm.push(
                    <div className={'row title-wrapper'} style={{marginBottom: '-10px'}}>
                        <div className={'text-wrapper'}>
                            <div className={"image"}
                                 style={{backgroundImage: "url(" + asset.displayImage + ")", height: '30px'}}/>
                            <p>Adding {asset.label} ({displayCode})</p>
                        </div>
                    </div>
                )
                if (this.props.existingList?.length) {
                    let channelMessages = []
                    this.props.existingList.forEach((existingChannel) =>
                        channelMessages.push(<div className={'row'} style={{height: '40px'}}>
                                <p>Channel <span
                                    style={{
                                        fontWeight: 'bold',
                                        color: '#49b9c4'
                                    }}>{existingChannel.assetIndex + 1}</span> already exists
                                    as '<span style={{fontWeight: 'bold', color: '#49b9c4'}}>{existingChannel.name}</span>'
                                </p>
                            </div>
                        )
                    )
                    channelsForm.push(
                        <div style={{marginTop: '20px'}}>{channelMessages}</div>
                    )
                }
                this.state.channels.forEach(function (item, index) {
                        channelsForm.push(
                            <div>
                                <div className={'row'} style={{marginTop: '20px', width: '120px'}}>
                                    <Dropdown
                                        disabled={(self.state.channels.length + self.props.existingList.length) === 3}
                                        label={`Channel Number`}
                                        name={`measurement-type-${item.assetIndex}`}
                                        value={item.assetIndex}
                                        fixeditems={self.state.channelOptions
                                            .filter((o) => self.props.existingList.map((e) => e.assetIndex).indexOf(o.value) < 0)
                                        }
                                        onChange={(input) => self.handleChannelChange(input.target.value, item.assetIndex, 'assetIndex')}
                                    />
                                </div>
                                {item.assetIndex !== null ? <div>
                                    <div className={'row'}>
                                        <Input
                                            label={`Channel ${item.assetIndex + 1} Name`}
                                            name={`Name${item.assetIndex + 1}`}
                                            error={self.state.triedToSave && self.errors[`ChannelName${item.assetIndex + 1}`]}
                                            value={item.name}
                                            placeholder={`Channel ${item.assetIndex + 1} Name`}
                                            onChange={(input) => {
                                                self.handleChannelChange(input.target.value, item.assetIndex, 'name')
                                            }}
                                        />
                                    </div>
                                    <div className={'row'}>
                                        <Input
                                            label={`Channel ${item.assetIndex + 1} ${item.measurementType === 'DELIVERED_GAS' ? 'm3 per Impressions' : 'Impressions per unit'}`}
                                            value={item.measurementType === 'DELIVERED_GAS' ? 1 / item.impKwh : item.impKwh}
                                            name={`Imp${item.assetIndex + 1}`}
                                            error={self.state.triedToSave && self.errors[`ChannelImp${item.assetIndex + 1}`]}
                                            type={"number"}
                                            placeholder={item.measurementType === 'DELIVERED_GAS' ? 'e.g. 0.001' : 'e.g. 1000'}
                                            onChange={(input) => self.handleChannelChange(item.measurementType === 'DELIVERED_GAS' ? 1 / input.target.value : input.target.value, item.assetIndex, 'impKwh')}
                                        />
                                    </div>
                                    <div className={'row'} style={{marginTop: '14px'}}>
                                        <Dropdown
                                            label={`Channel ${item.assetIndex + 1} Measurement Type`}
                                            name={`measurement-type-${item.assetIndex + 1}`}
                                            value={item.measurementType}
                                            fixeditems={[
                                                {
                                                    value: 'DELIVERED',
                                                    title: 'Electricity'
                                                },
                                                {
                                                    value: 'DELIVERED_WATER',
                                                    title: 'Water'
                                                },
                                                {
                                                    value: 'DELIVERED_GAS',
                                                    title: 'Gas'
                                                },
                                                {
                                                    value: 'DELIVERED_WATER_HOT',
                                                    title: 'Hot Water'
                                                },
                                            ]}
                                            placeholder={'e.g. Water'}
                                            onChange={(input) => self.handleChannelChange(input.target.value, item.assetIndex, 'measurementType')}
                                        />
                                    </div>
                                    <div className={'row'} style={{marginTop: '10px', height: '50px'}}>
                                        <Toggle
                                            onClick={(val) => self.handleChannelChange(val, item.assetIndex, 'generation')}
                                            inactivestatename={`Channel ${item.assetIndex + 1} Non-generation appliance`}
                                            activestatename={`Channel ${item.assetIndex + 1} Generation Appliance`}
                                            active={item.generation}/>
                                    </div>
                                </div> : null}
                                <div className={'add-device-divider'}/>
                            </div>)
                    }
                )
            }

            if (!pulse) {
                singleAssetForm = (
                    <div>
                        <div className={'row title-wrapper'} style={{marginBottom: '-10px'}}>
                            <div className={'text-wrapper'}>
                                <div className={"image"}
                                     style={{
                                         backgroundImage: "url(" + asset.displayImage + ")",
                                         height: '30px'
                                     }}/>
                                <p>Adding {asset.deviceType.title} {this.state.mac ? "(" + this.state.mac + ")" : ''}</p>
                            </div>
                        </div>
                        <div className={'row'} style={{marginTop: '14px'}}>
                            <Input
                                label={'Device Name'}
                                name={'Name'}
                                error={this.state.triedToSave && this.errors['Name']}
                                value={this.state.newAsset.name}
                                placeholder={asset.sku === 'CLI-SEN-POW' ? "Clamp 1 name" : asset.sku === 'SOCKET-DOUBLE-OWON' ? "Gang One name" : "Device name"}
                                onChange={(input) => self.handleAssetChange(input.target.value, 'name')}
                            />
                        </div>
                        {asset.sku === 'MET-REA' ?
                            <div className={'row'}>
                                <Input
                                    label={'Impressions / kWh'}
                                    value={this.state.newAsset.impKwh}
                                    name={'Imp'}
                                    error={this.state.triedToSave && this.errors['Imp']}
                                    type={"number"}
                                    placeholder={'e.g. 1000'}
                                    onChange={(input) => self.handleAssetChange(input.target.value, 'impKwh')}
                                />
                            </div>
                            : null}

                        <div className={'row'} style={{marginTop: '14px'}}>

                            <Dropdown
                                label={`Device Category`}
                                name={`deviceCategory`}
                                error={this.state.triedToSave && this.errors['deviceCategory']}
                                value={this.state.newAsset.deviceCategory}
                                fixeditems={self.state.deviceCategories}
                                placeholder={'e.g. Lighting'}
                                onChange={(input) => self.handleAssetChange(input.target.value, 'deviceCategory')}
                            />
                        </div>
                        {asset.sku !== 'SEN-TEM-HUM' && asset.sku !== 'SEN-LIGHT-MOTION' ?
                            <div className={'row'} style={{marginTop: '14px'}}>
                                <Dropdown
                                    label={'Device Label'}
                                    name={'appliance-type'}
                                    value={this.state.newAsset.applianceType}
                                    fixeditems={self.state.fixedCategorys}
                                    placeholder={'e.g. Kettle'}
                                    onChange={(input) => self.handleAssetChange(input.target.value, 'applianceType')}
                                />
                            </div> : null}
                    </div>
                )
            }
        }

        if (pulse) {
            if ((this.state.channels?.length + this.props.existingList?.length) < 3) {
                addChannelButton = <div
                    className={'row'}
                    style={{marginTop: '20px', height: '50px'}}>
                    <Button
                        label={"+ Add Channel"}
                        onClick={() => this.increaseChannels()}
                    />
                </div>
            }

            if (this.state.channels.length > 1) {
                removeChannelButton = <div
                    className={'row'}
                    style={{marginTop: '20px', height: '50px'}}>
                    <Button
                        color={'gd-red'}
                        label={"- Remove Channel"}
                        onClick={() => this.decreaseChannels()}
                    />
                </div>
            }
        }

        if (this.state.error) {
            errorText = (
                <p>{this.state.error}</p>
            )
        }

        return (
            <div>
                {singleAssetForm}
                {channelsForm}
                {addChannelButton}
                {removeChannelButton}
                {errorText}
                {this.state.showDeviceCategoryModal ? <AddEditDeviceCategoryModal
                    onClose={() => {
                        this.getDeviceCategory().then((res) => {
                            this.setState({deviceCategories: res, showDeviceCategoryModal: null})
                        })
                    }}
                    open={!!this.state.showDeviceCategoryModal}
                    item={this.state.showDeviceCategoryModal}
                    setNewDeviceCategory={(deviceCategory) => {
                        this.handleAssetChange(deviceCategory, 'deviceCategory')
                    }
                    }
                /> : ''}
            </div>
        )
    }
}

export default DeviceForm;

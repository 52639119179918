import React from 'react';
import GridDuck from 'gridduck';
import {DemandListCell, List, StandardListCell, StateSelectMenu, StateListCell, DummyListCell, SensorDataListCell} from "gd-react";
import history from "../../meta/history";
import AddGroupModal from "../../modals/AddGroup/AddGroupModal";
import NoItems from "../../components/noItems";
import AddHubModal from "../../modals/AddHub/AddHubModal";
import SiteAssetListPage from "./SiteAssetListPage";
import AddManualDeviceModal from "../../modals/AddDevice/AddManualDeviceModal";
import './MobileSiteAssetListPage.scss';
import Icon from "../../components/Icon/Icon";

class MobileSiteAssetListPage extends SiteAssetListPage {

    getColor(item) {
        
        let colors = {
            'ON': 'gd-green',
            'MONITORING': 'gd-blue',
            'OFF': 'gd-red',
        }
        return colors[item.currentState]
    }

    render() {
        let self = this;
        let assets;
        let filters;

        if (this.props.filterType === 'site_id') {
            filters = [
                {
                    field: 'siteId',
                    value: this.props.item.id
                },
                {
                    field: 'locationId',
                    value: this.props.item.locationId
                },
                {
                    field: 'parentAsset',
                    value: null
                },
            ];
        } else if (this.props.filterType === 'site_group_id') {
            filters = [
                {
                    field: 'siteGroupId',
                    value: this.props.item.id
                }
            ];

        } else if (this.props.filterType === 'org_partner_id') {
            filters = [
                {
                    field: 'orgPartnerId',
                    value: this.props.item.id
                }
            ];

        } else if (this.props.filterType === 'asset_id' && this.props.type === 'hub') {

            filters = [
                {
                    field: 'gatewayId',
                    value: this.props.item.id
                }
            ]
        }
        let noDevicePage;
        if (this.props.hubs && (!this.props.hubs.list || !this.props.hubs.list.length)) {
            noDevicePage =
                <NoItems text={'"' + this.props.item.name + '" has no Hubs'} createtext={'Add Hub'} modal={AddHubModal}
                         disabled={this.state.orgPermission !== 'admin' && this.state.orgPermission !== 'owner'}/>
        }
        // if (this.props.hubs && (this.props.hubs.list.length && (!this.props.dataItems.list || !this.props.dataItems.list.length))) {
        //     noDevicePage = <NoItems text={'"' + this.props.item.name + '" has no Devices'} createtext={'Add Device'}
        //                             modal={AddManualDeviceModal}
        //                             disabled={this.state.orgPermission !== 'admin' && this.state.orgPermission !== 'owner'}/>
        // }
        if (this.props.item) {
            assets = (
                <List
                    removeHeaders
                    noitemelem={noDevicePage}
                    forceExpanded
                    itemsPerPage={25}
                    columns={[
                        {
                            component: StandardListCell,
                            isMobile: true,
                            bold: true, sortable: true,
                            custom: function (item) {
                                return <div className={'name-status-container'}>
                                    {item.gatewayDisconnected || item.disconnected ?
                                        <div className={'icon'}><Icon size={'10'}
                                                                      icon={'AiOutlineDisconnect'}
                                                                      color={'gd-grey'}/>
                                        </div> : <div className={'icon'}><Icon size={'10'}
                                                                               icon={'FaCircle'}
                                                                               color={self.getColor(item)}/>
                                        </div>}
                                        <span className={'name'}>{item.name}</span>

                            </div>
                            },
                            maxWidth: 'none'
                        },
                        {
                            component: SensorDataListCell,
                            title: 'Data',
                            isMobile: true,
                            field: 'temperature',
                            maxWidth: '40px'
                        },
                        {
                            component: StateListCell,
                            simplified: true,
                            bold: true, sortable: true,
                            maxWidth: '30px'
                        },
                    ]}
                    rowOnClick={this.goToAsset}
                    getlist={this.getList}
                    parentField={this.props.type === 'hub' ? null : "parentAsset"}
                    childrenField={this.props.type === 'hub' ? null : "children"}
                    filters={filters}
                />
            )
        }
        return (
            <div className='mobile-site-group-sites'>
                {assets}
            </div>
        );
    }
}

export default MobileSiteAssetListPage;

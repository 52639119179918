import React, {Component} from "react";
import "./AddFileModal.scss";
import '../../styles/_layout.scss';
import {Button, Card, CardBody, CardHeader, Dropdown, GdAutocomplete, Icon, Input, Loader} from "gd-react";
import GridDuck from "gridduck";
import moment from "moment-timezone";
import SaveBar from "../../components/SaveBar/SaveBar";
import history from "../../meta/history";
import FileUploadDropper from "../../components/FileManager/FileUploadDropper";
import FileList from "../../components/FileManager/FileList";
import FileSummary from "../../components/FileManager/FileSummary";
import GenericLoader from "../../components/GenericLoader";
import GdModal from "../../components/GdModal/GdModal";

class AddFileModal extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.closeModal = this.closeModal.bind(this);
        this.selectSite = this.selectSite.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.getSites = this.getSites.bind(this);
        this.getSiteShortcuts = this.getSiteShortcuts.bind(this);
        this.errors = {};
        this.changed = false;

        this.state = {
            site: '',
            files: [],
            loaded: true,
            siteShortCuts: []
        }
        this.modalClosed = false;
    }

    async getSites(searchTerm, reset) {

        let self = this
        if (reset) this.siteOffset = 0
        let filters = []
        let limit = 25
        if (searchTerm) filters.push({
            field: 'search',
            value: searchTerm
        })
        let sitesRes = await GridDuck.getSites({filters: filters, offset: this.siteOffset,})
        let sites = sitesRes.list
            .filter(s => s._permission === 'admin')
            .map((s) => {
                return {
                    id: s.id,
                    title: s.name,
                    locationId: s.locationId,
                    subtitle: s.locationName ? s.locationName : '',
                    icon: 'FaBuilding',
                }
            })
        //updating offset to begin 'get' after latest result
        let newOffset = self.siteOffset + limit;
        self.siteOffset = newOffset
        
        return Promise.resolve(sites)
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    async selectSite(site, siteObj) {

        

        

        if (siteObj) {
            let item = {
                itemType: 'site',
                itemId: siteObj?.id
            };
            
            await GridDuck.createSearchHistory(item)
            
        }

        this.setState({
            site: siteObj,
            loaded: true
        });
    }

    saveChanges() {
        let self = this;
        this.setState({triedToSave: true})
        if (Object.keys(this.errors).length > 0) return;
        history.push('/site/' + this.state.site.id + '/files')
        this.closeModal();
    }

    async getSiteShortcuts() {
        let filters = [
            {
                field: 'itemType',
                value: 'site'
            }
        ]

        let res = await GridDuck.getSearchHistorys({filters: filters})
        let shortcuts = res.list.map((r) => {
            return {
                id: r.itemId,
                title: r.name,
                subtitle: r.metadata.locationName,
                locationId: r.metadata.locationId,
                icon: 'GiAnticlockwiseRotation',
                type: 'Recent'
            }
        })
        this.setState({siteShortcuts: shortcuts})
    }

    checkErrors() {
        let errors = {};
        if (!this.state.site || !this.state.site.id) errors['site'] = true;
        this.errors = errors;
    }

    render() {
        let footer, self = this;

        if (this.state.loaded) footer = <Button label={'Go to file manager'} onClick={this.saveChanges}/>
        this.checkErrors();

        return (
            <GdModal
                title={"Add Files to Site"}
                open={this.props.open}
                footer={footer}
                contentLabel={'Add Site'}
                onClose={this.closeModal}>
                {this.state.loaded ? <CardBody>
                    <div className={'row'} style={{marginBottom: '15px'}}>
                        <GdAutocomplete
                            async
                            icon
                            subtitle
                            lazyload
                            getShortcuts={self.getSiteShortcuts}
                            shortcuts={self.state.siteShortcuts}
                            getList={self.getSites}
                            name={'site'}
                            getOptionSelected={function (option, value) {
                                if (option && value) return option.id === value.id;
                            }}
                            value={this.state.site}
                            placeholder={'Select a site'}
                            onChange={this.selectSite}
                            label="Site"/>
                    </div>

                </CardBody> : ''}
            </GdModal>
        )
    }
}

export default AddFileModal;
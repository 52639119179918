import React, {Component} from "react";
import '../../styles/_layout.scss';
import GridDuck from "gridduck";
import './Gaia.scss'
import GaiaMessages from "./GaiaMessages";
import MessageBox from "./MessageBox";
import GenericLoader from "../GenericLoader";
import GaiaMessage from "./GaiaMessage";

class Gaia extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.state = {
            newMessage: '',
            updateRef: 0
        }
        this.sendMessage = this.sendMessage.bind(this);
        this.updateMessages = this.updateMessages.bind(this);
        this.getMessages = this.getMessages.bind(this);
        this.messageCount = 0;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.conversation?.id !== this.props.conversation?.id) {
            this.props.conversation.on('updated', this.updateMessages);
        }
    }

    updateMessages() {

        this.setState({messages: this.messages.list}, () => {
            let lastMessage = this.messages.list[this.messages.list.length - 1];
            if (lastMessage.role === 'assistant' && lastMessage.content && lastMessage.content.length && this.messageCount !== this.messages.list.length) {
                this.setState({loadingMessage: false});
                this.messageCount = this.messages.list.length;
            }
        });
    }

    async sendMessage(message) {
        this.state.messages.push({
            id: Math.random() + '',
            role: 'user',
            content: message,
            createdAt: new Date().getTime() / 1000
        });
        this.setState({loadingMessage: true, messages: this.state.messages});
        await GridDuck.createGaiaMessage({content: message, conversationId: this.props.conversation.id});
    }

    async getMessages() {
        this.messages = await GridDuck.getGaiaMessages({
            filters: [{
                field: 'conversationId',
                value: this.props.conversation.id
            }]
        });
        if (this.messageCount === 0) {
            this.messageCount = this.messages.list.length;
        }
        this.messages.on('updated', this.updateMessages);
        this.setState({messages: this.messages.list});
    }

    async componentDidMount() {
        await this.getMessages();
    }

    render() {
        let lastElement;
        if (this.state.messages && this.state.messages.length > 0) {
            lastElement = this.state.messages[this.state.messages.length - 1];
        }

        return (
            [(<div className={'messages'}>
                {this.state.messages ? <GaiaMessages messages={this.state.messages}/> : <GenericLoader/>}
            </div>),
                (<MessageBox disabled={this.state.loadingMessage || (lastElement && lastElement.role === 'assistant' && !lastElement.content)} sendMessage={this.sendMessage}/>)]
        )
    }
}


export default Gaia;
import React from 'react';
import {Link, Redirect} from 'react-router-dom';
import {Button, Input, Pattern, Toast} from 'gd-react';
import GridDuck from 'gridduck';
import './DesktopLogin.scss';
import 'css-doodle'
import animatedLogo from '../images/animated-logo-once.svg'
import cookie from 'react-cookies'
import GenericLoader from "../components/GenericLoader";
import history from "../meta/history";
import PasswordChecker from "../components/PasswordChecker/PasswordChecker";
import {Icon} from "@material-ui/core";

class ConfirmInvite extends React.Component {
	constructor(props) {
		super(props);
		this.handleSubmit = this.handleSubmit.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.pwChanged = this.pwChanged.bind(this);
		this.onFormChange = this.onFormChange.bind(this);
		this.checkErrors = this.checkErrors.bind(this);
		this.errors = {};
		this.state = {
			emailAddress: '',
			organisationName: '',
			logoImageThumbnail: '',
			orgPermission: '',
			pwPassed: false,
			status: '',
			invite: {
				name: '',
				jobRole: '',
				password: ''
			}
		}
	}

	componentDidMount() {
		let self = this;
		GridDuck.getVerifyOrgInvite({id: this.props.match.params.inviteId}).then(function (res) {
				self.setState({
					emailAddress: res.emailAddress,
					organisationName: res.organisationName,
					logoImageThumbnail: res.whiteLabel ? res.logoImageThumbnail : '',
					orgPermission: res.orgPermission,
					loaded: true
				})
		}).catch(function (err) {
			history.push("/")
			self.setState({
				loaded: true,
				status: "Invalid Invitation"
			});
		})
	}

	handleChange(event) {
		this.setState({[event.target.name]: event.target.value});
	}

	handleSubmit(e) {
		let self = this;
		this.setState({triedToSave: true})
		
		if (Object.keys(this.errors).length > 0) return new Promise(function (resolve, reject) {
			return reject();
		}).catch(function () {
			return Promise.reject();
		});
		if (e) {
			e.preventDefault();
		}

		return GridDuck.createConfirmOrgInvite({
			code: self.props.match.params.inviteId,
			emailAddress: self.state.emailAddress,
			name: self.state.invite.name,
			jobRole: self.state.invite.jobRole,
			password: self.state.invite.password,
			orgPermission: self.state.orgPermission
		}).then(function (res) {
			return GridDuck.login(self.state.emailAddress, self.state.invite.password).then(function (res) {
				let accessToken = GridDuck.config.accessToken;
				delete accessToken.scope;
				cookie.save('accessToken', accessToken, {path: '/'});
				history.push({
					pathname: '/siteGroup/all',
					state: {
						showToast: 'Account Successfully Created'
					}
				});
			});

		});

	}

	pwChanged(passed, pw) {
		this.state.pwPassed = passed;
		this.state.invite.password = pw;
		this.setState(this.state)
	}

	onFormChange(val) {
		
		this.state.invite[val.target.name] = val.target.value;
		this.setState({invite: this.state.invite});
	}

	checkErrors() {
		let errors = {};
		if (!this.state.invite.name.length) errors['name'] = true;
		if (!this.state.invite.jobRole.length) errors['jobRole'] = true;
		if (!this.state.pwPassed) errors['pw'] = true;
		this.errors = errors;
	}
}

export default ConfirmInvite;

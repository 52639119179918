import React from "react";
import {
    Button,
    Dropdown,
    EditableList,
    Icon,
    Input,
    ScheduleSetter,
    ScheduleTimeline,
    StandardListCell,
    GdCheckbox,
    StateOptions
} from "gd-react";
import {createPortal} from 'react-dom';
import GridDuck from "gridduck";
import "./EditRule.scss"
import moment from "moment/moment";
import _ from 'lodash';

class EditRulePageTimeswitch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            page: 1,
            triedToProgress: false,
            specificSchedule: false,
            rule: {
                name: props.rule.name,
                states: [],
                conditions: [],
                start: new Date().getTime(),
                end: new Date().getTime(),
                inactiveState: null,
                state: null,
                description: props.rule.description,
                id: props.rule.id,
                viewing: props.rule.id,
                creating: props.rule.creating,
            }
        };
        if (props.rule.actions && props.rule.actions.length > 0) {
            this.state.rule.actions = JSON.parse(JSON.stringify(props.rule.actions));
        }
        this.rule = _.cloneDeep(props.rule);
        this.errors = {}
        this.hasChanged = this.hasChanged.bind(this);
        this.isDifferentRules = this.isDifferentRules.bind(this)
        this.isDifferentAssets = this.isDifferentAssets.bind(this)
        this.handleNameChange = this.handleNameChange.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this)
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        this.setInactiveState = this.setInactiveState.bind(this);
        this.rulesUpdated = this.rulesUpdated.bind(this);
        this.getActiveList = this.getActiveList.bind(this);
        this.getAvailableList = this.getAvailableList.bind(this);
        this.handleAssetListChange = this.handleAssetListChange.bind(this);
        this.blockNext = this.blockNext.bind(this);
        this.formatEnd = this.formatEnd.bind(this)
        this.formatStart = this.formatStart.bind(this)
        this.save = this.save.bind(this);
        this.next = this.next.bind(this)
        this.setRuleState = this.setRuleState.bind(this)
        this.checkErrors = this.checkErrors.bind(this)
        this.addSpecificStateCondition = this.addSpecificStateCondition.bind(this)
        this.removeSpecificStateCondition = this.removeSpecificStateCondition.bind(this)
        this.processSpecificStates = this.processSpecificStates.bind(this)
        this.generateStatesFromExistingSpecific = this.generateStatesFromExistingSpecific.bind(this)
        this.processStandardScheduleIntoActions = this.processStandardScheduleIntoActions.bind(this)
        let scheduleAction = this.props.rule.actions?.find((action) => action.isSchedule)
        let timeAction = this.props.rule.actions?.find((action) => action.type === 'TIME')
        this.prevConditions = scheduleAction?.schedules.map((schedule) => {
            return {
                ...schedule,
                state: this.props.rule.actions.find((action) => action.id === schedule.state)?.stateType
            }
        })
        if (timeAction) {
            this.prevStates = this.generateStatesFromExistingSpecific()
        }
    }

    async componentDidMount() {
        let specificSchedule = false
        let states = [
            {
                start: new Date().getTime(),
                end: new Date().getTime(),
                state: null,
            }
        ];
        if (this.props.rule.actions?.find((action) => action.type === 'TIME')) {
            // specific date and time rule
            specificSchedule = true
            states = this.generateStatesFromExistingSpecific()
        }
        let scheduleAction = this.props.rule?.actions?.find((action) => action.isSchedule)
        if (scheduleAction) {
            //     standard schedule
            let rule = this.state.rule
            let conditions = scheduleAction.schedules.map((schedule) => {
                return {
                    ...schedule,
                    state: this.props.rule.actions.find((action) => action.id === schedule.state)?.stateType
                }
            })
            rule.conditions = conditions
            this.setState({rule: rule})
        }
        let user = await GridDuck.getAccount({id: GridDuck.userId})
        let rule = this.state.rule;
        rule.states = states
        let inactiveState = this.props.rule.inactiveState ? this.props.rule.inactiveState : StateOptions.find(t => t.type === 'default').options[0].state;
        rule.inactiveState = inactiveState
        this.setState({
            user: user,
            loaded: true,
            specificSchedule: specificSchedule,
            rule: rule
        })
    }

    generateStatesFromExistingSpecific() {
        let timeActions = this.props.rule.actions.filter((s) => s.type === 'TIME')
        timeActions.sort((a, b) => a.startTime - b.startTime);
        let states = timeActions.map((s) => {
            let activeAction = this.props.rule.actions.find((a) => a.id === s.activeAction)
            let state = activeAction.stateType
            return {
                start: s.startTime,
                end: s.endTime,
                state: state,
            }
        })
        return states
    }

    async save(data) {
        if (data.cancel && this.props.onSave) {
            this.props.onSave();
            return Promise.resolve();
        }
        this.saved = true;
        let n_rule
        let promises = [];
        let self = this;
        let newAssets = this.state.rule.actions?.find((a) => a.assets)?.assets || [];
        let oldAssets = this.props.rule.actions?.find((a) => a.assets)?.assets || [];
        if (this.state.rule.creating) {
            let newRuleProps = {
                name: this.state.rule.name,
                template: 'timeswitch',
                inactiveState: this.state.rule.inactiveState,
                description: this.state.rule.description,
            };
            if (!this.state.specificSchedule) {
                newRuleProps.actions = this.processStandardScheduleIntoActions();
            } else {
                delete newRuleProps.alerts;
                newRuleProps.actions = this.processSpecificStates()
            }
            promises.push(
                GridDuck.createRule(newRuleProps).then(function (newRule) {
                    n_rule = newRule;
                    self.setState({rule: n_rule});
                })
            );
        } else {
            if (!this.state.specificSchedule) {
                if (this.props.rule.description !== this.state.rule.description) {
                    promises.push(this.props.rule.setDescription(this.state.rule.description));
                }
                if (this.props.rule.name !== this.state.rule.name) {
                    promises.push(this.props.rule.setName(this.state.rule.name));
                }
                if (this.props.rule.inactiveState !== this.state.rule.inactiveState) {
                    promises.push(this.props.rule.setInactiveState(this.state.rule.inactiveState));
                }
                if (this.isDifferentRules(this.prevConditions, this.state.rule.conditions) || this.isDifferentAssets(oldAssets, newAssets)) {
                    promises.push(
                        self.props.rule.set({actions: []})
                            .then(function () {
                                let actions = self.processStandardScheduleIntoActions()
                                return self.props.rule.set({
                                    actions: actions
                                });
                            })
                    )
                }
            } else {
                if (this.props.rule.description !== this.state.rule.description) {
                    promises.push(this.props.rule.setDescription(this.state.rule.description));
                }
                if (this.props.rule.name !== this.state.rule.name) {
                    promises.push(this.props.rule.setName(this.state.rule.name));
                }
                if (this.props.rule.inactiveState !== this.state.rule.inactiveState) {
                    promises.push(this.props.rule.setInactiveState(this.state.rule.inactiveState));
                }
                if (this.isDifferentRules(this.prevStates, this.state.rule.states) || this.isDifferentAssets(oldAssets, newAssets)) {
                    promises.push(
                        self.props.rule.set({actions: []})
                            .then(function () {
                                let actions = self.processSpecificStates()
                                return self.props.rule.set({
                                    actions: actions
                                });
                            })
                    )
                }
            }
        }
        await Promise.all(promises);
        if (this.props.onSave) {
            this.props.onSave(n_rule);
        }
        return Promise.resolve();
    }

    addSpecificStateCondition() {
        let rule = this.state.rule;
        let states = this.state.rule.states;
        let prevState = states[states.length - 1]
        states.push({
            start: prevState.end,
            end: prevState.end,
            state: null,
        })
        rule.states = states
        this.setState({rule: rule})
    }

    removeSpecificStateCondition(i) {
        let rule = this.state.rule;
        rule.states.splice(i, 1)
        this.setState({rule: rule})
    }

    getActiveList() {
        let assetsAction = this.rule.actions?.find((a) => a.assets)
        if (assetsAction) {
            return Promise.all(
                assetsAction.assets.map(function (assetId) {
                    return GridDuck.getAsset({id: assetId});
                })
            ).then(function (res) {
                return Promise.resolve({list: res, total: res.length})
            });
        } else {
            return Promise.resolve({list: [], total: 0});
        }
    }

    setRuleState(e, i) {
        let rule = this.state.rule;
        rule.states[i].state = e.target.value
        this.setState({rule: rule, hasChanged: true});
    }

    async getAvailableList(params) {
        let assetsAction = this.rule.actions?.find((a) => a.assets)
        let assets = await GridDuck.getAssets(params);
        if (assetsAction) {
            assets.list = assets.list.filter(a => !this.rule.actions?.find((a) => a.assets).assets.includes(a.id));
            assets.total = assets.total -= this.rule.actions?.find((a) => a.assets).assets.length;
        }
        return assets
    }

    processStandardScheduleIntoActions() {
        //  - top level action that designates the following a schedule
        //  - an inactive state action
        //  - an action for every subsequent novel active state after that, drawn from 'conditions'
        let actions = []
        // 1) create a 0th schedule action, where inactive action is labelled with an index, and conditions states also contain an indexed state
        let scheduleAction = {
            "id": 0,
            "inactiveAction": 1,
            "isSchedule": true,
            // SCHEDULES TO BE CREATED LAST
        }
        actions.push(scheduleAction)
        let assetAction = this.state.rule.actions.find((action) => action.assets)
        // 2) create the inactive state action
        let inactiveAction = {
            "type": "state",
            "id": 1,
            "stateType": this.state.rule.inactiveState,
            "isAlert": true,
            "assets": assetAction.assets,
        }
        actions.push(inactiveAction)
        // 3 for each condition, create an active action if the state is novel
        let activeActions = []
        let index = 2
        for (const condition of this.state.rule.conditions) {
            if (!activeActions.find((activeAction) => activeAction.stateType === condition.state)) {
                let activeAction = {
                    "type": "state",
                    "id": index,
                    "stateType": condition.state,
                    "isAlert": true,
                    "assets": assetAction.assets,
                }
                activeActions.push(activeAction)
                index++
            }
        }
        actions.push(...activeActions)
        // 4) create the schedules array and append to the 0th schedule action
        // each schedule item is just a condition, with the state found in activeActions and labelled by index rather than name string
        let schedules = this.state.rule.conditions.map((condition) => {
            let stateIndex = activeActions.find((activeAction) => activeAction.stateType === condition.state).id;
            return {
                ...condition,
                state: stateIndex
            }
        })
        actions[0].schedules = schedules
        return actions
    }

    processSpecificStates() {
        let actions = []
        // 1) add inactive state and all unique active states to the actions list
        let states = this.state.rule.states;
        let assetAction = this.state.rule.actions.find((action) => action.assets)
        let inactiveState = {
            "id": states.length,
            "type": 'state',
            "isAlert": true,
            "stateType": this.state.rule.inactiveState,
            "assets": assetAction.assets
        }
        actions.push(inactiveState)
        for (const [i, s] of Object.entries(states)) {
            let existingState = actions.find((a) => a.stateType === s.state)
            if (!existingState) {
                let newActiveState = {
                    "id": actions[actions.length - 1].id + 1,
                    "type": 'state',
                    "isAlert": true,
                    "stateType": s.state,
                    "assets": assetAction.assets
                }
                actions.push(newActiveState)
            }
        }
        // 2) for each state time block, create a time action referencing the existing state actions
        let timeActions = []
        for (const [i, s] of Object.entries(states)) {
            let index = parseInt(i)
            let activeActionId = actions.find((a) => a.stateType === s.state).id;
            // inactiveActionId will be the next time block, unless this is the last state, in which case it will be inactivestateid
            let inactiveActionId;
            if (index + 1 === states.length) {
                inactiveActionId = actions.find((a) => a.stateType === this.state.rule.inactiveState).id;
            } else {
                inactiveActionId = index + 1
            }
            let timeAction = {
                id: index,
                activeAction: activeActionId,
                inactiveAction: inactiveActionId,
                type: "TIME",
                start_time: s.start,
                end_time: s.end
            }
            timeActions.push(timeAction)
        }
        let fullActions = timeActions.concat(actions)
        return fullActions
    }

    rulesUpdated(rules) {
        this.setState(prevState => ({
            rule: {
                ...prevState.rule,
                conditions: rules
            }
        }));
    }

    isDifferentAssets(oldAssets, newAssets) {
        let isDifferent = false
        if (oldAssets && newAssets) {
            if (!newAssets.every((value, index) => value === oldAssets[index]) || !oldAssets.every((value, index) => value === newAssets[index]))
                isDifferent = true
        }
        return isDifferent
    }

    isDifferentRules(oldConditions, newConditions) {
        let isDifferent = false;
        if (oldConditions && newConditions) {
            if (oldConditions.length !== newConditions.length) isDifferent = true
            oldConditions.forEach(function (oldCondition, index) {
                let newCondition = newConditions[index];
                if (newCondition) Object.keys(newCondition).forEach(function (key) {
                    if ((newCondition[key] !== oldCondition[key])) {
                        isDifferent = true;
                    }
                })
            })
        }
        return isDifferent;
    }

    hasChanged() {
        let hasChanged = false
        if (this.state.hasChanged) {
            return true;
        }
        if (this.state.saved || this.saved) {
            return hasChanged
        }
        let newAssets = this.state.rule.actions?.find((a) => a.assets)?.assets || []
        let oldAssets = this.props.rule.actions?.find((a) => a.assets)?.assets || []
        
        if (this.props.rule.name !== this.state.rule.name) {
            
            hasChanged = true
        }
        if (this.props.rule.description !== this.state.rule.description) {
            
            hasChanged = true
        }
        if (this.props.rule.inactiveState !== this.state.rule.inactiveState) {
            
            hasChanged = true
        }
        if (this.isDifferentAssets(oldAssets, newAssets)) {
            
            hasChanged = true
        }
        // conditions have changed for standard
        if (!this.state.specificSchedule && this.isDifferentRules(this.prevConditions, this.state.rule.conditions)) {
            
            hasChanged = true
        }
        // states have changed for specific
        if (this.state.specificSchedule && this.isDifferentRules(this.prevStates, this.state.rule.states)) {
            
            hasChanged = true
        }
        return hasChanged
    }

    handleDescriptionChange(val) {
        let description = val.target.value;
        this.setState(prevState => ({
            rule: {
                ...prevState.rule,
                description: description
            }
        }));
    }

    setInactiveState(e) {
        let rule = this.state.rule;
        rule.inactiveState = e.target.value;
        this.setState({rule: rule, hasChanged: true});
    }

    handleNameChange(val) {
        let name = val.target.value;
        this.setState(prevState => ({
            rule: {
                ...prevState.rule,
                name: name
            }
        }));
    }

    handleAssetListChange(a, b, itemsToAddRemove) {
        let self = this;
        let rule = this.state.rule;

        if (!rule.actions) {
            rule.actions = [
                {}
            ]
        }
        let _assets = this.rule.actions?.find((a) => a.assets)?.assets || [];
        rule.actions[0].assets = _.uniq(_assets.filter(itemId => !itemsToAddRemove.itemsToRemove.find(i => i.id === itemId)).concat(itemsToAddRemove.itemsToAdd.map(i => i.id)));
        this.setState(() => ({
            rule: rule,
            hasChanged: true
        }), function () {

        });
    }

    blockNext() {
        switch (this.state.page) {
            case 1:
                return this.state.rule.name === undefined || this.state.rule.name === null || this.state.rule.name === '';
            case 2:
                if (this.state.specificSchedule) {
                    //     requirements for specific instance schedule (dev feature)
                    for (const state of this.state.rule.states) {
                        if (!state.state) return true
                    }
                    for (const [i, state] of Object.entries(this.state.rule.states)) {
                        //     if end date is less than start
                        if (state.end < state.start) {
                            return true
                        }
                    }
                    return false
                } else {
                    // standard timeswitch requirements
                    if (this.state.rule.conditions?.length === 0) {
                        return true;
                    }
                    let returnVar;
                    this.state.rule.conditions.forEach(function (condition) {
                        if (condition.startHour === undefined || condition.startMinute === undefined || condition.endHour === undefined || condition.endMinute === undefined) {
                            returnVar = true;
                        }
                    });
                    return !!returnVar;
                }
            case 3:
                return !this.state.rule.actions || this.state.rule.actions[0].assets.length === 0;
            default:

        }
        return false;
    }

    async next() {
        this.setState({triedToProgress: true})
        if (Object.keys(this.errors).length > 0) return Promise.reject()
        this.setState({page: this.state.page + 1})
        return Promise.resolve()
    }

    onCheckboxChange(val) {
        this.setState({specificSchedule: val.target.checked});
    }

    formatStart(i) {
        let date = moment(this.state.rule.states[i].start)
            .format("YYYY-MM-DDTHH:mm")
        return date
    }

    formatEnd(i) {
        let date = moment(this.state.rule.states[i].end).format("YYYY-MM-DDTHH:mm")
        return date
    }

    handleTimeChange(start, newVal, index) {
        let rule = this.state.rule;
        if (start) {
            rule.states[index].start = new Date(newVal).getTime()
        } else {
            rule.states[index].end = new Date(newVal).getTime()
        }
        this.setState({rule: rule, hasChanged: true});
    }

    checkErrors() {
        let errors = {}
        let rule = this.state.rule;
        if (rule.start > rule.end) {
            errors['time'] = true
        }
        if (!rule.inactiveState) {
            errors['inactiveState'] = true
        }

        this.errors = errors
    }

    render() {
        let self = this
        this.checkErrors()
        let stateOptions = [];
        if (!this.drawerFooter) {
            this.drawerFooter = document.getElementById('drawer-footer');
        }
        StateOptions.forEach(function (stateOption) {
            stateOption.options.forEach(function (option) {
                if (!option.nonSelectable && !stateOptions.find(sO => sO.value === option.state)) {
                    stateOptions.push({
                        value: option.state,
                        title: option.label,
                        color: option.color
                    });
                }
            });
        });
        let content;
        let saveButton;
        let statesUsed = [StateOptions.map(s => s.options).flat().find(s => s.state === this.state.rule.inactiveState)];
        if (this.state.rule.conditions && statesUsed[0]) {
            self.state.rule.conditions.forEach(function (condition) {
                if (!statesUsed.find(s => s.state === condition.state)) {
                    let thisState = StateOptions.map(s => s.options).flat().find(s => s.state === condition.state);
                    statesUsed.push(thisState);
                }
            });
        }
        switch (this.state.page) {
            case 1:
                content = (<div className={"details"}>
                        <div className={'details-inner'}>
                            <div className={'inner-header'}>
                                <p className={"title-row"}>
                                    Details
                                </p>
                                <p className={"help-row"}>
                                    First off some basic details to describe what the {this.props.rule.template} is for.
                                </p>
                            </div>
                            <div className={'inner-body'}>
                                <div className={"name"}>
                                    <Input autoFocus label={'Name*'} value={this.state.rule.name}
                                           onChange={this.handleNameChange}/>
                                </div>
                                <div className={"description"}>
                                    <Input label={'Description'} value={this.state.rule.description}
                                           multiline
                                           inputProps={{minRows: 3, maxRows: 4}}
                                           onChange={this.handleDescriptionChange}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                break;
            case 2:
                let datePicker;
                if (this.state.specificSchedule) {
                    let datepickerRows = this.state.rule.states.map((s, i) => {
                        return <div
                            className={'row'}
                            style={{marginTop: '10px'}}
                        >
                            <div className={'date-cell'}>
                                <label>Start</label>
                                <Input
                                    type="datetime-local"
                                    error={this.state.triedToProgress && this.errors['state' + i]}
                                    id="meeting-time"
                                    name="meeting-time"
                                    value={this.formatStart(i)}
                                    onChange={(evt) => this.handleTimeChange(true, evt.target.value, i)}
                                />
                            </div>
                            <div className={'date-cell'}>
                                <label>End</label>
                                <Input
                                    type="datetime-local"
                                    error={this.state.triedToProgress && this.errors['state' + i]}
                                    id="meeting-time"
                                    name="meeting-time"
                                    value={this.formatEnd(i)}
                                    onChange={(evt) => this.handleTimeChange(false, evt.target.value, i)}
                                />
                            </div>
                            <div className={'date-cell'}>
                                <label>State</label>
                                <Dropdown
                                    style={{marginTop: '15px'}}
                                    nolabel='true'
                                    error={this.state.triedToProgress && this.errors['action']}
                                    onChange={(e) => this.setRuleState(e, i)}
                                    value={s.state}
                                    fixeditems={stateOptions}/>
                            </div>
                            <div onClick={() => this.removeSpecificStateCondition(i)} className={'icon-cell'}>
                                <Icon icon={'FaTimes'}
                                      size={16}
                                      color={'gd-red'}/>
                            </div>
                        </div>
                    })
                    datePicker = <div>
                        {datepickerRows}
                        <div className={'button-cell'}>
                            <Button additionalclasses={'md'}
                                    onClick={this.addSpecificStateCondition}
                                    color={'gd-green'}
                                    label={'+ Add Condition'}/>
                        </div>
                    </div>
                } else {
                    datePicker =
                        <div>
                            <ScheduleTimeline
                                rules={this.state.rule.conditions}
                                inactiveState={this.state.rule.inactiveState}/>
                            <ScheduleSetter rules={this.state.rule.conditions}
                                            inactiveState={this.state.rule.inactiveState}
                                            onUpdate={this.rulesUpdated}/>
                        </div>
                }

                content = (
                    <div className={"details"}>
                        <div className={'details-inner'}>
                            <div className={'inner-header'}>
                                <p className={"title-row"}>
                                    Times
                                </p>
                                <p className={"help-row"}>
                                    Set the times that devices will be on or off.
                                </p>
                                {this.state.user?.god && !this.props.rule.actions ?
                                    <GdCheckbox checked={this.state.specificSchedule}
                                                name={'specificSchedule'}
                                                onChange={this.onCheckboxChange}
                                                label={'Specific Date & Time'}/> : null}
                            </div>
                            <div className={'inner-body'}>
                                <div className={'row'} style={{marginTop: '15px'}}>
                                    <Dropdown
                                        label={'Default State'}
                                        onChange={this.setInactiveState}
                                        error={this.state.triedToProgress && this.errors['inactiveState']}
                                        value={this.state.rule.inactiveState}
                                        fixeditems={stateOptions}/>
                                </div>
                                {datePicker}
                            </div>
                        </div>
                    </div>
                )
                break;
            case 3:
                let compatibleSkus = StateOptions
                    .filter(a => !a.virtual)
                    .filter(function (assetType) {
                        let compatible = true;
                        statesUsed.forEach(function (stateUsed) {
                            if (stateUsed.state !== 'NONE' && !assetType.options.find(o => o.state === stateUsed.state)) {
                                compatible = false;
                            }
                        });
                        return compatible;
                    }).map(a => a.type);
                content = (
                    <div className={"details"}>
                        <div className={"details-inner"} style={{overflow: 'hidden', 'maxWidth': '850px'}}>
                            <div className={'inner-header'}>
                                <p className={"title-row"}>
                                    Devices
                                </p>
                                <p className={"help-row"}>
                                    The devices this will be controlling.
                                </p>
                            </div>
                            <div className={'inner-body not-scrolling'}>
                                <EditableList
                                    isEditing={true}
                                    getActiveList={this.getActiveList}
                                    getAvailableList={this.getAvailableList}
                                    availableFilters={[
                                        {
                                            field: 'sku',
                                            value: compatibleSkus
                                        },
                                        {
                                            field: '_permission',
                                            value: ['edit', 'admin']
                                        }
                                    ]}
                                    noCancel={true}
                                    noSave={true}
                                    editingColumns={[
                                        {component: StandardListCell, title: 'Site Name', field: 'siteName'},
                                    ]}
                                    onChange={this.handleAssetListChange}
                                />
                            </div>
                        </div>
                    </div>
                )
                break;
            default:
        }

        return (<div className={"inner-rule no-scroll"}>
            {content}
            {this.drawerFooter ? createPortal(<div
                style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: '1 1'}}>
                {this.state.rule.creating ?
                    <div style={{marginRight: '10px', display: 'flex'}}><Button label={"Start Again"} color={'gd-grey'}
                                                                                outline
                                                                                onClick={this.props.restartTemplate}/>
                    </div> : null}
                {!this.state.rule.creating
                && !this.hasChanged()
                    ?
                    <Button label={"Cancel"} outline color={'gd-grey'} onClick={() => this.save({cancel: true})}/> : ''}
                {!this.state.rule.creating
                && this.hasChanged()
                    ?
                    <Button label={"Save and exit"} additionalclasses={'md'} color={'gd-green'} progressRes={true}
                            onClick={this.save}/> : null}
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    {this.state.page === 1 ? <span/> : ''}
                    {this.state.page !== 1 ?
                        <div style={{marginRight: '10px', display: 'flex'}}><Button label={"Back"} color={'gd-grey'}
                                                                                    onClick={() => this.setState({page: this.state.page - 1})}/>
                        </div> : ''}
                    {this.state.page === 1 ? <Button
                        disabled={this.blockNext()}
                        label={"Next"}
                        onClick={() => this.setState({page: this.state.page + 1})}/> : ''}
                    {this.state.page === 2 ? <Button
                        progressRes
                        disabled={this.blockNext()}
                        label={"Next"}
                        onClick={this.next}/> : ''}

                    {this.state.page === 3 && this.state.rule.creating ?
                        <Button color={'gd-green'} additionalclasses={'sm'} label={"Save"}
                                disabled={this.blockNext()}
                                progressRes={true}
                                onClick={this.save}/> : ''}
                </div>
            </div>, this.drawerFooter) : null}

            {/*<Prompt*/}
            {/*    when={this.hasChanged()}*/}
            {/*    message="You have unsaved changes. Do you definitely want to leave?"*/}
            {/*/>*/}
        </div>)
    }
}

export default EditRulePageTimeswitch;

import React from 'react';
import './Account.scss';
import '../../styles/_layout.scss';
import {
    Card,
    CardHeader,
    CardBody,
    Button,
    Input,
    Toggle,
    GdCheckbox,
    Dropdown,
    Toast,
    Loader,
    Icon,
    ScheduleTimeline
} from "gd-react";
import pSBC from "../../services/pSBC";
import SaveBar from "../../components/SaveBar/SaveBar";
import GridDuck from "gridduck";
import PasswordChecker from "../../components/PasswordChecker/PasswordChecker";
import {v4 as uuidv4} from "uuid";
import EditAddressModal from "../../modals/EditAddress/EditAddressModal";
import GenericLoader from "../../components/GenericLoader";
import SiteOccupancyScheduleModal from "../../modals/SiteOccupancyScheduleModal/SiteOccupancyScheduleModal";
import history from "../../meta/history";
import * as _ from "lodash";
import {ChromePicker} from "react-color";
import Popover from "@material-ui/core/Popover";
import generatePaletteFromBrandCol from "../../services/generatePaletteFromBrandCol";
import checkFeature from "../../services/SubFeatureCheckService";
import SubTierModal from "../../modals/SubTier/SubTierModal";

class OrganisationGeneral extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            newPasswordCheck: false,
            currentPassword: '',
            uploadProgress: 0,
            uploadComplete: false,
            uploadingImage: false,
            showOccupancyScheduleModal: false,
            openSubTierModal: null,
            tierUpdated: null,
            orgDetails: {
                organisationName: this.props.item.organisationName || '',
                logoImageThumbnail: this.props.item.logoImageThumbnail || '',
                squareLogoImageThumbnail: this.props.item.squareLogoImageThumbnail || '',
                customDomainUrl: '',
                websiteUrl: '',
                contactPhone: '',
                vatId: '',
                defaultOccupancySchedule: '',
                organisationId: this.props.item.organisationId,
                whiteLabel: false,
                whiteLabelCustomTheme: false,
                inheritWhiteLabelFrom: '',
                inheritWhiteLabelFromBool: false,
                whiteLabelTheme: generatePaletteFromBrandCol()
            },
            accentColour: generatePaletteFromBrandCol().light.primaryAccent,
            hasEditAccess: !!(this.props.item.orgPermission === 'edit' || this.props.item.orgPermission === 'admin' || this.props.item.orgPermission === 'owner')
        }

        // 49b9c4
        this.onFormChange = this.onFormChange.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.hasChanged = this.hasChanged.bind(this);
        this.newPwChanged = this.newPwChanged.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.setUserInfo = this.setUserInfo.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.imageUploaded = this.imageUploaded.bind(this);
        this.accountUpdated = this.accountUpdated.bind(this);
        this.changeCurrentPassword = this.changeCurrentPassword.bind(this);
        this.updateAddress = this.updateAddress.bind(this);
        this.toggleWhiteLabel = this.toggleWhiteLabel.bind(this);
        this.newPasswordCheck = false;


    }

    updateAddress() {
        this.setState({openAddressModal: true});
    }

    componentDidMount() {
        return this.setUserInfo();
        // this.props.item.on('updated', this.accountUpdated)
    }

    componentWillUnmount() {
        this.props.item.off('updated', this.accountUpdated);
    }

    accountUpdated() {
        let orgDetails = this.state.orgDetails;
        orgDetails.logoImageThumbnail = this.props.item.logoImageThumbnail
        this.setState({
            orgDetails: orgDetails
        });
    }

    handleClose() {
        this.setState({open: false})
    }

    hasChanged(force) {
        let self = this;
        let hasChanged = false;
        if (self.props.item) {
            Object.entries(self.props.item).map(([key, value]) => {
                let updatedKey = key.replace(/^_+/i, '');
                if ((updatedKey in self.state.orgDetails) && self.state.orgDetails[updatedKey] !== value && updatedKey !== 'logoImageThumbnail') {
                    hasChanged = true;
                }
            })
        }
        if (force) hasChanged = true;
        return hasChanged;
    }

    async setUserInfo() {
        let self = this, inheritedLogo, squareInheritedLogo;

        let organisation = await GridDuck.getOrganisation({id: self.state.orgDetails.organisationId});
        let occupancySchedulesRes = await GridDuck.getOccupancySchedules({getAll: true});
        let partnerOrgsRes = await GridDuck.getOrgPartners({getAll: true});


        if (organisation.inheritWhiteLabelFrom) {
            let partner = partnerOrgsRes.list.find(po => po.orgFromId === organisation.inheritWhiteLabelFrom);

            if (partner) {
                inheritedLogo = partner.orgFromLogoImage;
                squareInheritedLogo = partner.orgFromSquareLogoImage;
            }
        }

        let partnerOrgs = partnerOrgsRes.list.map(os => {
            return {
                id: os.orgFromId,
                value: os.orgFromId,
                title: os.orgFromName
            }
        }).filter((po) => po.id !== self.state.orgDetails.organisationId);


        partnerOrgs.unshift({
            id: 'none',
            value: '',
            title: 'No one'
        })

        let occupancySchedules = occupancySchedulesRes.list.map(os => {
            return {
                id: os.id,
                value: os.id,
                title: os.name
            }
        });
        occupancySchedules.unshift({
            id: 'none',
            value: 'none',
            title: 'No Default'
        });
        if (this.state.hasEditAccess) {
            occupancySchedules.unshift({
                id: 'add_new',
                value: 'add_new',
                title: 'Create New',
                color: '#49b9c4',
                icon: 'FaPlus'
            })
        }


        self.setState({
            orgDetails: {
                organisationName: this.props.item.organisationName || '',
                logoImageThumbnail: this.props.item.logoImageThumbnail || '',
                squareLogoImageThumbnail: this.props.item.squareLogoImageThumbnail || '',
                websiteUrl: organisation.websiteUrl || '',
                contactPhone: organisation.contactPhone || '',
                vatId: organisation.vatId || '',
                organisationId: this.props.item.organisationId,
                defaultOccupancySchedule: organisation.defaultOccupancySchedule || 'none',
                whiteLabel: organisation.whiteLabel,
                inheritWhiteLabelFrom: organisation.inheritWhiteLabelFrom,
                whiteLabelCustomTheme: organisation.whiteLabelCustomTheme,
                customDomainUrl: organisation.customDomainUrl,
                whiteLabelTheme: organisation.whiteLabelTheme && Object.keys(organisation.whiteLabelTheme).length ? {
                    dark: _.mapKeys(organisation.whiteLabelTheme.dark, (v, k) => _.camelCase(k)),
                    light: _.mapKeys(organisation.whiteLabelTheme.light, (v, k) => _.camelCase(k)),
                } : this.state.orgDetails.whiteLabelTheme
            },
            accentColour: organisation.whiteLabelTheme && Object.keys(organisation.whiteLabelTheme).length ? _.mapKeys(organisation.whiteLabelTheme.light, (v, k) => _.camelCase(k)).primaryAccent : this.state.orgDetails.whiteLabelTheme.light.primaryAccent,
            occupancySchedules: occupancySchedules,
            partnerOrgs: partnerOrgs,
            allPartnerOrgs: partnerOrgsRes.list,
            allOccupancySchedules: occupancySchedulesRes.list,
            hasChanged: false,
            inheritedLogo: inheritedLogo,
            squareInheritedLogo: squareInheritedLogo,
            loaded: true
        });

    }

    async saveChanges(override) {


        let self = this;
        if (override.defaultOccupancySchedule) self.state.orgDetails.defaultOccupancySchedule = override.defaultOccupancySchedule;
        if (this.state.orgDetails.whiteLabelCustomTheme) {
            this.state.orgDetails.whiteLabelTheme = generatePaletteFromBrandCol(this.state.accentColour);
        }
        let organisation = await GridDuck.getOrganisation({id: self.state.orgDetails.organisationId});
        await organisation.set(this.state.orgDetails);
        await self.setUserInfo();
        self.setState({open: true});
        if (this.state.whiteLabelChanged) {
            // Reload page for white label
            return window.location.reload();
        }
        return Promise.resolve();
    }
    ;

    cancelChanges() {
        return this.setUserInfo();
    }
    ;

    updatePassword() {
        let self = this;
        return GridDuck.createPassword({
            oldPassword: this.state.currentPassword,
            newPassword: this.state.newPassword
        }).then(function (res) {

            window.setTimeout(function () {
                self.setState({updatePassword: false});
            }, 1000)
            return Promise.resolve();
        }).catch(function (err) {

            self.setState({updateError: 'Incorrect current password'});
            return Promise.reject();
        });
    }
    ;

    onFormChange(val) {

        let inheritedLogo = this.state.inheritedLogo;
        let squareInheritedLogo = this.state.squareInheritedLogo;
        if (val.target.name === 'defaultOccupancySchedule' && val.target.value === 'add_new') {
            val.stopPropagation();
            val.preventDefault();
            return this.setState({showOccupancyScheduleModal: true})
        }


        if (val.target.name === 'inheritWhiteLabelFrom' && val.target.value && val.target.value !== '') {
            let partner = this.state.allPartnerOrgs.find(po => po.orgFromId === val.target.value);

            if (partner) {
                inheritedLogo = partner.orgFromLogoImage;
                squareInheritedLogo = partner.orgFromLogoImage;
            }
        } else if (val.target.name === 'inheritWhiteLabelFrom' && (val.target.value || val.target.value === '')) {
            inheritedLogo = null;
            squareInheritedLogo = null;
        }
        this.state.orgDetails[val.target.name] = val.target.value;
        this.setState({
            orgDetails: this.state.orgDetails,
            hasChanged: true,
            inheritedLogo: inheritedLogo,
            squareInheritedLogo: squareInheritedLogo
        });
    }

    onCheckboxChange(val, params) {
        let inheritedLogo = this.state.inheritedLogo;
        let squareInheritedLogo = this.state.squareInheritedLogo;
        if (val.target.name === 'inheritWhiteLabelFromBool') {
            inheritedLogo = null;
            squareInheritedLogo = null;
        }
        this.state.orgDetails[val.target.name] = val.target.checked;
        this.setState({
            orgDetails: this.state.orgDetails,
            hasChanged: true,
            inheritedLogo: inheritedLogo,
            squareInheritedLogo: squareInheritedLogo,
            whiteLabelChanged: params.whiteLabelChanged
        });
    }

    changeCurrentPassword(val) {
        this.setState({updateError: ''});
        this.setState({currentPassword: val.target.value});
    }

    newPwChanged(passedChecks, newPassword) {


        this.setState({
            newPasswordCheck: passedChecks,
            newPassword: newPassword
        })
    }

    imageUploaded(selectorFiles) {
        let file = selectorFiles[0];
        if (!file) {
            return;
        }
        let self = this;
        let newFile = {
            itemType: 'organisation',
            itemId: this.props.item.organisationId,
            name: file.name,
            type: file.type,
            size: file.size,
            file: file,
            ref: uuidv4(),
        };
        this.setState({uploadingImage: true, uploadProgress: 0, uploadComplete: false});
        GridDuck.createFile(newFile, function uploadProgress(progressEvt) {

            self.setState({uploadProgress: Math.round((progressEvt.loaded / newFile.size) * 100)});
        }).then(function (file) {

            self.state.orgDetails.logoImageThumbnail = file.location;
            self.setState({uploadComplete: true, uploadingImage: false});


        });
    }

    toggleWhiteLabel(e) {
        checkFeature('WHITE_LABEL').then(featureAvailable => {
            if (featureAvailable) {
                this.onCheckboxChange({target: {name: 'whiteLabel', checked: !this.state.orgDetails.whiteLabel}}, {whiteLabelChanged: true});
            } else {
                this.setState({openSubTierModal: 'WHITE_LABEL', tierUpdated: () => this.toggleWhiteLabel(e)});
            }
        })
    }

    squareImageUploaded(selectorFiles) {
        let file = selectorFiles[0];
        if (!file) {
            return;
        }
        let self = this;
        let newFile = {
            itemType: 'organisationSquare',
            itemId: this.props.item.organisationId,
            name: file.name,
            type: file.type,
            size: file.size,
            file: file,
            ref: uuidv4(),
        };
        this.setState({uploadingSquareImage: true, squareUploadProgress: 0, squareUploadComplete: false});
        GridDuck.createFile(newFile, function uploadProgress(progressEvt) {

            self.setState({squareUploadProgress: Math.round((progressEvt.loaded / newFile.size) * 100)});
        }).then(function (file) {

            self.state.orgDetails.squareLogoImageThumbnail = file.location;
            self.setState({squareUploadComplete: true, uploadingSquareImage: false});


        });
    }

    render() {

        let detailsCard, alertsCard, imageStyle, self = this, squareImageStyle, schedule;
        let footerSaveBar = this.state.hasChanged ?
            <SaveBar onSaveClick={this.saveChanges} onCancelClick={this.cancelChanges}/> : '';

        if (this.state.orgDetails && this.state.orgDetails.defaultOccupancySchedule && this.state.allOccupancySchedules) {
            let activeSchedule = this.state.allOccupancySchedules.find((aos) => aos.id === this.state.orgDetails.defaultOccupancySchedule) || {};
            if (activeSchedule) {
                schedule = activeSchedule.schedule;
            }
            if (schedule) {
                schedule = _.map(schedule, s => _.mapKeys(s, (v, k) => _.camelCase(k)))
            }
        }

        if (this.state.orgDetails && this.state.orgDetails.organisationName) {

            imageStyle = {
                backgroundImage: 'url("' + this.state.orgDetails.logoImageThumbnail + '")'
            }

            if (this.state.inheritedLogo && (this.state.inheritWhiteLabelFromBool || (this.state.orgDetails.inheritWhiteLabelFrom && this.state.orgDetails.inheritWhiteLabelFrom !== ''))) {

                imageStyle = {
                    backgroundImage: 'url("' + this.state.inheritedLogo + '")'
                }
            }
            squareImageStyle = {
                backgroundImage: 'url("' + this.state.orgDetails.squareLogoImageThumbnail + '")'
            }

            if (this.state.squareInheritedLogo && (this.state.inheritWhiteLabelFromBool || (this.state.orgDetails.inheritWhiteLabelFrom && this.state.orgDetails.inheritWhiteLabelFrom !== ''))) {

                squareImageStyle = {
                    backgroundImage: 'url("' + this.state.squareInheritedLogo + '")'
                }
            }


            detailsCard =
                <div>


                    <div className={'row'}>
                        <Input disabled name={'organisationName'} onChange={this.onFormChange}
                               value={this.state.orgDetails.organisationName}
                               label={'Name'}/>
                    </div>
                    <div className={'row'}>
                        <p className={'info-text'}>Please contact <a
                            href={'mailto:support@gridduck.com'}>support@gridduck.com</a> to request a name change
                            of
                            your organisation.</p>
                    </div>
                    <div className={'row'}>
                        <Input disabled={!this.state.hasEditAccess} name={'websiteUrl'} onChange={this.onFormChange}
                               value={this.state.orgDetails.websiteUrl}
                               label={'Website URL'}/>
                    </div>
                    <div className={'row'}>
                        <Input disabled={!this.state.hasEditAccess} name={'contactPhone'}
                               onChange={this.onFormChange}
                               value={this.state.orgDetails.contactPhone}
                               label={'Phone Number'}/>
                    </div>
                    <div className={'row'}>
                        <Input disabled={!this.state.hasEditAccess} name={'vatId'} onChange={this.onFormChange}
                               value={this.state.orgDetails.vatId}
                               label={'VAT ID'}/>
                    </div>
                    <div className={'row'} style={{marginTop: '15px'}}>
                        {this.state.occupancySchedules ? <Dropdown
                            name={'defaultOccupancySchedule'}
                            value={this.state.orgDetails.defaultOccupancySchedule}
                            fixeditems={this.state.occupancySchedules}
                            disabled={!this.state.hasEditAccess}
                            placeholder={'Default Site Occupancy Schedule'}
                            label="Default Site Occupancy Schedule"
                            onChange={this.onFormChange}
                        /> : <GenericLoader/>}
                    </div>
                    <div className={'row reverse'}>
                        <p className={'link'}
                           onClick={() => history.push('/settings/organisation/occupancy-schedules')}>Manage
                            Schedules</p>
                    </div>
                    <div className={'row'}>
                        {schedule ? <ScheduleTimeline
                            rules={schedule}
                            actions={[{
                                id: -1,
                                name: 'Active',
                                color: '#49c570'
                            }]}
                            inactiveState={'inactive'}/> : null}
                    </div>

                </div>

        }

        return (
            this.props.item && this.state.loaded ? <div className='page narrow Account not-flex grey'>
                <Toast onClose={this.handleClose} message={"Save Successful"}
                       anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                       open={this.state.open}/>

                <div className={'column top'}>
                    <div className={'detail-content'}>
                        {footerSaveBar}
                    </div>
                    <div className={'detail-content'}>
                        <Card>
                            <CardHeader>Details</CardHeader>
                            <CardBody>{detailsCard}
                            </CardBody>
                        </Card>
                        <SiteOccupancyScheduleModal
                            onSave={(msg, item) => this.saveChanges({defaultOccupancySchedule: item.id})}
                            onClose={() => this.setState({showOccupancyScheduleModal: false})}
                            open={this.state.showOccupancyScheduleModal}/>
                    </div>
                    <div className={'detail-content'}>
                        <Card>
                            <CardHeader>Branding / White Label</CardHeader>
                            <CardBody>
                                <div className={'label-and-info'}>
                                    <label className={'logo-label'}>Logo Icon + Text</label>
                                    <p className={'info'}>This is used for the logo at the top left of the
                                        dashboard</p>
                                </div>
                                <div className={'row ver-center'}>
                                    <div>
                                        <div className={'profile-picture org long'} style={imageStyle}>
                                            {!this.state.orgDetails.logoImageThumbnail && !this.state.inheritedLogo ?
                                                <Icon icon={'FaImage'} size={'50'}/> : ''}
                                        </div>
                                    </div>
                                    {!this.state.inheritWhiteLabelFromBool && (!this.state.orgDetails.inheritWhiteLabelFrom || this.state.orgDetails.inheritWhiteLabelFrom === '') ?
                                        <div>
                                            <input accept={'image/*'} id={"image-upload"}
                                                   ref={input => this.imageUploadRef = input}
                                                   type={"file"}
                                                   onChange={(e) => this.imageUploaded(e.target.files)} hidden/>
                                            <div className={'row'}>
                                                {this.state.hasEditAccess ? !window.$exampleAccount ? !this.state.uploadingImage ?
                                                    <Button label={'Upload'}
                                                            onClick={() => this.imageUploadRef.click()}/> :
                                                    <Loader type={'circular'} withprogress size={50}
                                                            value={this.state.uploadProgress < 100 ? this.state.uploadProgress : 100}/> : '' : ''}
                                            </div>
                                            <div className={'row'} style={{
                                                textAlign: 'center',
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}>
                                                <a style={{fontSize: '11px', marginTop: '10px'}} target={'_blank'}
                                                   className={'link'}
                                                   href={'https://s3.eu-west-1.amazonaws.com/gridduck.com/GridDuck-White-Label-Asset-Guide.pdf'}>
                                                    White Label Logo Guide
                                                </a>
                                            </div>
                                        </div> : null}

                                </div>
                                <div className={'label-and-info'}>
                                    <label className={'logo-label'}>Logo Icon (square format)</label>
                                    <p className={'info'}>This is used for the logo in your automated email report
                                        PDFs</p>
                                </div>
                                <div className={'row ver-center'}>
                                    <div>

                                        <div className={'profile-picture org'} style={squareImageStyle}>
                                            {!this.state.orgDetails.squareLogoImageThumbnail && !this.state.squareInheritedLogo ?
                                                <Icon icon={'FaImage'} size={'50'}/> : ''}
                                        </div>
                                    </div>
                                    {!this.state.inheritWhiteLabelFromBool && (!this.state.orgDetails.inheritWhiteLabelFrom || this.state.orgDetails.inheritWhiteLabelFrom === '') ?
                                        <div>
                                            <input accept={'image/*'} id={"image-upload"}
                                                   ref={input => this.squareImageUploadRef = input}
                                                   type={"file"}
                                                   onChange={(e) => this.squareImageUploaded(e.target.files)}
                                                   hidden/>
                                            <div className={'row'}>
                                                {this.state.hasEditAccess ? !window.$exampleAccount ? !this.state.uploadingSquareImage ?
                                                    <Button label={'Upload'}
                                                            onClick={() => this.squareImageUploadRef.click()}/> :
                                                    <Loader type={'circular'} withprogress size={50}
                                                            value={this.state.squareUploadProgress < 100 ? this.state.squareUploadProgress : 100}/> : '' : ''}
                                            </div>

                                        </div> : null}

                                </div>
                                {this.state.openSubTierModal ? <SubTierModal open={this.state.openSubTierModal}
                                                                             tierUpdated={this.state.tierUpdated}
                                                                             onClose={() => this.setState({openSubTierModal: false})}/> : ''}
                                {(!this.state.orgDetails.inheritWhiteLabelFrom || this.state.orgDetails.inheritWhiteLabelFrom === '') ?
                                    <div className={'row'}>
                                        <GdCheckbox checked={this.state.orgDetails.whiteLabel} name={'whiteLabel'}
                                                    onChange={this.toggleWhiteLabel}
                                                    label={'Enable White Label'}/>
                                    </div> : null}
                                {this.state.orgDetails.whiteLabel && (this.state.orgDetails.inheritWhiteLabelFrom && this.state.orgDetails.inheritWhiteLabelFrom !== '') ?
                                    <div className={'row'}>
                                        <GdCheckbox
                                            checked={this.state.inheritWhiteLabelFromBool || (this.state.orgDetails.inheritWhiteLabelFrom && this.state.orgDetails.inheritWhiteLabelFrom !== '')}
                                            name={'inheritWhiteLabelFromBool'}
                                            disabled
                                            onChange={(val) => {
                                                this.state.inheritWhiteLabelFromBool = val.target.checked;
                                                if (!val.target.checked) {
                                                    this.state.orgDetails.inheritWhiteLabelFrom = '';
                                                }
                                                this.setState({
                                                    orgDetails: this.state.orgDetails,
                                                    hasChanged: !val.target.checked,
                                                    inheritedLogo: null
                                                });
                                            }}
                                            label={'Inheriting Branding from Partner Organisation'}/>
                                    </div> : null}
                                {this.state.orgDetails.whiteLabel && (this.state.inheritWhiteLabelFromBool || (this.state.orgDetails.inheritWhiteLabelFrom && this.state.orgDetails.inheritWhiteLabelFrom !== '')) ?
                                    <div className={'row'} style={{marginBottom: '15px'}}>
                                        {this.state.partnerOrgs ? <Dropdown
                                            name={'inheritWhiteLabelFrom'}
                                            value={this.state.orgDetails.inheritWhiteLabelFrom}
                                            fixeditems={this.state.partnerOrgs}
                                            disabled
                                            placeholder={'Inheriting Branding from...'}
                                            label="Inheriting Branding from..."
                                            onChange={this.onFormChange}
                                        /> : <GenericLoader/>}
                                    </div> : null}
                                {this.state.orgDetails.whiteLabel && !this.state.inheritWhiteLabelFromBool && (!this.state.orgDetails.inheritWhiteLabelFrom || this.state.orgDetails.inheritWhiteLabelFrom === '') && this.state.orgDetails?.customDomainUrl ?
                                    <div className={'row'} style={{marginBottom: '15px'}}>
                                        <Input top label={<div>Custom Domain URL (contact <a
                                            href={'mailto:support@gridduck.com'}>support@gridduck.com </a>
                                            to change)</div>} value={this.state.orgDetails?.customDomainUrl}
                                               disabled/></div> : null}
                                {this.state.orgDetails.whiteLabel && !this.state.inheritWhiteLabelFromBool && (!this.state.orgDetails.inheritWhiteLabelFrom || this.state.orgDetails.inheritWhiteLabelFrom === '') && !this.state.orgDetails?.customDomainUrl ?
                                    <div className={'row'} style={{marginBottom: '15px'}}>
                                        <p style={{margin: 0}}>Contact <a
                                            href={'mailto:support@gridduck.com'}>support@gridduck.com</a> to get your
                                            own {encodeURIComponent(this.state.orgDetails.organisationName.replace(' ', '').toLowerCase())}.gridduck.com
                                            domain or to host the dashboard on your own domain.</p></div> : null}
                                {this.state.orgDetails.whiteLabel && !this.state.inheritWhiteLabelFromBool && (!this.state.orgDetails.inheritWhiteLabelFrom || this.state.orgDetails.inheritWhiteLabelFrom === '') ?
                                    <div className={'row'}>
                                        <GdCheckbox checked={this.state.orgDetails.whiteLabelCustomTheme}
                                                    name={'whiteLabelCustomTheme'}
                                                    onChange={(e) => {
                                                        this.onCheckboxChange(e, {whiteLabelChanged: true});
                                                    }}
                                                    label={'Use Custom Colour'}/>
                                    </div> : null}
                                {this.state.orgDetails.whiteLabelCustomTheme && this.state.orgDetails.whiteLabel && (!this.state.orgDetails.inheritWhiteLabelFrom || this.state.orgDetails.inheritWhiteLabelFrom === '') ?
                                    <div className={'row'}>

                                        <div onClick={(e) => {
                                            this.setState({
                                                showPicker: true,
                                                anchorEl: e.currentTarget
                                            });
                                        }}>
                                            <div className={'clickable'} style={{
                                                backgroundColor: this.state.accentColour,
                                                width: '50px',
                                                height: '50px',
                                                borderRadius: '10px'
                                            }}/>
                                            <Input onChange={(e) => {
                                                this.setState({
                                                    showPicker: true,
                                                    anchorEl: e.currentTarget
                                                });
                                            }} label={'Hex Code'} value={this.state.accentColour}/>
                                        </div>
                                        <Popover
                                            id={'color-picker'}
                                            disablePortal
                                            open={this.state.showPicker}
                                            anchorEl={this.state.anchorEl}
                                            onClose={() => this.setState({showPicker: null})}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}>
                                            <ChromePicker
                                                color={this.state.accentColour}
                                                onChangeComplete={(color) => {

                                                    self.setState({
                                                        accentColour: color.hex,
                                                        whiteLabelChanged: true,
                                                        hasChanged: true
                                                    });
                                                }}
                                            /> </Popover>
                                    </div> : null}
                            </CardBody>
                        </Card>
                        <SiteOccupancyScheduleModal
                            onSave={(msg, item) => this.saveChanges({defaultOccupancySchedule: item.id})}
                            onClose={() => this.setState({showOccupancyScheduleModal: false})}
                            open={this.state.showOccupancyScheduleModal}/>
                    </div>
                    <div className={'detail-content'}>
                        {footerSaveBar}
                    </div>
                </div>

            </div> : <GenericLoader text={'Fetching'} textLineTwo={'Details...'}/>
        );
    }
}

export default OrganisationGeneral;

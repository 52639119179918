import React, {Component} from "react";
import '../../../styles/_layout.scss';
import {Button, CardBody, Icon, Loader, Tabs} from "gd-react";
import GridDuck from "gridduck";
import GdModal from "../../../components/GdModal/GdModal";
import IntegrationStatus from "../IntegrationStatus";
import getFormat from "../../../services/formatter";

class PowerRadarModal extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.closeModal = this.closeModal.bind(this);
        this.state = {};
        this.errors = {};
        this.changed = false;
        this.onTabClick = this.onTabClick.bind(this);
        this.setTabs = this.setTabs.bind(this);
        this.beingIntegration = this.beingIntegration.bind(this);

        this.modalClosed = false;
    }

    componentDidMount() {
        
        this.setTabs();
    }

    async beingIntegration() {
        
        await GridDuck.createThirdPartyIntegration({integrationMetaData: {id: 'power-radar'}});
        
        
        await this.props.reloadData();
        
        window.setTimeout(() => {
            this.setTabs();
        }, 300);
        return Promise.resolve();
    }

    setTabs() {
        
        let tabs = [[
            {
                id: 1,
                title: 'Instructions',
                onTabClick: this.onTabClick,
                selected: !this.state.tabs || this.state.tabs[0][0].selected
            }
        ]]
        if (this.props.item.metadata.setup) {
            tabs[0].unshift({
                id: 2,
                title: 'Status',
                onTabClick: this.onTabClick,
                selected: this.state.tabs && this.state.tabs[0][1] && this.state.tabs[0][1].selected
            });
        }
        
        this.setState({tabs: tabs});
    }

    onTabClick(ev, tab) {
        this.state.tabs.forEach(function (tabArr) {
            tabArr.forEach(function (t) {
                t.selected = (tab.id === t.id);
            })
        })
        this.setState(this.state);
    }


    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    render() {
        let footer, self = this;
        

        return (
            <GdModal
                title={'PowerRadar Integration'}
                open={this.props.open}
                contentLabel={'PowerRadar Integration'}
                onClose={this.closeModal}>
                {this.state.tabs ? <Tabs tabData={this.state.tabs}/> : null}
                <CardBody>
                    {this.state.tabs && (this.state.tabs[0].length === 1 && this.state.tabs[0][0].selected) || (this.state.tabs && this.state.tabs[0].length === 2 && this.state.tabs[0][1].selected) ?
                        <div>
                            {!this.props.item.metadata.setup ?
                                <Button progressRes label={'Begin Integration'} additionalclasses={'md'}
                                        onClick={this.beingIntegration}/> : null}

                            {this.props.item.metadata.setup ? <div>
                                <p><a
                                    href={'https://support.powerradar.energy/kb/guide/en/export-jobs-setup-udqNRLujJu/Steps/2418173,2418355'}
                                    target={'_blank'}>
                                    PowerRadar Export Job set up instructions
                                </a></p>
                                <p>Follow the steps in the link above with the following details:</p>
                                <ul className={'integration-steps'}>
                                    <li><span>Job Name:</span> GridDuck Integration</li>
                                    <li><span>Job Status:</span> Enabled</li>
                                    <li><span>Job Category:</span> Measurements</li>
                                    <li><span>Sites:</span> All (Unless you only wish for certain sites to be sent to
                                        GridDuck)
                                    </li>
                                    <li><span>Job Execution Frequency:</span> Every 5 minutes</li>
                                    <li><span>Format & Transportation:</span> JSON over HTTPS</li>
                                    <li><span>HTTPS Method:</span> POST</li>
                                    <li><span>HTTPS URL:</span> {this.props.item.metadata.power_radar_ftp_url}</li>
                                </ul>
                            </div> : null}
                        </div> : null}
                    {this.state.tabs && this.state.tabs[0].length === 2 && this.state.tabs[0][0].selected ?
                        <div>
                            <div className={'status-list-item row'}
                                 style={{marginBottom: '10px', alignItems: 'center'}}>
                                <span style={{marginRight: '10px'}}>Status: </span> <IntegrationStatus
                                item={this.props.item} inline/>
                            </div>
                            {this.props.item.metadata.connected ? <div>
                                <p className={'status-list-item'}>
                                    <span>Last contacted:</span> {getFormat('lastContacted')(this.props.item.metadata.last_contacted)}
                                </p>
                                <p className={'status-list-item'}><span>Sites:</span> {this.props.item.metadata.sites}
                                </p>
                                <p className={'status-list-item'}>
                                    <span>Devices:</span> {this.props.item.metadata.devices}</p>
                            </div> : null}
                        </div> : null}
                </CardBody>
            </GdModal>
        )
    }
}

export default PowerRadarModal;
import history from "../meta/history";
import cookie from "react-cookies";

const getUrl = function () {
    let url = window.location.search
    
    return new URLSearchParams(url);
}
export const addCookie = function (name, value) {
    cookie.save(name, value, {path: '/'});
}
export const getCookie = function (name) {
    return cookie.load(name);
}
export const deleteCookie = function (name) {
    cookie.remove(name, {path: '/'});
}
export const updateCookie = function (name, value) {
    cookie.save(name, value, {path: '/'});
}
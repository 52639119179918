import React from "react";
import {
    List, ProcessStateListCell, DataRequestDetailListCell, ButtonListCell, Button, StandardListCell
} from "gd-react";
import GridDuck from 'gridduck';
import moment from "moment";
import history from "../../meta/history";
import ShareDataRequestModal from "../../modals/ShareDataRequest/ShareDataRequestModal";
import './DataArchive.scss'
import {confirmAlert} from "react-confirm-alert";
import NoItems from "../../components/noItems";
import TariffModal from "../TariffModal";
import AddInvoiceModal from "../../modals/AddInvoice/AddInvoiceModal";
import AddGroupModal from "../../modals/AddGroup/AddGroupModal";
import CreateDataDownload from "./CreateDataDownload";
import AddDataDownloadModal from "../../modals/AddDataDownload/AddDataDownloadModal";


class DataArchive extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.getList = this.getList.bind(this);
        this.delete = this.delete.bind(this);
        this.share = this.share.bind(this);
        this.download = this.download.bind(this);
        
    }

    delete(object) {
        return object.delete();
    }

    share(object) {
        this.setState({shareData: object.id});
    }

    download(object) {
        if (this.props.invoices) {
            GridDuck.createInvoiceRequestLink({
                invoiceRequestId: object.id
            }).then(function (dataRequestLink) {
                window.open(dataRequestLink.url, '_blank');
            });
        } else {
            GridDuck.createDataRequestLink({
                dataRequestId: object.id
            }).then(function (dataRequestLink) {
                window.open(dataRequestLink.url, '_blank');
            });
        }
    }

    getList(params) {
        if (this.props.invoices) {
            return GridDuck.getInvoiceRequests(params);
        } else {
            return GridDuck.getDataRequests(params).then(function (res) {
                let promises = res.list.map(function (dataRequest) {
                    return GridDuck.getAsset({
                        id: dataRequest.assetIds[0]
                    }).catch(function (err) {
                        return Promise.resolve({
                            siteTimezone: 'Europe/London'
                        });
                    });
                })
                return Promise.all(promises)
                    .then(function (assets) {
                        assets.forEach(function (asset, idx) {
                            let assetDate = new moment(res.list[idx].startDate * 1000).tz(asset.siteTimezone);
                            let browserDate = new moment(res.list[idx].startDate * 1000);
                            let offset = 0;
                            if (assetDate && browserDate) {
                                offset = (assetDate.utcOffset() - browserDate.utcOffset()) * 60;
                            }
                            res.list[idx]._startDate = moment(res.list[idx].startDate * 1000).unix() + offset;
                            assetDate = new moment(res.list[idx].endDate * 1000).tz(asset.siteTimezone);
                            browserDate = new moment(res.list[idx].endDate * 1000);
                            if (assetDate && browserDate) {
                                offset = (assetDate.utcOffset() - browserDate.utcOffset()) * 60;
                            }
                            res.list[idx]._endDate = moment(res.list[idx].endDate * 1000).unix() + offset;
                        })
                        return res;
                    })
            });
        }
    }


    render() {
        let self = this, columns;
        if (this.props.invoices) {
            columns = [{component: ProcessStateListCell, title: 'Status', field: 'status', maxWidth: '50px'},
                {
                    component: StandardListCell,
                    title: 'ID',
                    field: 'invoiceNo',
                    maxWidth: '150px'
                },{
                    component: StandardListCell,
                    title: 'Recipient Name',
                    field: 'customerName',
                    maxWidth: '150px'
                },
                {
                    component: StandardListCell,
                    type: 'dateRange',
                    title: 'Billing Range',
                    custom: (val) => <span>{val.startDate} - {val.endDate}</span>,
                    maxWidth: 'none'
                },
                {
                    component: ButtonListCell,
                    title: "Download",
                    field: "status",
                    icon: 'FaDownload',
                    disabled: (object) => object.status !== 'READY',
                    color: 'gd-blue',
                    onClick: this.download, maxWidth: '80px'
                },
                {
                    component: ButtonListCell,
                    title: "Share",
                    field: "status",
                    icon: 'FaShare',
                    disabled: (object) => object.status !== 'READY',
                    color: 'gd-blue',
                    onClick: this.share,
                    maxWidth: '80px'
                }
            ]

            if (this.props.hasEditPermission) {
                columns.push({
                    component: ButtonListCell,
                    title: "Delete",
                    field: "status",
                    icon: 'FaTrash',
                    color: 'gd-red',
                    onClick: (object) => confirmAlert({
                        customUI: ({onClose}) => {
                            return (
                                <div className='dialog'>
                                    <div className={"top-content"}>
                                        <h2>Delete {object.name}</h2>
                                        <p>Are you sure you want to
                                            delete {object.name}?</p>
                                    </div>
                                    <div className={"bottom-menu"}>
                                        <Button label={"No"} color={'gd-grey'} onClick={onClose}/>
                                        <Button color={'gd-red'}
                                                onClick={() => {
                                                    self.delete(object);
                                                    onClose();
                                                }}
                                                label={"Yes, delete"}
                                        />
                                    </div>
                                </div>
                            );
                        }
                    }),
                    maxWidth: '80px'
                })
            }
        } else {
            columns = [
                {component: ProcessStateListCell, title: 'Status', field: 'status', maxWidth: '50px'},
                {
                    component: DataRequestDetailListCell,
                    type: 'count',
                    title: '# Devices',
                    field: 'assetIds',
                    maxWidth: '150px'
                },
                {
                    component: DataRequestDetailListCell,
                    type: 'dateRange',
                    title: 'Date Range',
                    field: 'assetIds'
                },
                {
                    component: DataRequestDetailListCell,
                    type: 'granularity',
                    title: 'Granularity',
                    field: 'assetIds',
                    maxWidth: 'none'
                },
                {
                    component: DataRequestDetailListCell,
                    type: 'dataType',
                    title: 'Data Type',
                    maxWidth: 'none'
                },
                {
                    component: ButtonListCell,
                    title: "Download",
                    field: "status",
                    icon: 'FaDownload',
                    disabled: (object) => object.status !== 'READY',
                    color: 'gd-blue',
                    onClick: this.download, maxWidth: '80px'
                },
                {
                    component: ButtonListCell,
                    title: "Share",
                    field: "status",
                    icon: 'FaShare',
                    disabled: (object) => object.status !== 'READY',
                    color: 'gd-blue',
                    onClick: this.share,
                    maxWidth: '80px'
                },
                {
                    component: ButtonListCell,
                    title: "Delete",
                    field: "status",
                    icon: 'FaTrash',
                    color: 'gd-red',
                    onClick: (object) => confirmAlert({
                        customUI: ({onClose}) => {
                            return (
                                <div className='dialog'>
                                    <div className={"top-content"}>
                                        <h2>Delete {object.name}</h2>
                                        <p>Are you sure you want to
                                            delete {object.name}?</p>
                                    </div>
                                    <div className={"bottom-menu"}>
                                        <Button label={"No"} color={'gd-grey'} onClick={onClose}/>
                                        <Button color={'gd-red'}
                                                onClick={() => {
                                                    self.delete(object);
                                                    onClose();
                                                }}
                                                label={"Yes, delete"}
                                        />
                                    </div>
                                </div>
                            );
                        }
                    }),
                    maxWidth: '80px'
                }
            ]
        }
        let noItemsElem;
        if (this.props.invoices) {
            noItemsElem = <NoItems text={'You haven\'t created any invoices yet'} createtext={'Create Invoice'} modal={AddInvoiceModal}/>;
        }
        if (this.props.dataDownloader) {
            noItemsElem = <NoItems text={'You haven\'t downloaded any data yet'} createtext={'Download Data'} modal={AddDataDownloadModal}/>;
        }
        return (

            <div className={"page data-archive"}>
                <List
                    noitemelem={noItemsElem}
                    columns={columns}
                    getlist={this.getList}
                    filters={[]}/>
                {this.state.shareData ?
                    <ShareDataRequestModal invoices={this.props.invoices} open={!!(this.state.shareData)} dataRequestId={this.state.shareData}
                                           onClose={() => this.setState({shareData: null})}/> : ''}
                {this.state.openInvoice ?
                    <AddInvoiceModal onClose={() => this.setState({openInvoice: false})}
                                 open={this.state.openInvoice}/> : null}
            </div>
        )
    }
}

export default DataArchive;
import React from "react";
import {Menu} from '@material-ui/core'
import {Icon, GdCheckbox, Dropdown} from "gd-react";
import GridDuck from 'gridduck'
import * as _ from 'lodash';


class OrgManagementFilter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            loading: true,
            columns: this.props.columns
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    handleClick(e) {
        let self = this;
        self.setState({
            anchorEl: e ? e.currentTarget : this.state.anchorEl
        });
    }

    handleClose() {
        this.setState({anchorEl: null});
    }

    addOrReplaceInArray(array, string, condition) {
        // Create a clone of the array
        let clonedArray = [...array];

        const index = clonedArray.map((item) => item.id).indexOf(string);

        if (condition) {
            // Add string if it doesn't exist
            if (index === -1) {
                let originalIndex = this.props.potentialOptions.map((po) => po.id).indexOf(string)
                let item = this.props.potentialOptions[originalIndex];
                if (item) clonedArray.push(item);
            }
        } else {
            // Remove string if it exists
            if (index > -1) {
                clonedArray.splice(index, 1);
            }
        }

        return clonedArray;
    }

    render() {

        return (
            <div className={'filter-menu list-align'}>
                <div style={{position: 'relative', overflow: 'hidden'}} onClick={this.handleClick}>
                    <div className={'filterIcon clickable'}><Icon icon={'MdFilterList'}/> {this.props.label}</div>
                </div>
                <Menu
                    id="filter-menu"
                    getContentAnchorEl={null}
                    anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                    transformOrigin={{vertical: "top", horizontal: "left"}}
                    anchorEl={this.state.anchorEl}
                    open={!!(this.state.anchorEl)}
                    disableEnforceFocus
                    onClose={this.handleClose}>
                    <div className={'filter-menu-container kpi-tooltip'}>
                        <div className={'menu-header'}>
                            {this.props.label}
                        </div>
                        <div className={'column'}>
                            {this.props.potentialOptions.map(o => {
                                    
                                    let checkedIds = this.props.columns.map(o => o.id);
                                    return (<GdCheckbox
                                        checked={checkedIds.indexOf(o.id) !== -1}
                                        onChange={(e) => {
                                            this.props.onUpdate(this.addOrReplaceInArray(this.props.columns, o.id, e.target.checked), this.props.onlyIs)
                                        }}
                                        label={o.title}
                                    />)
                                }
                            )}
                        </div>

                    </div>
                </Menu>
            </div>
        )
    }
}

export default OrgManagementFilter

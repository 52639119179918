import React from 'react';
import './Account.scss';
import GridDuck from 'gridduck';
import {Card, CardBody, CardHeader, Button, Icon, Tooltip} from "gd-react";
import GenericLoader from "../../components/GenericLoader";
import moment from "moment-timezone";
import SubTiers from "../../modals/SubTier/SubTiers";

class DashboardSubscription extends React.Component {
    constructor(props) {
        super(props);
        const self = this;
        this.state = {};
    }

    render() {
        return (
            <div className='page narrow Account not-flex grey'>
                <div className={'column top'} style={{alignItems: 'start'}}>
                    <div className={'row'}>
                        <SubTiers/>
                    </div>
                </div>

            </div>
        );
    }
}

export default DashboardSubscription;

import React from 'react';
import './DesktopSiteAssetListPage.scss';
import {
    List,
    LastActiveCell,
    StandardListCell,
    StateListCell,
    SensorDataListCell,
    DataTypeListCell,
    DeviceCategoryListCell
} from "gd-react";
import SiteAssetListPage from "./SiteAssetListPage";
import GettingStarted from "../../components/GettingStarted";
import GenericLoader from "../../components/GenericLoader";
import IssuePopover from "../../components/IssuePopover/IssuePopover";

class DesktopSiteAssetListPage extends SiteAssetListPage {

    render() {

        let assets;
        let noDevicePage;
        if (this.props.hubs) {
            noDevicePage =
                <GettingStarted permission={this.state.orgPermission}
                                closed={{one: true, two: true, three: this.props.hubs.list.length}}/>
        }
        let filters;

        if (this.props.item) {
            if (this.props.filterType === 'site_id') {
                filters = [
                    {
                        field: 'siteId',
                        value: this.props.item.id
                    },
                    {
                        field: 'locationId',
                        value: this.props.item.locationId
                    },
                    {
                        field: 'parentAsset',
                        value: null
                    },
                ];
            } else if (this.props.filterType === 'site_group_id') {
                filters = [
                    {
                        field: 'siteGroupId',
                        value: this.props.item.id
                    },{
                        field: 'parentAsset',
                        value: null
                    },
                ];
            } else if (this.props.filterType === 'org_partner_id') {
                filters = [
                    {
                        field: 'orgPartnerId',
                        value: this.props.item.id
                    },{
                        field: 'parentAsset',
                        value: null
                    },
                ];
            } else if (this.props.filterType === 'asset_id' && this.props.type === 'hub') {

                filters = [
                    {
                        field: 'gatewayId',
                        value: this.props.item.id
                    }
                ]
            }

            let columns = [
                {
                    component: StandardListCell,
                    bold: true,
                    sortable: true,
                    title: 'Name',
                    field: 'name'
                },
                {
                    component: DataTypeListCell,
                    title: 'Data Type',
                    field: 'dataType',
                    sortable: true,
                    defaultSort: 'desc'
                },
                {
                    component: DeviceCategoryListCell,
                    title: 'Device Category',
                    field: 'deviceCategory',
                    sortable: true,
                    defaultSort: 'desc'
                },
                {
                    component: SensorDataListCell, title: 'Data',
                    field: 'temperature'
                },
                {
                    component: StateListCell,
                    endAdornment: (object) => {
                        return <IssuePopover
                            type={'Asset'}
                            updateDisconnectedDrawer={this.props.updateFunctions.updateDisconnectedDrawer}
                            item={object}/>
                    },
                    sortable: false,
                    title: 'Status',
                    field: 'currentState',
                    showLabel: true,
                    permission: this.state.orgPermission
                },
                {component: LastActiveCell, title: 'Last Active', field: 'lastContacted', maxWidth: 'none'}
            ];

            if (this.props.filterType !== 'site_id') {
                columns.splice(1, 0,
                    {component: StandardListCell, bold: true, sortable: true, title: 'Site', field: 'siteName'},)
            }

            assets =
                (<List
                    noitemelem={noDevicePage}
                    searchable
                    sortby={{
                        field: 'name',
                        type: 'asc'
                    }}
                    // itemsPerPage={5}
                    columns={columns}
                    rowOnClick={this.goToAsset}
                    getlist={this.getList}
                    parentField={this.props.type === 'hub' ? null : "parentAsset"}
                    childrenField={this.props.type === 'hub' ? null : "children"}
                    expanded={this.props.type !== 'hub'}
                    filters={filters}
                />
            )
        }
        return (
            <div className='page'>
                {this.props.loaded ? assets : <GenericLoader/>}
            </div>
        );
    }
}

export default DesktopSiteAssetListPage;

import React, {Component} from "react";
import "./SubTiers.scss"
import '../../styles/_layout.scss';
import {Toast, Icon, Button, Tooltip} from "gd-react";
import GenericLoader from "../../components/GenericLoader";
import GridDuck from 'gridduck';
import Popover from "@material-ui/core/Popover";

class SubTiers extends Component {

    constructor(props) {
        super(props);

        this.state = {
            showConfirmation: null,
            anchorEl: null,
            account: null,
            frequency: "ANNUAL",
            loaded: false
        }

        this.updateAccount = this.updateAccount.bind(this);
    }

    updateAccount = () => {
        this.setState({account: this.account}, () => {
            if (this.props.tierUpdated) {
                this.props.tierUpdated(this.account);
            }
        });
    }

    async componentDidMount() {
        let owner;
        if (!this.state.account) {
            const account = await GridDuck.getAccount({id: GridDuck.userId});
            this.account = account;
            this.hasPerm = account?.orgPermission === 'admin' || account?.orgPermission === 'owner';
            if (!this.hasPerm) {
                const orgMembers = await GridDuck.getOrganisationMembers({
                    filters: [{
                        field: 'orgPermission',
                        value: 'owner'
                    }]
                });
                owner = orgMembers.list.find(m => m.orgPermission === 'owner');
            }
            this.setState({
                loaded: true,
                account: account,
                owner: owner,
                frequency: account.dashboardTierFrequency || "ANNUAL"
            });
            this.account.on('updated', this.updateAccount);
        }
    }

    componentWillUnmount() {
        if (this.account && this.account.off) {
            this.account.off('updated', this.updateAccount);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.account && prevProps && (prevProps.account.dashboardTier !== this.props.account.dashboardTier)) {
            if (this.props.tierUpdated) {
                this.props.tierUpdated(this.account);
            }
        }
    }

    featureRow(icon, feature, enabled, is_requested_feature) {
        return <Tooltip id={'featureTooltip'} label={feature.description && enabled ?
            <div className={'feature-tooltip'}>
                <p className={'f-name'}>{feature.name}</p>
                <p>{feature.description}</p>
                <div className={'img-container'} style={{backgroundImage: `url(${feature.imageUrl})`}}/>
                <div className={'img-loader'}><GenericLoader/></div>
            </div> : null
        }>
            <div
                className={`tier-feature ${enabled ? 'enabled' : ''} ${is_requested_feature ? 'bold' : ''} ${feature.description && enabled ? 'has-tooltip' : ''}`}>
                <Icon icon={icon}/>
                <span
                    className={'feature-name'}>{feature.cap ? (feature.cap + 'x') : feature.capRelevant ? 'Unlimited' : ''} {feature.name}</span>
            </div>
        </Tooltip>
    }

    async changePlan(tier, frequency) {
        if (tier.id === 'ESSENTIAL') {
            frequency = 'ANNUAL';
        }
        await this.state.account.set({dashboardTier: tier.id, dashboardTierFrequency: frequency});
        this.setState({
            showToast: true,
            anchorEl: null,
            showConfirmation: null,
            frequency: frequency
        });
        return Promise.resolve();
    }

    render() {
        const tiers = this.state.loaded && this.state.account ? this.state.account.allTierFeatures.sort((a, b) => a.level - b.level) : null;
        let reasonItem;
        let requiredTier;
        if (tiers) {
            let allFeatures = tiers.find(t => t.id === this.state.account.dashboardTier)?.features?.sort((a, b) => a.position - b.position);
            if (allFeatures) {
                reasonItem = allFeatures.find(f => f.id === this.props.frequestedFeature);
                if (!reasonItem) {
                    allFeatures = tiers[tiers.length - 1].features.sort((a, b) => a.position - b.position);
                    reasonItem = allFeatures.find(f => f.id === this.props.frequestedFeature);
                }
                for (const tier of this.state.account.allTierFeatures.sort((a, b) => a.level - b.level)) {
                    const tierHasFeature = tier.features.find(f => f.id === this.props.frequestedFeature);
                    if (this.props.frequestedFeature === 'MEMBERS') {
                        const thisTierIndex = tiers.findIndex(t => t.id === this.state.account.dashboardTier);
                        requiredTier = tiers[thisTierIndex + 1];
                    } else {
                        if (tierHasFeature && tier.id !== 'ESSENTIAL') {
                            requiredTier = tier;
                            break;
                        }
                    }
                }
            }
        }
        const frequencyOptions = [
            {
                id: 'ANNUAL',
                discount: '-10%',
                name: 'Annual'
            },
            {
                id: 'MONTHLY',
                name: 'Monthly'
            }
        ];

        return (
            <div>
                {this.state.loaded ? <div className="SubTierModal">
                    {this.props.inModal ? [
                            (<p className={'sub-tier-modal-heading'}>Upgrade your plan</p>),
                            (<p className={'sub-tier-modal-sub-heading'}>{reasonItem?.cap ? `You've hit your limit
                            of` : `To use`} <span
                                className={'brand-col'}>{reasonItem?.cap ? reasonItem.cap + 'x' : ''} {reasonItem?.name}</span> {reasonItem?.cap ? `to add more` : ``} upgrade
                                to <span
                                    className={'brand-col'}>{requiredTier?.name}</span> plan.</p>), !this.hasPerm ?
                                <p>
                                    You lack the access level to update the plan, please contact your dashboard admin
                                    (<span className={'link'} onClick={() => {
                                    window.location.href = `mailto:${this.state.owner?.username}?subject=GridDuck%20dashboard%20upgrade%20request&body=Hey ${this.state.owner?.name},%0A%0A${this.state.account?.name}%20is%20requesting%20to%20change%20your%20${window._companyName}%20Tier%20level%20to%20${requiredTier?.name}.%0A%0AClick%20this%20link%20to%20upgrade:%0A%0A%3Chttps://dashboard.gridduck.com/settings/billing-and-shipping/manage-plan%3E`
                                }}>{this.state.owner?.username}</span>) to
                                    request an upgrade to {requiredTier?.name} Tier.
                                </p> : null] :
                        <p className={'sub-tier-modal-heading'}>Manage your plan</p>}
                    <div className={"row"} style={{justifyContent: 'center'}}>
                        <div className={"frequency-toggle"}>
                            {frequencyOptions.map(fo =>
                                <div
                                    onClick={() => this.setState({frequency: fo.id})}
                                    className={`frequency-option ${this.state.frequency === fo.id ? 'selected' : ''}`}>{fo.name}
                                    {fo.discount ? <span className={'discount'}>{fo.discount}</span> : null}
                                </div>)}
                        </div>
                    </div>
                    <div className="tiers row">
                        {tiers.map(tier => {
                                const is_current = ((this.state.frequency === this.state.account.dashboardTierFrequency || this.state.account.dashboardTier === 'ESSENTIAL') && tier.id === this.state.account.dashboardTier);
                                // const is_popular = tier.id === 'PRO';
                                const is_required = tier.id === requiredTier?.id;
                                const is_popular = requiredTier ? tier.id === requiredTier?.id : tier.id === 'PRO';
                                return <div className={'column'}>
                                    <div className={`popular ${is_popular ? 'on' : ''}`}>
                                        {is_popular && requiredTier ? 'Recommended' : !requiredTier && is_popular ? 'Most Popular' : null}
                                    </div>
                                    <div
                                        className={`tier ${is_popular ? ' pop' : ''} ${is_required ? ' required' : ''}`}>
                                        <p className={'tier-name'}>{tier.name}</p>
                                        <p className={'tier-description'}>{tier.description}</p>
                                        {tier.priceAnnually && tier.priceMonthly ?
                                            <p className={'tier-cost'}>
                                                {tier.id !== 'ESSENTIAL' ? <span
                                                    className={`${this.state.frequency === 'ANNUAL' && tier.priceAnnually ? 'strikeout' : ''}`}>{`£${this.state.frequency === 'ANNUAL' ? (tier.priceMonthly * 12) + '/yr' : tier.priceMonthly + '/mth'}`}</span> : null}
                                                {this.state.frequency === 'ANNUAL' || tier.id === 'ESSENTIAL' ? <span
                                                    style={{marginLeft: '5px'}}>{`£${this.state.frequency === 'ANNUAL' ? tier.priceAnnually + '/yr' : tier.priceMonthly + '/mth'}`}</span> : null}
                                            </p> :
                                            <p className={'tier-cost'}>Contact sales for pricing</p>}
                                        {this.hasPerm ? <div
                                            onClick={is_current ? null : tier.id !== 'PARTNER' ? (e) => this.setState({
                                                anchorEl: e.currentTarget,
                                                showConfirmation: {tier: tier, frequency: this.state.frequency}
                                            }) : () => {
                                                window.location.href = 'mailto:sales@gridduck.com?subject=Partner%20Plan%20Enquiry&body=I%20would%20like%20to%20learn%20more%20about%20becoming%20a%20GridDuck%20Partner';
                                            }}
                                            className={`plan-button ${is_current ? 'current no-pointer' : ''} ${is_popular ? ' most-popular' : ''}`}>
                                            {`${is_current ? 'Current Plan' : tier.id !== 'PARTNER' ? 'Choose Plan' : 'GET IN TOUCH'}`}
                                        </div> : <div
                                            onClick={() => {
                                                window.location.href = `mailto:${this.state.owner?.username}?subject=GridDuck%20dashboard%20upgrade%20request&body=Hey ${this.state.owner?.name},%0A%0A${this.state.account?.name}%20is%20requesting%20to%20change%20your%20${window._companyName}%20Tier%20level%20to%20${tier.name}.%0A%0AClick%20this%20link%20to%20upgrade:%0A%0A%3Chttps://dashboard.gridduck.com/settings/billing-and-shipping/manage-plan%3E`
                                            }}
                                            className={`plan-button ${is_current ? 'current no-pointer' : ''} ${is_popular ? ' most-popular' : ''}`}>
                                            {`${is_current ? 'Current Plan' : 'Contact Admin'}`}
                                        </div>}
                                        <Popover
                                            id={'confirm-plan-change'}
                                            disablePortal
                                            open={this.state.showConfirmation && this.state.showConfirmation.tier.id === tier.id}
                                            anchorEl={this.state.anchorEl}
                                            onClose={() => this.setState({showConfirmation: null})}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}>
                                            <div className={'confirmation-popover'}>
                                                <p className={'confirm-heading'}>Confirm Plan Change</p>
                                                <p className={'confirm-description'}>Change plan
                                                    to <span>{tier.name}</span> ({this.state.frequency === 'MONTHLY' ? 'Monthly' : 'Annual'})
                                                </p>
                                                <Button label={'Confirm'}
                                                        progressRes
                                                        additionalclasses={'md'}
                                                        onClick={() => this.changePlan(tier, this.state.frequency)}/>
                                            </div>
                                        </Popover>
                                        <div className={'support-feature'}>
                                            {this.featureRow('FaHeadset', {name: tier.supportDescription}, true)}
                                        </div>
                                        {tiers[tiers.length - 1].features.sort((a, b) => a.position - b.position).map(atf => {
                                            const featureExistsInTier = tier.features.find(f => f.id === atf.id);
                                            return this.featureRow('FaCheck', featureExistsInTier || atf, featureExistsInTier, reasonItem?.id === atf.id && is_required);
                                        })}
                                    </div>
                                </div>
                            }
                        )}
                    </div>
                </div> : <div className={'loader-wrapper absolute'}><GenericLoader/></div>}
                <Toast onClose={() => this.setState({showToast: null})}
                       message={`Plan updated to ${tiers?.find(t => t.id === this.state.account.dashboardTier)?.name} ${this.state.frequency === 'MONTHLY' ? 'Monthly' : 'Annual'}`}
                       open={this.state.showToast}
                       severity="success"
                       anchorOrigin={{
                           vertical: 'bottom',
                           horizontal: 'left',
                       }}
                />
            </div>
        )
    }
}

export default SubTiers;
import React from "react";
import "./widget.scss";
import GDGraphs from 'gd-graphs'
import GenericLoader from "../../../components/GenericLoader";
import {DataTypes} from "gd-react";

class WidgetGraph extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {};
        let self = this, graphFunc, graphParams;
        this.setVal = this.setVal.bind(this);
        if (this.props.type === 'line') graphFunc = GDGraphs.lineGraph;
        if (this.props.type === 'bar') graphFunc = GDGraphs.barChart;
        if (this.props.type === 'breakdown') graphFunc = GDGraphs.assetBreakdown;

        graphParams = {
            "element": "#graph" + this.props.id.replace(/ /g,''),
            "timezone": this.props.dateData.timezone,
            "dataTypes": this.props.dataTypes,
            "start": this.props.dateData.start,
            "end": this.props.dateData.end,
            "granularity": this.props.granularity,
            "color": this.props.graphType !== 'category_breakdown' ? this.props.color : null,
            "widget": true,
            "hidden": [],
            "showTooltip": true,
            "combined": !this.props.average,
            "average": this.props.average,
            onVisibleItems: function (visibleItems) {
                if (!self.graphRemoved) {
                    self.setState({noData: visibleItems.length === 0})
                }
            },
            error: function () {
                if (!self.graphRemoved) {
                    self.setState({error: true, ready: true}, () => {
                        self.props.onLoad();
                    })
                }
            },
            onReady: function () {
                if (!self.graphRemoved) {
                    self.setState({ready: true}, () => {
                        self.props.onLoad();
                    })
                }
            },
            onKPIData: function (kpiData) {
            },
            onDefaultSelected: function (selectedItems) {
            },
            tooltipOverride: function (tooltipData) {
                if (!self.graphRemoved) {
                    if (tooltipData && tooltipData.data && tooltipData.data[0]) self.setVal(tooltipData.data[0], tooltipData.date, tooltipData.compareDate, tooltipData.data[0].color);
                }
            },
            zoomCallback: function (zoomData) {
            }
        }

        if (this.props.filterType && this.props.filterType !== 'asset_id') {
            graphParams.filterType = this.props.filterType;
            graphParams.filterId = this.props.filterId;
        }
        if (this.props.filterType === 'asset_id' && this.props.dataItems) {
            graphParams.assets = this.props.dataItems;
        }

        if (this.props.graphType === 'group_breakdown') {
            graphParams.groupBy = {
                id: 'siteId',
                title: 'siteName'
            };
        }
        if (this.props.graphType === 'category_breakdown') {
            graphParams.groupBy = {
                id: 'deviceCategoryId',
                title: 'deviceCategory'
            };
        }

        if (this.props.dateData.compare_start && this.props.dateData.compare_end) {
            graphParams.compareTo = {
                show: true,
                start: this.props.dateData.compare_start,
                end: this.props.dateData.compare_end
            }
        }

        if (this.props.aggregateBy) graphParams.aggregateBy = this.props.aggregateBy;
        let start = this.props.dateData.start, end = this.props.dateData.end, day = 86400, week = 86400 * 7 - (60 * 60);
        if (((end - start < day) && this.props.aggregateBy === 'hourOfDay') || ((end - start < week) && this.props.aggregateBy === 'dayOfWeek')) {
            this.state.rangeTooSmall = true;
            this.state.ready = true;
            self.props.onLoad();
            return;
        }
        let dT = DataTypes.find(dT => dT.type === this.props.dataTypes[0]);
        if (dT && dT.nonAdditive) {
            graphParams.average = true;
            graphParams.combined = false;
        }
        this.graph = graphFunc(graphParams);

    }

    componentDidMount() {
        let loader = document.querySelector('.loader-box.lb' + this.props.id + ' svg');
        if (loader) loader.style.color = this.props.color;
        this.props.showLegend(false);
    }

    setVal(val, date, compareDate, col) {
        let value = val.value;
        if (val === '*') value = '';
        else if (val && !val.value && val.value !== 0) value = '-'
        let compareValue = val.compareValue;
        if (val === '*') compareValue = '';
        else if (val && !val.compareValue && val.compareValue !== 0) compareValue = '-'
        let show = (value || compareValue || date || compareDate);
        this.props.showLegend(show);
        this.setState({
            value: value,
            compareValue: compareValue,
            date: date,
            compareDate: compareDate,
            show: show,
            color: col
        })
    }

    componentWillUnmount() {
        if (this.graph) {
            this.graphRemoved = true;
            this.graph.remove();
        }
    }

    render() {
        return [(<div key={'wv' + this.props.id}
                      className={'widget-value ' + (this.state.show ? 'showing' : '')}>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                <div style={{display: 'flex', color: this.state.color ? this.state.color : this.props.color}}>
                    {this.state.value} {this.props.dateData.compare_start && this.state.compareValue && !this.state.compareDate ?
                    <span className='compare'
                          style={{marginLeft: '5px'}}>{'(' + this.state.compareValue + ')'}</span> : null}
                </div>
                <span className={'date-label'}>{this.state.date}</span>
            </div>
            <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
                <span className='compare'>{this.state.compareDate ? this.state.compareValue : null}</span>
                <span className={'date-label compare'}>{this.state.compareDate}</span>
            </div>
        </div>),
            (<div key={'g' + this.props.id} id={'graph' + this.props.id.replace(/ /g,'')} className={'widget-graph'}
                  onMouseLeave={() => {
                      this.setState({show: false});
                      this.props.showLegend(false);
                  }}/>),
            (!this.state.ready ?
                <div key={'lb' + this.props.id} className={'loader-box lb' + this.props.id}><GenericLoader/>
                </div> : null),
            ((this.state.error || this.state.noData) && this.state.ready && !this.props.isPendingData ?
                <p key={'ne' + this.props.id} className={'not-enough'}>No Data</p> : null),
            (this.state.noData && this.props.isPendingData && this.state.ready ?
                <p key={'ne' + this.props.id} className={'not-enough'}>Loading Data...</p> : null),
            (this.state.rangeTooSmall ?
                <p key={'ne' + this.props.id} className={'not-enough'}>Date range too small</p> : null)
        ]
    }

}

export default WidgetGraph;

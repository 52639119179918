import React from 'react';
import './MainOrganisationPage.scss';
import GridDuck from 'gridduck';
import UserDrawer from "../../components/Drawer/UserDrawer";
import {
    AccountAdminListCell,
    AccessLevelListCell,
    TickListCell,
    LastActiveCell,
    EmailListCell,
    ProfileListCell,
    Button,
    DemandListCell,
    EditableList,
    List,
    SiteGroup,
    StandardListCell,
    StateListCell
} from "gd-react";
import BreadcrumbService from "../../meta/breadcrumb-service";
import BreadcrumbList from "../../components/BreadcrumbList";
import Modal from 'react-modal';
import history from "../../meta/history";
import EditLocationModal from "../LocationModal";

class OrganisationMembers extends React.Component {
    constructor(props) {
        super(props);
        this.getList = this.getList.bind(this);
        this.openUserDrawer = this.openUserDrawer.bind(this)
        let self = this;
        GridDuck.getAccount({id: GridDuck.userId}).then(function (account) {
            self.setState({
                myId: account.id,
                hasOwner: account.orgPermission === 'owner',
                hasAdmin: account.orgPermission === 'admin' || account.orgPermission === 'owner'
            });
        });
        this.state = {
            showUserDrawer: null,
            filters: this.props.listFilters
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
    }

    async getList(retrievalParams) {
        return GridDuck.getOrganisationMembers(retrievalParams);
    }

    // todo
    //      limit access to open drawer for admin or owner users

    openUserDrawer(user) {
        
        // history.push('/organisation/user/' + user.id);
        this.setState({showUserDrawer: user})
    }

    render() {
        let columns = [
            {component: ProfileListCell, bold: true, sortable: true, title: 'Name', field: 'name', defaultSort: 'asc'},
            {component: StandardListCell, title: 'Role', field: 'jobRole'},
            // {component: EmailListCell, sortable: true, title: 'Email Address', field: 'username'},
            {
                component: LastActiveCell,
                title: 'Last active',
                field: 'lastActive',
                comparisonOnly: true,
                onlineStatus: true
            },
            {
                component: AccessLevelListCell,
                title: 'Organisation Access Level',
                field: 'orgPermission',
                myId: this.state.myId,
                hasAdmin: this.state.hasAdmin,
                hasOwner: this.state.hasOwner,
                maxWidth: 'none'
            },
        ];
        // if (this.state.hasAdmin) {
        //     // columns.push(
        //     //     {component: TickListCell, title: 'MFA', field: 'mfaEnabled', maxWidth: '50px'}
        //     // )
        //     columns.push(
        //         {
        //             component: AccountAdminListCell,
        //             title: '',
        //             field: 'name',
        //             myId: this.state.myId,
        //             hasAdmin: this.state.hasAdmin,
        //             hasOwner: this.state.hasOwner
        //         }
        //     );
        // }
        return (
            <div className='page'>
                <List
                    rowHeight={'30px'}
                    columns={columns}
                    getlist={this.getList}
                    filters={this.props.listFilters}
                    rowOnClick={this.openUserDrawer}
                />
                {this.state.showUserDrawer ? <UserDrawer
                    onClose={() => {
                        this.setState({showUserDrawer: null});
                    }}
                    hasAdmin={this.state.hasAdmin}
                    hasOwner={this.state.hasOwner}
                    open={!!this.state.showUserDrawer}
                    item={this.state.showUserDrawer}/> : null}
            </div>
        );
    }
}

export default OrganisationMembers;

import GridDuck from 'gridduck';
import {addStandingChargesFromRange} from "./TariffTypesService";

const groupBy = function (xs, key) {
    return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
};

const spendFilters = function (spendDataType, start, end, assets, filterType, filterId) {

    if (filterType) {
        if (filterType === 'asset_id') {
            return [
                {
                    field: 'assetId',
                    value: filterId
                },
                {
                    field: 'dataType',
                    value: spendDataType
                },
                {
                    field: 'start',
                    value: start,
                },
                {
                    field: 'end',
                    value: end,
                },
                {
                    field: 'granularity',
                    value: end - start
                },
            ]
        }
        return [
            {
                field: 'assetId',
                value: "filtered"
            },
            {
                field: filterType,
                value: filterId
            },
            {
                field: 'dataType',
                value: spendDataType
            },
            {
                field: 'start',
                value: start,
            },
            {
                field: 'end',
                value: end,
            },
            {
                field: 'granularity',
                value: end - start
            },
        ]
    }

    return [
        {
            field: 'assetId',
            value: assets.map(a => a.id).join(',')
        },
        {
            field: 'dataType',
            value: spendDataType
        },
        {
            field: 'start',
            value: start,
        },
        {
            field: 'end',
            value: end,
        },
        {
            field: 'granularity',
            value: end - start
        },
    ]
};

const generateTotals = function (assets) {
    let totalValues = [];
    assets.forEach((asset) => {
        let assetTotal = asset.telemetry.length ? asset.telemetry[0].consumption : null;
        if (assetTotal || assetTotal === 0) {
            let existingCurrency = totalValues.find(c => c.currency === asset.currency)
            if (existingCurrency) {
                existingCurrency.value += assetTotal;
            } else {
                totalValues.push({
                    currency: asset.currency,
                    value: assetTotal
                })
            }
        }
    });
    return totalValues;
};

const percentageIncrease = function (a, b) {
    let percent;
    if (b !== 0) {
        if (a !== 0) {
            percent = (b - a) / a * 100;
        } else {
            percent = b * 100;
        }
    } else {
        percent = -a * 100;
    }
    return Math.floor(percent);
};

const getSpend = async function (assets, dateData, spendDataType, filterType, filterId) {

    let day_in_seconds = 86400;
    let days = (dateData.end - dateData.start) / day_in_seconds;

    let filters = spendFilters(spendDataType, dateData.start, dateData.end, assets, filterType, filterId);

    let spend_values, compare_spend_values;
    try {
        spend_values = await GridDuck.getAssetConsumptionBatchs({filters: filters})
    } catch (e) {

    }


    if (dateData.compare_start && dateData.compare_end) {
        let compare_filters = spendFilters(spendDataType, dateData.compare_start, dateData.compare_end, assets, filterType, filterId);
        try {
            compare_spend_values = await GridDuck.getAssetConsumptionBatchs({filters: compare_filters})
        } catch (e) {

        }
    }

    if (!spend_values || !spend_values.list) {
        return {
            totalValues: []
        };
    }

    let filteredList = spend_values.list;
    let comparedFilteredList;
    if (compare_spend_values) {
        comparedFilteredList = compare_spend_values.list;
    }
    if (filterType === 'site_id' || filterType === 'site_group_id'|| filterType === 'org_partner_id') {
        filteredList = filteredList.filter(a => !a.parent);
        if (comparedFilteredList) {
            comparedFilteredList = comparedFilteredList.filter(a => !a.parent);
        }
    }
    let assets_with_tariffs = filteredList.filter((r) => !r.noTariff);
    let assets_with_tariffs_comparison;
    if (compare_spend_values) {
        assets_with_tariffs_comparison = comparedFilteredList.filter((r) => !r.noTariff)
    }
    let assets_without_tariffs = spend_values.list.filter((r) => r.noTariff);
    let not_fully_covered = assets_without_tariffs.length;

    let total = generateTotals(assets_with_tariffs);
    let compareTotals;
    if (assets_with_tariffs_comparison) {
        compareTotals = generateTotals(assets_with_tariffs_comparison);
    }

    //Merge comparisons with total
    let totalValues = total.map((v) => {
        let comparisonValue;
        if (compareTotals) {
            comparisonValue = compareTotals.find((cv) => cv.currency === v.currency);
        }
        if (comparisonValue) {
            v.comparisonValue = comparisonValue.value;
            let percentageChange = percentageIncrease(v.comparisonValue, v.value);
            v.percentageChange = Math.abs(Math.round(percentageChange));
            v.percentageDirection = percentageChange < 0 ? 'down' : 'up';
        }
        return v;
    })

    let sites_with_tariffs = [];
    if (assets_without_tariffs && assets_without_tariffs.length) {
        for (const a of assets_without_tariffs) {

            let site_res = await GridDuck.getSite({id: a.siteId});
            sites_with_tariffs.push({
                id: site_res.id,
                name: site_res.name
            })
        }
    }
    //Calculate standing charge
    let site_ids = Object.keys(groupBy(assets_with_tariffs, 'siteId')),
        _sites = groupBy(assets_with_tariffs, 'currency'),
        sites_per_currency = {};

    Object.entries(_sites).forEach(([key, value], i) => {
        sites_per_currency[key] = Object.keys(groupBy(value, 'siteId'));
    });



    if (site_ids && site_ids.length) {
        let tariffs_from_sites = await GridDuck.getTariffs({
            filters: [{
                field: 'siteIds',
                value: site_ids
            }, {field: 'utilityType', value: spendDataType}]
        });

        let tariffs_grouped_by_currency = groupBy(tariffs_from_sites.list, '_currency');
        let standingCharges = addStandingChargesFromRange(tariffs_from_sites.list, dateData.start, dateData.end);
        let comparisonStandingCharges = dateData.compare_start && dateData.compare_end ? addStandingChargesFromRange(tariffs_from_sites.list, dateData.compare_start, dateData.compare_end) : null;

        for (const [key, value] of Object.entries(tariffs_grouped_by_currency)) {
            for (const _tariff of value) {
                let standingChargeObj = standingCharges ? standingCharges.find(sc => sc.id === _tariff.id) : null;
                let comparisonStandingChargeObj = comparisonStandingCharges ? comparisonStandingCharges.find(sc => sc.id === _tariff.id) : null;
                if (!standingChargeObj) {
                    continue;
                }
                let eCurrency = totalValues.find(c => c.currency === key);
                let total = parseFloat(_tariff.standingCharge) * standingChargeObj.standingChargeDays * sites_per_currency[key].length;
                let comparisonTotal = comparisonStandingChargeObj ? parseFloat(_tariff.standingCharge) * comparisonStandingChargeObj.standingChargeDays * sites_per_currency[key].length : 0
                if (eCurrency) {
                    if (!eCurrency.standingChargeValue) {
                        eCurrency.standingChargeValue = total;
                    } else {
                        eCurrency.standingChargeValue += total;
                    }
                    if (!eCurrency.standingChargeComparisonValue) {
                        eCurrency.standingChargeComparisonValue = comparisonTotal;
                    } else {
                        eCurrency.standingChargeComparisonValue += comparisonTotal;
                    }
                } else {
                    totalValues.push({
                        currency: key,
                        standingChargeValue: total,
                        standingChargeComparisonValue: comparisonTotal
                    })
                }
            }
        }
    }



    return {
        totalValues: totalValues,
        noTariff: !assets_with_tariffs.length,
        notFullyCovered: not_fully_covered,
        sitesWithoutTariffs: sites_with_tariffs
    };
};

export default getSpend;

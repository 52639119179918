import React from "react";
import "./DayOfWeekWidget.scss";
import WidgetHeader from "../WidgetHeader";
import WidgetGraph from "../WidgetGraph";

class DayOfWeekWidget extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {};
        this.dataType = this.props.dataType.type;
    }

    render() {

        return (
            <div className={'widget ' + (!this.state.ready ? 'loading' : '') + (this.state.showLegend ? 'show-legend' : '')}>
                <WidgetHeader info={{
                    title: 'Day of Week Average',
                    type: this.props.type,
                    icon: this.props.icon,
                    dataType: this.dataType,
                    color: this.props.color,
                    className: this.props.className,
                    dateData: this.props.dateData,
                    assets: this.props.assets,
                    item: this.props.item,
                    graphType: 'bar',
                    widgetType: 'day_of_week'
                }}/>

                <WidgetGraph showLegend={(val) => this.setState({showLegend: val})} dateData={this.props.dateData}
                             aggregateBy={'dayOfWeek'} granularity={24 * 60 * 60} color={this.props.color}
                             isPendingData={this.props.isPendingData}
                             onLoad={() => this.setState({ready: true})} filterType={this.props.filterType} filterId={this.props.filterId}
                             average={this.props.title} dataTypes={[this.dataType]} dataItems={this.props.dataItems}
                             type={'bar'} id={this.props.id}/>

            </div>
        )
    }

}

export default DayOfWeekWidget;

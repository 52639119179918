import React from "react";
import GridDuck from "gridduck";
import history from "../../meta/history";
import getFormat from "../../services/formatter";
import * as _ from 'underscore';
import {Button, CollapsibleTable, EditableList, Icon, Menu, StandardListCell, StateOptions} from "gd-react";
import Modal from "react-modal";
import GenericLoader from "../../components/GenericLoader";

class Fleet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.getList = this.getList.bind(this);
        this.getList();
        this.getInnerData = this.getInnerData.bind(this);
    }

    getInnerData(rowId) {
        function skuDetails(sku) {
            let type = StateOptions.find(sO => sO.type === sku);
            if (type) {
                return {
                    name: type.label,
                    image: '/images/products/' + sku + '.png'
                }
            } else {
                return {
                    name: 'Unknown',
                    image: '/images/products/unknown.png'
                }
            }
        }

        
        return GridDuck.getAssetGods({
            filters: [{
                field: 'gatewayId',
                value: rowId
            }]
        }).then(function (assets) {
            
            return assets.list.map(function (asset) {
                return [
                    {value: asset.disconnected ? [<Icon icon={'GiPlainCircle'} color={'gd-red'}/>,<a href={'/device/' + asset.id}>&nbsp;{asset.name}</a>] :
                            [<Icon icon={'GiPlainCircle'} color={'gd-green'}/>,<a href={'/device/' + asset.id}>&nbsp;{asset.name}</a>]},
                    {value: getFormat('lastContacted')(asset.lastContacted)},
                    {value: skuDetails(asset.sku).name},
                    {value: asset.assetSignalStrength !== undefined ? getFormat('signal')(asset.assetSignalStrength) : '-'}
                ]
            })
        })
    }

    onFormChange(val) {
        this.setState({emailAddress: val.target.value});
    }


    getList() {
        let self = this;
        let organisations;
        GridDuck.getGatewaySoftwareVersions({getAll: true, items: 100}).then(function (versionList) {
            self.setState({targetVersion: versionList.list.find(v => v.target).id});
        })
        GridDuck.getGatewayGods({getAll: true, items: 100}).then(function (res) {
            let hubs = res.list.sort((hb, ha) => ha.lastContacted - hb.lastContacted);
            self.setState({hubs: hubs, loaded: true})
        });
    }

    render() {
        const formatUpdateSlot = function (slot) {
            if (slot === -1) {
                return 'Instant';
            } else if (slot < 10) {
                return '0' + slot + ':00';
            } else {
                return slot + ':00';
            }
        }

        let self = this;
        return (
            <div className='page Account not-flex'>
                {this.state.loaded ? <div className={'column top'}>
                    <p>Connected: {self.state.hubs.filter(h => !h.disconnected).length} / {self.state.hubs.length}</p>
                    <CollapsibleTable
                        getInnerData={this.getInnerData}
                        columns={{
                            outerColumns: [
                                {title: 'Name'},
                                {title: 'Serial No'},
                                {title: 'Location Name'},
                                {title: 'Last Contacted'},
                                {title: 'Connection'},
                                {title: 'Signal'},
                                {title: 'Model'},
                                {title: 'Firmware Status'},
                                {title: 'Devices'}
                            ],
                            innerColumns: [
                                {title: 'Device Name'},
                                {title: 'Last Contacted'},
                                {title: 'Type'},
                                {title: 'Signal'},
                            ]
                        }} tableData={
                        _.map(self.state.hubs, function (hub) {
                            let firmwareStatus;
                            let connectionStatus = hub.activeConnection ? hub.activeConnection.substr(0,1).toUpperCase() + hub.activeConnection.substr(1) : 'Unknown';
                            if (hub.activeConnection==='mobile') {
                                connectionStatus += ' (' +getFormat('mobileSignalStrength')(hub.mobileStrength) + ')';
                            } else if (hub.activeConnection==='wifi') {
                                connectionStatus += ' (' +getFormat('assetSignalStrengthSummary')(hub.wifiStrength) + ')';
                            }
                            if (hub.version === self.state.targetVersion) {
                                firmwareStatus = [<Icon size={20} icon={'TiTick'} color={'gd-green'}/>,<span>Up-to-date</span>];
                            } else if (hub.version !== hub.targetVersion) {
                                firmwareStatus = [<Icon size={20} icon={'MdTimer'} color={'gd-blue'}/>,<span>Queued</span>];
                            } else {
                                firmwareStatus = [<Icon size={20} icon={'TiDelete'} color={'gd-red'}/>,<span>Out-of-date</span>];
                            }
                            return {
                                id: hub.id,
                                data: [
                                    {value: hub.disconnected ? [<Icon icon={'GiPlainCircle'} color={'gd-red'}/>,<a href={'/hub/' + hub.id}>&nbsp;{hub.name}</a>] :
                                                [<Icon icon={'GiPlainCircle'} color={'gd-green'}/>,<a href={'/hub/' + hub.id}>&nbsp;{hub.name}</a>]},
                                    {value: hub.serialNumber},
                                    {value: hub.locationName},
                                    {value: getFormat('lastContacted')(hub.lastContacted)},
                                    {value: connectionStatus},
                                    {value: 'N/A'},
                                    {value: hub.modelNumber},
                                    {value: firmwareStatus},
                                    {value: hub.connectedDevices + "/" + hub.totalDevices},
                                ],
                                innerData: []
                            }
                        })
                    }/>
                </div> : <div style={{
                    display: 'flex',
                    flex: '1 1',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                }}><GenericLoader text={'Fetching...'} textLineTwo={'Hub Data'}/></div>}
            </div>
        )
    }
}

export default Fleet;

import React from "react";
import GridDuck from "gridduck";
import history from "../../meta/history";
import * as _ from 'underscore';
import moment from "moment";
import {
    LastActiveCell,
    CollapsibleTable,
    Icon,
    Pagination,
    Menu,
    Input,
    Tooltip,
    GdCheckbox,
    DataTypes,
    Dropdown,
    Loader,
    Button,
    DateTimeRangePicker
} from "gd-react";
import GenericLoader from "../../components/GenericLoader";
import cookie from "react-cookies";
import SubscriptionComponent from "./OrgMgmtTools/DisplaySubscriptions";
import DetailsPopover from "./OrgMgmtTools/DetailsPopover";
import './OrganisationManagement.scss';
import getFormat from "../../services/formatter";
import NotificationsFilter from "../../components/Notifications/NotificationsFilter";
import OrgManagementFilter from "../../components/OrgManagement/OrgManagementFilter";

class OrganisationManagement extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            filters: [
                {
                    field: 'getAllGlobally',
                    value: true
                }
            ],
            columns: [
                {title: 'Organisation', id: 'organisation'},
                {title: 'Sites', id: 'sites'},
                {title: 'Devices', id: 'devices'},
                {title: 'Active Devices', id: 'active_devices'},
                {title: 'Exempt from license fee', id: 'sub_payment_exempt'}
            ],
            orgFilters: [],
            organisations: null,
            pageNum: 1,
            // fixme Pagination isn't working anyway for some reason on the API from what it looks like, so having it make the massive request on every next page click is redundant.
            itemsPerPage: 1000,
            editingIsFree: false,
            exhibitionOnly: false,
            onlyWithDevices: false,
            noExhibition: true,
            paidOnly: false,
            includeInternal: false,
            dateRange: {},
            dataTypes: [],
            dataType: null,
            loaded: false,
            lockedOnly: false
        };
        this.loading = true;
        this.getList = this.getList.bind(this);
        this.getUsage = this.getUsage.bind(this)
        this.editFreePaid = this.editFreePaid.bind(this);
        this.editLocked = this.editLocked.bind(this);
        this.loginAsUser = this.loginAsUser.bind(this);
        this.getDataTypes = this.getDataTypes.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this)
        this.debouncedHandleInputChange = this.debounce(this.debouncedChange.bind(this), 500);
        this.potentialOptions = [
            {title: 'Organisation', id: 'organisation',},
            {title: 'Sites', id: 'sites'},
            {title: 'Devices', id: 'devices'},
            {title: 'Active Devices', id: 'active_devices'},
            {title: 'Usage', id: 'usage'},
            {title: 'Subs', id: 'subs'},
            {title: 'Seats', id: 'seats'},
            {title: 'Hubs', id: 'hubs'},
            {title: 'SIMS', id: 'sims'},
            {title: 'Sub Items', id: 'sub_items'},
            {title: 'Total Sub Value', id: 'total_sub_value'},
            {title: 'Completed Signup', id: 'completed_signup'},
            {title: 'Free/Paid', id: 'free_paid'},
            {title: 'Locked (unable to login)', id: 'locked'},
            {title: 'Exempt from license fee', id: 'sub_payment_exempt'},
            {title: 'Tier', id: 'dashboard_tier'},
            {title: 'Tier Frequency', id: 'dashboard_tier_frequency'}
        ]
    }

    renderComponentById(id, organisation) {
        // Dynamically return the component based on the id
        switch (id) {
            case 'organisation':
                return <div>Organisation Component</div>;
            case 'sites':
                return <div>Sites Component</div>;
            case 'devices':
                return <div>Devices Component</div>;
            case 'active_devices':
                return <div>Active Devices Component</div>;
            case 'usage':
                return <div>Usage Component</div>;
            case 'subs':
                return <div>Subs Component</div>;
            case 'sub_items':
                return <div>Sub Items Component</div>;
            case 'total_sub_value':
                return <div>Total Sub Value Component</div>;
            case 'completed_signup':
                return <div>Completed Signup Component</div>;
            case 'free_paid':
                return <div>Free/Paid Component</div>;
            case 'locked':
                return <div>Locked Component (Unable to Login)</div>;
            default:
                return <div>Unknown Component</div>;
        }
    }

    componentDidMount() {
        this.getDataTypes();
        this.getList();
    }

    onFormChange(val) {
        this.setState({emailAddress: val.target.value});
    }

    async editFreePaid(organisation, free) {
        await organisation.set({id: organisation.id, free: free});
        this.getList();
    }

    async editLocked(organisation, locked) {
        await organisation.set({id: organisation.id, account_locked: locked});
        this.getList();
    }
    async editSubPayment(organisation, sub_payment_exempt) {
        await organisation.set({id: organisation.id, sub_payment_exempt: sub_payment_exempt});
        this.getList();
    }

    debounce(func, waitTime) {
        let timeout;
        return (...args) => {
            // everytime this is called on keystroke, it wipes the current timer,
            // which may not have executed, and establishes another. eventually when the
            //  keystrokes end, one of these prop funcs will execute
            clearTimeout(timeout)
            timeout = setTimeout(() => func(...args), waitTime)
        }
    }

    convertDataTypeToFormatterString(dataType) {
        switch (dataType) {
            case 'DELIVERED':
                return 'delivered'
            case 'DELIVERED_WATER_HOT':
                return 'waterPulse';
            case 'DELIVERED_GAS':
                return 'gasPulse'
            case 'DELIVERED_GAS_SPEND':
                return 'spend';
            case 'GAS_ENERGY_DELIVERED':
                return 'delivered';
            case 'GAS_ENERGY_DELIVERED_SPEND':
                return 'spend';
            case 'GENERATED_DELIVERED':
                return 'delivered';
            case 'DELIVERED_WATER':
                return 'waterPulse';
            case 'DELIVERED_SPEND':
                return 'spend';
            case 'DELIVERED_WATER_SPEND':
                return 'spend';
            case 'DELIVERED_WATER_HOT_SPEND':
                return 'spend';
            case 'EXPORTED_DELIVERED':
                return 'delivered';
            default:
                return '';
        }
    }

    getDataTypes() {
        let dataTypes = DataTypes.filter((dt) => dt.graphType === 'bar').map((dt) => {
            return {
                title: dt.label + ' (' + dt.unit + ')' + ' - ' + dt.id,
                value: dt.id,
            }
        })
        this.setState({dataTypes: dataTypes})
    }

    handleInputChange(val) {
        let value = val.target.value
        this.debouncedHandleInputChange(value)
    }

    debouncedChange(value) {
        this.setState({
            search: value,
            loaded: false
        }, this.getList)
    }

    generateUsageHTML(organisation) {
        if (!organisation.usage) {
            return <Button
                onClick={() => this.getUsage(organisation)}
                disabled={!this.state.dateRange.start || !this.state.dataType}
                label={"Calculate"}
                color={"gd-brand"}
            />
        } else if (organisation.usage === 'loading') {
            return <div
            ><Loader type={'circular'} size={20}/></div>
        } else {
            return <div>{organisation.usage}</div>
        }
    }

    async getUsage(organisation) {
        if (!this.state.dateRange.start || !this.state.dataType) return
        let list = this.state.organisations
        let element = list.find((org) => org.id === organisation.id)
        element.usage = 'loading';
        this.setState({organisations: list})
        const start = Math.round(new Date(this.state.dateRange.start).getTime() / 1000)
        const end = Math.round(new Date(this.state.dateRange.end).getTime() / 1000)
        let granularity = (end - start)
        let data = await GridDuck.getAssetConsumptionBatchs({
            filters: [
                {
                    field: 'organisationId',
                    value: organisation.id
                },
                {
                    field: 'assetId',
                    value: 'filtered'
                },
                {
                    field: 'dataType',
                    value: this.state.dataType
                },
                {
                    field: 'start',
                    value: start
                },
                {
                    field: 'end',
                    value: end
                },
                {
                    field: 'granularity',
                    value: granularity
                }
            ]
        });

        let assetsList = data.list;

        let total = this.generateTotals(assetsList);
        let display_value = getFormat(this.convertDataTypeToFormatterString(this.state.dataType))(total)
        element.usage = display_value
        this.setState({organisations: list})
    }

    generateTotals(assets) {

        // for each asset in the set, add all of the telemetry.consumption values together
        let totalValue = 0;
        assets.forEach((asset) => {
            asset.telemetry.forEach((datum) => {
                if (datum.consumption || datum.consumption === 0) {
                    totalValue += datum.consumption;
                }
            })
        });
        return totalValue;
    }

    getList(pageNum) {
        if (!this.state.search) return;
        this.setState({loaded: false})
        this.loading = true
        let self = this;
        let filters = [];
        if (this.state.exhibitionOnly) {
            filters.push({
                field: 'exhibitionOnly',
                value: true
            });
        }
        if (this.state.onlyWithDevices) {
            filters.push({
                field: 'onlyWithDevices',
                value: true
            });
        }
        if (this.state.noExhibition) {
            filters.push({
                field: 'noExhibition',
                value: true
            });
        }
        if (this.state.paidOnly) {
            filters.push({
                field: 'paidOnly',
                value: true
            });
        }
        if (this.state.includeInternal) {
            filters.push({
                field: 'includeInternal',
                value: true
            });
        }
        if (this.state.lockedOnly) {
            filters.push({
                field: 'lockedOnly',
                value: true
            });
        }
        if (this.state.lockedOnly) {
            filters.push({
                field: 'exemptOnly',
                value: true
            });
        }
        if (this.state.search) {
            filters.push({
                field: 'search',
                value: this.state.search
            });
        }
        let orgParams = JSON.stringify({
            filters: filters,
            items: this.state.itemsPerPage,
            offset: (pageNum - 1) * this.state.itemsPerPage
        })
        this.orgParams = orgParams;
        GridDuck.getOrganisationManagements({
            filters: filters,
            items: this.state.itemsPerPage,
            offset: (pageNum - 1) * this.state.itemsPerPage
        }).then(function (res) {
            if (orgParams !== self.orgParams) {
                return;
            }
            let sortedOrgList = _.groupBy(res.list, (org) => {
                return !!org.lastActive
            });
            let list = sortedOrgList['true']
            if (sortedOrgList['false'] && sortedOrgList['true']) {
                list = [...sortedOrgList['true'], ...sortedOrgList['false']]
            }
            self.setState({organisations: list, total: res.total, loaded: true}, () => self.loading = false)
        });
    }

    loginAsUser(email) {
        return GridDuck.createLoginAs({username: email})
            .then(function (loginAs) {
                let accessToken = {
                    expires: loginAs.expires,
                    token: loginAs.token,
                    mfaRequired: loginAs.mfaRequired,
                    scope: loginAs.scope,
                    tokenType: loginAs.tokenType,
                    user: {id: loginAs.user.id}
                };
                GridDuck.setAccessToken({
                    accessToken
                });
                delete accessToken.scope;
                cookie.save('accessToken', accessToken, {path: '/'});
                history.push("/")
                window.location.reload();
            });
    }

    render() {
        let self = this;
        const orgFilterOptions = [
            {title: 'Only show Exhibition Sign ups', id: 'exhibitionOnly'},
            {title: 'Only with devices', id: 'onlyWithDevices'},
            {title: 'No Exhibition', id: 'noExhibition'},
            {title: 'Paid Only', id: 'paidOnly'},
            {title: 'Include Internal Organisations', id: 'includeInternal'},
            {title: 'Only locked out', id: 'lockedOnly'}
        ];
        return (
            <div className='page Account not-flex'>
                <div className={'row'} style={{padding: '10px 20px'}}>
                    <div className={'column'}>
                        <div className={'kpi-value'}><strong>{this.state.total}</strong> Organisations</div>
                    </div>
                </div>
                <div className={'row'} style={{padding: '10px 20px'}}>
                    <OrgManagementFilter
                        label={'Edit Columns'}
                        potentialOptions={this.potentialOptions}
                        onUpdate={(val) => {
                            this.setState({columns: val});
                        }}
                        columns={this.state.columns}/>
                    <OrgManagementFilter
                        label={'Filter Orgs'}
                        potentialOptions={orgFilterOptions}
                        onUpdate={(val) => {
                            let stateUpdate = {orgFilters: val};
                            orgFilterOptions.forEach((filter) => {
                                stateUpdate[filter.id] = val.find(f => f.id === filter.id);
                            });
                            this.setState(stateUpdate, this.getList);
                        }}
                        columns={this.state.orgFilters}/>


                </div>

                {this.state.columns.find(c => c.id === 'usage') ? <div className={'row'}>
                    <DateTimeRangePicker pastOnly={true}
                                         limitWidth
                                         summaryMode
                                         placement={'bottomEnd'}
                                         label={'Usage Date Range'}
                                         top={'true'}
                                         value={this.state.dateRange && this.state.dateRange.start && this.state.dateRange.end ? [this.state.dateRange.start, this.state.dateRange.end] : null}
                                         onChange={(val) => {
                                             this.setState({dateRange: val})
                                         }}/>
                    <Dropdown label={'Data Type'}
                              placeholder={'Select Data Type'}
                              onChange={(e) => {
                                  this.setState({dataType: e.target.value})
                              }}
                              fixeditems={this.state.dataTypes}
                              value={this.state.dataType}/>
                </div> : null}
                <div className={'search-input-wrapper boxy'}>
                    <Input label={''}
                           nolabel='true'
                           placeholder={'Type to search'}
                           InputProps={{
                               startAdornment: (
                                   <div className={'input-icon'}>
                                       <Icon color={'grey'} size={'10'} icon={'FaSearch'}/>
                                   </div>)
                           }}
                           onChange={this.handleInputChange}/>
                </div>
                {this.state.loaded ? <div className={'column top'}>
                    <CollapsibleTable columns={{
                        outerColumns: this.state.columns,
                        innerColumns: [{title: 'Email Address'}, {title: 'Access Level'}, {title: 'Last Active'}]
                    }}
                                      tableData={
                                          _.map(self.state.organisations, function (organisation) {
                                              let subItems = 0;
                                              organisation.subscriptions.forEach(function (subscription) {
                                                  subscription.items.data.forEach(function (item) {
                                                      subItems += item.quantity;
                                                  })
                                              });

                                              const totalSubscriptionValue = organisation.subscriptions.reduce((total, subscription) => {
                                                  const subscriptionTotal = subscription.items.data.reduce((subTotal, item) => {
                                                      return subTotal + (item.price.unitAmount ? item.price.unitAmount * item.quantity : 0);
                                                  }, 0);
                                                  return total + subscriptionTotal;
                                              }, 0);

                                              let innerData = organisation.users?.map(function (user) {
                                                  return [
                                                      {
                                                          value: <div style={{display: 'flex'}}><Tooltip
                                                              label={'Login as...'}><Icon
                                                              onIconClick={() => self.loginAsUser(user.username)}
                                                              icon={'FaKey'} size={'12'} color={'gd-blue'}/></Tooltip><p
                                                              style={{margin: '0 10px'}}>{user.username}</p></div>
                                                      },
                                                      {
                                                          value: <div
                                                              style={{display: 'flex'}}>{user.orgPermission}</div>
                                                      },
                                                      {
                                                          value: <div style={{display: 'flex'}}><LastActiveCell
                                                              comparisonOnly
                                                              onlineStatus
                                                              value={user.lastActive}
                                                              object={user}/></div>
                                                      }
                                                  ];
                                              });
                                              let data = []

                                              data = self.state.columns.map((c) => {
                                                  let component
                                                  switch (c.id) {
                                                      case 'organisation':
                                                          component = ({
                                                              value: <a target={'_blank'}
                                                                        href={'https://dashboard.stripe.com/customers/' + organisation.stripeId}>{organisation.name}</a>
                                                          })
                                                          break;
                                                      case 'sites':
                                                          component = ({value: <div>{organisation.sites.length}</div>})
                                                          break;
                                                      case 'devices':
                                                          component = ({value: <div>{organisation.appliances}</div>})
                                                          break;
                                                      case 'active_devices':
                                                          component = ({
                                                              value:
                                                                  <div>{organisation.appliances - organisation.issues}</div>
                                                          })
                                                          break;
                                                      case 'usage':
                                                          component = ({value: self.generateUsageHTML(organisation)})
                                                          break;
                                                      case 'subs':
                                                          component = ({
                                                              value: <div className={'clickable link'}
                                                                          onClick={(e) => self.setState({
                                                                              details: SubscriptionComponent(organisation),
                                                                              anchorEl: e.currentTarget
                                                                          })}>{organisation.subscriptions.length}</div>
                                                          })
                                                          break;
                                                      case 'seats':
                                                          component = ({value: <div>{organisation.seats}</div>})
                                                          break;
                                                      case 'hubs':
                                                          component = ({value: <div>{organisation.hubCount}</div>})
                                                          break;
                                                      case 'sims':
                                                          component = ({value: <div>{organisation.simCount}</div>})
                                                          break;
                                                      case 'sub_items':
                                                          component = ({value: <div>{subItems}</div>})
                                                          break;
                                                      case 'total_sub_value':
                                                          component = ({
                                                              value:
                                                                  <div>£{(totalSubscriptionValue / 100).toFixed(2)}</div>
                                                          })
                                                          break
                                                      case 'completed_signup':
                                                          component = ({value: organisation.acceptedTerms ? 'Yes' : 'No'})
                                                          break
                                                      case 'free_paid':
                                                          component = ({
                                                              value: <div>{organisation.free ? 'Free' : 'Paid'} <Menu
                                                                  menuHeader={{title: 'Switch'}}
                                                                  controlFromElement={<Icon icon={'FaEdit'} size={'12'}
                                                                                            color={'gd-blue'}/>}
                                                                  menuItems={[
                                                                      {
                                                                          icon: {
                                                                              color: 'gd-grey',
                                                                              name: 'FaCheck',
                                                                              size: '12'
                                                                          },
                                                                          label: 'Free',
                                                                          onMenuItemClick: function () {
                                                                              self.editFreePaid(organisation, true);
                                                                          }
                                                                      },
                                                                      {
                                                                          icon: {
                                                                              color: 'gd-grey',
                                                                              name: 'FaTimes',
                                                                              size: '12'
                                                                          },
                                                                          label: 'Paid',
                                                                          onMenuItemClick: function () {
                                                                              self.editFreePaid(organisation, false);
                                                                          }
                                                                      }
                                                                  ]}/></div>
                                                          })
                                                          break
                                                      case 'locked':
                                                          component = ({
                                                              value: <div>{organisation.accountLocked ? 'Locked' : '-'}
                                                                  <Menu
                                                                      controlFromElement={<Icon icon={'FaEdit'}
                                                                                                size={'12'}
                                                                                                color={'gd-blue'}/>}
                                                                      menuItems={[
                                                                          {
                                                                              icon: {
                                                                                  color: 'gd-grey',
                                                                                  name: 'FaCheck',
                                                                                  size: '12'
                                                                              },
                                                                              label: `${organisation.accountLocked ? 'Unlock' : 'Lock'}`,
                                                                              onMenuItemClick: function () {
                                                                                  self.editLocked(organisation, !organisation.accountLocked);
                                                                              }
                                                                          }
                                                                      ]}/></div>
                                                          })
                                                          break
                                                      case 'dashboard_tier':
                                                          component = ({value: <div>{organisation.dashboardTier}</div>})
                                                          break;
                                                      case 'dashboard_tier_frequency':
                                                          component = ({value: <div>{organisation.dashboardTierFrequency}</div>})
                                                          break;
                                                      case 'sub_payment_exempt':
                                                          component = ({
                                                              value: <div>{organisation.subPaymentExempt ? 'Exempt' : '-'}
                                                                  <Menu
                                                                      controlFromElement={<Icon icon={'FaEdit'}
                                                                                                size={'12'}
                                                                                                color={'gd-blue'}/>}
                                                                      menuItems={[
                                                                          {
                                                                              icon: {
                                                                                  color: 'gd-grey',
                                                                                  name: 'FaCheck',
                                                                                  size: '12'
                                                                              },
                                                                              label: `${organisation.subPaymentExempt ? 'Remove Exemption' : 'Make Exempt'}`,
                                                                              onMenuItemClick: function () {
                                                                                  self.editSubPayment(organisation, !organisation.subPaymentExempt);
                                                                              }
                                                                          }
                                                                      ]}/></div>
                                                          })
                                                          break
                                                      default:
                                                          component = <div></div>
                                                  }
                                                  return component
                                              })
                                              return {
                                                  id: organisation.id,
                                                  data: data,
                                                  innerData: innerData,
                                              }
                                          })
                                      }/>
                    <DetailsPopover anchorEl={this.state.anchorEl} details={this.state.details}
                                    onClose={() => this.setState({details: null, anchorEl: null})}/>
                </div> : <div style={{
                    display: 'flex',
                    flex: '1 1',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                }}>{!this.state.loaded && this.state.search ? <GenericLoader text={'Fetching...'} textLineTwo={'Organisations'}/> :
                    <div>Search an Organisation</div>}</div>}
                {this.state.loaded ? <div className={'fixed-footer'}>
                    <Pagination pageNum={this.state.pageNum}
                                pages={Math.max(1, Math.ceil(this.state.total / this.state.itemsPerPage))}
                                updateList={this.getList}/>
                </div> : null}

            </div>
        )
    }
}

export default OrganisationManagement;

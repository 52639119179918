import React from "react";
import GridDuck from "gridduck";
import history from "../../meta/history";
import getFormat from "../../services/formatter";
import * as _ from 'underscore';
import {Button, CollapsibleTable, EditableList, Icon, Menu, StandardListCell, StateOptions} from "gd-react";
import Modal from "react-modal";
import GenericLoader from "../../components/GenericLoader";
import {Tooltip} from "@material-ui/core";
import "./Devices.scss";

class Devices extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.getList = this.getList.bind(this);
        this.getList();
    }

    getList() {
        let self = this;
        GridDuck.getAssetGods({
            getAll: true,
            // filters: [{
            //     field: 'no_telemetry',
            //     value: true
            // }],
            filters: [
                {
                    field: 'real',
                    value: true
                }
            ],
            items: 100
        }).then(function (res) {

            let devices = res.list.sort(function(deviceB, deviceA) {
                if (deviceB.disconnected && deviceA.disconnected) {
                    return deviceB.lastContacted - deviceA.lastContacted;
                }
                if (!deviceB.disconnected && !deviceA.disconnected) {
                    return deviceB.name - deviceA.name;
                }
                if (deviceB.disconnected && !deviceA.disconnected) {
                    return -1;
                }
                if (!deviceB.disconnected && deviceA.disconnected) {
                    return 1;
                }
            });
            self.setState({devices: devices, loaded: true})
        });
    }

    render() {
        const getName = function(sku) {
            let device = StateOptions.find(t => t.type === sku);
            return device ? device.label : sku;
        }
        const getConnection = function(sku) {
            let device = StateOptions.find(t => t.type === sku);
            return device ? device.connection : 'Unknown';        }

        let self = this;
        return (
            <div className='page Account< not-flex'>
                {this.state.loaded ? <div className={'column top'}>
                    <p>Connected: {self.state.devices.filter(h => !h.disconnected).length} / {self.state.devices.length}</p>
                    <CollapsibleTable
                        columns={{
                            outerColumns: [
                                {title: 'Name'},
                                {title: 'Site'},
                                {title: 'Serial'},
                                {title: 'Type'},
                                {title: 'Last Contacted'},
                                {title: 'Connection'},
                                {title: 'Signal'},
                                {title: 'ECL'},
                                {title: 'Hub'},
                            ],
                            innerColumns: [
                            ]
                        }} tableData={
                        _.map(self.state.devices.sort((hb, ha) => ha.lastContacted - hb.lastContacted), function (device) {
                            let nbIotBar;
                            if (device.nbIotEcl !== undefined) {
                                nbIotBar = (<Icon icon='MdSignalCellular1Bar' size={'14'}/>);
                                if (device.nbIotEcl === 1) {
                                    nbIotBar = (<Icon icon='MdSignalCellular2Bar' size={'14'}/>);
                                }
                                if (device.nbIotEcl === 0) {
                                    nbIotBar = (<Icon icon='MdSignalCellular4Bar' size={'14'}/>);
                                }
                            }

                            let menuItem;
                            if (device.disconnected) {
                                menuItem = {
                                    icon: {
                                        color: 'gd-green',
                                        name: 'GiPlainCircle',
                                        size: '12'
                                    },
                                    label: 'Make Connected',
                                    onMenuItemClick: function () {
                                        device.setDisconnected(false);
                                    }
                                };
                            } else {
                                menuItem = {
                                    icon: {
                                        color: 'gd-red',
                                        name: 'GiPlainCircle',
                                        size: '12'
                                    },
                                    label: 'Make Disconnected',
                                    onMenuItemClick: function () {
                                        device.setDisconnected(true);
                                    }
                                };
                            }

                            let connectedIcon = (<Menu style={{display: "inline-block"}} menuHeader={{
                                title: 'Toggle connected',
                            }} controlFromElement={<Tooltip title={'Toggle connected'}>
                                <div className={'top-nav-icon'}><Icon icon={'GiPlainCircle'}
                                                                      color={device.disconnected || device.gatewayDisconnected ? 'gd-red' : 'gd-green'} size={'16'}/></div>
                            </Tooltip>}
                                  menuItems={[
                                      menuItem
                                  ]}/>)



                            return {
                                id: device.id,
                                data: [
                                    {value: device.disconnected || device.gatewayDisconnected ? [connectedIcon,<a href={'/device/' + device.id}>&nbsp;{device.name}</a>] :
                                            [connectedIcon,<a href={'/device/' + device.id}>&nbsp;{device.name}</a>]},
                                    {value: <a href={'/site/' + device.siteId}>{device.siteName}</a>},
                                    {value: device.code},
                                    {value: getName(device.sku)},
                                    {value: getFormat('lastContacted')(device.lastContacted)},
                                    {value: getConnection(device.sku)},
                                    {value: device.assetSignalStrength !== undefined ? getFormat('signal')(device.assetSignalStrength) : 'N/A'},
                                    {value: nbIotBar},
                                    {value: <a href={'/hub/' + device.gatewayId}>{device.gatewayName}</a>},
                                ],
                                innerData: []
                            }
                        })
                    }
                    />
                </div> : <div style={{
                    display: 'flex',
                    flex: '1 1',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100%'
                }}><GenericLoader text={'Fetching...'} textLineTwo={'Device Data'}/></div>}
            </div>
        )
    }
}

export default Devices;

import React, {Component} from "react";
import "./AddDeviceModal.scss";
import '../../styles/_layout.scss';
import DeviceForm from "../../components/DeviceForm";
import {
	Button,
	CardBody,
	GdCheckbox,
	Dropdown,
	GdAutocomplete,
	GdModal,
	Icon, Input,
} from "gd-react";
import GridDuck from "gridduck";
import {isMobile, isTablet} from "react-device-detect";
import GenericLoader from "../../components/GenericLoader";

class AddElectralinkDeviceModal extends Component {

	constructor(props) {
		super(props);
		let self = this;
		this.errors = {}
		let onSitePage = window.location.href.indexOf('site/')
		let siteId;
		this.errors = {}
		this.state = {
			site: '',
			sites: [],
			noHubs: false,
			mpan: '',
			manualHub: null,
			triedToSave: false,
			addingSingleAsset: null,
			//todo image urls for device types
			fixedCategorys: [],
			page: 1,
			loaded: false
		};
		this.selectSite = this.selectSite.bind(this);
		this.cancelAdd = this.cancelAdd.bind(this)
		this.createAsset = this.createAsset.bind(this);
		this.closeModal = this.closeModal.bind(this);
		this.getSites = this.getSites.bind(this)
		this.navBack = this.navBack.bind(this)
		this.checkErrors = this.checkErrors.bind(this);
		this.handleMpan = this.handleMpan.bind(this);
		this.handleNext = this.handleNext.bind(this)
		this.modalClosed = false;
		this.formRef = React.createRef()
	}

	checkErrors() {
		let errors = {};
		let page = this.state.page
		if (page === 2) {
			if (!this.state.mpan) {
				errors['mpan'] = true
			}
		}
		if (page === 3) {

		}
		
		this.errors = errors
	}

	closeModal() {
		
		this.modalClosed = true;
		this.props.onClose();
	}

	createAsset(asset) {
		let self = this;
		self.setState({addingSingleAsset: asset})
	}

	getSites(searchTerm) {
		return GridDuck.getSites({
			filters: [{
				field: 'search',
				value: searchTerm
			}]
		}).then(function (siteList) {
			return Promise.resolve(
				siteList.list
					.filter(s => s._permission === 'admin')
					.map(
						s => ({
							id: s.id,
							title: s.name
						})
					)
			)
		})
	}

	selectSite(site, siteObj) {
		
		if (!site || !siteObj) return
		this.setState({
			site: siteObj,
			page: 2,
		}, this.fetchHubs);
	}

	cancelAdd() {
		this.setState({addingSingleAsset: null})
	}

	handleMpan(input) {
		this.setState({
			mpan: input.target.value,
			triedToSave: false
		});
	}

	navBack() {
		let page = this.state.page += -1;
		this.setState({page: page})
	}

	handleNext(e) {
		let self = this
		this.setState({triedToSave: true, loaded: false})
		if (Object.keys(this.errors).length > 0) return new Promise(function (resolve, reject) {
			
			self.setState({loaded: true})
			return reject();
		}).catch(function (resolve, reject) {
			return reject();
		});
		if (e) {
			e.preventDefault();
		}
		let asset = {
			assetCode: this.state.mpan,
			siteId: this.state.site.id,
			displayName: 'Electricity Meter',
			sku: 'ELECTRALINK'
		}
		
		self.setState({addingSingleAsset: asset, page: 3})
		setTimeout(() => {
			self.setState({loaded: true})
		}, 1000)
	}


	render() {
		this.checkErrors()
		let self = this;
		let page1;
		let page2;
		let page3;
		let nextButton;
		let noHubMessage;
		let footer = [];
		let submitButton;
		let backButton;


		//page 1
		if (this.state.page === 1) {
			if (this.state.noHubs) {
				noHubMessage = <div className={'row'} style={{marginTop: '20px'}}>
					<p>Selected site has no hubs - please choose another site</p>
				</div>
			}
			page1 =
				<div>
					<div className={'row'}>
						<GdAutocomplete
							async
							name={'site'}
							getOptionSelected={function (option, value) {
								if (option && value) return option.id === value.id;
							}}
							value={this.state.site}
							placeholder={'Select a site'}
							getList={self.getSites}
							onChange={this.selectSite}
							label="Site"/>
					</div>
					{noHubMessage}
				</div>
		}

		// page 2
		if (this.state.page === 2) {
			page2 = <div>
				<div className={'row'}>
					<Input
						required
						error={this.errors['mpan'] && this.state.triedToSave}
						maxLength={19}
						top="true"
						label={'MPAN'}
						name={'mpan'}
						onChange={this.handleMpan}
						value={this.state.mpan}
					/>
				</div>
			</div>
			backButton = (
				<div className={'button-container'}>
					<Button
						onClick={() => {
							self.setState({
								page: 1,
								mac: '',
								manualHub: null,
								deviceType: null,
								site: {},
								triedToSave: false
							})
						}}
						outline
						label={"Back"}
						color={"gd-grey"}
					/>
				</div>
			)
			nextButton = (
				<div className={'button-container'}>
					<Button
						key={'next-butt'}
						// additionalclasses={'sm left'}
						onClick={this.handleNext}
						label={"Next"}
						color={"gd-brand"}
					/>
				</div>);
			footer.push([nextButton, backButton])
		}

		//page 3
		if (this.state.page === 3) {

			backButton = (
				<div className={'button-container'}>
					<Button
						onClick={() => {
							this.formRef.current.clearFields();
							self.setState({
								page: 2,
								addingSingleAsset: null,
								mac: '',
								deviceType: {},
								triedToSave: false
							})
						}}
						outline
						label={"Back"}
						color={"gd-grey"}
					/>
				</div>
			)
			submitButton = (
				<div className={'button-container'}>
					<Button
						key={'submit-butt'}
						// additionalclasses={'sm left'}
						onClick={self.formRef.current.submitAsset}
						progressRes={true}
						label={"Submit"}
						color={"gd-green"}
					/>
				</div>);
			footer.push([submitButton, backButton])

		}


		return (
			<GdModal
				open={this.props.open}
				contentLabel={'Add Half-Hourly Electricity Meter'}
				title={'Add Half-Hourly Electricity Meter'}
				isMobile={this.props.isMobile}
				footer={footer.length ? footer : null}
				onClose={this.closeModal}>
				<CardBody>
					{page1}
					{page2}
					<DeviceForm
						type={'electralink'}
						ref={this.formRef}
						hubs={this.state.hubs}
						site={this.state.site}
						asset={this.state.addingSingleAsset}
						closeForm={this.closeModal}
					/>
				</CardBody>
			</GdModal>
		)
	}
}

export default AddElectralinkDeviceModal;

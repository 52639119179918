import React from "react";
import {
    GdCheckbox,
    StateOptions,
    Button,
    Dropdown,
    EditableList,
    Icon,
    Input,
    StandardListCell,
} from "gd-react";
import {Prompt} from "react-router-dom";
import "../Rules.scss";
import GridDuck from "gridduck";
import Modal from "react-modal";
import history from "../../meta/history";
import {createPortal} from "react-dom";

class EditRulePageThermostat extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customPeriod: (props.rule.triggers.length > 0 && props.rule.triggers[0].periodStartDefined === null && props.rule.triggers[0].periodDuration !== 86400 && props.rule.triggers[0].periodDuration !== 3600 && props.rule.triggers[0].periodDuration !== 60),
            customThrottleDuration: (props.rule.throttleDuration !== undefined && props.rule.throttleDuration !== 86400 && props.rule.throttleDuration !== 3600 && props.rule.throttleDuration !== 60),
            page: 1,
            assetNames: 'Select Assets',
            assetListFilters: [
                {
                    field: 'dataType',
                    value: props.rule.triggers[0].dataType
                }
            ],
            alertAssetListFilters: [
                {
                    field: 'userState',
                    value: props.rule.alerts[0].state
                },
                {
                    field: '_permission',
                    value: ['edit', 'admin']
                }
            ],
            rule: {
                name: props.rule.name,
                persistent: props.rule.persistent,
                description: props.rule.description,
                id: props.rule.id,
                throttleDuration: props.rule.throttleDuration,
                alerts: JSON.parse(JSON.stringify(props.rule.alerts)),
                triggers: JSON.parse(JSON.stringify(props.rule.triggers)),
                creating: props.rule.creating
            }
        };
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
        let self = this;
        if (this.props.rule.triggers.length > 0 && this.props.rule.triggers[0].assets.length > 0) {
            let promises = [];
            this.props.rule.triggers[0].assets.forEach(function (a) {
                promises.push(GridDuck.getAsset({id: a}));
            })
            Promise.all(promises).then(function (assets) {
                let assetNames = assets.map(a => a.name + ' (' + a.siteName + ')').join(", ");
                self.setState({assetNames: assetNames});
            })
        }
        this.setDataType = this.setDataType.bind(this);
        this.setAssetLogic = this.setAssetLogic.bind(this);
        this.selectAssets = this.selectAssets.bind(this);
        this.getActiveList = this.getActiveList.bind(this);
        this.getAvailableList = this.getAvailableList.bind(this);
        this.closeAssetsModal = this.closeAssetsModal.bind(this);
        this.setAssets = this.setAssets.bind(this);
        this.setThresholdType = this.setThresholdType.bind(this);
        this.handleThresholdChange = this.handleThresholdChange.bind(this);
        this.handleExitThresholdChange = this.handleExitThresholdChange.bind(this);
        this.setPeriodStartDefined = this.setPeriodStartDefined.bind(this);
        this.setTimeValue = this.setTimeValue.bind(this);
        this.setCustomPeriod = this.setCustomPeriod.bind(this);
        this.setThrottleDuration = this.setThrottleDuration.bind(this);
        this.setCustomThrottlePeriod = this.setCustomThrottlePeriod.bind(this);
        this.blockNext = this.blockNext.bind(this);
        this.handleSymmetryChange = this.handleSymmetryChange.bind(this);

        this.getActiveListAssets = this.getActiveListAssets.bind(this);
        this.getAvailableListAssets = this.getAvailableListAssets.bind(this);
        this.handleAssetListChange = this.handleAssetListChange.bind(this);
        this.setAlertState = this.setAlertState.bind(this);
        this.setAlertDefaultState = this.setAlertDefaultState.bind(this);


        this.hasChanged = this.hasChanged.bind(this);
        this.save = this.save.bind(this);
    }
    
    handleAssetListChange(newAssetList) {
        let rule = this.state.rule;
        rule.alerts[0].assets = newAssetList.map(a => a.id);
        this.setState(() => ({
            rule: rule
        }));
    }

    getAvailableListAssets(params) {
        return GridDuck.getAssets(params);
    }

    getActiveListAssets() {
        let promises = this.state.rule.alerts[0].assets.map(a => GridDuck.getAsset({id: a}));
        return Promise.all(promises).then(function (results) {
            return Promise.resolve({list: results, total: results.length});
        })
    }

    setCustomThrottlePeriod(val) {
        let rule = this.state.rule;
        rule.throttleDuration = val.target.value;
        this.setState({rule: rule});
    }

    setThrottleDuration(evt) {
        let throttleDuration = {
            id: evt.target.value
        };
        if (throttleDuration.id === 'custom') {
            this.setState({customThrottleDuration: true})
        } else {
            let rule = this.state.rule;
            rule.throttleDuration = throttleDuration.id;
            this.setState({customThrottleDuration: false, rule: rule});
        }
    }

    setAlertState(e) {
        let rule = this.state.rule;
        rule.alerts[0].state = e.target.value;
        this.setState({
            rule: rule, alertAssetListFilters: [
                {
                    field: 'userState',
                    value: e.target.value
                },
                {
                    field: '_permission',
                    value: ['edit', 'admin']
                }
            ]
        });
    }

    setAlertDefaultState(e) {
        let rule = this.state.rule;
        rule.alerts[0].defaultState = e.target.value;
        this.setState({
            rule: rule, alertAssetListFilters: [
                {
                    field: 'userState',
                    value: e.target.value
                },
                {
                    field: '_permission',
                    value: ['edit', 'admin']
                }
            ]
        });
    }

    setCustomPeriod(val) {
        let rule = this.state.rule;
        rule.triggers[0].periodDuration = val.target.value;
        this.setState({rule: rule});
    }

    setTimeValue(evt) {
        let timeValue = {
            id: evt.target.value
        };
        let rule = this.state.rule;
        if (this.state.rule.triggers[0].periodStartDefined !== null) {
            rule.triggers[0].periodStartDefined = timeValue.id;
            this.setState({rule: rule});
        } else {
            if (timeValue.id === 'custom') {
                rule.triggers[0].periodStartDefined = null;
                this.setState({customPeriod: true, rule: rule});
            } else {
                rule.triggers[0].periodDuration = timeValue.id;
                rule.triggers[0].periodStartDefined = null;
                this.setState({customPeriod: false, rule: rule});
            }
        }
    }

    setPeriodStartDefined(evt) {
        let periodStartDefined = {
            id: evt.target.value
        };
        let rule = this.state.rule;
        if (periodStartDefined.id) {
            rule.triggers[0].periodStartDefined = 'week';
        } else {
            rule.triggers[0].periodDuration = 86400;
            rule.triggers[0].periodStartDefined = null;
            rule.throttleDuration = 3600;
        }
        this.setState({rule: rule});
    }

    handleSymmetryChange(e) {
        let rule = this.state.rule;
        rule.triggers[0].isSymmetric = !e.target.checked;
        if (!e.target.checked) {
            rule.triggers[0].thresholdExit = null;
        }
        this.setState({rule: rule});
    };

    handleThresholdChange(val) {
        if (isNaN(val.target.value)) {
            return;
        }
        let factor = 1;
        if (this.state.rule.triggers[0].dataType === 'TEMPERATURE' || this.state.rule.triggers[0].dataType === 'HUMIDITY') {
            factor = 100;
        }
        if (this.state.rule.triggers[0].dataType === 'CURRENT') {
            factor = 1000;
        }
        let rule = this.state.rule;
        rule.triggers[0].threshold = parseInt(val.target.value * factor);
        this.setState({rule: rule});
    };

    handleExitThresholdChange(val) {
        if (isNaN(val.target.value)) {
            return;
        }
        let factor = 1;
        if (this.state.rule.triggers[0].dataType === 'TEMPERATURE' || this.state.rule.triggers[0].dataType === 'HUMIDITY') {
            factor = 100;
        }
        if (this.state.rule.triggers[0].dataType === 'CURRENT') {
            factor = 1000;
        }
        let rule = this.state.rule;
        rule.triggers[0].thresholdExit = parseInt(val.target.value * factor);
        this.setState({rule: rule});
    }

    setThresholdType(evt) {
        let rule = this.state.rule;
        rule.triggers[0].thresholdType = evt.target.value;
        this.setState({rule: rule});
    }

    setAssets(assets) {
        
        let rule = this.state.rule;
        rule.triggers[0].assets = assets.map(a => a.id);
        this.setState({
            rule: rule,
            selectingAssets: false,
            assetNames: assets.map(a => a.name + ' (' + a.siteName + ')').join(", ")
        });
        return Promise.resolve();
    }

    closeAssetsModal() {
        this.setState({selectingAssets: false});
    }

    getActiveList(props) {
        
        let promises = this.state.rule.triggers[0].assets.map(a => GridDuck.getAsset({id: a}))
        return Promise.all(promises).then(function (results) {
            
            return Promise.resolve({list: results, total: results.length});
        })
    }


    getAvailableList(params) {
        return GridDuck.getAssets(params)
    }

    selectAssets() {
        this.setState({selectingAssets: true});
    }

    setAssetLogic(evt) {
        let rule = this.state.rule;
        rule.triggers[0].requireAll = evt.target.value;
        this.setState({rule: rule});
    }

    isDifferentList(oldList, newList) {
        return !oldList.every((value, index) => value === newList[index]);
    }

    async save() {
        let newRule;
        let self = this;
        if (this.props.rule.creating) {
            let newRuleProps = {
                name: this.state.rule.name,
                template: 'thermostat',
                description: this.state.rule.description,
                persistent: this.state.rule.persistent,
                triggers: this.state.rule.triggers,
                alerts: this.state.rule.alerts
            };
            
            newRule = await GridDuck.createRule(newRuleProps)
            let rule = self.state.rule;
            rule.id = newRule.id;
            self.setState({rule: rule});
        } else {
            await this.props.rule.set(this.state.rule);
        }
        if (this.props.onSave) this.props.onSave(newRule);
        return Promise.resolve();
    }

    hasChanged() {
        if (this.state.saved || this.saved) {
            return false;
        }
        if (this.props.rule.creating) {
            return false;
        }
        return (this.props.rule.name !== this.state.rule.name) ||
            (this.props.rule.description !== this.state.rule.description) ||
            (this.props.rule.triggers[0].requireAll !== this.state.rule.triggers[0].requireAll) ||
            (this.props.rule.triggers[0].dataType !== this.state.rule.triggers[0].dataType) ||
            (this.props.rule.triggers[0].thresholdType !== this.state.rule.triggers[0].thresholdType) ||
            (this.props.rule.triggers[0].threshold !== this.state.rule.triggers[0].threshold) ||
            (this.props.rule.triggers[0].thresholdExit !== this.state.rule.triggers[0].thresholdExit) ||
            (this.props.rule.triggers[0].periodStartDefined !== this.state.rule.triggers[0].periodStartDefined) ||
            (this.props.rule.triggers[0].periodDuration !== this.state.rule.triggers[0].periodDuration) ||
            (this.props.rule.triggers[0].periodDuration !== this.state.rule.triggers[0].periodDuration) ||
            (this.props.rule.throttleDuration !== this.state.rule.throttleDuration) ||
            (this.props.rule.triggers[0].assets.length !== this.state.rule.triggers[0].assets.length) ||
            (!this.props.rule.alerts[0].assets && this.state.rule.alerts[0].assets) ||
            (this.props.rule.alerts[0].assets && this.state.rule.alerts[0].assets && this.props.rule.alerts[0].assets.length !== this.state.rule.alerts[0].assets.length) ||
            (this.props.rule.triggers[0].assets && this.isDifferentList(this.props.rule.triggers[0].assets, this.state.rule.triggers[0].assets)) ||
            (this.props.rule.alerts[0].assets && this.isDifferentList(this.props.rule.alerts[0].assets, this.state.rule.alerts[0].assets))

    }

    handleNameChange(val) {
        let name = val.target.value;
        this.setState(prevState => ({
            rule: {
                ...prevState.rule,
                name: name
            }
        }));
    }

    handleDescriptionChange(val) {
        let description = val.target.value;
        this.setState(prevState => ({
            rule: {
                ...prevState.rule,
                description: description
            }
        }));
    }

    setDataType(evt) {
        let rule = this.state.rule;
        let type = evt.target.value;
        let chosenType;
        StateOptions.forEach(function (stateOption) {
            stateOption.dataTypes.forEach(function (dataType) {
                if (dataType.type === type) {
                    chosenType = dataType;
                }
            })
        });
        rule.triggers[0].dataType = type;
        rule.triggers[0].assets = [];
        if (chosenType && chosenType.graphType !== 'bar') {
            rule.triggers[0].instantaneous = true;
            rule.persistent = true;
        } else {
            rule.triggers[0].instantaneous = false;
            rule.persistent = false;
        }
        this.setState({
            rule: rule, assetNames: 'Select Assets', assetListFilters: [
                {
                    field: 'dataType',
                    value: type
                }
            ]
        });
    }

    blockNext() {
        switch (this.state.page) {
            case 1:
                return this.state.rule.name === undefined || this.state.rule.name === null || this.state.rule.name === '';
            case 2:
                if (this.state.rule.triggers[0].assets.length === 0) {
                    return true;
                }
                if (this.state.rule.triggers[0].threshold === undefined || this.state.rule.triggers[0].threshold === null) {
                    return true;
                }
                if (!this.state.rule.triggers[0].isSymmetric && (this.state.rule.triggers[0].thresholdExit === undefined || this.state.rule.triggers[0].thresholdExit === null)) {
                    return true;
                }
                if (!this.state.rule.triggers[0].isSymmetric && this.state.rule.triggers[0].thresholdType === 'above' && this.state.rule.triggers[0].thresholdExit > this.state.rule.triggers[0].threshold) {
                    return true;
                }
                if (!this.state.rule.triggers[0].isSymmetric && this.state.rule.triggers[0].thresholdType === 'below' && this.state.rule.triggers[0].thresholdExit < this.state.rule.triggers[0].threshold) {
                    return true;
                }
                return false;
            case 3:
                if (!this.state.rule.alerts[0].assets || this.state.rule.alerts[0].assets.length === 0) {
                    return true;
                }
                break;
        }
        return false;
    }

    render() {
        if (!this.drawerFooter) {
            this.drawerFooter = document.getElementById('drawer-footer');
            
        }
        let factor = 1;
        if (this.state.rule.triggers[0].dataType === 'TEMPERATURE' || this.state.rule.triggers[0].dataType === 'HUMIDITY') {
            factor = 100;
        }
        if (this.state.rule.triggers[0].dataType === 'CURRENT') {
            factor = 1000;
        }
        let saveButton;
        let content;
        switch (this.state.page) {
            case 1:
                content = (<div className={"details"}>
                        <div className={'details-inner'}>
                            <div className={'inner-header'}>
                                <p className={"title-row"}>
                                    Details
                                </p>
                                <p className={"help-row"}>
                                    First off some basic details to describe what the {this.props.rule.template} is for.
                                </p>
                            </div>
                            <div className={'inner-body'}>
                                <div className={"name"}>
                                    <Input autoFocus label={'Name*'} value={this.state.rule.name}
                                           onChange={this.handleNameChange}/>
                                </div>
                                <div className={"description"}>
                                    <Input label={'Description'} value={this.state.rule.description}
                                           multiline
                                           inputProps={{minRows: 3, maxRows: 4}}
                                           onChange={this.handleDescriptionChange}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                break;
            case 2:
                let dataOptions = [];
                StateOptions.forEach(function (stateOption) {
                    stateOption.dataTypes
                        .filter(d => d.graphType === 'line')
                        .forEach(function (dataType) {
                            if (!dataOptions.find(dO => dO.value === dataType.type)) {
                                let dataOption = {
                                    value: dataType.type,
                                    title: (dataType.category !== dataType.label ? dataType.category + ' ' : '') + dataType.label + " (" + dataType.unit + ")"
                                };
                                dataOptions.push(dataOption);
                            }
                        });
                });
                let assetLogicOptions = [
                    {
                        value: false,
                        title: 'For any of',
                    },
                    {
                        value: true,
                        title: 'For all of',
                    },
                ]
                let thresholdTypes = [
                    {
                        value: 'above',
                        title: 'Above',
                    },
                    {
                        value: 'below',
                        title: 'Below',
                    },
                ];
                let timeTypeOptions = [
                    {
                        value: true,
                        title: 'Over the current',
                    },
                    {
                        value: false,
                        title: 'Over the previous',
                    },
                ]
                let definedTimeOptions;
                if (this.state.rule.triggers.length > 0 && this.state.rule.triggers[0].periodStartDefined !== null) {
                    definedTimeOptions = [
                        {
                            value: 'year',
                            title: 'Year',
                        },
                        {
                            value: 'month',
                            title: 'Month',
                        },
                        {
                            value: 'week',
                            title: 'Week',
                        },
                        {
                            value: 'day',
                            title: 'Day',
                        },
                        {
                            value: 'hour',
                            title: 'Hour',
                        },
                        {
                            value: 'minute',
                            title: 'Minute',
                        },
                    ]
                } else {
                    definedTimeOptions = [
                        {
                            value: 'custom',
                            title: 'Custom',
                        },
                        {
                            value: 86400,
                            title: '24 hours',
                        },
                        {
                            value: 3600,
                            title: 'Hour',
                        },
                        {
                            value: 60,
                            title: 'Minute',
                        },
                    ]
                }

                let timeType;
                let periodStartDefined;
                if (this.state.rule.triggers.length > 0 && !this.state.rule.triggers[0].instantaneous) {
                    timeType = (<Dropdown nolabel='true' onChange={this.setPeriodStartDefined}
                                          fixeditems={timeTypeOptions}
                                          value={this.state.rule.triggers[0].periodStartDefined !== null}/>);

                    let definedTimeValue;
                    if (this.state.rule.triggers[0].periodStartDefined !== null) {
                        definedTimeValue = this.state.rule.triggers[0].periodStartDefined;
                    } else {
                        if (this.state.customPeriod) {
                            definedTimeValue = 'custom';
                        } else {
                            definedTimeValue = this.state.rule.triggers[0].periodDuration;
                        }
                    }


                    periodStartDefined = (
                        <Dropdown nolabel='true' onChange={this.setTimeValue}
                                  fixeditems={definedTimeOptions} value={definedTimeValue}/>);
                }

                let customPeriod;
                if (this.state.rule.triggers.length > 0 && this.state.rule.triggers[0].periodStartDefined === null && this.state.customPeriod) {
                    customPeriod = (
                        <span style={{
                            display: 'flex',
                            alignItems: 'center',
                            flex: '1 1',
                            minWidth: '200px'
                        }}>
							<Input nolabel="true" label={''} value={this.state.rule.triggers[0].periodDuration}
                                   onChange={this.setCustomPeriod}/> <p style={{'margin-left': '10px'}}>seconds</p>
						</span>
                    );
                }

                let throttleDuration;
                let customThrottlePeriod;
                if (this.state.customThrottleDuration) {
                    customThrottlePeriod = [
                        (<p>every</p>),
                        (<Input nolabel="true" label={''} value={this.state.rule.throttleDuration}
                                onChange={this.setCustomThrottlePeriod}/>), (
                            <p>seconds</p>)];
                }
                if (this.state.rule.triggers.length > 0 && !this.state.rule.triggers[0].periodStartDefined && !this.state.rule.triggers[0].instantaneous) {
                    let throttleDurationOptions = [
                        {
                            value: 'custom',
                            title: 'Custom',
                        },
                        {
                            value: 86400,
                            title: 'Every 24 hours',
                        },
                        {
                            value: 3600,
                            title: 'Every Hour',
                        },
                        {
                            value: 60,
                            title: 'Every Minute',
                        },
                    ]
                    throttleDuration =
                        <div className={'condition-row'}>
                            <p>and inform me</p>
                            <Dropdown nolabel='true' fixeditems={throttleDurationOptions}
                                      onChange={this.setThrottleDuration}
                                      value={this.state.customThrottleDuration ? 'custom' : this.state.rule.throttleDuration}/> {customThrottlePeriod}
                        </div>
                }
                content = (<div className={"details"}>
                    <div className={'details-inner'}>
                        <div className={'inner-header'}>
                            <p className={"title-row"}>
                                Conditions
                            </p>
                            <p className={"help-row"} style={{marginBottom: '15px'}}>
                                This is where you define what triggers your state changes.
                            </p>
                        </div>
                        <div className={'inner-body'}>
                            <div className={'condition-row'}>
                                <p>If the</p>
                                <Dropdown nolabel='true' onChange={this.setDataType}
                                          value={this.state.rule.triggers[0].dataType}
                                          fixeditems={dataOptions}/>
                                <Dropdown nolabel='true' onChange={this.setAssetLogic} fixeditems={assetLogicOptions}
                                          value={this.state.rule.triggers[0].requireAll}/>
                            </div>
                            <div className={'condition-row assets'}>
                                <Button label={this.state.assetNames} additionalclasses={'fake-input'} nonBold={true}
                                        color={"gd-grey"}
                                        onClick={this.selectAssets}/>
                            </div>
                            <div className={'condition-row'}>
                                <Dropdown nolabel='true' onChange={this.setThresholdType} fixeditems={thresholdTypes}
                                          value={this.state.rule.triggers[0].thresholdType}/>
                                <Input nolabel="true" label={''} placeholder={"Threshold"}
                                       type={"number"}
                                       value={this.state.rule.triggers.length > 0 ? this.state.rule.triggers[0].threshold / factor : null}
                                       onChange={this.handleThresholdChange}/>
                            </div>
                            <div className={'condition-row'}>
                                <GdCheckbox onChange={this.handleSymmetryChange}
                                            checked={!this.state.rule.triggers[0].isSymmetric}
                                            label={"Exit on different conditions"}/>
                            </div>
                            {(this.state.rule.triggers.length > 0 && !this.state.rule.triggers[0].isSymmetric) ?
                                <div className={'condition-row'}>
                                    <p>Exit {this.state.rule.triggers[0].thresholdType === 'above' ? 'below' : 'above'}</p>
                                    <Input nolabel="true" label={''} placeholder={"Threshold"}
                                           type={"number"}
                                           value={this.state.rule.triggers.length > 0 ? this.state.rule.triggers[0].thresholdExit / 100 : null}
                                           onChange={this.handleExitThresholdChange}/>
                                </div>
                                : ''}
                            {(this.state.rule.triggers.length > 0 && !this.state.rule.triggers[0].instantaneous) ?
                                <div className={'condition-row'}>
                                    {timeType}
                                    {periodStartDefined}
                                    {customPeriod}
                                </div>
                                : ''}

                            {throttleDuration}
                        </div>
                    </div>
                </div>)
                break;
            case 3:
                let alertStates = [
                    {
                        value: 'ON',
                        title: 'On'
                    },
                    {
                        value: 'OFF',
                        title: 'Off',
                    }
                ]
                content = (<div className={"details"}>
                    <div className={'details-inner'}>
                        <div className={'inner-header'}>
                            <p className={"title-row"}>
                                Action
                            </p>
                            <p className={"help-row"} style={{marginBottom: '15px'}}>
                                This is what happens when the rule is true, and also what it happens to.
                            </p>
                            <div className={'condition-row'} style={{marginBottom: '15px', flex: '0'}}>
                                <p style={{marginLeft: 0}}>When condition is true the devices will turn </p>

                                <Dropdown nolabel='true' onChange={this.setAlertState} fixeditems={alertStates}
                                          value={this.state.rule.alerts[0].state}/>


                                <p> otherwise device will be </p><Dropdown nolabel='true'
                                                                           onChange={this.setAlertDefaultState}
                                                                           fixeditems={alertStates}
                                                                           value={this.state.rule.alerts[0].defaultState}/>
                            </div>
                        </div>

                        <EditableList
                            isEditing={true}
                            noCancel={true}
                            key={2}
                            getActiveList={this.getActiveListAssets}
                            getAvailableList={this.getAvailableListAssets}
                            editingColumns={[
                                {component: StandardListCell, title: 'Site Name', field: 'siteName'},
                            ]}
                            availableFilters={this.state.alertAssetListFilters}
                            save={this.save}
                            noSave={true}
                            onChange={this.handleAssetListChange}
                        />
                    </div>
                </div>);
        }

        if (this.hasChanged()) {
            saveButton = (
                <Button color={'gd-green'} label={"Save and exit"} progressRes={true} onClick={this.save}
                       />);
        }

        return (<div className={"inner-rule"}>
            {/*<div className={"top-bar"}>*/}
            {/*    <Icon icon={'FaTimes'} color={'gd-grey'} size={'25'}*/}
            {/*          onIconClick={() => history.push(this.props.rule.creating ? '/rules/create' : ('/rule/' + this.state.rule.id))}/>*/}
            {/*    {saveButton}*/}
            {/*</div>*/}
            {content}
            <Modal
                isOpen={this.state.selectingAssets}
                onRequestClose={this.closeAssetsModal}
                contentLabel="Select Alert Devices">
                <EditableList
                    isEditing={true}
                    key={1}
                    noCancel={false}
                    onCancelOnlyCallback={this.closeAssetsModal}
                    editingColumns={[
                        {component: StandardListCell, title: 'Site Name', field: 'siteName'},
                    ]}
                    availableFilters={this.state.assetListFilters}
                    getActiveList={this.getActiveList}
                    getAvailableList={this.getAvailableList}
                    save={this.setAssets}
                />
            </Modal>
            {this.drawerFooter ? createPortal(<div
                style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: '1 1'}}>
                {this.state.rule.creating ?
                    <div style={{marginRight: '10px', display: 'flex'}}><Button label={"Start Again"} color={'gd-grey'} outline
                                                                                onClick={this.props.restartTemplate}/>
                    </div> : null}
                {!this.state.rule.creating && !this.hasChanged() ?
                    <Button label={"Cancel"} outline color={'gd-grey'} onClick={() => this.save({cancel: true})}/> : ''}
                {!this.state.rule.creating && this.hasChanged() ?
                    <Button label={"Save and exit"} additionalclasses={'md'} color={'gd-green'} progressRes={true}
                            onClick={this.save}/> : null}
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    {this.state.page === 1 ? <span/> : ''}
                    {this.state.page !== 1 ?
                        <div style={{marginRight: '10px'}}><Button label={"Back"} color={'gd-grey'}
                                                                   onClick={() => this.setState({page: this.state.page - 1})}/>
                        </div> : ''}
                    {this.state.page !== 3 ? <Button disabled={this.blockNext()} label={"Next"}
                                                     onClick={() => this.setState({page: this.state.page + 1})}/> : ''}
                    {this.state.page === 3 && this.state.rule.creating ?
                        <Button additionalclasses={'sm'} color={'gd-green'} label={"Save"} disabled={this.blockNext()}
                                progressRes={true} onClick={this.save}/> : ''}
                </div>
            </div>, this.drawerFooter) : null}

            {/*<Prompt*/}
            {/*    when={this.hasChanged()}*/}
            {/*    message="You have unsaved changes. Do you definitely want to leave?"*/}
            {/*/>*/}
        </div>)
    }
}

export default EditRulePageThermostat;

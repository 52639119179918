import React, {Component} from "react";
import "./AddEditCustomField.scss";
import '../../styles/_layout.scss';
import {Button, Dropdown, EditableList, Icon, Input, List, StandardListCell, Tabs, CardBody} from "gd-react";
import NoItems from "../../components/noItems";
import * as _ from "lodash";
import GridDuck from "gridduck";

class addEditCustomFieldsSideContent extends Component {

    f_type = {
        number: 'valueNumber',
        text: 'valueText',
        // boolean: 'valueBoolean'
    };

    constructor(props) {
        super(props);

        this.perm_levels = ['edit', 'admin'];
        this.onTabClick = this.onTabClick.bind(this);
        this.navToPage = this.navToPage.bind(this);
        this.deleteCustomFieldInstance = this.deleteCustomFieldInstance.bind(this);
        this.saveCustomFieldChanges = this.saveCustomFieldChanges.bind(this);
        this.getActiveList = this.getActiveList.bind(this);
        this.getViewOnlyList = this.getViewOnlyList.bind(this);
        this.getAvailableList = this.getAvailableList.bind(this);
        this.getList = this.getList.bind(this);
        this.sitesChanged = this.sitesChanged.bind(this);
        this.createNewField = this.createNewField.bind(this);
        this.moveNext = this.moveNext.bind(this);
        this.createTabs = this.createTabs.bind(this);
        this.state = {
            newValue: null,
            prevValue: null,
            customField: null,
            creatingNewField: false,
            newSitesList: [],
            customFieldValues: []
        }

        this.createTabs();

    }

    moveNext(moveTo) {
        this.onTabClick(null, this.pageTabs[0][moveTo]);
    }

    sitesChanged(newSitesList) {
        
        this.setState({newSitesList: newSitesList, changedCustomField: true, sitesUpdated: true})
    }

    onTabClick(ev, tab) {
        this.pageTabs.forEach(function (tabArr) {
            tabArr.forEach(function (t) {
                t.selected = (tab.id === t.id);
            })
        })
        this.setState(this.state);
    }

    navToPage(item) {
        this.createTabs();
        let value = item[this.f_type[item.customFieldType]];
        this.setState({newValue: value, prevValue: value, customField: item})
    }

    async createNewField(completeSave) {
        let self = this;

        if (completeSave) {
            
            let createField = {customFieldTypeId: this.props.item.id}
            if (this.props.item.fieldType === 'text') createField.valueText = self.state.newValue;
            if (this.props.item.fieldType === 'number') createField.valueNumber = parseInt(self.state.newValue);
            // if (this.props.item.fieldType === 'boolean') createField.valueBoolean = self.state.newValue === 'true' ? true : false;
            createField.siteIds = self.state.newSitesList.map((s) => s.id)
            let newFieldRes = await GridDuck.createCustomField(createField);
            self.setState({
                newValue: null,
                prevValue: null,
                customField: null,
                creatingNewField: false,
                newSitesList: [],
                changedCustomField: false
            });
            
            return Promise.resolve();
        } else {
            
            this.setState({newValue: '', prevValue: '', customField: 'new', creatingNewField: true}, () => {
                self.createTabs(true);
                self.onTabClick(null, self.pageTabs[0][0]);
                return Promise.resolve();
            });
        }
    }

    editValues(e) {
        let self = this;
        let value = e.target.value;
        self.setState({newValue: value, changedCustomField: true})
    }

    editBoolValues(e) {
        let self = this;
        let value = false;
        if (e.target.value === 'true') value = true;
        self.setState({newValue: value, changedCustomField: true})
    }

    async getActiveList(params) {
        let self = this
        if (!this.state.newSitesList.length && !this.state.creatingNewField) {
            params.filters = [
                {
                    field: 'customFields',
                    value: [this.state.customField.id]
                },
                {field: '_permission', value: this.perm_levels}
            ]
            let actualList = await GridDuck.getSites(params);
            if (!actualList.list.length) {
                return Promise.resolve({list: [], total: 0})
            }
            let list = actualList.list;
            self.setState({newSitesList: actualList});
            
            return Promise.resolve({list: list, total: list.length});
        } else return Promise.resolve({
            list: self.state.newSitesList || [],
            total: self.state.newSitesList.length || 0
        });
    }

    getAvailableList(params) {
        if (!params.filters) params.filters = [];
        // params.filters = params.filters.concat([{field: '_permission', value: this.perm_levels}]);
        return GridDuck.getSites(params)
    }

    async getViewOnlyList(params) {
        let self = this
        params.filters = [
            {
                field: 'customFields',
                value: [this.state.customField.id]
            },
        ]
        return GridDuck.getSites(params);
    }

    async deleteCustomFieldInstance() {
        let self = this
        await self.state.customField.delete();
        this.setState({showToast: 'Custom Field Deleted', customField: null, deleteConfirmation: ''})
    }

    async getList(retrievalParams) {
        retrievalParams.filters = [
            {
                field: 'customFieldTypeId',
                value: [this.props.item.id]
            }
        ];
        let customFields = await GridDuck.getCustomFields(retrievalParams);
        let customFieldValues = customFields.list.map((cf) => {
            let value;
            value = cf.valueText || cf.valueNumber;
            return value.toString();
        });
        
        this.setState({
            customFieldValues: customFieldValues
        });
        return Promise.resolve(customFields);
    }

    async saveCustomFieldChanges() {
        let self = this
        let field = self.state.customField

        let fieldUpdate = {}

        if (this.state.sitesUpdated) {
            
            fieldUpdate.siteIds = self.state.newSitesList.map((s) => s.id)
        }

        if (self.state.newValue !== self.state.prevValue) {
            
            if (field.customFieldType === 'text') fieldUpdate.valueText = self.state.newValue;
            if (field.customFieldType === 'number') fieldUpdate.valueNumber = self.state.newValue;
            // if (field.customFieldType === 'boolean') fieldUpdate.valueBoolean = self.state.newValue;
        }
        await field.set(fieldUpdate);
        self.setState({changedCustomField: false, saved: true});
        return Promise.resolve();
    }

    createTabs() {
        this.pageTabs = [[
            {
                id: 1,
                title: 'Details',
                onTabClick: this.onTabClick,
                selected: true
            },
            {
                id: 2,
                title: 'Sites',
                onTabClick: this.onTabClick
            }
        ]]
        if (this.props.item && this.props.item._permission !== 'view' && !this.state.creatingNewField) {
            this.pageTabs[0].push({
                id: 3,
                title: 'Delete',
                onTabClick: this.onTabClick
            })
        }
    }

    render() {
        this.pageTabs[0][1].disabled = (!this.state.newValue);
        let self = this;
        let viewOnly = (this.props.item && this.props.item._permission === 'view');
        let r = self.state.customField
        let sameAsValue = (this.state.newValue && (this.state.newValue !== this.state.prevValue) && this.state.customFieldValues.find(cfv => cfv === this.state.newValue));
        
        
        
        let valueInput;
        let displayValue;
        let sideFooter;

        if (r) {
            if (!this.state.creatingNewField && this.state.changedCustomField && ((this.pageTabs[0][0] && this.pageTabs[0][0].selected) || (this.pageTabs[0][1] && this.pageTabs[0][1].selected))) {
                sideFooter =
                    <Button progressRes disabled={viewOnly}
                            additionalclasses={'sm'}
                            label={'Save'}
                            color={'gd-green'}
                            onClick={this.saveCustomFieldChanges}/>

            }
            if (this.pageTabs[0][2] && this.pageTabs[0][2].selected) {
                sideFooter =
                    <Button progressRes
                            disabled={(this.state.deleteConfirmation !== 'DELETE') || viewOnly}
                            additionalclasses={'md'}
                            label={'Permanently Delete'} color={'gd-red'}
                            onClick={this.deleteCustomFieldInstance}/>

            }

            if (this.state.creatingNewField && (this.pageTabs[0][0] && this.pageTabs[0][0].selected)) {
                sideFooter =
                    <Button label={'Next'}
                            color={'gd-green'}
                            disabled={(!this.state.newValue) || sameAsValue}
                            onClick={() => this.moveNext(1)}/>

            }
            if (this.state.creatingNewField && (this.pageTabs[0][1] && this.pageTabs[0][1].selected)) {
                sideFooter = [(<Button label={'Create'}
                                       progressRes
                                       additionalclasses={'sm'}
                                       disabled={!this.state.newSitesList.length}
                                       color={'gd-green'}
                                       onClick={() => this.createNewField(true)}/>),
                    (<span style={{marginLeft: '10px'}}/>),
                    (<Button label={'Back'}
                             color={'gd-grey'}
                             outline
                             onClick={() => this.moveNext(0)}/>)]

            }

            switch (this.props.item.fieldType) {
                case 'text':
                    displayValue = r.valueText;
                    valueInput = <Input label={'Value'}
                                        onChange={(e) => {
                                            self.editValues(e)
                                        }}
                                        disabled={viewOnly}
                                        placeholder={'Type New Text Value'}
                                        value={self.state.newValue}
                    />
                    break;
                case 'number':
                    displayValue = r.valueNumber;
                    valueInput = <Input label={'Value'}
                                        onChange={(e) => {
                                            self.editValues(e)
                                        }}
                                        disabled={viewOnly}
                                        placeholder={'Type New Number Value'}
                                        type={'number'}
                                        value={self.state.newValue}
                    />
                    break;
                // case 'boolean':
                //     displayValue = r.valueBoolean ? 'True' : 'False';
                //     valueInput = <Dropdown label={'Value'}
                //                            onChange={(e) => {
                //                                self.editBoolValues(e)
                //                            }}
                //                            disabled={viewOnly}
                //                            placeholder={'Select Value'}
                //                            fixeditems={[
                //                                {
                //                                    title: 'True',
                //                                    value: 'true'
                //                                },
                //                                {
                //                                    title: 'False',
                //                                    value: 'false'
                //                                }
                //                            ]}
                //                            value={self.state.newValue}/>
                //     break;
                default:
                    displayValue = '';
                    valueInput = null;
            }
        } else {
            if (!this.state.creatingNewField && !viewOnly) {
                sideFooter =
                    <Button label={'Create New Value'}
                            color={'gd-green'}
                            onClick={() => this.createNewField()}/>
            }
        }
        return <div className={"modal-side-content custom-field-vals"}>
            <div className={'CardHeader'}>
                {this.state.customField ?
                    <div className={'back-button-wrapper clickable'} onClick={() =>
                        self.setState({
                            newValue: null,
                            prevValue: null,
                            customField: null,
                            creatingNewField: false,
                            newSitesList: [],
                            changedCustomField: false
                        })}>
                        <Icon icon={'FaChevronLeft'}/> {self.state.newValue}</div> : 'Values'}
            </div>
            {!this.state.customField ?
                <List
                    removeHeaders
                    noitemelem={<NoItems
                        text={<div className={'no-applications-text'}>'{this.props.item.name}' has not
                            been
                            applied to any sites
                        </div>}
                    />}
                    columns={[
                        {
                            component: StandardListCell,
                            title: '',
                            field: 'value',
                            maxWidth: 'none',
                            custom: function (item) {
                                let value = item[self.f_type[item.customFieldType]];
                                if (_.isString(value) || _.isNumber(value)) {
                                    return value
                                } else {
                                    return value ? 'True' : 'False'
                                }
                            }
                        },
                    ]}
                    rowOnClick={
                        (item) => {
                            self.navToPage(item)
                        }}
                    getlist={this.getList}
                /> : <div className={'row'} style={{overflow: 'hidden'}}>
                    <div className={'column'}>
                        <Tabs tabData={this.pageTabs}/>
                        <CardBody additionalclasses={(this.pageTabs[0][1] && this.pageTabs[0][1].selected ? ' no-padding' : '')}>
                            {this.pageTabs[0][0] && this.pageTabs[0][0].selected ?
                                <div className={'row'}
                                     style={{marginTop: '-10px'}}>
                                    {valueInput}
                                </div>
                                : ''}
                            {this.pageTabs[0][1] && this.pageTabs[0][1].selected ?
                                viewOnly ? <List
                                    removeHeaders
                                    noitemelem={<NoItems
                                        text={<div className={'no-applications-text'}>'{this.props.item.name}'
                                            has not
                                            been applied to any sites
                                        </div>}
                                    />}
                                    columns={[
                                        {
                                            component: StandardListCell,
                                            title: 'Site',
                                            field: 'name',
                                            maxWidth: 'none'
                                        },
                                    ]}
                                    getlist={this.getViewOnlyList}
                                /> : <EditableList
                                    isEditing={!this.props.item || this.props.item._permission !== 'view'}
                                    columns={[
                                        {
                                            component: StandardListCell,
                                            bold: true,
                                            title: 'Name',
                                            field: 'name',
                                            sortable: true,
                                            defaultSort: 'asc'
                                        },
                                        {
                                            component: StandardListCell,
                                            title: 'Access level',
                                            field: '_permission',
                                            maxWidth: 'none'
                                        },
                                    ]}
                                    key={1}
                                    onChange={this.sitesChanged}
                                    noCancel={true}
                                    noSave
                                    getActiveList={this.getActiveList}
                                    getAvailableList={this.getAvailableList}
                                />
                                : ''}
                            {this.pageTabs[0][2] && this.pageTabs[0][2].selected ?
                                <Input required
                                           error={this.state.triedToDelete}
                                           top='true'
                                           onChange={(e) => this.setState({
                                               deleteConfirmation: e.target.value,
                                               changedCustomFieldType: true
                                           })}
                                           placeholder={'DELETE'}
                                           name={'delete'}
                                           value={this.state.deleteConfirmation}
                                           label={'Enter DELETE'}/>
                                : ''}
                        </CardBody>
                        {sameAsValue ?
                            <p className={'error-text'}>This value already exists for {this.props.item.name}</p> : null}
                    </div>
                </div>
            }
            <div className={'modal-footer with-top-border'}>
                {sideFooter}
            </div>
        </div>
    }
}

export default addEditCustomFieldsSideContent;
import React from "react";
import "./AnomalyDetection.scss";
import {EditableList, SensorDataListCell, StandardListCell, StateListCell} from "gd-react";
import GridDuck from "gridduck";
import history from "../../meta/history";
import GenericLoader from "../../components/GenericLoader";

class AnomalyMonitoredDevices extends React.Component {
    constructor(props) {
        super(props);
        this.getList = this.getList.bind(this);
        this.goToAsset = this.goToAsset.bind(this);
        this.getAvailableList = this.getAvailableList.bind(this);
        this.save = this.save.bind(this);
        this.state = {}
    }

    componentDidMount() {
        let self = this;
        GridDuck.getAccount({id: GridDuck.userId})
            .then(function (user) {
                
                self.setState({orgPerm: user.orgPermission});
            });
    }

    async save(newAssets, itemsToAddRemove) {
        // Clear Down current assets flagged
        let allAssets = await this.getList({getAll: true});
        await Promise.all(allAssets.list.map(a => a.setAnomalyDetection(false)));
        // Set assets to be monitored from new list
        let _newAssets = allAssets.list.filter(assetId => !itemsToAddRemove.itemsToRemove.find(i => i.id === assetId)).concat(itemsToAddRemove.itemsToAdd);
        return await Promise.all(_newAssets.map(a => a.setAnomalyDetection(true)));
    }

    getList(retrievalParams) {
        retrievalParams.filters = [
            {
                field: 'anomalyDetection',
                value: true
            }
        ];
        return GridDuck.getAssets(retrievalParams);
    }


    goToAsset(asset) {
        history.push("/device/" + asset.id);
    }

    getAvailableList(retrievalParams) {
        return GridDuck.getAssets(retrievalParams);
    }

    render() {
        return (
            <div className='page'>
                {this.state.orgPerm ? <EditableList
                    columns={[
                        {component: StandardListCell, bold: true, sortable: true, title: 'Name', field: 'name'},
                        {component: StandardListCell, title: 'Site Name', field: 'siteName'},
                    ]}
                    getActiveList={this.getList}
                    getAvailableList={this.getAvailableList}
                    save={this.save}
                    disabled={this.state.orgPerm === 'view'}
                    rowOnClick={this.goToAsset}
                    parentField={"parentAsset"}
                    childrenField={"children"}
                    expanded={true}
                /> : <GenericLoader/>}
            </div>
        )
    }

}

export default AnomalyMonitoredDevices;
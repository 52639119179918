import React, {Component} from "react";
import "../AddDevice/AddDeviceModal.scss";
import '../../styles/_layout.scss';
// import '../../styles/_theme.scss'
import './AuditModal.scss';
import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Dropdown,
	GdAutocomplete,
	Icon,
	Input,
	Loader,
	StateOptions
} from "gd-react";
import GridDuck from "gridduck";
import moment from "moment";
import history from "../../meta/history";
import GenericLoader from "../../components/GenericLoader";
import getFormat from "../../services/formatter";
import GdModal from "../../components/GdModal/GdModal";

class AuditModal extends Component {

	constructor(props) {
		super(props);
		let self = this;
		this.state = {
			loaded: false,
			itemExists: null,
			fileIcon: null,
			deviceImage: null,
			sku: null
		};
		this.closeModal = this.closeModal.bind(this);
		this.processFile = this.processFile.bind(this)
		this.processHub = this.processHub.bind(this)
		this.skuDetails = this.skuDetails.bind(this);
		this.modalClosed = false;
	}

	closeModal() {
		
		this.setState({loaded: false, itemExists: null, fileIcon: null, deviceImage: null, sku: null})
		this.modalClosed = true;
		this.props.onClose();
	}

	skuDetails(sku) {
		let self = this;
		if (sku) {
			
			self.setState({deviceImage: '/images/products/' + sku + '.png', sku: sku})
		} else {
			self.setState({deviceImage: '/images/products/unknown.png'})
		}
	}

	processDevice() {
		let self = this;
		let device = this.props.item;
		let filters = [
			{
				field: this.props.itemType,
				value: this.props.itemId
			},
		];
		
		GridDuck.getAssets({
			filters: filters
		}).then((res) => {
			for (let i = 0; i < res.list.length; i++) {
				if (res.list[i].id === device.id) {
					this.skuDetails(res.list[i].sku)
					self.setState({itemExists: true, loaded: true})
					break
				} else {
					self.setState({itemExists: false, loaded: true})
				}
			}
		})
	}

	processHub() {
		let self = this;
		let hub = this.props.item;
		let filters = [
			{
				field: 'siteId',
				value: this.props.itemId
			},
		];
		GridDuck.getGateways({
			filters: filters
		}).then((res) => {
			for (let i = 0; i < res.list.length; i++) {
				if (res.list[i].id === hub.id) {
					self.setState({itemExists: true, loaded: true})
					break
				} else {
					self.setState({itemExists: false, loaded: true})
				}
			}
		})
	}


	processFile() {
		let self = this;
		let file = this.props.item;
		let filters = [
			{
				field: 'itemType',
				value: this.props.itemType
			},
			{
				field: 'itemId',
				value: this.props.itemId
			},
		];
		let fileIcon;
		switch (file.type) {
			case 'application/pdf':
				fileIcon = 'FaFilePdf'
				break
			case 'application/powerpoint':
				fileIcon = 'FaFilePowerpoint'
				break
			case 'application/zip':
				fileIcon = 'FaFileArchive'
				break
			case 'video/mp4':
				fileIcon = 'FaFileVideo'
				break
			case 'audio/mp3':
				fileIcon = 'FaFileAudio'
				break
			case 'audio/wav':
				fileIcon = 'FaFileAudio'
				break
			case 'application/word':
				fileIcon = 'FaFileWord'
				break
			default:
				fileIcon = 'FaQuestionCircle'
		}
		GridDuck.getFiles({
			filters: filters
		}).then((res) => {
			for (let i = 0; i < res.list.length; i++) {
				if (res.list[i].id === file.id) {
					self.setState({itemExists: true, fileIcon: fileIcon, loaded: true})
					break
				} else {
					self.setState({itemExists: false, fileIcon: fileIcon, loaded: true})
				}
			}
		})
	}

	render() {
		let self = this;
		let modalContent
		let auditHeader = <div className={'audit-header-modal'}>
			<p className={'audit-header-text'}>Information below fixed as
				of {getFormat('lastContacted')(this.props.audit.createdDate)}
			</p>
		</div>
		let doesNotExistHeader;
		if (this.state.loaded && this.state.itemExists === false) doesNotExistHeader = <div className={'doesNotExist-header-modal'}>
			<p className={'audit-header-text'}>This item no longer exists
			</p>
		</div>
		if (this.props.previewType === 'file') {
			let file = this.props.item;
			if (file && this.state.itemExists === null) this.processFile()
			let imageStyle;
			if (file) imageStyle = {
				backgroundImage: `url('` + file.location + `')`
			}
			if (this.state.loaded === true) {
				modalContent = <CardBody>
					{auditHeader}
					{doesNotExistHeader}
					<div className={'audit-modal-overview'}>
						<div className={'preview-container'}>
							{file.type === 'image/png' || file.type === 'image/jpeg' ?
								<div className={'file-preview'} style={imageStyle}></div> :
								<Icon size={'60'} color={"gd-red"} icon={self.state.fileIcon}/>}
						</div>
						<div className={'stat-container'}>
							<p><span className={'bold'}>Size: </span>{getFormat('fileSize')(file.size)}</p>
							<p><span className={'bold'}>File Type: </span>{file.type}</p>
							<p><span className={'bold'}>Description: </span>{file.description}</p>
						</div>
					</div>
				</CardBody>
			} else {
				modalContent = <CardBody>
					<GenericLoader/>
				</CardBody>
			}
		} else if (this.props.previewType === 'device') {
			
			let device = this.props.item;
			if (device && this.state.itemExists === null) this.processDevice()

			let imageStyle;
			if (device && this.state.loaded) imageStyle = {
				backgroundImage: `url(${this.state.deviceImage})`

			}
			if (this.state.loaded === true) {
				modalContent = <CardBody>
					{auditHeader}
					{doesNotExistHeader}
					<div className={'audit-modal-overview'}>
						<div className={'preview-container'}>
							<div className={'file-preview'} style={imageStyle}></div>
						</div>
						<div className={'stat-container'}>
							<p><span className={'bold'}>Status:</span> {device.disconnected ? 'DISCONNECTED' : 'CONNECTED'}</p>
							{this.state.sku !== 'SEN-TEM-HUM' ? <p><span className={'bold'}>Demand:</span> {device.consumption || device.consumption == 0 ? getFormat('consumption')(device.consumption) : '-'}</p> : null}
							{this.state.sku === 'SEN-TEM-HUM' ? <p><span className={'bold'}>Temperature:</span> {device.temperature ? getFormat('temperature')(device.temperature) : '-'}</p> : null}
							{this.state.sku === 'SEN-TEM-HUM' ? <p><span className={'bold'}>Humidity:</span> {device.humidity ? getFormat('humidity')(device.humidity) : '-'}</p> : null}
							<p><span className={'bold'}>ID:</span> {device.id ? device.id : '-'}</p>
							{this.state.sku === 'MET-REA' ? <p><span className={'bold'}>Imp/kWh:</span> {device.impKwh}</p> : null}
							{this.state.sku === 'SEN-POW' ? <p><span className={'bold'}>Battery Percentage: </span> {device.batteryPercent}</p> : null}
							{this.state.sku === 'SEN-POW' ? <p><span className={'bold'}>Battery Voltage: </span> {device.batteryVoltage}</p> : null}
						</div>
					</div>
				</CardBody>
			} else {
				modalContent = <CardBody>
					<GenericLoader/>
				</CardBody>
			}
		} else if (this.props.previewType === 'hub') {
			let hub = this.props.item;
			if (hub && this.state.itemExists === null) this.processHub()
			let imageStyle;
			if (hub && this.state.loaded) imageStyle = {
				backgroundImage: `url('/images/products/GAT-DOM.png')`
			}
			if (this.state.loaded === true) {
				modalContent = <CardBody>
					{auditHeader}
					{doesNotExistHeader}
					<div className={'audit-modal-overview'}>
						<div className={'preview-container'}>
							<div className={'file-preview'} style={imageStyle}></div>
						</div>
						<div className={'stat-container'}>
							<p><span className={'bold'}>Status: </span> {hub.status}</p>
							<p><span className={'bold'}>ID: </span> {hub.id}</p>
							<p><span className={'bold'}>Power Source: </span> {hub.powerSource ? hub.powerSource : '-'}</p>
							<p><span className={'bold'}>Connection Type: </span> {hub.connectionType ? hub.connectionType : '-'}</p>
							{hub.connectionType === 'mobile' ? <p><span className={'bold'}>Mobile Strength: </span>{hub.mobileStrength}</p> : null}
						</div>
					</div>
				</CardBody>
			} else {
				modalContent = <CardBody>
					<GenericLoader/>
				</CardBody>
			}
		}

		return (
			<GdModal
				open={this.props.open}
				title={this.state.loaded ? this.props.item.name : ''}
				onClose={this.closeModal}>
				{modalContent}
			</GdModal>
		)
	}

}

export default AuditModal;

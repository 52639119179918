import React from "react";
import GridDuck from "gridduck";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    DemandListCell, Dropdown,
    EditableList,
    GdAutocomplete,
    Input,
    StandardListCell,
    StateListCell,
    Toast
} from "gd-react";
import cookie from "react-cookies";
import history from "./../../meta/history";
import GenericLoader from "../../components/GenericLoader";

class HubFirmware extends React.Component {
    constructor(props) {
        super(props);
        let self = this;
        GridDuck.getGatewaySoftwareVersions({getAll: true}).then(function (versionList) {
            self.setState({versionList: versionList});
        })
        this.state = {};
        this.updateAllHubs = this.updateAllHubs.bind(this);
        this.externallyUpdated = this.externallyUpdated.bind(this);
    }

    externallyUpdated() {
        let self = this;
        self.setState({versionList: null});
        GridDuck.getGatewaySoftwareVersions({getAll: true}).then(function (versionList) {
            self.setState({versionList: versionList});
        })
    }

    componentWillUnmount() {
        if (this.state.versionList) {
            this.state.versionList.off('updated',this.externallyUpdated)
        }
    }

    updateAllHubs() {
        let self = this;
        let targetId = this.state.versionList.list.find(v => v.target).id;
        self.setState({updateStatus: 'Updating Hubs'});
        return GridDuck.getGatewayGods({getAll: true})
            .then(async function (gwList) {
                self.setState({updateStatus: 'Updated 0/' + gwList.total + ' Hubs'});
                for (let i = 0; i < gwList.total; i++) {
                    await gwList.list[i].set({
                        targetVersion: targetId
                    });
                    self.setState({updateStatus: 'Updated ' + (i + 1) + '/' + gwList.total + ' Hubs'});
                }
                setTimeout(function () {
                    self.setState({updateStatus: null});
                }, 5000);
                return Promise.resolve();
            })
    }

    render() {
        let self = this;
        let firmwareOptions;
        let currentFirmware;
        let currentFirmwareDetails;
        if (this.state.versionList) {
            let firmwareItems = this.state.versionList.list
                .filter(function (v) {
                    return !v.archived && !v.rejected
                })
                .map(function (v) {
                    return {
                        id: v.id,
                        value: v.id,
                        title: v.notes + ' (' + v.id + '-' + '"' + v.versionString + '")'
                    };
                });
            let selectedFirmware;
            
            let firmwareVersion;
            if (this.state.selectedFirmware) {
                firmwareVersion = this.state.versionList.list.find(v => v.id === this.state.selectedFirmware);
                selectedFirmware = (<CardBody>
                    ID: {firmwareVersion.id} <br/>
                    String: {firmwareVersion.versionString} <br/>
                    Notes: {firmwareVersion.notes}
                </CardBody>)
            }
            firmwareOptions = ([
                    <CardBody>
                        <Dropdown
                            label={'Available Firmware'}
                            name={'chosenFirmware'}
                            value={this.state.selectedFirmware}
                            fixeditems={firmwareItems}
                            onChange={(i) => self.setState({selectedFirmware: i.target.value})}/>
                        {selectedFirmware}
                        <div style={{
                            'display': 'flex',
                            'flexDirection': 'row-reverse',
                            marginTop: '30px',
                            justifyContent: 'center'
                        }}>
                            <Button progressRes label={"Make Target"}
                                    onClick={function() {
                                        return firmwareVersion.set({target: true}).then(self.externallyUpdated)
                                    }}
                                    progressRes/>
                        </div>
                        <div style={{
                            'display': 'flex',
                            'flexDirection': 'row-reverse',
                            marginTop: '30px',
                            justifyContent: 'center'
                        }}>
                            <Button progressRes color={'gd-red'} label={"Archive Firmware"}
                                    onClick={function() {
                                        return firmwareVersion.set({archived: true}).then(self.externallyUpdated)
                                    }}
                                    progressRes/>
                        </div>
                    </CardBody>,
                ]
            )


            currentFirmware = this.state.versionList.list.find(v => v.target);
            currentFirmwareDetails = ([<CardBody>
                    ID: {currentFirmware ? currentFirmware.id : ''} <br/>
                    String: {currentFirmware ? currentFirmware.versionString : ''} <br/>
                    Notes: {currentFirmware ? currentFirmware.notes : ''}
                </CardBody>,
                    <CardBody>
                        <div style={{
                            'display': 'flex',
                            'flexDirection': 'row-reverse',
                            marginTop: '30px',
                            justifyContent: 'center'
                        }}>
                            <Button progressRes label={"Update All Hubs To Target"} onClick={this.updateAllHubs}/>
                        </div>
                    </CardBody>,
                    this.state.updateStatus ? (<CardBody>{this.state.updateStatus}</CardBody>) : ''
                ]
            )
        } else {
            currentFirmwareDetails = (<GenericLoader text={'Fetching Data...'} textLineTwo={'This may take a while'}/>);
            firmwareOptions = (<GenericLoader text={'Fetching Data...'} textLineTwo={'This may take a while'}/>)
        }
        return (
            <div className='page narrow Account not-flex grey'>
                <div className={'column top'}>
                    <div className={'detail-content'}>
                        <Card>
                            <CardHeader>Current Firmware Target</CardHeader>
                            {currentFirmwareDetails}
                        </Card>
                    </div>
                    <div className={'detail-content'}>
                        <Card>
                            <CardHeader>Manage Firmware Versions</CardHeader>
                            {firmwareOptions}
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

export default HubFirmware;

import getFormat from "../../services/formatter";
import React from "react";
import "./savingsCalculator.scss";
import GridDuck from "gridduck";
import {GdAutocomplete, Input, Button} from "gd-react";
import savingImage from './../../images/Energy-Saving-Simulator.png';
import OpeningHours from "../../components/OpeningHours/OpeningHours";
import Appliances from "../../components/Appliances/Appliances";
import SavingBreakdown from "../../components/SavingBreakdown/SavingBreakdown";
import moment from "moment";

const price_per_kwh = 0.23;
const price_date = moment().format('MMMM') + ' ' + moment().format('YYYY');

class SavingsCalculator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            processedSavings: {},
            businessName: '',
            stage: 1,
            appliances: [{id: Math.random().toString(), typeId: null, number: '1'}],
            orgFromAutocomplete: {},
            selectedOrganisation: {
                opening_hours: [
                    {day: 'Sunday', not_closed: 'true', open: '00:00', close: '00:00'},
                    {day: 'Monday', not_closed: 'true', open: '00:00', close: '00:00'},
                    {day: 'Tuesday', not_closed: 'true', open: '00:00', close: '00:00'},
                    {day: 'Wednesday', not_closed: 'true', open: '00:00', close: '00:00'},
                    {day: 'Thursday', not_closed: 'true', open: '00:00', close: '00:00'},
                    {day: 'Friday', not_closed: 'true', open: '00:00', close: '00:00'},
                    {day: 'Saturday', not_closed: 'true', open: '00:00', close: '00:00'}
                ]
            },
            emailAddress: ''
        };

        this.applianceTypeData = [
            {
                name: 'Drinks Fridge',
                typeId: 1,
                minAverage: 5.25,
                minsLeadTime: 120
            },
            {
                name: 'Ice Machine',
                typeId: 2,
                minAverage: 4.7,
                minsLeadTime: 180
            },
            {
                name: 'Rapid Chillier (Soda Pump)',
                typeId: 3,
                minAverage: 6.98,
                minsLeadTime: 120
            },
            {
                name: 'Electric Panel Heater',
                typeId: 4,
                minAverage: 33.4,
                minsLeadTime: 120
            }
        ];

        this.searchBusiness = this.searchBusiness.bind(this);
        this.selectBusiness = this.selectBusiness.bind(this);
        this.updateHours = this.updateHours.bind(this);
        this.updateAppliances = this.updateAppliances.bind(this);
        this.addAnotherAppliance = this.addAnotherAppliance.bind(this);
        this.removeAppliance = this.removeAppliance.bind(this);
        this.processHoursData = this.processHoursData.bind(this);
        this.calculateSavings = this.calculateSavings.bind(this);
        this.submitInbound = this.submitInbound.bind(this);
    }

    submitInbound() {
        let placesId = this.state.placesId || '';
        let businessName = this.state.businessName || this.state.selectedOrganisation.details.structuredFormatting.mainText;
        return new Promise((resolve, reject) => {
            GridDuck.createCalculatorInbound({
                emailAddress: this.state.emailAddress,
                googlePlacesId: placesId,
                businessName: businessName
            }).then((res) => {

                resolve();
                this.setState({stage: 2, inboundId: res.id});
            });
        });
    }

    calculateSavings() {
        let self = this;
        return new Promise((resolve, reject) => {
            this.processHoursData(this.state.selectedOrganisation.opening_hours);
            let ph = this.processedHours, minsInWeek = 10080, totalSaved = 0, totalBeforeSaving = 0;
            for (const appliance of this.state.appliances) {
                let atd = this.applianceTypeData.find((atd) => atd.typeId === appliance.typeId);
                let minsLeadTime = ph.daysOpen * atd.minsLeadTime;
                let updatedMinsOff = ph.minsInWeekClosed - minsLeadTime;
                appliance.name = atd.name;
                appliance.totalKwhSaved = Math.round(((updatedMinsOff * atd.minAverage) / 1000) * parseInt(appliance.number)) * 52;
                appliance.kwhWeekSaved = Math.round(((updatedMinsOff * atd.minAverage) / 1000) * parseInt(appliance.number));
                appliance.totalCarbonSaved = Math.round(appliance.kwhWeekSaved * 0.233) * 52;
                appliance.energyBeforeSavingInWeek = ((minsInWeek * atd.minAverage) / 1000) * parseInt(appliance.number);
                appliance.pounds = Math.round(appliance.totalKwhSaved * price_per_kwh)
                totalSaved += appliance.kwhWeekSaved;
                totalBeforeSaving += appliance.energyBeforeSavingInWeek;
            }

            let totalCarbon = Math.round(totalSaved * 0.233) * 52;

            let processedSavingsData = {
                totalPercentageSaved: Math.round((totalSaved / totalBeforeSaving) * 100),
                totalKwhSaved: Math.round(totalSaved) * 52,
                totalCarbonSaved: totalCarbon,
                elephants: Math.round(totalCarbon / 4000),
                pounds: Math.round((Math.round(totalSaved) * 52) * price_per_kwh),
                date: price_date,
                price_per_kwh: price_per_kwh
            }

            GridDuck.createCalculatorResult({
                inboundId: self.state.inboundId,
                totalSavingData: processedSavingsData,
                applianceSavingData: self.state.appliances,
                hours: this.state.selectedOrganisation.opening_hours
            }).then((res) => {
                self.setState({stage: 4, processedSavingsData: processedSavingsData});
                resolve();
            })

        })
    }

    updateAppliances(updateObj) {
        this.state.appliances[updateObj.i][updateObj.key] = updateObj.val;

        this.setState({appliances: this.state.appliances});
    }

    addAnotherAppliance() {
        this.state.appliances.push({id: Math.random().toString(), typeId: '', number: '1'})
        this.setState({appliances: this.state.appliances});
    }

    removeAppliance(oi) {
        let appliances = this.state.appliances.filter((a, i) => i !== oi);
        this.setState({appliances: appliances});
    }

    parseTime(s) {
        let c = s.split(':');
        return parseInt(c[0]) * 60 + parseInt(c[1]);
    }

    getDiff(start_time, end_time) {
        let a = this.parseTime(start_time), b = this.parseTime(end_time);
        if (b < a) // means its the next day.
            return this.parseTime("23:59") - (a + b);
        else if (b > a)
            return b - a;
        else if (b - a === 0)
            return 24 * 60;
        else
            alert("Invalid data");
    }

    getHoursInWeek(hours) {
        let minsOpen = 0, minsClosed = 0, minsInDay = (24 * 60);
        for (const hour of hours) {
            if (hour.not_closed === 'true') {
                let open = this.getDiff(hour.open, hour.close);
                minsOpen += open;
                minsClosed += minsInDay - open;
            } else minsClosed += minsInDay;
        }
        return {
            open: minsOpen,
            closed: minsClosed
        }
    }

    processHoursData(hours) {
        this.processedHours = {
            daysOpen: hours.filter((h) => h.not_closed === 'true').length,
            minsInWeekOpen: this.getHoursInWeek(hours).open,
            minsInWeekClosed: this.getHoursInWeek(hours).closed
        };
    }

    updateHours(hours) {
        this.state.selectedOrganisation.opening_hours = hours;
        this.setState({selectedOrganisation: this.state.selectedOrganisation, updated: true});
        this.processHoursData(this.state.selectedOrganisation.opening_hours);
    }

    async selectBusiness(item, other) {
        if (!other || !other.id) return;
        let place = await GridDuck.getPlaces({id: other.id});
        let placeDetails = this.state.locationsFromSearch.find((lfs) => other.id === lfs.placeId);
        this.setState({updated: false, placesId: placeDetails.placeId}, () => this.setState({
            selectedOrganisation: {
                details: placeDetails,
                opening_hours: [
                    {day: 'Sunday', not_closed: 'true', open: '00:00', close: '00:00'},
                    {day: 'Monday', not_closed: 'true', open: '00:00', close: '00:00'},
                    {day: 'Tuesday', not_closed: 'true', open: '00:00', close: '00:00'},
                    {day: 'Wednesday', not_closed: 'true', open: '00:00', close: '00:00'},
                    {day: 'Thursday', not_closed: 'true', open: '00:00', close: '00:00'},
                    {day: 'Friday', not_closed: 'true', open: '00:00', close: '00:00'},
                    {day: 'Saturday', not_closed: 'true', open: '00:00', close: '00:00'}
                ]
            },
            gHours: place.businessDetails.opening_hours,
            orgFromAutoComplete: other
        }))
    }

    async searchBusiness(newText) {
        if (!newText.length) return;
        let res = await GridDuck.getPlacess({filters: [{field: 'input', value: newText}]});
        let locations = res.list[0].locations;
        this.setState({locationsFromSearch: locations});

        return locations.map(function (l) {
            return {
                id: l.placeId,
                title: l.structuredFormatting.mainText + ' - ' + l.structuredFormatting.secondaryText,
                subTitle: 'Test',
                icon: 'FaBuilding'
            }
        });
    }

    async componentDidMount() {
        //TODO Remove when done testing
        // let locs = await this.searchBusiness('GridDuck');
        // await this.selectBusiness(null, locs[0]);
        // this.setState({stage: 2})
    }

    render() {
        let leftPanelContent, rightPanelContent;
        rightPanelContent = <div>
            <div className={'panel-header'}>
                <p>Saving with GridDuck</p>
            </div>
            <div className={'panel-content'}>
                <p>Not every appliance needs to be on when your business is closed, especially the ones that use a lot
                    of energy like drinks fridges, ice machines and heaters. By turning them off out of hours you can
                    save a great deal on your energy spend.</p>
                <p className={'bold'}>Example</p>
                <p>The London Cocktail Club managed to save <span className={'bold'}>£1,124</span> per year by using
                    GridDuck to automatically turn off
                    their fridges and ice machines off out of hours.</p>
                <p>Find out how much you could be saving with our energy savings calculator.</p>
                <img src={savingImage}/>
            </div>
        </div>
        let businessName = this.state.businessName ? {
            mainText: this.state.businessName,
            secondaryText: ''
        } : this.state.selectedOrganisation.details ? this.state.selectedOrganisation.details.structuredFormatting : '';
        if (this.state.stage === 1) {
            leftPanelContent = <div>
                <div className={'panel-header'}>
                    <p>1. Details</p>
                </div>
                <div className={'panel-content'}>
                    <div className={'row'} style={{marginBottom: '15px'}}>
                        <Input top={'true'} required label={"Email Address"} placeholder={'example@gridduck.com'}
                               value={this.state.emailAddress}
                               onChange={e => this.setState({emailAddress: e.target.value})}/>
                    </div>
                    {!this.state.enterBusinessName ? <div>
                        <div className={'row'}>
                            <GdAutocomplete
                                async
                                name={'business'}
                                label={'Business Name'}
                                required
                                value={this.state.orgFromAutoComplete}
                                getOptionSelected={function (option, value) {
                                    if (option && value) return option.id === value.id;
                                }}
                                placeholder={'E.g. GridDuck'}
                                getList={this.searchBusiness}
                                onChange={this.selectBusiness}/>
                        </div>
                        <div className={'row'} style={{marginBottom: '15px', flexDirection: 'row-reverse'}}>
                            <p style={{margin: '10px', fontSize: '12px'}}><a className={'link'}
                                                                             onClick={() => this.setState({
                                                                                 enterBusinessName: true,
                                                                                 selectedOrganisation: {
                                                                                     opening_hours: [
                                                                                         {
                                                                                             day: 'Sunday',
                                                                                             not_closed: 'true',
                                                                                             open: '00:00',
                                                                                             close: '00:00'
                                                                                         },
                                                                                         {
                                                                                             day: 'Monday',
                                                                                             not_closed: 'true',
                                                                                             open: '00:00',
                                                                                             close: '00:00'
                                                                                         },
                                                                                         {
                                                                                             day: 'Tuesday',
                                                                                             not_closed: 'true',
                                                                                             open: '00:00',
                                                                                             close: '00:00'
                                                                                         },
                                                                                         {
                                                                                             day: 'Wednesday',
                                                                                             not_closed: 'true',
                                                                                             open: '00:00',
                                                                                             close: '00:00'
                                                                                         },
                                                                                         {
                                                                                             day: 'Thursday',
                                                                                             not_closed: 'true',
                                                                                             open: '00:00',
                                                                                             close: '00:00'
                                                                                         },
                                                                                         {
                                                                                             day: 'Friday',
                                                                                             not_closed: 'true',
                                                                                             open: '00:00',
                                                                                             close: '00:00'
                                                                                         },
                                                                                         {
                                                                                             day: 'Saturday',
                                                                                             not_closed: 'true',
                                                                                             open: '00:00',
                                                                                             close: '00:00'
                                                                                         }
                                                                                     ]
                                                                                 },
                                                                                 orgFromAutoComplete: {},
                                                                                 placesId: ''
                                                                             })}>Can't
                                find your business?</a></p>
                        </div>
                    </div> : null}
                    {this.state.enterBusinessName ? <div>
                        <div className={'row'}>
                            <Input top={'true'} required label={"Business Name"} placeholder={'E.g. GridDuck'}
                                   value={this.state.businessName}
                                   onChange={e => this.setState({businessName: e.target.value})}/>
                        </div>
                        <div className={'row'} style={{marginBottom: '15px', flexDirection: 'row-reverse'}}>
                            <p style={{margin: '10px', fontSize: '12px'}}><a className={'link'}
                                                                             onClick={() => this.setState({
                                                                                 enterBusinessName: false,
                                                                                 businessName: ''
                                                                             })}>Search Business on Google</a></p>
                        </div>
                    </div> : null}
                    <div className={'row'} style={{flexDirection: 'row-reverse'}}>
                        <Button progressRes='true' additionalclasses={'sm'}
                                disabled={!((this.state.selectedOrganisation.details || this.state.businessName.length) && this.state.emailAddress.length)}
                                onClick={this.submitInbound} label={'Next'}/>
                    </div>
                </div>
            </div>
        }
        if (this.state.stage === 2) {
            leftPanelContent = <div>
                <div className={'panel-header'}>
                    <p>2. Opening Hours</p>
                    <p className={'sub-heading'}>{businessName.mainText + (businessName.secondaryText.length ? ' - ' : '') + businessName.secondaryText}</p>
                </div>
                <div className={'panel-content'}>
                    <OpeningHours gHours={this.state.gHours} updated={this.state.updated}
                                  hours={this.state.selectedOrganisation.opening_hours}
                                  onUpdate={this.updateHours}/>
                    <div className={'row'} style={{flexDirection: 'row-reverse', justifyContent: 'space-between'}}>
                        <Button onClick={() => {
                            this.setState({stage: 3});
                            
                        }} label={'Next'}/>
                        <Button onClick={() => {
                            this.setState({stage: 1});
                        }} label={'Back'} color={'gd-grey'}/>
                    </div>
                </div>
            </div>
        }
        if (this.state.stage === 3) {
            let validForm = !this.state.appliances.find((a) => {
                return !a.typeId || !a.number || a.number === 0;
            });
            leftPanelContent = <div>
                <div className={'panel-header'}>
                    <p>2. Appliances</p>
                    <p className={'sub-heading'}>{businessName.mainText + (businessName.secondaryText.length ? ' - ' : '') + businessName.secondaryText}</p>

                </div>
                <div className={'panel-content'}>
                    <div className={'appliances-wrapper'}>
                        <Appliances validForm={validForm} addAnother={this.addAnotherAppliance}
                                    removeOne={this.removeAppliance}
                                    applianceTypeData={this.applianceTypeData} appliances={this.state.appliances}
                                    onUpdate={this.updateAppliances}/>
                    </div>

                    <div className={'row'} style={{flexDirection: 'row-reverse', justifyContent: 'space-between'}}>
                        <Button additionalclasses={'sm'} progressRes='true' onClick={this.calculateSavings}
                                label={'Calculate Savings'} disabled={!validForm}/>
                        <Button onClick={() => {
                            this.setState({stage: 2});
                        }} outline label={'Back'} color={'gd-grey'}/>
                    </div>
                </div>
            </div>
        }
        if (this.state.stage === 4) {
            leftPanelContent = <div>
                <div className={'panel-header'}>
                    <p>Results</p>
                    <p className={'sub-heading'}>{businessName.mainText + (businessName.secondaryText.length ? ' - ' : '') + businessName.secondaryText}</p>
                </div>
                <div className={'panel-content'}>
                    <div className={'results'}>
                        <p className={'desc-txt'}>You could be saving</p>
                        <p className={'stat'} style={{
                            margin: '30px 0 30px',
                            fontSize: '60px',
                        }}>£{getFormat('comma')(this.state.processedSavingsData.pounds)}*</p>
                        <p className={'desc-txt'} style={{marginBottom: '50px'}}>per year</p>
                        <SavingBreakdown hidePrice
                                         includePercentage={this.state.processedSavingsData.totalPercentageSaved}
                                         processedSavingsData={this.state.processedSavingsData}/>

                    </div>

                    <div className={'row'} style={{flexDirection: 'row-reverse', justifyContent: 'space-between'}}>
                        <Button onClick={() => {
                            window.location.reload();
                        }} outline label={'Start Again'} color={'gd-grey'}/>
                        {/*<Button onClick={() => {*/}
                        {/*    this.setState({stage: 3});*/}
                        {/*}} label={'Back'} color={'gd-grey'}/>*/}
                    </div>
                </div>
            </div>
            rightPanelContent = <div>
                <div className={'panel-header'}>
                    <p>Breakdown</p>
                </div>
                <div className={'panel-content'} style={{padding: '20px 30px'}}>
                    {this.state.appliances.sort((aa, bb) => bb.totalKwhSaved - aa.totalKwhSaved).map(ao => {
                        return <div className={'breakdown'}>
                            <p className={'name'}>{this.applianceTypeData.find((a) => a.typeId === ao.typeId).name} (x{ao.number})</p>
                            <SavingBreakdown includePercentage totalKwh={this.state.processedSavingsData.totalKwhSaved}
                                             processedSavingsData={ao}/>
                        </div>
                    })}
                    <p style={{fontSize: '14px', color: 'grey'}}>*Money savings are calculated based on the average price of
                        electricity in the UK for {price_date} (£{price_per_kwh} / kWh)</p>
                </div>
            </div>
        }
        return (
            <div className={'savings-calculator'}>
                <div className={'left-side'} style={{
                    'display': 'flex',
                    'flexDirection': 'column',
                    'flex': 50,
                    'justifyContent': 'center',
                    'alignItems': 'end',
                    padding: '0 15px 0 5px'
                }}>
                    <div className={'panel-card left'}>
                        {leftPanelContent}
                    </div>
                </div>
                <div className={'right-side'} style={{
                    'display': 'flex',
                    'flexDirection': 'column',
                    'overflow': 'hidden',
                    'flex': 50,
                    justifyContent: 'center',
                    alignItems: 'start',
                    padding: '0 5px 0 15px'
                }}>
                    <div className={'panel-card right'}>
                        {rightPanelContent}
                    </div>

                </div>

            </div>
        )
    }
}

export default SavingsCalculator;
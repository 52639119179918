import React from "react";
import GridDuck from "gridduck";
import {Button, Icon, List, StandardListCell, Toast} from "gd-react";
import NoItems from "../components/noItems";
import TariffModal from "./TariffModal";
import history from "../meta/history";
// import '../styles/_theme.scss'
import AddGroupModal from "../modals/AddGroup/AddGroupModal";
import {generateTariffTypeCell, generateUtilityTypeCell} from "../services/TariffTypesService";

class TariffListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.getList = this.getList.bind(this);
        this.openTariffModal = this.openTariffModal.bind(this);
        this.currencies = [
            {
                id: 0,
                title: '$',
                value: 'USD',
                lowestDenom: 'cents'
            },
            {
                id: 1,
                title: '€',
                value: 'EUR',
                lowestDenom: 'cents'
            },
            {
                id: 2,
                title: '£',
                value: 'GBP',
                lowestDenom: 'pence'
            },
            {
                id: 3,
                title: '₦ (NGN)',
                value: 'NGN',
                lowestDenom: 'kobo'
            }
        ]
    }

    openTariffModal(tariff) {
        history.push('/settings/organisation/tariffs?id=' + tariff.id);
        this.setState({showTariffModal: tariff})
    }

    componentDidMount() {
        let self = this;
        GridDuck.getAccount({id: GridDuck.userId})
            .then(function (user) {

                self.setState({orgPerm: user.orgPermission});
                let urlParams = new URLSearchParams(window.location.search);
                let tariffId = urlParams.get('id');
                if (tariffId) {
                    GridDuck.getTariff({id: tariffId}).then((tariff) => {
                        self.setState({showTariffModal: tariff})
                    })
                }
            });
    }

    getList(retrievalParams) {
        return GridDuck.getTariffs(retrievalParams);
    }

    render() {
        let self = this;
        return (
            <div className={"page white"}>
                {this.state.orgPerm ? <List
                    noitemelem={<NoItems text={'You haven\'t created any tariffs yet'} createtext={'Create Tariff'}
                                         modal={TariffModal}/>}
                    searchable
                    columns={[
                        {component: StandardListCell, title: 'Name', field: 'name'},
                        {
                            component: StandardListCell,
                            title: 'Tariff Type',
                            field: 'utilityType',
                            custom: generateTariffTypeCell,
                            maxWidth: '240px'
                        },
                        {
                            component: StandardListCell,
                            title: 'Utility Type',
                            field: 'utilityType',
                            custom: generateUtilityTypeCell
                        },
                        {
                            component: StandardListCell, title: 'Rates', field: 'rates.length',
                            custom: (object) => {
                                let _rates = object.rates;
                                //FIXME Why is this happening (Really not the fix we want)
                                if (object.rates && typeof object.rates === 'string') {
                                    _rates = JSON.parse(object.rates);
                                }
                                return _rates ? _rates.length : '?'
                            }
                        }
                    ]}
                    getlist={this.getList}
                    rowOnClick={this.openTariffModal}/> : null}
                {(this.state.showTariffModal || this.state.openTariff) ?
                    <TariffModal orgPerm={this.state.orgPerm} onSave={(message) => this.setState({showToast: message})}
                                 onClose={() => {
                                     this.setState({showTariffModal: null, openTariff: false});
                                     history.push('/settings/organisation/tariffs');
                                 }}
                                 open={!!(this.state.showTariffModal || this.state.openTariff)}
                                 item={this.state.showTariffModal}/> : null}

                <Toast onClose={() => {
                    this.setState({showToast: false});
                }} message={this.state.showToast}
                       open={!!(this.state.showToast)}
                       severity="success"
                       anchorOrigin={{
                           vertical: 'bottom',
                           horizontal: 'left',
                       }}/>

            </div>
        )
    }
}

export default TariffListPage;

import React from "react";
import "./AnomalyDetection.scss";
import {Grid} from "@material-ui/core";
import GDGraphs from "gd-graphs";
import GridDuck from "gridduck";
import GenericLoader from "../../components/GenericLoader";
import getFormat from "../../services/formatter";

class Anomaly extends React.Component {
    constructor(props) {
        super(props);
        GDGraphs.config({
            gridduckJS: GridDuck
        });
        this.state = {
            graphLoaded: false
        };
    }

    componentDidMount() {
        let self = this;
        let graphParams = {
            element: '#anomaly-graph-' + this.props.anomaly.id,
            anomaly: this.props.anomaly,
            widget: true,
            onReady: function () {
                self.setState({graphLoaded: true});
                
            }
        };

        this.graph = GDGraphs.anomaly(graphParams);
    }

    render() {
        let anomaly = this.props.anomaly;
        let showingAnomaly = this.props.showingAnomaly;
        let id = 'anomaly-' + this.props.anomaly.id;

        return (
            <Grid id={id} item xs={showingAnomaly ? 12 : 12} sm={showingAnomaly ? 12 : 12} md={showingAnomaly ? 12 : 6}
                  lg={showingAnomaly ? 12 : 4} xl={showingAnomaly ? 4 : 3}>
                <div className={'anomaly ' + (this.props.showingAnomaly && this.props.anomaly.id === this.props.showingAnomaly.id ? 'selected' : '')}
                     onClick={(e) => this.props.onClick(e, id)}>
                    <div className={'anomaly-inner'}>
                        {this.props.anomaly.endTs ?
                            <div className={'anomaly-status ' + (anomaly.userStatus)}>{anomaly.userStatus}</div> :
                            <div className={'anomaly-status ongoing'}>ongoing</div>}
                        <div className={'graph-wrapper'}>
                            <div id={'anomaly-graph-' + anomaly.id}
                                 className={'anomaly-graph ' + (this.state.graphLoaded ? ' loaded' : '')}
                                 style={{"height": "100px"}}>
                            </div>
                            {!this.state.graphLoaded ? <div className={'anomaly-loader'}><GenericLoader/></div> : null}
                        </div>
                        <p className={'anomaly-title'}>{anomaly.deviceName + ' (' + anomaly.siteName + ')'}</p>
                        <p className={'anomaly-sub-title'}>{getFormat('dateUtc')(anomaly.startTs)} {anomaly.endTs !== undefined ? (' - ' + getFormat('dateUtc')(anomaly.endTs)) : ''}</p>
                    </div>
                </div>
            </Grid>
        )
    }

}

export default Anomaly;
import React from "react";
import {GdCheckbox, Icon, Input, List, StandardListCell} from "gd-react";
import './Notifications.scss'
import NoItems from "../noItems";
import GridDuck from "gridduck";
import ListPopover from "../ListPopover/ListPopover";
import getFormat from "../../services/formatter";
import history from "../../meta/history";
import generateNotificationMetaData from "../../services/generateNotificationMetaData";
import NotificationsFilter from "./NotificationsFilter";


class NotificationsPopover extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            showingNotifications: ['info', 'warning', 'error']
        }
        this.notificationListRef = React.createRef();
        this.getList = this.getList.bind(this)
        this.handleClose = this.handleClose.bind(this);
        // this.markNotificationsSeen = this.markNotificationsSeen.bind(this);
        this.navigateToClickedItem = this.navigateToClickedItem.bind(this);
    }

    async getList(params) {
        // params.filters = [
        //     {field: 'new', value: true},
        //     {field: 'category', value: this.state.showingNotifications}
        // ];
        return await GridDuck.getNotifications(params);
        // return {list: generateNotificationMetaData(response.list), total: response.total};
    }

    navigateToClickedItem(item) {
        let _item = generateNotificationMetaData(item);
        if (_item.url) {
            history.push(_item.url);
        }
    }

    handleClose() {
        if (this.props.onClose) this.props.onClose();
    }

    render() {
        return (
            <ListPopover
                id="notifications-popover"
                anchorEl={this.props.anchorEl}
                onClose={this.handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right"
                }}>
                <div>
                    <div className={'row notifications-title space-between'}>
                        <div>New Notifications</div>
                        <span/>
                        <NotificationsFilter label={'Notifications'} potentialOptions={['info', 'warning', 'error']} onUpdate={(val) => {
                            this.setState({showingNotifications: val}, () => {
                                if (this.notificationListRef && this.notificationListRef.current) this.notificationListRef.current.getItems(null, true, null, true)
                            });
                        }} notificationTypes={this.state.showingNotifications}/>
                        {/*<a className={'clickable'} onClick={this.markNotificationsSeen}*/}
                        {/*   style={{paddingRight: '20px', fontSize: '12px'}}>Mark all as seen</a>*/}
                    </div>
                    <div className={'row list-container list-container-popover'}>
                        <List removeHeaders
                              lazyload
                              itemsPerPage={10}
                              rowHeight={'35px'}
                              ref={this.notificationListRef}
                              noitemelem={<div className={'no-notifications'}>
                                  <Icon icon={'AiOutlineFileDone'} size={40} color={'gd-brand'}/>
                                  <div style={{marginTop: '10px'}}>You're all caught up</div>
                              </div>}
                              columns={[
                                  {
                                      component: StandardListCell,
                                      maxWidth: '20px',
                                      custom: function (item) {
                                          let _item = generateNotificationMetaData(item);
                                          return <div><Icon size={'14'} icon={_item.icon?.name || 'FaQuestion'}
                                                            color={_item?.icon?.color || _item.color}/>
                                          </div>
                                      }
                                  },
                                  {
                                      component: StandardListCell,
                                      maxWidth: 'none',
                                      custom: function (item) {
                                          let _item = generateNotificationMetaData(item);
                                          return <div className={'column'}>
                                              <div className={'row'}>
                                                  <p className={'notifications-name'}>{_item.name}
                                                      {item.subtitle ?
                                                          <span
                                                              className={'notifications-subtitle'}>{' • ' + _item.subtitle}</span> : null}
                                                  </p>
                                              </div>
                                              <div className={'notifications-description'}>{_item.date}</div>
                                          </div>
                                      }
                                  },
                                  {
                                      component: StandardListCell,
                                      maxWidth: '30px',
                                      custom: function (item) {
                                          let _item = generateNotificationMetaData(item);
                                          if (_item.url) {
                                              return <div><Icon size={'14'} icon={'FaArrowRight'}
                                                                color={'grey'}/>
                                              </div>
                                          } else return ''
                                      }
                                  },
                              ]}
                              filters={[
                                  {field: 'new', value: true},
                                  {field: 'category', value: this.state.showingNotifications}
                              ]}
                              getlist={this.getList}
                              noClickCheck={(item) => {
                                  let _item = generateNotificationMetaData(item);
                                  return _item.noClick;
                              }}
                              rowOnClick={this.navigateToClickedItem}/>
                    </div>
                    <div
                        className={'footer row space-between'} style={{alignItems: 'center'}}>
                        {/*checked={this.state.acceptTerms} name={'alertEmail'}*/}
                        {/*onChange={this.onCheckboxChange}*/}
                        {this.props.account ?
                            <GdCheckbox checked={this.props.account?.muteNotificationSound} onChange={(e) => {
                                this.props.account.set({muteNotificationSound: e.target.checked});
                            }} label={'Mute alert sound'}/> : null}
                        <div style={{display: 'flex'}}>
                        <span className={'history-button'} onClick={() => {
                            this.handleClose()
                            this.props.openNotificationsDrawer()
                        }}>Notification History </span>
                            <div className={'icon'}>
                                <Icon size={'12'} icon={'FaChevronRight'} color={'#49b9c4'}/>
                            </div>
                        </div>
                    </div>
                </div>
            </ListPopover>
        )
    }
}

export default NotificationsPopover

import React from "react";
import {StatusListCell, LastActiveCell, List, StandardListCell} from "gd-react";
import GridDuck from 'gridduck';
import history from "../../meta/history";
import AddHubModal from "../../modals/AddHub/AddHubModal";
import NoItems from "../../components/noItems";
import SiteSettingsGateways from "./SiteSettingsGateways";


class MobileSiteSettingsGateways extends SiteSettingsGateways {
	render() {
		let permissions;
		if (this.props.item) {
			let filters;
			if (this.props.filterType === 'site_group_id' && this.props.filterId && this.props.filterId.indexOf('all') === -1) {
				filters = [
					{
						field: 'siteGroupId',
						value: this.props.filterId
					}
				]
			}
			if (this.props.filterType === 'org_partner_id' && this.props.filterId) {
				filters = [
					{
						field: 'orgPartnerId',
						value: this.props.filterId
					}
				]
			}
			

			if (this.props.filterType === 'site_id' && this.props.filterId && window.location.pathname.indexOf('in-site') !== -1) {
				filters = [
					{
						field: 'siteId',
						value: this.props.filterId
					}
				]
			}
			permissions = (
				<List
					noitemelem={<NoItems text={'"' + this.props.item.name + '" has no Hubs'} createtext={'Add Hub'} modal={AddHubModal}/>}
					columns={[
						{
							component: StandardListCell,
							bold: true,
							title: 'Name',
							field: 'name',
							sortable: true
						},
						{
							component: StatusListCell,
							title: 'Status',
							field: 'disconnected',
						}
					]}
					getlist={this.getList}
					filters={filters}
				/>
			)
		}
		return (
			<div className='page'>
				{permissions}
			</div>
		);
	}
}

export default MobileSiteSettingsGateways;

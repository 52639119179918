import React from "react";
import {
    Dropdown,
    Button,
    Card,
    CardBody,
    CardHeader,
    Input,
    List,
    StandardListCell,
    StateListCell,
    DemandListCell
} from "gd-react";
import moment from "moment-timezone";
import Modal from "react-modal";
import GridDuck from "gridduck";

class AssetSettingsEventLog extends React.Component {
    constructor(props) {
        super(props);
        let self = this;
        if (this.props.item) {
            this.state = {
                filters: [
                    {
                        "field": "assetId",
                        "value": this.props.item.id
                    },
                    {
                        "field": "start",
                        "value": 0
                    },
                    {
                        "field": "end",
                        "value": new Date().getTime() / 1000
                    }
                ]
            };
        } else {
            this.state = {};
        }
        this.getList = this.getList.bind(this);
    }

    getList(retrievalParams) {

        return GridDuck.getAssetEvents(retrievalParams);
    }


    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.item.id !== prevProps.item.id) {
            let self = this;
            this.setState({
                filters: [
                    {
                        "field": "assetId",
                        "value": this.props.item.id
                    },
                    {
                        "field": "start",
                        "value": 0 //Math.round(new Date().getTime() / 1000) - 60 * 60 * 24
                    },
                    {
                        "field": "end",
                        "value": Math.round(new Date().getTime() / 1000)
                    }
                ]
            });
        }
    }


    render() {
        let list;
        if (this.state && this.state.filters && this.props.loaded) {
            list = (
                <List
                    nohover
                    columns={[
                        {component: StandardListCell, sortable: false, title: 'Time', field: 'time', maxWidth: '10', custom: function (value) {return moment.unix(value.time).format('DD/MM/YYYY, HH:mm:ss')}},
                        {component: StandardListCell, sortable: false, title: 'Event', field: 'type', maxWidth: 'none'}
                    ]}
                    getlist={this.getList}
					itemsPerPage={20}
					filters={this.state.filters}/>
            )
        }

        return (
			<div className='page'>
                {list}
            </div>
        )
    }
}

export default AssetSettingsEventLog;

import React, {Component} from "react";

import "./SaveBar.scss";
import {Button} from "gd-react";
import {isMobile} from 'react-device-detect'
import {Prompt} from "react-router-dom";

class SaveBar extends Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {


        return (
            <div className={'header-bar' + (isMobile ? ' mobile': '')}
            >
                <div className={'header-bar-inner'}>
                    <Button onClick={this.props.onSaveClick} additionalclasses={'sm'} color={'gd-green'}
                            label={'Save'} progressRes/>
                    <span style={{display: 'flex', flex: '1 1'}}/>
                    <Button outline additionalclasses={'sm'} color={'gd-grey'} onClick={this.props.onCancelClick}
                            label={'Cancel'}/>
                </div>
                <Prompt
                    when={true}
                    message="You have unsaved changes. Do you definitely want to leave?"
                />
            </div>
        )
    }
}


export default SaveBar;

import React from "react";
import GridDuck from "gridduck";
import history from "./../../meta/history";
import {StateOptions} from "gd-react";
import DesktopDataPage from "../data/DesktopDataPage";

class MainSiteGroupPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {updateRef: 0, loaded: false};
        let self = this;
        this.updateFilter = this.updateFilter.bind(this);
        this.getSiteGroupData = this.getSiteGroupData.bind(this);
        this.accountUpdated = this.accountUpdated.bind(this);
        this.redirect = this.redirect.bind(this);
        GridDuck.getAccount({id: GridDuck.userId})
            .then(function (account) {
                self.account = account;
                self.setState({
                    account: account,
                    orgPermission: account.orgPermission
                });
                self.account.on('updated', self.accountUpdated);
            })
    }

    accountUpdated() {
        this.setState({account: this.account});
    }

    redirect(account) {
        // 
        // //todo if url = sitegroup/all
        // let url = history.location.pathname;
        // 
        // let singleSite = account.sitesCount === 1;
        // 
        // if (singleSite && url === '/siteGroup/all') {
        //     
        //     GridDuck.getSites({getAll: true})
        //         .then(function (sitesRes) {
        //             let siteId = sitesRes.list[0].id;
        //             
        //             return history.push(`/site/${siteId}/summary`)
        //         })
        // } else {
        //     this.setState({loaded: true})
        // }
    }

    getSiteGroupData() {
        let self = this;
        let {siteGroupId} = this.props.match.params;

        // self.setState({
        //     siteGroup: null,
        //     assets: null,
        //     loaded: false
        // })
        GridDuck.getAccount({id: GridDuck.userId}).then((acc) => {
            if (siteGroupId === 'all') {
                history.push('/siteGroup/all_' + acc.id + '_' + acc.organisationId);
                return;
            }

            GridDuck.getSiteGroup({
                id: siteGroupId
            }).then(function (siteGroup) {

                

                let allDataTypes = [];
                let availableDataTypes = [];
                StateOptions.forEach(function (sO) {
                    sO.dataTypes.forEach(function (sDT) {
                        if (!allDataTypes.find(dT => dT.type === sDT.type)) {
                            allDataTypes.push(sDT);
                        }
                    });
                })
                siteGroup.dataTypes.forEach(function (dataTypeName) {
                    let dataType = allDataTypes.find(dT => dT.type === dataTypeName)
                    if (!availableDataTypes.find(dT => dT.type === dataType.type)) {
                        if (dataType.category !== 'Signal') {
                            availableDataTypes.push(dataType);
                        }
                    }
                })

                

                let state = {
                    siteGroup: siteGroup,
                    availableDataTypes: availableDataTypes,
                    loaded: true
                };
                if (siteGroupId !== 'all_' + acc.id + '_' + acc.organisationId) {
                    state.listFilters = [{
                        field: 'id',
                        value: siteGroup.siteIds
                    }]
                } else {
                    state.listFilters = [];
                }
                self.setState(state);
                siteGroup.on('updated', self.updateFilter);
            }).catch(function (err) {
                
                history.push('/404');
            });
        })
    }

    componentDidMount() {
        this.getSiteGroupData();
    }

    updateFilter(field, value) {
        if (field === 'siteIds') {
            this.setState({
                listFilters: [{
                    field: 'id',
                    value: value
                }]
            });
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let newSiteGroupId = this.props.match.params.siteGroupId;
        let oldSiteGroupId = prevProps.match.params.siteGroupId;
        if (newSiteGroupId && oldSiteGroupId && newSiteGroupId !== oldSiteGroupId) {
            this.getSiteGroupData();
        }
    }

    componentWillUnmount() {
        if (this.state.siteGroup) {
            this.state.siteGroup.off('updated', this.updateFilter);
        }
        if (this.account && this.account.off) {
            this.account.off('updated', this.accountUpdated);
        }
    }
}

export default MainSiteGroupPage;

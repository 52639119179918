import React from 'react';
import GridDuck from 'gridduck'
import './MainProductListPage.scss';
import './OrderCheckoutPage.scss';
import {Button, Card, CardBody, CardHeader, GdAutocomplete, Icon, Input, Toast, Tooltip} from "gd-react";
import * as _ from 'underscore'
import GenericLoader from "../../components/GenericLoader";
import ProductList from "./ProductList";
import history from "../../meta/history";
import getFormat from "../../services/formatter";
import EditAddressModal from "../../modals/EditAddress/EditAddressModal";
import AddPaymentCardModal from "../../modals/AddPaymentCard/AddPaymentCardModal";
import PaymentMethods from "../../components/PaymentMethods";

class OrderCheckoutPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedOrg: this.props.selectedOrganisation || {}
        };
        this.checkout = this.checkout.bind(this);
        this.updateAddress = this.updateAddress.bind(this);
        this.toTitleCase = this.toTitleCase.bind(this);
        // this.loadCards = this.loadCards.bind(this);

        this.loadAddress = this.loadAddress.bind(this);
        // this.selectOrg = this.selectOrg.bind(this);
        // this.searchOrg = this.searchOrg.bind(this);
    }

    // searchOrg(string) {
    //     let self = this;
    //     return GridDuck.getOrganisationManagements({
    //         filters: [{
    //             field: 'query',
    //             value: string
    //         }]
    //     }).then(function (results) {
    //         if (!self.state.orgs) self.setState({orgs: results.list});
    //         let fixedResults = results.list.map(function (r) {
    //             return {
    //                 id: r.id,
    //                 title: r.name,
    //                 subTitle: r.free,
    //                 icon: 'GoPerson'
    //             }
    //         });
    //         
    //         return Promise.resolve(fixedResults)
    //     });
    // }

    checkout() {
        let self = this;
        self.setState({creatingOrder: true})
        return GridDuck.createOrder({
            physicalItems: this.state.session.physicalItems,
            subscriptionItems: this.state.session.subscriptionItems,
            sessionId: this.state.session.id,
            orgId: this.state.selectedOrg.id,
            vat: this.props.vat === 'yes',
            discount: this.props.discountId
        }).then(function () {
            self.setState({completedOrder: true, creatingOrder: false})
        })
    }

    deleteCard(card) {
        let self = this;
        self.setState({loadedCards: false});
        card.delete().then(function () {
            self.loadCards();
        });
    }

    // loadCards() {
    //     let self = this;
    //     self.setState({loadedCards: false})
    //     GridDuck.getPaymentCards({
    //         getAll: true,
    //         filters: []
    //     }).then(function (res) {
    //         self.setState({cards: res.list, loadedCards: true});
    //     })
    // }
    //
    // async selectOrg(item, other) {
    //     
    //     if (other && other.id) {
    //         this.setState({loadingOrg: true, selectedOrg: {}});
    //         let allOrgData = await GridDuck.getOrganisationManagement({id: other.id})
    //         this.setState({loadingOrg: false});
    //         
    //         this.setState({
    //             selectedOrg: allOrgData
    //         });
    //     } else {
    //         this.setState({
    //             selectedOrg: {}
    //         });
    //     }
    // }

    loadAddress() {
        let self = this;
        self.setState({loadedAddress: false})
        GridDuck.getAccount({id: GridDuck.userId}).then(function (res) {
            self.setState({account: res, loadedAddress: true});
        })
    }

    toTitleCase(str) {
        return str.replace(
            /\w\S*/g,
            function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            }
        );
    }

    updateAddress() {
        this.setState({openAddressModal: true});
    }

    componentDidMount() {
        let self = this;
        let sessionId = this.props.sessionId;
        Promise.all([GridDuck.getSession({id: sessionId}), GridDuck.getProducts({getAll: true}), GridDuck.getAccount({id: GridDuck.userId}), GridDuck.getPaymentCards({
            getAll: true,
            filters: []
        })]).then((sessions) => {
            let this_session = sessions[0];
            let products = sessions[1].list;
            let account = sessions[2];
            let paymentCards = sessions[3].list;
            if (this_session) {
                let totalPhysicalCost = 0, totalSubscriptionCost = 0;
                _.forEach(this_session.physicalItems, (pi) => {
                    let product = products.find((p) => p.id === pi.product);
                    totalPhysicalCost += product.prices.find((price) => (price.id === pi.price) && price.type === 'one_time').unitAmount * pi.quantity;
                })
                _.forEach(this_session.subscriptionItems, (pi) => {
                    let product = products.find((p) => p.id === pi.product);
                    totalSubscriptionCost += product.prices.find((price) => (price.id === pi.price) && price.type === 'recurring' && !price.metadata.support).unitAmount * pi.quantity;
                })
                self.setState({
                    session: this_session,
                    products: sessions[1].list,
                    loaded: true,
                    loadedCards: true,
                    loadedAddress: true,
                    totalPhysicalCost: totalPhysicalCost,
                    totalSubscriptionCost: totalSubscriptionCost,
                    account: account,
                    cards: paymentCards
                });
            }
        });
    }

    render() {
        let self = this;
        let orgAddress = this.state.account ? this.state.account.orgAddress : null;

        return <div className={'mega-prod-wrapper ' + (this.state.creatingOrder ? ' disabled' : '')} style={{overflow: 'hidden'}}>

            {this.state.loaded && !this.state.completedOrder ? <div className={'row'} style={{overflow: 'hidden'}}>
                    <div className={'page narrow prdks checkout'}>
                        {/*<div style={{*/}
                        {/*    width: '100%',*/}
                        {/*    marginBottom: '30px'*/}
                        {/*}}>*/}
                        {/*    <GdAutocomplete*/}
                        {/*        autoFocus*/}
                        {/*        async*/}
                        {/*        label={'Organisation'}*/}
                        {/*        onChange={this.selectOrg}*/}
                        {/*        getList={this.searchOrg}*/}
                        {/*        placeholder={'GridDuck'}/>*/}
                        {/*</div>*/}

                        {this.state.loadingOrg ?
                            <GenericLoader text={'Fetching...'} textLineTwo={'Organisation data'}/> : null}

                        {!this.state.selectedOrg.acceptedTerms && this.state.selectedOrg.id ? <div>
                            <p>This Account hasn't completed the signup process yet.</p>
                            {!this.state.selectedOrg.free ? <p>Payment
                                Methods: {this.state.selectedOrg.paymentMethods && this.state.selectedOrg.paymentMethods.length ? 'Yes' : 'No'}</p> : null}
                            <p>Address: {this.state.selectedOrg.address ? 'Yes' : 'No'}</p>
                            <p style={{marginBottom: '30px'}}>Accepted
                                Terms: {this.state.selectedOrg.acceptedTerms ? 'Yes' : 'No'}</p>
                        </div> : null}

                        {this.state.selectedOrg.id ? <div className={'products-inner-page checkouts'} style={{
                                width: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}>
                                {this.state.selectedOrg.address ?
                                    <div className={'detail-content'}>
                                        <Card>
                                            <div className={'CardHeader'} style={{
                                                paddingRight: '10px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}>Address
                                            </div>
                                            {this.state.loadedAddress ? <div>
                                                {this.state.selectedOrg.address && <CardBody>
                                                    <p>{this.state.selectedOrg.address.line1}</p>
                                                    <p>{this.state.selectedOrg.address.line2}</p>
                                                    <p>{this.state.selectedOrg.address.city}</p>
                                                    <p>{this.state.selectedOrg.address.postal_code}</p>
                                                    <p>{this.state.selectedOrg.address.country}</p>
                                                </CardBody>}
                                                {/*{!this.state.account.orgAddress ? <div style={{*/}
                                                {/*    'display': 'flex',*/}
                                                {/*    'flexDirection': 'row-reverse',*/}
                                                {/*    padding: '20px 10px'*/}
                                                {/*}}>*/}
                                                {/*    <EditAddressModal inPage item={this.state.account}*/}
                                                {/*                      onClose={(reload) => {*/}
                                                {/*                          this.setState({openAddressModal: false})*/}
                                                {/*                          this.loadAddress();*/}
                                                {/*                      }}/>*/}
                                                {/*</div> : null}*/}

                                                {/*{this.state.account.orgAddress ? <div style={{*/}
                                                {/*    'display': 'flex',*/}
                                                {/*    'flexDirection': 'row-reverse',*/}
                                                {/*    padding: '20px 10px'*/}
                                                {/*}}>*/}
                                                {/*    <Button color={'gd-grey'} onClick={this.updateAddress}*/}
                                                {/*            label={'Edit'}/>*/}
                                                {/*</div> : null}*/}
                                            </div> : <GenericLoader text={'Fetching'} textLineTwo={'Address...'}/>}
                                        </Card>
                                    </div> : null}
                                {this.state.selectedOrg.paymentMethods && this.state.selectedOrg.paymentMethods.length ?
                                    <div className={'detail-content'}>
                                        <Card>
                                            <div className={'CardHeader'} style={{
                                                paddingRight: '10px',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'space-between'
                                            }}>Payment Methods
                                            </div>
                                            <PaymentMethods methods={this.state.selectedOrg.paymentMethods} loadMethods={() => {
                                            }}/>
                                        </Card>
                                    </div>
                                    : null}
                            </div>
                            : null}

                    </div>


                    <div style={{
                        backgroundColor: 'white',
                        maxWidth: '400px',
                        overflow: 'hidden',
                        display: 'flex',
                        flex: '1 1',
                        flexDirection: 'column',
                        borderLeft: '1px solid #ececec'
                    }}>
                        <div className={'checkout-bar'} style={{borderTop: '0', borderBottom: '1px solid #ececec'}}>
                            <Button onClick={this.props.editItems} color={'gd-grey'}
                                    label={'Edit Items'}/>
                        </div>
                        <ProductList products={this.state.products} session={this.state.session}/>
                        <div className={'checkout-bar totals'}>
                            <p>Total<br/>{this.props.discount ? '- Discount' : ''}<br/>{this.props.vat === 'yes' ? '+ VAT' : ''}
                            </p>
                            <p>{this.props.currencySymbol}{getFormat('comma', true)((((this.state.totalPhysicalCost - this.props.discount.hardware) * (this.props.vat === 'yes' ? 1.2 : 1)) / 100).toFixed(2))}<span><br/>one time</span>
                            </p>
                            <p>{this.props.currencySymbol}{getFormat('comma', true)((((this.state.totalSubscriptionCost - this.props.discount.subscription) * (this.props.vat === 'yes' ? 1.2 : 1)) / 100).toFixed(2))}<span><br/>per month</span>
                            </p>
                        </div>

                        <div className={'checkout-bar'}>
                            <Button
                                disabled={!this.state.selectedOrg.id || !this.state.selectedOrg.acceptedTerms}
                                onClick={this.checkout}
                                color={'gd-green'}
                                progressRes
                                label={'Submit Order'}/>
                        </div>

                    </div>
                </div> :
                null}

            {!this.state.loaded ?
                <div style={{display: 'flex', flex: '1 1', justifyContent: 'center', alignItems: 'center'}}>
                    <GenericLoader text={'Preparing'} textLineTwo={'Your Order...'}/></div> : null}

            {this.state.loaded && this.state.completedOrder ?
                <div style={{
                    'display': 'flex',
                    'flexDirection': 'column',
                    'flex': 50,
                    'justifyContent': 'center',
                    'alignItems': 'center'
                }}>
                    <p className={'subHeader'}>Order Sent!</p>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        flexDirection: 'row'
                    }}>
                        <Button label={"Create Another"}
                                onClick={() => window.location.reload()}/>
                    </div>
                    {/*<p className={'header'}>Check your email for an invite</p>*/}
                </div> : null}

            {this.state.openAddPaymentCardModal ?
                <AddPaymentCardModal item={this.state.account} open={this.state.openAddPaymentCardModal}
                                     onClose={(reload) => {
                                         this.setState({openAddPaymentCardModal: false})
                                         if (reload) this.loadCards();
                                     }}/> : ''}
            {this.state.openAddressModal ?
                <EditAddressModal item={this.state.account} open={this.state.openAddressModal}
                                  onClose={(reload) => {
                                      this.setState({openAddressModal: false})
                                      this.loadAddress();
                                  }}/> : ''}

        </div>
    }
}

export default OrderCheckoutPage;
import React, {Component} from "react";
import "./AddSiteModal.scss";
import '../../styles/_layout.scss';
import {Button, CardBody, Dropdown, GdAutocomplete, GdCheckbox, Input} from "gd-react";
import GridDuck from "gridduck";
import moment from "moment-timezone";
import history from "../../meta/history";
import GenericLoader from "../../components/GenericLoader";
import BreadcrumbService from "../../meta/breadcrumb-service";
import LocationForm from "../../components/LocationForm";
import GdModal from "../../components/GdModal/GdModal";

class AddSiteModal extends Component {

    constructor(props) {
        super(props);
        let self = this;
        this.closeModal = this.closeModal.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.onSiteGroupChange = this.onSiteGroupChange.bind(this);
        this.locationFormRef = React.createRef();
        this.errors = {};
        this.changed = false;

        this.state = {
            newSite: {
                name: ''
            },
            frequency: 'weekly',
            siteGroup: '',
            alsoCreateReport: true,
            loaded: true,
            locationId: '',
            newLocationName: '',
            newLocationMetaData: ''
        }
        // Promise.all([
        //     GridDuck.getSiteGroups({getAll: true}),
        // ]).then(function (results) {
        //     if (!self.modalClosed) self.setState({
        //         siteGroups: results[0].list.map(function (sg) {
        //             if (sg.id !== 'all') return {title: sg.name, value: sg.id, id: sg.id}
        //             else return {title: null, value: null, id: null}
        //         }),
        //         originalSGs: results[0].list,
        //         loaded: true
        //     })
        // });
        this.modalClosed = false;
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    onFormChange(val) {
        
        this.state.newSite[val.target.name] = val.target.value;
        this.setState({newSite: this.state.newSite});
    }

    onSiteGroupChange(val, newValue) {
        this.state.siteGroup = newValue;
        this.setState(this.state);
    }

    async saveChanges() {
        
        let self = this;
        this.setState({triedToSave: true})
        BreadcrumbService.siteGroup = null;
        let locationErrors = this.locationFormRef.current.checkErrors(true);
        if (Object.keys(this.errors).length > 0 || Object.keys(locationErrors).length > 0) {
            
            return Promise.reject();
        }

        let newSiteObj = this.state.newSite;
        let locationObj = await this.locationFormRef.current.createNewLocation();
        // todo put the locatioionid inside an object when creating a new location
        
        newSiteObj.locationId = locationObj.id;
        
        let newSite = await GridDuck.createSite(newSiteObj);

        if (self.state.alsoCreateReport) {
            let orgMembers = await GridDuck.getOrganisationMembers({getAll: true});
            let recipients = orgMembers.list.map((om) => om.username);
            await GridDuck.createReport({
                name: self.state.newSite.name,
                siteIds: [newSite.id],
                includeMegaPdf: true,
                perDevice: true,
                frequency: self.state.frequency,
                repeatOn: 1,
                recipients: recipients
            });
        }

        

        if (self.state.siteGroup) {

            let siteGroupToUpdate = await GridDuck.getSiteGroup({id: self.state.siteGroup.id});

            if (siteGroupToUpdate) {
                let site_ids = siteGroupToUpdate.siteIds || [];
                site_ids.push(newSite.id);
                
                await siteGroupToUpdate.set({siteIds: site_ids})
            }
        }
        history.push('/site/' + newSite.id + '/devices');
        self.closeModal();
        return Promise.resolve();
    }

    checkErrors() {
        let errors = {};
        if (!this.state.newSite.name.length) errors['name'] = true;
        this.errors = errors;
    }

    getSiteGroups(searchTerm) {
        return GridDuck.getSiteGroups({
            filters: [{
                field: 'search',
                value: searchTerm
            }]
        }).then(function (siteList) {
            return Promise.resolve(
                siteList.list
                    .filter(s => s._permission === 'admin')
                    .map(
                        s => ({
                            id: s.id,
                            title: s.name + (s.locationName ? (' (' + (s.locationName) + ')') : '')
                        })
                    )
            )
        })
    }

    render() {
        let footer;
        if (this.state.loaded) footer =
            <Button label={'Create site'} additionalclasses={'sm'} color={'gd-green'} onClick={this.saveChanges}
                    progressRes/>;

        this.checkErrors();
        return (
            <GdModal
                open={this.props.open}
                contentLabel={'Create Site'}
                title={'Create Site'}
                footer={footer}
                onClose={this.closeModal}>
                {this.state.loaded ? <CardBody>
                    <div style={{marginBottom: '15px'}}>
                        <Input required error={this.errors['name'] && this.state.triedToSave} top='true'
                               onChange={this.onFormChange} onKeyPress={(event) => {
                            if (event.key === 'Enter') this.saveChanges();
                        }}
                               name={'name'} value={this.state.newSite.name} label={'Name'}/>
                    </div>
                    <div style={{marginBottom: '15px'}}>
                        <GdAutocomplete
                            async
                            name={'siteGroup'}
                            getOptionSelected={function (option, value) {
                                if (option && value) return option.id === value.id;
                            }}
                            value={this.state.siteGroup}
                            placeholder={'Select a site group'}
                            getList={this.getSiteGroups}
                            onChange={this.onSiteGroupChange}
                            label="Site group"/>

                    </div>
                    <LocationForm ref={this.locationFormRef}
                                  onLocationIdChange={(val) => this.setState({locationId: val})}
                                  onNewLocationNameChange={(val) => this.setState({newLocationName: val})}
                                  onNewLocationMetadataChange={(val) => this.setState({newLocationMetaData: val})}/>
                    <div style={{marginTop: '15px'}}>
                        <GdCheckbox checked={this.state.alsoCreateReport} name={'receiveAuditEmails'}
                                    onChange={(val) => this.setState({alsoCreateReport: val.target.checked})}
                                    label={<div style={{display: 'flex', flex: '1 1'}}><p style={{
                                        whiteSpace: 'nowrap',
                                        lineHeight: '30px',
                                        margin: '5px 15px 5px 0'
                                    }}>Send me a </p>
                                        <Dropdown style={{minWidth: '100px'}} value={this.state.frequency}
                                                  nolabel='true' label={''} fixeditems={[{
                                            id: 1,
                                            title: 'weekly',
                                            value: 'weekly'
                                        }, {id: 2, title: 'monthly', value: 'monthly'}]}
                                                  onChange={(val) => this.setState({frequency: val.target.value})}/>
                                        <p style={{whiteSpace: 'nowrap', lineHeight: '30px', margin: '5px 15px'}}>energy
                                            report for this site</p></div>}/>
                    </div>
                </CardBody> : ''}
                {!this.state.loaded ? <CardBody>
                    <GenericLoader/>
                </CardBody> : ''}

            </GdModal>
        )
    }
}

export default AddSiteModal;

import {Dropdown, Icon, Input, Toast, DataTypes, DateTimeRangePicker, GdCheckbox} from "gd-react";
import React from "react";
import GenericLoader from "./GenericLoader";
import './TariffForm.scss'
import Rates from './Rates'
import BillingCyclePicker from "./BillingCyclePicker";

class TariffForm extends React.Component {
    constructor(props) {
        super(props);
        const tariffDataTypes = DataTypes.filter(dt => dt.tariffable);
        const utilityTypes = tariffDataTypes.map((tdt, i) => {
            const comparatorDataType = DataTypes.find(dt => tdt.tariffComparatorDataType === dt.type);
            let contextDataTypeLabel = comparatorDataType ? comparatorDataType.label : '';
            let contextDataTypeUnit = comparatorDataType ? comparatorDataType.unit === 'Wh' ? 'kWh' : comparatorDataType.unit : '';
            return {
                id: i,
                title: `${tdt.category} ${contextDataTypeLabel !== 'Usage' ? contextDataTypeLabel : ''} (${contextDataTypeUnit})`,
                type: tdt.tariffType,
                comparatorDataType: comparatorDataType,
                comparatorDataUnit: contextDataTypeUnit,
                value: tdt.type,
                unit: tdt.unit
            }
        });
        let tariffTypeDt = utilityTypes.find(ut => ut.value === this.props.formData?.utilityType);
        let tariffType = tariffTypeDt ? tariffTypeDt.type : 'spend';

        this.state = {
            utilityTypes: utilityTypes,
            currencies: [
                {
                    id: 0,
                    title: '$ (USD)',
                    value: 'USD',
                    lowestDenom: 'cents'
                },
                {
                    id: 1,
                    title: '€ (EUR)',
                    value: 'EUR',
                    lowestDenom: 'cents'
                },
                {
                    id: 2,
                    title: '£ (GBP)',
                    value: 'GBP',
                    lowestDenom: 'pence'
                },
                {
                    id: 3,
                    title: '₦ (NGN)',
                    value: 'NGN',
                    lowestDenom: 'kobo'
                }
            ],
            loaded: true,
            editing: '',
            errorMessages: [],
            tariffType: tariffType
        };
        this.handleTimeChange = this.handleTimeChange.bind(this);
        this.handleCostChange = this.handleCostChange.bind(this)
        this.isAllTheTime = this.isAllTheTime.bind(this)
        this.setAll = this.setAll.bind(this);
    }

    handleRateChange(direction) {
        let rates = this.props.formData.rates, self = this;

        if (direction === 'add') {
            //Look for gap in timeline
            let filledTimes = [], allTimes = [];
            for (let i = 1; i <= 24; i = i + 0.5) {
                let timeInside = rates.find((r) => {
                    let t = self.props.funcs.convertTimesToWholeNumbers(r);

                    return (t.start + 1 < i && t.end + 1 > i);
                });
                if (timeInside) filledTimes.push(i);
                allTimes.push(i);
            }




            let gapTimes = allTimes.filter(t => !filledTimes.includes(t)).reduce((arr, val, i, a) => {
                if (!i || val !== a[i - 1] + 0.5) arr.push([]);
                arr[arr.length - 1].push(val);
                return arr;
            }, []);



            let firstRate = rates[0];
            let lastRate = rates[rates.length - 1];
            if (lastRate.endHour === firstRate.startHour && lastRate.endMinute === firstRate.endMinute) {
                window.alert("No space in timeline to add rate");
                return;
            }
            rates.push({
                startHour: lastRate.endHour,
                startMinute: lastRate.endMinute,
                endHour: firstRate.startHour,
                endMinute: firstRate.startMinute,
                rate: 0,
                id: Math.random()
            })
        } else if (direction === 'minus') {
            rates.pop();
        }

        if (this.props.onRatesChange) this.props.onRatesChange(rates);

    }

    isAllTheTime(index, rate) {
        if (!index) index = 0;
        let r = this.props.formData.rates[index];
        if (rate) r = rate;
        return (r.startHour === r.endHour && r.startMinute === r.endMinute);
    }

    setAll(r, forceEndHour) {
        let rate = r ? r : this.props.formData.rates[0];


        rate.startMinute = 0
        rate.startHour = 0
        rate.endMinute = 0
        rate.endHour = (forceEndHour || forceEndHour === 0) ? forceEndHour : this.isAllTheTime() ? 1 : 0
        let rates = [rate]

        if (this.props.onRatesChange) this.props.onRatesChange(rates);
    }

    handleTimeChange(val, timeName, rate) {
        rate[timeName] = val



        if (this.isAllTheTime(null, rate)) {

            this.setAll(rate, 0);
        } else {
            if (this.props.onRatesChange) this.props.onRatesChange(this.props.formData.rates);
        }
    }

    handleCostChange(rate, newVal) {
        let rates = this.props.formData.rates;

        rate.rate = newVal;
        if (this.props.onRatesChange) this.props.onRatesChange(rates);
    }

    render() {

        let errorMessages = [];

        let allTheTime = this.isAllTheTime();

        if (this.props.errorMessages && this.props.errorMessages.length) {

            errorMessages = this.props.errorMessages.map((message, i) => {

                return <div key={i + 'm'} className={'row'} style={{marginTop: '20px'}}>
                <span className={'error-text'}>
                {message}
                    </span>
                </div>
            })
        }



        let contractRange = [new Date(this.props.formData.contractStart * 1000), new Date(this.props.formData.contractEnd * 1000)];

        return (
            this.state.loaded ? <div>
                    <div>
                        <div className={'top-container'}>
                            <div className={'row'} style={{alignItems: 'flex-end', marginBottom: '15px'}}>
                                <Input label={'Name'}
                                       disabled={this.props.disabled}
                                       error={this.props.errors['name'] && this.props.triedToSave}
                                       onChange={(e) => this.props.onNameChange(e.target.value)}
                                       value={this.props.formData.name}/>
                            </div>
                            <div className={'row'} style={{alignItems: 'flex-end'}}>
                                <div style={{width: '200px'}}>
                                    <Dropdown
                                        disabled={this.props.disabled}
                                        title={'Tariff Type'}
                                        value={this.state.tariffType}
                                        label={'Tariff Type'}
                                        fixeditems={[
                                            {id: 1, title: 'Spend', value: 'spend'},
                                            {id: 1, title: 'Carbon', value: 'carbon'}
                                        ]}
                                        onChange={(e) => {
                                            this.setState({tariffType: e.target.value}, () => {
                                                //  Change data type to be first in list filtered by new tariff type
                                                this.props.onUtilityTypeChange(this.state.utilityTypes.filter(ut => ut.type === this.state.tariffType)[0].value);
                                            });
                                        }}/>
                                </div>
                                <div style={{width: '200px', paddingLeft: '10px'}}>
                                    <Dropdown
                                        disabled={this.props.disabled}
                                        title={'Data Type'}
                                        value={this.props.formData.utilityType}
                                        label={'Data Type'}
                                        fixeditems={this.state.utilityTypes.filter(ut => ut.type === this.state.tariffType)}
                                        error={this.props.errors['utilityType'] && this.props.triedToSave}
                                        onChange={(e) => {
                                            this.props.onUtilityTypeChange(e.target.value);
                                        }}/>
                                </div>
                                {this.state.tariffType === 'spend' ?
                                    <div style={{maxWidth: '150px', paddingLeft: '10px'}}>
                                        <Dropdown
                                            title={'Currency'}
                                            disabled={this.props.disabled}
                                            value={this.props.formData.currency}
                                            label={'Currency'}
                                            fixeditems={this.state.currencies}
                                            error={this.props.errors['currency'] && this.props.triedToSave}
                                            onChange={(e) => {

                                                this.props.onCurrencyChange(e.target.value);
                                            }}/>
                                    </div> : null}
                            </div>
                            <div className={'row normal-checkbox'}>
                                <GdCheckbox checked={this.props.formData.applyContractRange} name={'applyContractRange'}
                                            onChange={(e) => {

                                                this.props.onApplyContractRangeChange(e.target.checked);
                                            }} label={'Apply Contract Start & End Dates'}/>
                            </div>
                            {this.props.formData.applyContractRange ? <div className={'row'}>
                                <div style={{width: '250px'}}>
                                    <DateTimeRangePicker cleanable={false} key={'selector'} placement={'auto'}
                                                         label={'Contract Range'}
                                                         onChange={(dates) => {

                                                             let startUnix = new Date(dates.start).getTime() / 1000;
                                                             let endUnix = new Date(dates.end).getTime() / 1000;
                                                             this.props.onContractRangeChange({
                                                                 start: startUnix,
                                                                 end: endUnix
                                                             });
                                                         }}
                                                         value={contractRange}/>
                                </div>
                            </div> : null}
                            <div className={'row normal-checkbox'}>
                                <GdCheckbox checked={this.props.formData.applyBillingCycle} name={'applyBillingCycle'}
                                            onChange={(e) => {

                                                this.props.onApplyBillingCycleChange(e.target.checked);
                                            }} label={'Apply Billing Cycle'}/>
                            </div>
                            {this.props.formData.applyBillingCycle ? <div className={'row'}>
                                <BillingCyclePicker
                                    billingCycleType={this.props.formData.billingCycleType}
                                    billingCycleStartDay={this.props.formData.billingCycleStartDay}
                                    onBillingCycleTypeChange={(val) => this.props.onBillingCycleTypeChange(val)}
                                    onBillingCycleStartDayChange={(val) => this.props.onBillingCycleStartDayChange(val)}
                                />
                            </div> : null}
                            <div className={'row'}>
                                <div>
                                    <div className={'row ' + (!this.props.disabled ? 'not-disabled' : null)}
                                         style={{alignItems: 'end'}}>

                                        <Input label={'Number of Rates'}
                                               type={'number'}
                                               disabled={true}
                                               inputProps={{step: 1, min: 1}}
                                               style={{maxWidth: '124px'}}
                                               onChange={(e) => this.handleRateChange(e)}
                                               value={this.props.formData.rates.length}/>


                                        {!this.props.disabled ? <div
                                            className={'plus-minus-button minus ' + (this.props.formData.rates.length === 1 ? 'disabled' : '')}
                                            onClick={() => this.handleRateChange('minus')}>
                                            <Icon size={'11'} icon={'FaMinus'}/>
                                        </div> : null}

                                        {!this.props.disabled ? <div className={'plus-minus-button'}
                                                                     onClick={() => this.handleRateChange('add')}>
                                            <Icon size={'11'} icon={'FaPlus'}/>
                                        </div> : null}

                                    </div>
                                </div>
                                {this.state.tariffType === 'spend' ?
                                    <div style={{maxWidth: '350px', paddingLeft: '10px'}}>
                                        <Input
                                            label={'Daily Standing Charge in ' + this.state.currencies.find(c => c.value === this.props.formData.currency).lowestDenom + ' (exc. VAT)'}
                                            type={'number'}
                                            disabled={this.props.disabled}
                                            onBlur={(e) => {
                                                let value = e.target.value;
                                                if (!value) value = 0;
                                                this.props.onStandingChargeChange(value);
                                            }}
                                            onChange={(e) => {
                                                let value = e.target.value;
                                                if (value < 0) value = 0;
                                                if (!value && value !== 0) value = ''
                                                this.props.onStandingChargeChange(value);
                                            }}
                                            value={this.props.formData.standingCharge}/>
                                    </div> : null}
                            </div>
                        </div>
                        {errorMessages}
                        <Rates
                            all={allTheTime}
                            disabled={this.props.disabled}
                            setAll={this.setAll}
                            errors={this.props.errors}
                            triedToSave={this.props.triedToSave}
                            currency={this.state.currencies.find(c => c.value === this.props.formData.currency)}
                            unit={this.state.utilityTypes.find(c => c.value === this.props.formData.utilityType)}
                            handleTimeChange={this.handleTimeChange}
                            handleCostChange={this.handleCostChange}
                            tariffType={this.state.tariffType}
                            rates={this.props.formData.rates}/>
                    </div>
                    <Toast onClose={() => this.setState({showToast: false})} message={this.state.showToast}
                           open={!!(this.state.showToast)}
                           severity="success"
                           anchorOrigin={{
                               vertical: 'bottom',
                               horizontal: 'left',
                           }}/>
                </div> :
                <div style={{maxHeight: '40px', marginTop: '-40px', marginBottom: '70px'}}><GenericLoader/></div>
        )
    }
}

export default TariffForm;

import React, {Component} from "react";
import '../../styles/_layout.scss';
import './SiteOccupancyScheduleModal.scss'
import {
    Button,
    CardBody,
    Dropdown,
    Input, StateOptions,
    Tabs,
    ScheduleSetter, ScheduleTimeline,
    EditableList, StandardListCell, Toast
} from "gd-react";
import GridDuck from "gridduck";
import Toggle from "../../components/Toggle/Toggle";
import GdModal from "../../components/GdModal/GdModal";
import {v4 as uuidv4} from "uuid";
import DeleteModal from "../Delete/DeleteModal";
import GenericLoader from "../../components/GenericLoader";
import * as _ from "lodash";

class SiteOccupancyScheduleModal extends Component {

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.checkErrors = this.checkErrors.bind(this);
        this.sitesChanged = this.sitesChanged.bind(this);
        this.saveSites = this.saveSites.bind(this);
        this.onTabClick = this.onTabClick.bind(this);
        this.moveToTab = this.moveToTab.bind(this);
        this.deleteSiteOccupancySchedule = this.deleteSiteOccupancySchedule.bind(this);
        this.getAvailableList = this.getAvailableList.bind(this);
        this.getActiveList = this.getActiveList.bind(this);

        this.errors = {};
        this.changed = false;
        const item = this.props.item;
        this.state = {
            wide: false,
            deleteConfirmation: '',
            update: 1,
            newSitesList: [],
            SiteOccupancySchedule: {
                name: item ? item.name : '',
                schedule: item ? item.schedule : [{
                    "behaviour": "ON",
                    "monday": false,
                    "tuesday": false,
                    "wednesday": false,
                    "thursday": false,
                    "friday": false,
                    "saturday": false,
                    "sunday": false,
                    "startHour": 0,
                    "startMinute": 0,
                    "endHour": 0,
                    "endMinute": 0
                }],
            },
            tabs: [[
                {
                    id: 1,
                    title: 'Details',
                    selected: (this.props.openOnTab === 1 || !this.props.openOnTab),
                    onTabClick: this.onTabClick
                },
                {
                    id: 2,
                    title: 'Sites',
                    selected: (this.props.openOnTab === 2),
                    onTabClick: this.onTabClick
                }
            ]]
        }
        if (this.props.item && this.props.item._permission !== 'view') {
            this.state.tabs[0][2] = {
                id: 3,
                title: 'Delete',
                onTabClick: this.onTabClick,
                selected: (this.props.openOnTab === 3)
            }
        }

        this.perm_levels = ['edit', 'admin'];
        if (this.props.orgPerm === 'view') {
            this.perm_levels = ['view', 'edit', 'admin'];
        }

        this.modalClosed = false;
    }

    async getAvailableList(params) {
        if (!params.filters) params.filters = [];
        params.filters = params.filters.concat([{field: '_permission', value: this.perm_levels}]);
        let sites = await GridDuck.getSites(params);
        if (this.props.item) {
            sites.list = sites.list.filter(a => !this.props.item.siteIds.includes(a.id));
            sites.total = sites.total -= this.props.item.siteIds.length;
        }
        return Promise.resolve(sites);
    }

    async getActiveList(params) {
        let self = this

        if (!this.props.item || !this.props.item.id) return Promise.resolve({list: [], total: 0})

        // if (!this.state.newSitesList.length) {
        params.filters = [
            {
                field: 'occupancyScheduleIds',
                value: [this.props.item.id]
            },
            {field: '_permission', value: this.perm_levels}
        ]
        return GridDuck.getSites(params);
        // } else return Promise.resolve({list: self.state.newSitesList, total: self.state.newSitesList.length});
    }

    componentDidMount() {
        this.checkErrors();
    }

    deleteSiteOccupancySchedule() {
        let self = this;
        return this.props.item.delete().then(function () {
            if (self.props.onSave) self.props.onSave('Occupancy Schedule ' + this.props.item.name + ' Deleted');
            self.closeModal();
        })
    }

    onTabClick(ev, tab) {
        this.state.tabs.forEach(function (tabArr) {
            tabArr.forEach(function (t) {
                t.selected = (tab.id === t.id);
            })
        })
        this.setState(this.state);
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    onFormChange(val) {
        this.state.SiteOccupancySchedule[val.target.name] = val.target.value;
        this.setState({SiteOccupancySchedule: this.state.SiteOccupancySchedule, hasChanged: true});
    }

    sitesChanged(a, b, itemsToAddRemove) {
        let startingIds = this.props.item ? this.props.item.siteIds : [];
        let newSitesList = startingIds.filter(itemId => !itemsToAddRemove.itemsToRemove.find(i => i.id === itemId)).concat(itemsToAddRemove.itemsToAdd.map(i => i.id));
        this.setState({newSitesList: newSitesList, formUpdated: true, sitesUpdated: true})
    }

    async saveChanges(e) {
        let self = this, item = this.props.item, res, toastMessage;
        let SiteOccupancySchedule = this.state.SiteOccupancySchedule;
        // return;

        if (Object.keys(this.errors).length > 0) return this.setState({update: this.state.update++})
        if (item) {
            res = await item.set(SiteOccupancySchedule);
            toastMessage = 'Occupancy Schedule ' + SiteOccupancySchedule.name + ' Updated'
            this.setState({formUpdated: false});
        } else {
            res = await GridDuck.createOccupancySchedule(SiteOccupancySchedule);
            toastMessage = 'Occupancy Schedule ' + SiteOccupancySchedule.name + ' Created'
            this.setState({createdOccupancyScheduleId: res.id});
            if (this.props.setAsDefault) {

            }
        }
        if (this.state.sitesUpdated) await this.saveSites();
        if (self.props.onSave) self.props.onSave(toastMessage, res, e);
        if (!item) {
            self.closeModal();
        }
    }

    async saveSites() {
        let siteIds = this.state.newSitesList;
        if (!siteIds) siteIds = [];
        let id = this.props.item ? this.props.item.id : this.state.createdOccupancyScheduleId
        let occupancySchedule;
        try {
            occupancySchedule = await GridDuck.getOccupancySchedule({id: id})
        } catch (e) {
            
        }
        if (occupancySchedule) {
            
            try {
                await occupancySchedule.set({siteIds: siteIds});
            } catch (e) {
                
            }
        }
        return Promise.resolve();

    }

    checkErrors() {
        let errors = {};
        let SiteOccupancySchedule = this.state.SiteOccupancySchedule;
        if (!SiteOccupancySchedule) return errors;
        if (!SiteOccupancySchedule.name.length) errors['name'] = true;

        this.errors = errors;
    }

    onDeleteChange(val) {
        this.setState({deleteConfirmation: val.target.value});
    }

    moveToTab() {
        this.setState({triedToNext: true})
        if (Object.keys(this.errors).length > 0) return;
        this.onTabClick(null, {id: 2});
    }

    render() {
        this.checkErrors();

        // if (this.state.tabs[0][0].selected) footer = <Button label={'Next'} onClick={() => this.moveToTab(1)}/>
        let footer;
        if (!this.props.item || this.props.item._permission !== 'view') {
            footer = <Button progressRes disabled={Object.keys(this.errors).length > 0} additionalclasses={'sm'}
                             label={this.props.item ? 'Save' : 'Create'}
                             color={'gd-green'}
                             onClick={this.saveChanges}/>
        }

        let rules = _.map(this.state.SiteOccupancySchedule.schedule, s => _.mapKeys(s, (v, k) => _.camelCase(k)));

        return (
            <GdModal
                title={(!this.props.item ? 'Create ' : '') + this.state.SiteOccupancySchedule.name + ' Occupancy Schedule'}
                open={this.props.open}
                contentLabel={'Edit Occupancy Schedule'}
                footer={footer}
                onClose={this.closeModal}>
                {this.state.tabs ? <Tabs tabData={this.state.tabs}/> : null}
                <div className={'timeline-header'}>
                    <ScheduleTimeline
                        rules={rules}
                        actions={[{
                            id: -1,
                            name: 'Active',
                            color: '#49c570'
                        }]}
                        inactiveState={'inactive'}/>
                </div>
                {this.state.tabs[0][0].selected ?
                    <CardBody>
                        <div>
                            <div className={'row'} style={{marginBottom: '20px'}}>
                                <Input disabled={this.props.item && this.props.item._permission === 'view'} required
                                       error={this.errors['name'] && this.state.triedToSave} top='true'
                                       onChange={this.onFormChange}
                                       name={'name'} value={this.state.SiteOccupancySchedule.name}
                                       label={'Schedule Name'}/>
                            </div>
                            <p className={'fake-label'} style={{marginBottom: '15px'}}>Occupied Times *</p>

                            <div className={'row'}>
                                <ScheduleSetter rules={rules}
                                                actions={[{
                                                    id: -1,
                                                    name: 'Active',
                                                    color: '#49c570'
                                                }]}
                                                hideActionList
                                                defaultState={-1}
                                                inactiveState={'inactive'}
                                                onUpdate={this.props.item && this.props.item._permission === 'view' ? null : (rules) => {
                                                    this.setState(prevState => {
                                                        return {
                                                            SiteOccupancySchedule: {
                                                                ...prevState.SiteOccupancySchedule,
                                                                schedule: rules
                                                            }
                                                        }
                                                    });

                                                }}/>
                            </div>
                        </div>
                    </CardBody> : ''}
                {this.state.tabs[0][1] && this.state.tabs[0][1].selected ?
                    <div className={'elr-in-modal'}>
                        <div className={'row'} style={{height: '100%'}}>
                            <EditableList
                                isEditing={!this.props.item || this.props.item._permission !== 'view'}
                                disabled={this.props.item && this.props.item._permission === 'view'}
                                columns={[
                                    {
                                        component: StandardListCell,
                                        bold: true,
                                        title: 'Name',
                                        field: 'name',
                                        sortable: true,
                                        defaultSort: 'asc'
                                    },
                                    {
                                        component: StandardListCell,
                                        title: 'Access level',
                                        field: '_permission',
                                        maxWidth: 'none'
                                    },
                                ]}
                                key={1}
                                onChange={this.sitesChanged}
                                noCancel={true}
                                noSave
                                getActiveList={this.getActiveList}
                                getAvailableList={this.getAvailableList}
                            />
                        </div>
                    </div> : ''}
                {this.state.tabs[0][2] && this.state.tabs[0][2].selected ? <CardBody>
                    <DeleteModal itemType={'occupancySchedule'}
                                 item={this.props.item}
                                 open={this.state.tabs[0][2].selected}
                                 deleteRes={
                                     () => {
                                         if (this.props.onSave) this.props.onSave('Occupancy Schedule ' + this.props.item.name + ' Deleted');
                                         this.closeModal();
                                     }
                                 }
                                 onClose={(e) => {
                                     this.onTabClick(e, {id: 1})
                                 }}/>
                </CardBody> : null}

            </GdModal>
        )
    }

}

export default SiteOccupancyScheduleModal;

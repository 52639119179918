import React from 'react';
import './PortfolioList.scss';
import GridDuck from "gridduck";
import history from "../../meta/history";
import PortfolioListItem from './PortfolioListItem'
import ListSubheader from '@material-ui/core/ListSubheader';
import * as _ from 'lodash';

class PortfolioListItems extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            items: [],
            expanded: this.props.expanded,
            updateRef: 0,
        }
        this.siteUpdated = this.siteUpdated.bind(this);
        this.getItems = this.getItems.bind(this);
        this.getItemsFromItemId = this.getItemsFromItemId.bind(this);
        this.genItems = this.genItems.bind(this);
        this.debouncedGetItemsFromId = _.debounce(this.getItemsFromItemId, 300);
    }

    goToPage(page) {
        
        history.push("/" + page);
    }

    async getItemsFromItemId(_params) {
        let device;
        let itemToAddSubs;
        if (_params) {
            device = _params.device;
            itemToAddSubs = _params.itemToAddSubs;
        }
        
        
        
        let self = this;
        let params = {
            items: 100,
            getAll: true
        }, filters = [];
        if (self.props.sgl.id.indexOf('all') === -1 || device) {
            // label: 'Client',
            // value: 'client',
            // childrenLabel: 'site',
            // loadChildrenFilterField: 'clientId',
            // loadChildrenValueField: 'id',
            if (!device) {
                this.props.groupType.childrenFilters.forEach((gt) => {
                    filters.push({
                        field: gt.field,
                        value: gt.value || self.props.sgl[gt.valueField]
                    });
                });
            } else if (device && itemToAddSubs) {
                filters.push({
                    field: 'siteId',
                    value: itemToAddSubs.id
                });
            }

        }
        if (this.props.searchText) {
            filters.push({
                field: 'search',
                value: this.props.searchText
            });
        }
        filters.push({
            field: 'no_telemetry',
            value: true
        })
        if (filters.length) {
            params.filters = filters;
        }
        let items;
        if (this.props.groupType?.childrenLabel === 'site' && !device) {
            items = await GridDuck.getSites(params);
        } else if (this.props.groupType?.childrenLabel === 'device' || device) {
            items = await GridDuck.getAssets(params);
        }
        items.on('updated', function (info) {
            
            self.siteUpdated();
        });
        //FIXME: disabled this subscriber because on dashboard disconnect/reconnect it will hit the API for every site open
        items.list.forEach(function (subscribable) {
            if (subscribable && subscribable.on) {
                subscribable.on('updated', function (field, newValue, obj) {
                    
                    
                    self.siteUpdated();
                });
            }
        });
        
        if (!itemToAddSubs) {
            self.setState({items: items, loading: false});
        } else {
            itemToAddSubs.subItems = items;
            self.setState({updateRef: this.state.updateRef++});
        }
    }

    siteUpdated() {
        
        this.setState({updateRef: this.state.updateRef++});
    }

    async getItems(params) {
        let self = this;
        
        if (!params || !params.itemToAddSubs) {
            this.setState({loading: true, expanded: true, items: []}, () => {
                this.expandRetract(true);
            });
        }
        if (this.props.searchText) {
            this.debouncedGetItemsFromId(params);
        } else {
            this.getItemsFromItemId(params);
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.updateRef !== prevProps.updateRef && this.state.expanded) {
            this.getItems();
        }
        if (this.props.searchText.length && this.props.searchText !== prevProps.searchText) {
            this.getItems();
        } else if (this.props.searchText !== prevProps.searchText && this.props.searchText === '') {
            this.setState({items: [], loading: false, expanded: false}, () => {
                this.expandRetract(false);
            });
        }
    }

    expandRetract(isExpanded) {
        if (this.props.onExpandRetract) {
            this.props.onExpandRetract(isExpanded);
        }
    }

    componentDidMount() {
        if (this.props.expanded) {
            this.getItems();
        }
    }

    genItems(params) {
        let items = params.items;
        let haveChildren = params.haveChildren;
        // items, haveChildren
        let self = this;
        let icon = 'FaBuilding';
        if (this.props.type === 'device_group') {
            icon = 'FaPlug'
        }
        return items.list ? items.list.filter((s) => {
            if (this.props.searchText.length && haveChildren) {
                return s.name.toLowerCase().indexOf(this.props.searchText.toLowerCase()) !== -1;
            } else return true;
        }).sort((a, b) => a.name?.localeCompare(b.name, 'en', {numeric: true})).map((s, i) => {
            let siteUrl = (params.urlType || this.props.groupType?.childrenLabel) + '/' + s.id;
            let activeIssues = self.props.type === 'site_group' && haveChildren && s.issueIds && s.issueIds.length ? s.issueIds.filter(i => i && this.props.account ? this.props.account.ignoredAssets.indexOf(i.id) === -1 : true).length : null;

            if (s.disconnected || s.gatewayDisconnected) {
                activeIssues = 1;
            }

            return <PortfolioListItem key={'pfli' + s + i + '-s' + params.items.length} open={this.props.open} indent={params.indent || 1}
                                      url={siteUrl} name={s.name}
                                      updateDisconnectedDrawer={this.props.updateDisconnectedDrawer}
                                      icon={params.icon || icon}
                                      account={this.props.account}
                                      issues={s.issues}
                                      item={s}
                                      activeIssues={activeIssues}
                                      loading={this.state.loading}
                                      expanded={s.expanded}
                                      onExpand={this.props.groupType?.childrenLabel === 'site' && haveChildren ? (e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          
                                          s.expanded = true;
                                          this.setState({updateRef: this.state.updateRef++}, () => {
                                              self.getItems({device: true, itemToAddSubs: s});
                                          });
                                      } : null}
                                      onMinimise={(e) => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          s.expanded = false;
                                          this.setState({updateRef: this.state.updateRef++});
                                      }}
                                      hasSubItems={haveChildren}
                                      subItems={s.expanded && haveChildren && s.subItems ? this.genItems({
                                          items: s.subItems,
                                          indent: 2,
                                          icon: 'FaPlug',
                                          urlType: 'device'
                                      }) : null}
                                      onClick={() => {
                                          
                                          history.push('/' + siteUrl);
                                      }}/>
        }) : [];
    }

    render() {
        let self = this;

        let items = this.genItems({items: this.state.items, haveChildren: true, indent: 1});
        if (!this.props.searchText.length || (this.props.sgl.name.toLowerCase().indexOf(this.props.searchText.toLowerCase()) !== -1) || items.length || this.state.loading) {
            return <li>
                <ul><ListSubheader><PortfolioListItem url={this.props.siteGroupUrl}
                                                      open={this.props.open}
                                                      updateDisconnectedDrawer={this.props.updateDisconnectedDrawer}
                                                      noSites={this.props.noSites}
                                                      id={this.props.sgl.id}
                                                      item={this.props.sgl}
                                                      type={this.props.type}
                                                      loading={this.state.loading}
                                                      account={this.props.account}
                                                      searchText={this.props.searchText}
                                                      issues={this.props.sgl.issues}
                                                      activeIssues={this.props.activeIssues}
                                                      name={this.props.noSites ? 'Get Started' : this.props.sgl.id.indexOf('all') !== -1 ? ('All ' + _.capitalize(this.props.groupType?.childrenLabel) + 's') : this.props.sgl.name}
                                                      icon={this.props.noSites ? 'FaPaperPlane' : this.props.sgl.id.indexOf('all') !== -1 ? 'FaBorderAll' : this.props.icon}
                                                      key={this.props.sgl.id + 'dn'}
                                                      getItems={() => self.getItems()}
                                                      expanded={this.state.expanded}
                                                      onExpand={(e) => {
                                                          e.preventDefault();
                                                          e.stopPropagation();
                                                          // if (!items || !items.length) {
                                                          self.getItems()
                                                          // }
                                                          self.setState({expanded: true}, () => {
                                                              this.expandRetract(true);
                                                          })
                                                      }}
                                                      onMinimise={(e) => {
                                                          e.preventDefault();
                                                          e.stopPropagation();
                                                          self.setState({expanded: false}, () => {
                                                              this.expandRetract(false);
                                                          })
                                                      }}
                                                      onClick={() => {
                                                          
                                                          self.goToPage(this.props.siteGroupUrl);
                                                      }}/></ListSubheader>
                    <div key={this.props.sgl.id + 'ssi'}
                         className={'p-sites ' + (this.state.expanded ? 'open' : '')}>{items}</div>
                    {this.state.loading && (this.state.expanded) ?
                        <PortfolioListItem key={this.props.sgl.id + 'l'} open={this.props.open} indent={1}
                                           loader={this.props.groupType?.childrenLabel + 's'}/> : null}
                    {/*{sites && sites.length < this.props.sgl.siteIds.length && !this.state.loading && (this.state.expanded) ?*/}
                    {/*    <PortfolioListItem key={'s-'} open={this.props.open} indent={1} name={'Show More'}*/}
                    {/*                       icon={'FaPlus'}*/}
                    {/*                       onClick={() => {*/}
                    {/*                           self.getItems(this.props.sgl.siteIds);*/}
                    {/*                       }}/> : null}*/}
                </ul>
            </li>
        } else return null;
    }
}

export default PortfolioListItems;

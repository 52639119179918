import {DataTypes} from "gd-react";
import * as _ from 'underscore';

const widgetTypes = [
    {
        label: 'header',
        name: 'Total',
        dataTypes: {
            aggregate: true
        }
    },
    {
        label: 'spend',
        name: 'Spend',
        hideOnAll: true,
        dataTypes: {
            aggregate: true
        }
    },
    {
        label: 'carbon',
        name: 'Carbon',
        hideOnAll: true,
        dataTypes: {
            aggregate: true
        }
    },
    {
        label: 'historic',
        name: 'Historic',
        hideOnAll: true,
        dataTypes: {
            instantaneous: true,
            categorical: true
        }
    },
    {
        label: 'dow',
        name: 'Day of Week Average',
        dataTypes: {
            aggregate: true
        }
    },
    {
        label: 'hod',
        name: 'Hour of Day Average',
        dataTypes: {
            aggregate: true
        }
    },
    {
        label: 'group_breakdown',
        name: 'Site Breakdown',
        hideOnAll: true,
        hideIfObjectType: ['site', 'asset'],
        dataTypes: {
            aggregate: true
        }
    },
    {
        label: 'device_breakdown',
        name: 'Device Breakdown',
        hideOnAll: true,
        hideIfObjectType: ['asset'],
        dataTypes: {
            aggregate: true
        }
    },
    {
        label: 'category_breakdown',
        name: 'Category Breakdown',
        hideIfObjectType: ['asset'],
        hideOnAll: true,
        dataTypes: {
            aggregate: true
        }
    }
]

const snakeCase = string => {
    return string.replace(/\W+/g, " ")
        .split(/ |\B(?=[A-Z])/)
        .map(word => word.toLowerCase())
        .join('_');
};

const generateWidgetObjectBase = ({id, aggregate, allDataTypes}) => {
    let _id = id;
    let aggregates = allDataTypes.filter(dt => dt.dataType === 'aggregate');
    if (aggregates.length > 1) {
        let a = aggregate;
        if (aggregate.dataType === 'instantaneous' && aggregate.dataTypeSibling) {
            a = aggregates.find(_a => _a.type === aggregate.dataTypeSibling);
        }
        _id = id + '_' + a.label.toLowerCase();
    }
    return {
        o_id: _id,
        type: _id,
        filter_type: _id,
        data_type: aggregate.type,
        data_type_obj: aggregate,
        all_data_types: allDataTypes
    }
}

function reorderHistoricKeys(obj) {
    let keys = Object.keys(obj);
    let reorderedKeys = [];
    let historicMap = {};

    // Store historic keys separately for easy lookup
    keys.forEach(key => {
        if (key.endsWith('-historic')) {
            let baseKey = key.replace('-historic', '');
            historicMap[baseKey] = key; // Map base name to historic key
        }
    });

    keys.forEach((key, index) => {
        reorderedKeys.push(key);

        // If it's a carbon key, check if there's a matching historic key
        if (key.endsWith('-carbon')) {
            let baseKey = key.replace('-carbon', '');
            if (historicMap[baseKey]) {
                reorderedKeys.push(historicMap[baseKey]);

                // Remove from historicMap to avoid duplicates
                delete historicMap[baseKey];
            }
        }
    });

    // Preserve order but place historic keys right after carbon keys
    let reorderedObj = {};
    reorderedKeys.forEach(key => {
        reorderedObj[key] = obj[key];
    });

    return reorderedObj;
}

const generateWidgetObject = ({baseObj, label, name}) => {
    return {
        id: baseObj.o_id + '-' + label,
        name: name,
        ...baseObj
    }
}

const getTypes = function (isAll, checkOne, group, objectTypeName) {
    let all_data_types = DataTypes.filter(d => !d.downloadOnly);
    let data_types_grouped_by_category = _.groupBy(all_data_types, 'category');
    let possible_widgets = {};

    Object.entries(data_types_grouped_by_category).forEach((t) => {
        let id = snakeCase(t[0]);
        t[1].forEach(_dataType => {
            let widgetObject = generateWidgetObjectBase({
                id: id,
                aggregate: _dataType,
                allDataTypes: t[1]
            });
            widgetTypes.filter(d => d.dataTypes[_dataType.dataType]).forEach(d => {
                if ((!d.hideOnAll || d.hideOnAll && !isAll) && (!d.hideIfObjectType || !d.hideIfObjectType.find(ot => ot === objectTypeName))) {
                    if (Object.keys(possible_widgets).find(k => k.includes('historic') && k.includes(widgetObject.o_id)) && _dataType.dataType === 'instantaneous') {
                        widgetObject.o_id = widgetObject.o_id + '_' + _dataType.label.toLowerCase().replace('/', '').replace(' ', '');
                    }
                    possible_widgets[widgetObject.o_id + '-' + d.label] = generateWidgetObject({
                        baseObj: widgetObject,
                        label: d.label,
                        name: _dataType.dataType === 'aggregate' ? `${d.name}` : _dataType.label
                    });
                }
            });
        });

    });
    return reorderHistoricKeys(possible_widgets);
}

export default getTypes;
import React, {Component} from "react";
import "./SubTierModal.scss"
import '../../styles/_layout.scss';
import Modal from "react-modal";
import SubTiers from "./SubTiers";
import {Icon} from "gd-react";

class SubTierModal extends Component {

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);

        this.state = {}
        this.modalClosed = false;
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    render() {

        return (
            <div>
                <Modal
                    isOpen
                    onRequestClose={this.props.onClose}
                    contentLabel="Update Plan">
                    <div className="subTierModalWrapper">
                        <div className={"close-icon"}>
                            <Icon size={'20'} onIconClick={this.props.onClose} icon={'FaTimes'}/>
                        </div>
                        <SubTiers inModal account={this.props.account} frequestedFeature={this.props.open}
                                  tierUpdated={this.props.tierUpdated}/>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default SubTierModal;
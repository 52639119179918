import React from "react";
import {
    Button,
    List,
    StandardListCell,
    Icon
} from "gd-react";
import GridDuck from 'gridduck';
import './DeviceCategoryListPage.scss'
import AddEditDeviceCategoryModal from "../../modals/AddEditDeviceCategory/AddEditDeviceCategoryModal";
import history from "../../meta/history";
import SubTierModal from "../../modals/SubTier/SubTierModal";
import checkFeature from "../../services/SubFeatureCheckService";

class DeviceCategoryListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showDeviceCategoryModal: null,
            openSubTierModal: null
        };
        this.getList = this.getList.bind(this);
        this.checkAddDeviceCategory = this.checkAddDeviceCategory.bind(this);
    }

    async componentDidMount() {
        let self = this;
        GridDuck.getAccount({id: GridDuck.userId})
            .then(function (user) {
                self.setState({user: user});
            });
        let urlParams = new URLSearchParams(window.location.search);
        let id = urlParams.get('id');
        if (id) {
            let filters = [{
                field: 'id',
                value: id
            }]
            
            let res = await GridDuck.getDeviceCategorys({filters: filters})
            let item = res.list[0];
            self.setState({showDeviceCategoryModal: item});
        }
    }

    async getList(params) {
        return GridDuck.getDeviceCategorys(params);
    }

    async checkAddDeviceCategory() {
        const featureAvailable = await checkFeature('CUSTOM_DEVICE_CATEGORIES');
        if (featureAvailable) {
            this.setState({
                showDeviceCategoryModal: true,
                openSubTierModal: null
            });
        } else {
            this.setState({openSubTierModal: 'CUSTOM_DEVICE_CATEGORIES'})
        }
    }

    render() {
        return (
            this.state.user ? <div className={"page device-category"}>
                {this.state.openSubTierModal ? <SubTierModal open={this.state.openSubTierModal}
                                                             tierUpdated={this.checkAddDeviceCategory}
                                                             onClose={() => this.setState({openSubTierModal: false})}/> : ''}
                <div className={'widget-board-header'}>
                    <div className={'row reverse place-in-header'}>
                        {this.state.user.orgPermission !== 'view' ?
                            <Button color={'gd-green'} label={'+ Add New Device Category'}
                                    onClick={this.checkAddDeviceCategory}/> : null}
                    </div>
                </div>
                <List
                    noitemelem={<p>No Device Categories</p>}
                    columns={[
                        {
                            component: StandardListCell,
                            title: 'Icon',
                            field: 'icon',
                            maxWidth: '60px',
                            custom: function (item) {
                                return <div><Icon size={'14'} icon={item.icon || 'FaCog'} color={item.colour}/>
                                </div>
                            }
                        },
                        {
                            component: StandardListCell,
                            title: 'Name',
                            field: 'name',
                            maxWidth: '250px'
                        },
                        {
                            component: StandardListCell,
                            custom: function (item) {
                                return item.organisationId ? 'You' : window._companyName
                            },
                            title: 'Created by',
                            maxWidth: '150px'
                        },
                        {
                            component: StandardListCell,
                            custom: function (item) {
                                return item._permission
                            },
                            title: 'Permission',
                            maxWidth: 'none'
                        }]}
                    getlist={this.getList}
                    rowOnClick={(item) => {
                        this.setState({showDeviceCategoryModal: item})
                        history.push('/settings/organisation/device-categories?id=' + item.id);
                    }}
                    filters={[]}/>

                {this.state.showDeviceCategoryModal ?
                    <AddEditDeviceCategoryModal isGod={this.state.user.god}
                                                orgPerm={this.state.user.orgPermission}
                                                item={this.state.showDeviceCategoryModal}
                                                onClose={() => {
                                                    this.setState({
                                                        showDeviceCategoryModal: null
                                                    })
                                                    history.push('/settings/organisation/device-categories');
                                                }}
                                                open={this.state.showDeviceCategoryModal}/> : null}
            </div> : null
        )
    }
}

export default DeviceCategoryListPage;
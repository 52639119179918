import React, {Component} from "react";
import "./AddEditCustomField.scss";
import '../../styles/_layout.scss';
import GridDuck from "gridduck";
import {
    Button,
    CardBody,
    Input,
    Tabs,
    Toast,
    Icon, Dropdown
} from "gd-react";
import CustomFieldsForm from "./CustomFieldsForm";
import GenericLoader from "../../components/GenericLoader";
import {renderToPipeableStream} from "react-dom/server";
import GdModal from "../../components/GdModal/GdModal";

let f_type = {
    number: 'valueNumber',
    text: 'valueText',
    boolean: 'valueBoolean'
}

class AddEditCustomFieldModal extends Component {

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.editValues = this.editValues.bind(this)
        this.editBoolValues = this.editBoolValues.bind(this)
        this.addRow = this.addRow.bind(this);
        this.removeCustomField = this.removeCustomField.bind(this)
        this.editCustomFields = this.editCustomFields.bind(this)
        this.fetchCustomFieldNames = this.fetchCustomFieldNames.bind(this)
        this.fetchCustomFieldData = this.fetchCustomFieldData.bind(this)
        this.searchValues = this.searchValues.bind(this)
        this.saveChanges = this.saveChanges.bind(this)
        this.errors = {};
        this.customFieldList = [];
        this.changed = false;
        this.state = {
            customFields: [],
            loaded: false,
            fixedCustomFieldNames: [],
            deleteConfirmation: '',
            triedToSave: false
        }
        this.customFieldsFormRef = React.createRef()
        this.modalClosed = false;
    }

    async fetchCustomFieldData() {
        let self = this
        let filters = [
            {
                field: 'siteId',
                value: [this.props.site.id]
            }
        ]
        let customFieldsRes = await GridDuck.getCustomFields({filters: filters});
        let list = customFieldsRes.list;
        
        let customFieldList = [];
        if (list && list.length) {
            self.customFieldList = list;
            let promises = [], results = [];
            for (const cf of list) {
                let value;
                //filter out custom fields that already exist, but must include the already existing.
                value = cf[f_type[cf.customFieldType]];
                promises.push(self.searchValues(cf.customFieldTypeId).then((res) => customFieldList.push({
                    field: cf.customFieldName,
                    fieldType: cf.customFieldType,
                    customFieldTypeId: cf.customFieldTypeId,
                    customFieldId: cf.id,
                    hasChanged: false,
                    value: value,
                    icon: cf.icon,
                    color: cf.colour,
                    id: cf.id,
                    existingCustomFields: res
                })))
            }
            await Promise.all(promises);
        }
        
        return customFieldList
    }

    async fetchCustomFieldNames() {
        let filters = []
        let customFieldTypeRes = await GridDuck.getCustomFieldTypes({filters: filters})
        
        let customFieldNames = customFieldTypeRes.list.map((fT) => {
            return {
                id: fT.id,
                icon: fT.icon,
                color: fT.colour,
                fieldType: fT.fieldType,
                value: fT.name,
                title: fT.name
            }
        })
        return customFieldNames
    }

    async componentDidMount() {
        
        let self = this
        let customFieldNameRes = await this.fetchCustomFieldNames();
        let customFieldData = await this.fetchCustomFieldData();
        
        self.setState({
            fixedCustomFieldNames: customFieldNameRes,
            customFields: customFieldData,
            loaded: true
        })
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    editValues(e, r, newValue) {
        
        
        let self = this;
        let customFields = this.state.customFields
        if (newValue) {
            r.newValue = e.target.value
            r.value = 'add_new'
        } else {
            //todo if an existing option is selected, set this as the value and apply the
            // existing cf id to the site
            // the site receives a set of cf ids
            // these cf ids are either created

            let existingCustomField = r.existingCustomFields.find((ecf) => ecf.value === e.target.value)
            r.value = e.target.value;
            r.customFieldId = existingCustomField.id;
        }
        r.hasChanged = true
        self.setState({customFields: customFields, changed: true})
    }

    editBoolValues(e, r) {
        
        let self = this;
        let customFields = this.state.customFields
        let value = false;
        if (e.target.value === 'true') value = true;
        r.value = value;
        r.hasChanged = true
        self.setState({customFields: customFields, changed: true})
    }

    addRow() {
        let self = this
        let customFields = this.state.customFields;
        customFields.push({
            field: '',
            fieldType: '',
            value: null,
            id: 'new_' + customFields.length,
            customFieldId: '',
            customFieldTypeId: '',
            existingCustomFields: []
        })
        self.setState({customFields: customFields, changed: true})
    }

    async editCustomFields(e, r) {
        let customFields = this.state.customFields
        let fieldObj = this.state.fixedCustomFieldNames.find((ft) => ft.value === e.target.value)
        let fieldType = fieldObj.fieldType;
        let fieldTypeId = fieldObj.id
        let existingFields = await this.searchValues(fieldTypeId)
        r.field = e.target.value;
        r.value = null;
        r.newValue = null;
        r.existingCustomFields = existingFields;
        r.customFieldTypeId = fieldTypeId
        r.fieldType = fieldType;
        this.setState({customFields: customFields, changed: true})
    }

    removeCustomField(r) {
        let customFields = this.state.customFields.filter((cf) => {
            return cf.id !== r.id
        });
        this.setState({customFields: customFields, changed: true})
    }

    async searchValues(fieldTypeId) {
        let filters = [
            {
                field: 'customFieldTypeId',
                value: fieldTypeId
            },
        ]
        let existingFieldsRes = await GridDuck.getCustomFields({filters: filters})
        let existingFields = existingFieldsRes.list.map((f) => {
            return {
                title: f.valueText || f.valueNumber,
                value: f.valueText || f.valueNumber,
                id: f.id
            }
        });
        existingFields.unshift({
            value: 'add_new',
            title: <div className={'dropdown-button'}>{'+ Create New Value'}</div>
        });
        let uniqueExistingFields = existingFields.filter(function (field, i) {
            return existingFields.map((f) => f.value).indexOf(field.value) == i;
        })
        return uniqueExistingFields
    }

    checkErrors() {
        let errors = {};
        let customFields = this.state.customFields;
        for (const field of customFields) {
            if (!field.customFieldTypeId) errors['field' + field.id] = true
            if (field.value == null) errors['value' + field.id] = true
            if (field.value === 'add_new' && !field.newValue) errors['value' + field.id] = true
        }
        this.errors = errors;
    }

    async saveChanges() {
        let self = this
        this.setState({triedToSave: true})
        if (Object.values(this.errors).length) {
            
            return false
        }

        let customFields = this.state.customFields;

        

        let createdIds = [];

        for await (const cf of customFields) {
            if (!cf.customFieldId) {
                // does not exist, creating.
                let newCustomField = {}
                newCustomField.customFieldTypeId = cf.customFieldTypeId;
                if (cf.fieldType === 'number') {
                    if (cf.value === 'add_new') {
                        newCustomField.valueNumber = cf.newValue
                    } else {
                        newCustomField.valueNumber = cf.value
                    }
                }
                if (cf.fieldType === 'text') {
                    if (cf.value === 'add_new') {
                        newCustomField.valueText = cf.newValue
                    } else {
                        newCustomField.valueText = cf.value
                    }
                }
                if (cf.fieldType === 'boolean') {
                    newCustomField.valueBoolean = cf.value === 'true' ? true : false;
                }
                
                let createRes = await GridDuck.createCustomField(newCustomField)
                createdIds.push(createRes.id)
            } else {
                //instance already exists on another site
                createdIds.push(cf.customFieldId)
            }
        }
        try {
            
            await this.props.site.set({customFields: createdIds});
            if (this.props.onUpdate) this.props.onUpdate();
            setTimeout(() => self.closeModal(), 1000)
            return Promise.resolve()
        } catch (e) {
            
            return Promise.reject('error setting custom field ids in site')
        }
    }

    render() {
        this.checkErrors()
        let self = this
        let footer;
        if (this.state.changed) footer =
            <Button progressRes
                    disabled={Object.keys(this.errors).length > 0} additionalclasses={'sm'} label={'Save'}
                    color={'gd-green'}
                    onClick={this.saveChanges}/>
        let title;
        title = `Edit ${self.props.site.name}'s Custom Fields`

        return (
            <GdModal
                title={title}
                open={this.props.open}
                contentLabel={'Edit Custom Fields'}
                footer={footer}
                onClose={this.closeModal}>
                <CardBody>
                    {this.state.loaded ? <div className={'Add-Edit-Custom-Field'}>
                        <CustomFieldsForm
                            ref={this.customFieldsFormRef}
                            editCustomFields={this.editCustomFields}
                            removeCustomField={this.removeCustomField}
                            triedToSave={this.state.triedToSave}
                            addRow={this.addRow}
                            errors={this.errors}
                            fixedCustomFieldNames={this.state.fixedCustomFieldNames}
                            editValues={this.editValues}
                            editBoolValues={this.editBoolValues}
                            customFields={this.state.customFields}
                        />
                    </div> : <GenericLoader/>}
                </CardBody>
                <Toast onClose={() => this.setState({showToast: null})} message={this.state.showToast}
                       open={this.state.showToast}
                       severity="error"
                       anchorOrigin={{
                           vertical: 'bottom',
                           horizontal: 'left',
                       }}/>
            </GdModal>
        )
    }
}


export default AddEditCustomFieldModal;
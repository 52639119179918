import React from 'react';
import './Account.scss';
import '../../styles/_layout.scss';
import {Card, CardHeader, CardBody, Button, Input, Toggle, GdCheckbox, Dropdown, Toast, Loader, Icon} from "gd-react";
import SaveBar from "../../components/SaveBar/SaveBar";
import GridDuck from "gridduck";
import PasswordChecker from "../../components/PasswordChecker/PasswordChecker";
import {v4 as uuidv4} from "uuid";
import EditAddressModal from "../../modals/EditAddress/EditAddressModal";

class AccountDetails extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            open: false,
            newPasswordCheck: false,
            currentPassword: '',
            uploadProgress: 0,
            uploadComplete: false,
            uploadingImage: false,
            userDetails: {
                username: this.props.item.username,
                name: this.props.item.name,
                jobRole: this.props.item.jobRole,
                profilePictureThumbnail: this.props.item.profilePictureThumbnail,
                colour: this.props.item.colour,
                frequency: this.props.item.frequency,
                alertEmail: this.props.item.alertEmail,
                alertToast: this.props.item.alertToast,
                receiveAuditEmails: this.props.item.receiveAuditEmails,
                orgAddress: this.props.item.orgAddress,
                organisationId: this.props.item.organisationId
            }
        };
        this.onFormChange = this.onFormChange.bind(this);
        this.updatePassword = this.updatePassword.bind(this);
        this.hasChanged = this.hasChanged.bind(this);
        this.newPwChanged = this.newPwChanged.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.cancelChanges = this.cancelChanges.bind(this);
        this.setUserInfo = this.setUserInfo.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.onCheckboxChange = this.onCheckboxChange.bind(this);
        this.imageUploaded = this.imageUploaded.bind(this);
        this.accountUpdated = this.accountUpdated.bind(this);
        this.changeCurrentPassword = this.changeCurrentPassword.bind(this);
        this.updateAddress = this.updateAddress.bind(this);
        this.newPasswordCheck = false;
    }

    updateAddress() {
        this.setState({openAddressModal: true});
    }

    componentDidMount() {
        this.setUserInfo();
        this.props.item.on('updated', this.accountUpdated)
    }

    componentWillUnmount() {
        this.props.item.off('updated', this.accountUpdated);
    }

    accountUpdated() {
        let userDetails = this.state.userDetails;
        userDetails.profilePictureThumbnail = this.props.item.profilePictureThumbnail
        this.setState({
            userDetails: userDetails
        });
    }

    handleClose() {
        this.setState({open: false})
    }

    hasChanged() {
        let self = this;
        let hasChanged = false;
        if (self.props.item) {
            Object.entries(self.props.item).map(([key, value]) => {
                let updatedKey = key.replace(/^_+/i, '');
                if ((updatedKey in self.state.userDetails) && self.state.userDetails[updatedKey] !== value && updatedKey !== 'orgAddress') {
                    hasChanged = true;
                    
                }
            })
        }
        return hasChanged;
    }

    setUserInfo() {
        let self = this;

        self.setState({
            userDetails: {
                username: this.props.item.username,
                orgPermission: this.props.item.orgPermission,
                name: this.props.item.name,
                jobRole: this.props.item.jobRole,
                profilePictureThumbnail: this.props.item.profilePictureThumbnail,
                colour: this.props.item.colour,
                frequency: this.props.item.frequency,
                alertEmail: this.props.item.alertEmail,
                alertToast: this.props.item.alertToast,
                receiveAuditEmails: this.props.item.receiveAuditEmails,
                orgAddress: this.props.item.orgAddress,
                organisationId: this.props.item.organisationId
            }
        });

    }

    saveChanges() {
        let self = this;
        return this.props.item.set(this.state.userDetails).then(function () {
            self.setUserInfo();
            self.setState({open: true})
        });
    };

    cancelChanges() {
        this.setUserInfo();
    };

    updatePassword() {
        let self = this;
        return GridDuck.createPassword({
            oldPassword: this.state.currentPassword,
            newPassword: this.state.newPassword
        }).then(function (res) {
            
            window.setTimeout(function () {
                self.setState({updatePassword: false});
            }, 1000)
            return Promise.resolve();
        }).catch(function (err) {
            
            self.setState({updateError: 'Incorrect current password'});
            return Promise.reject();
        });
    };

    onFormChange(val) {
        
        this.state.userDetails[val.target.name] = val.target.value;
        this.setState({userDetails: this.state.userDetails});
    }

    onCheckboxChange(val) {
        this.state.userDetails[val.target.name] = val.target.checked
        this.setState({userDetails: this.state.userDetails});
    }

    changeCurrentPassword(val) {
        this.setState({updateError: ''});
        this.setState({currentPassword: val.target.value});
    }

    newPwChanged(passedChecks, newPassword) {
        
        
        this.setState({
            newPasswordCheck: passedChecks,
            newPassword: newPassword
        })
    }

    imageUploaded(selectorFiles) {
        let file = selectorFiles[0];
        let self = this;
        let newFile = {
            itemType: 'account',
            itemId: this.props.item.id,
            name: file.name,
            type: file.type,
            size: file.size,
            file: file,
            ref: uuidv4(),
        };
        this.setState({uploadingImage: true, uploadProgress: 0, uploadComplete: false});
        GridDuck.createFile(newFile, function uploadProgress(progressEvt) {
            
            self.setState({uploadProgress: Math.round((progressEvt.loaded / newFile.size) * 100)});
        }).then(function (file) {
            self.setState({uploadComplete: true, uploadingImage: false})
            
            
        });
    }

    render() {

        let detailsCard, alertsCard, imageStyle, self = this;
        let footerSaveBar = this.hasChanged() ?
            <SaveBar onSaveClick={this.saveChanges} onCancelClick={this.cancelChanges}/> : '';

        if (this.state.userDetails && this.state.userDetails.username) {

            imageStyle = {
                backgroundImage: 'url("' + this.state.userDetails.profilePictureThumbnail + '")'
            }

            detailsCard =
                <div>
                    <div className={'row ver-center'}>
                        <div className={'profile-picture'} style={imageStyle}>
                            {!this.state.userDetails.profilePictureThumbnail ? <Icon icon={'FaUser'} size={'30'}/> : ''}
                        </div>
                        <input accept={'image/*'} id={"image-upload"} ref={input => this.imageUploadRef = input}
                               type={"file"}
                               onChange={(e) => this.imageUploaded(e.target.files)} hidden/>
                        {!window.$exampleAccount ? !this.state.uploadingImage ?
                            <Button label={'Upload Profile Picture'} onClick={() => this.imageUploadRef.click()}/> :
                            <Loader type={'circular'} withprogress size={50}
                                    value={this.state.uploadProgress < 100 ? this.state.uploadProgress : 100}/> : ''}
                    </div>
                    <div className={'row'}>
                        <Input disabled value={this.state.userDetails.username} label={'Email address'}/>
                    </div>
                    <div className={'row'}>
                        <Input disabled={window.$exampleAccount} name={'name'} onChange={this.onFormChange}
                               value={this.state.userDetails.name}
                               label={'Name'}/>
                    </div>
                    <div className={'row'}>
                        <Input disabled={window.$exampleAccount} name={'jobRole'} onChange={this.onFormChange}
                               value={this.state.userDetails.jobRole}
                               label={'Job Role'}/>
                    </div>

                    {!window.$exampleAccount ? <div className={'row reverse'} style={{marginTop: '20px'}}>
                        {!this.state.updatePassword ? <Button onClick={() => this.setState({updatePassword: true})}
                                                              label={'Change Password'}/> : ''}
                    </div> : ''}
                    {this.state.updatePassword ? <div>
                        <div className={'divider'}/>
                        <div className={'row'}>
                            <Input type={'password'} error={this.state.updateError} label={'Current Password'}
                                   onChange={this.changeCurrentPassword}
                                   value={this.state.currentPassword}
                                   required/>
                        </div>
                        <PasswordChecker onChange={this.newPwChanged}/>
                        <div className={'row'} style={{justifyContent: 'space-between', marginTop: '20px'}}>

                            <Button color={'gd-grey'} onClick={() => this.setState({updatePassword: false})}
                                    label={'Cancel'}/>
                            {this.state.newPasswordCheck ?
                                <Button additionalclasses={'md'} onClick={this.updatePassword} progressRes
                                        label={'Update Password'} color={'gd-green'}/>
                                : ''}
                        </div>
                        <p className={'error-text'}>{this.state.updateError}</p>
                    </div> : ''}

                </div>

            // alertsCard = <div>
            //     <p>This is where you can manage how you receive notifications when devices and hubs lose
            //         connection</p>
            //     {/*<Toggle value={this.state.receiveAlerts} label={'Receive Alerts'}/>*/}
            //     <br/>
            //     <p><i>I would like to be notified via:</i></p>
            //     <GdCheckbox checked={this.state.userDetails.alertEmail} name={'alertEmail'}
            //                 onChange={this.onCheckboxChange} label={'Email'}/>
            //     <GdCheckbox checked={this.state.userDetails.alertToast} name={'alertToast'}
            //                 onChange={this.onCheckboxChange} label={'Dashboard notifications'}/>
            //     <br/>
            //     <Dropdown
            //         value={this.state.userDetails.frequency}
            //         onChange={this.onFormChange}
            //         label={"Frequency of reminders"}
            //         name={'frequency'}
            //         fixeditems={[{
            //             id: 'daily',
            //             title: 'Daily',
            //             value: 86400
            //         }, {
            //             id: 'hourly',
            //             title: 'Hourly',
            //             value: 3600
            //         }]}/>
            // </div>
            alertsCard = <div>
                <p>This is where you can manage how you receive notifications</p>
                <p><i>I would like to be emailed when a device or hub is disconnected:</i></p>
                <GdCheckbox checked={this.state.userDetails.alertEmail} name={'alertEmail'}
                            onChange={this.onCheckboxChange} label={'Receive Disconnected Emails'}/>
                <br/>
                <p><i>I would like to be notified when an install audit has been carried out:</i></p>
                <GdCheckbox disabled={window.$exampleAccount} checked={this.state.userDetails.receiveAuditEmails}
                            name={'receiveAuditEmails'}
                            onChange={this.onCheckboxChange} label={'Receive Audit Emails'}/>
                <br/>
            </div>
        }

        return (
            <div className='page narrow Account not-flex grey'>
                <Toast onClose={this.handleClose} message={"Save Successful"}
                       anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                       open={this.state.open}/>

                <div className={'column top'}>
                    <div className={'detail-content'}>
                        {footerSaveBar}
                    </div>
                    <div className={'detail-content'}>
                        <Card>
                            <CardHeader>Details</CardHeader>
                            <CardBody>{detailsCard}
                            </CardBody>
                        </Card>
                    </div>
                    {/*{this.state.userDetails.organisationId !== '3408fa58-7e6e-42ba-a89f-a77562ccbf21' ? <div className={'detail-content'}>*/}
                    {/*    {this.state.userDetails && <Card>*/}
                    {/*        <div className={'CardHeader'} style={{*/}
                    {/*            paddingRight: '10px',*/}
                    {/*            display: 'flex',*/}
                    {/*            alignItems: 'center',*/}
                    {/*            justifyContent: 'space-between'*/}
                    {/*        }}>Shipping Address{this.state.userDetails.orgPermission === 'owner' ? <Button color={'gd-grey'} onClick={this.updateAddress} label={'Edit'}/> : ''}*/}
                    {/*        </div>*/}
                    {/*        {this.state.userDetails.orgAddress && <CardBody>*/}
                    {/*            <p>{this.state.userDetails.orgAddress.line1}</p>*/}
                    {/*            <p>{this.state.userDetails.orgAddress.line2}</p>*/}
                    {/*            <p>{this.state.userDetails.orgAddress.city}</p>*/}
                    {/*            <p>{this.state.userDetails.orgAddress.postal_code}</p>*/}
                    {/*            <p>{this.state.userDetails.orgAddress.country}</p>*/}
                    {/*        </CardBody>}*/}
                    {/*    </Card>}*/}
                    {/*</div> : null}*/}
                    <div className={'detail-content'}>
                        <Card>
                            <CardHeader>Notifications</CardHeader>
                            <CardBody>
                                {alertsCard}
                            </CardBody>
                        </Card>
                    </div>


                </div>
                {this.state.openAddressModal ?
                    <EditAddressModal item={this.props.item} open={this.state.openAddressModal}
                                      onClose={(reload) => {
                                          self.setState({openAddressModal: false}, self.setUserInfo());
                                      }}/> : ''}

            </div>
        );
    }
}

export default AccountDetails;

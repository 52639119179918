import {Button, ScheduleTimeline, Icon, StateOptions} from "gd-react";
import React from "react";
import "../Rules.scss";


class RulePageAdvanced extends React.Component {
    constructor(props) {
        super(props);
        this.renderAction = this.renderAction.bind(this);
    }

    renderAction(action) {
        
        let unit;
        let dT;
        let dataType;
        let factor = 1;
        StateOptions.forEach(function (stateOption) {
            stateOption.dataTypes.forEach(function (dataType) {
                if (dataType.type === action.dataType) {
                    dT = dataType;
                }
            })
        })
        if (dT) {
            dataType = dT.label;
            unit = dT.unit;
            factor = dT.factor ? dT.factor : 1;
        }
        
        let elements = [];
        if (action.isAlert) {
            elements.push((<p className={'action-type'}>Action
                type: <span>{action.type === 'state' ? 'State Change' : action.type === 'boost' ? 'Boost' : action.type === 'snooze' ? 'Snooze' : action.type === 'email' ? 'Email notification' : 'Unknown'}</span>
            </p>));
            if (action.type === 'state') {
                elements.push((
                    <p className={'action-type'}>State: <span>{action.stateType === 'ON' ? 'On' : action.stateType === 'OFF' ? 'Off' : 'Unknown'}</span>
                    </p>))
            }
            if (action.duration && (action.type === 'boost' || action.type === 'snooze')) {
                elements.push((<p className={'action-type'}>Duration: <span>{action.duration}s</span></p>))
            }
            if (action.assets && (action.type === 'state' || action.type === 'boost' || action.type === 'snooze')) {
                elements.push((<p className={'action-type'}>Devices: <span>{action.assetNames}</span></p>))
            }
            if (action.recipients && (action.type === 'email')) {
                elements.push((<p className={'action-type'}>Recipients: <span>{action.recipientNames}</span></p>))
            }
        } else {
            if (!action.instantaneous) {
                elements.push((<p className={'action-type'}>Persistent triggering action</p>))
            } else {
                elements.push((<p className={'action-type'}>Event-triggering action</p>))
            }
            elements.push((<p className={'action-type'}>Data Type: <span>{dataType}</span></p>))
            elements.push((<p className={'action-type'}>Devices: <span>{action.assetNames}</span></p>))
            elements.push((<p className={'action-type'}>Comparator: <span>{action.thresholdType}</span></p>))
            elements.push((<p className={'action-type'}>Threshold: <span>{action.threshold * factor}{unit}</span></p>))
            if (action.thresholdExit) {
                elements.push((<p className={'action-type'}>Exit threshold: <span>{action.thresholdExit * factor}{unit}</span></p>))
            }
            if (action.activeAction !== -1) {
                elements.push((
                    <p className={'action-type'}>
                        Active action: <span>{this.props.rule.actions.find(a => a.id === action.activeAction).name}</span>
                        {action.checking ? (<Icon color={action.active === true ? 'gd-green' : 'gd-grey'}
                                                  icon={action.active === true ? 'FaCheck' : 'FaTimes'}/>) : ''}
                    </p>))
            }
            if (action.inactiveAction !== -1) {
                elements.push((
                    <p className={'action-type'}>
                        Inactive action: <span>{this.props.rule.actions.find(a => a.id === action.inactiveAction).name}</span>
                        {action.checking ? (<Icon color={action.active === false ? 'gd-green' : 'gd-grey'}
                                                  icon={action.active === false ? 'FaCheck' : 'FaTimes'}/>) : ''}
                    </p>))
            }
            if (action.nullAction && this.props.rule.actions.find(a => a.id === action.nullAction)) {
                elements.push((
                    <p className={'action-type'}>
                        No data action: <span>{this.props.rule.actions.find(a => a.id === action.nullAction).name}</span>
                        {action.checking ? (<Icon color={action.active === null ? 'gd-green' : 'gd-grey'}
                                                  icon={action.active === null ? 'FaCheck' : 'FaTimes'}/>) : ''}
                    </p>))
            }
        }
        return elements;
    }

    render() {
        let ruleData;
        let self = this;
        if (this.props.rule) {
            ruleData = [
                // (<p className={'title'}>{this.props.rule.name}</p>),
                (<p className={'description'}>{this.props.rule.description}</p>),
            ];
            this.props.rule.actions.sort((a, b) => a.id - b.id).forEach(function (action) {
                if (action.id === 0) {
                    ruleData.push(
                        (<div className={'action-name'}><h4>Main Rule</h4></div>)
                    )
                    if (action.isSchedule) {
                        ruleData.push(
                            (<ScheduleTimeline
                                rules={
                                    action.schedules
                                }
                                actions={self.props.rule.actions}
                                inactiveState={action.inactiveAction}/>)
                        )
                    } else {
                        ruleData = ruleData.concat(self.renderAction(action));
                    }
                } else {
                    ruleData.push(
                        (<div className={'action-name'}>
                            <div className={'indicator'} style={{backgroundColor: action.color}}/>
                            <h4>Action {action.name}</h4>
                            {action.checking ? (<Icon size={'18'} color='gd-green' icon='FaEye'/>) : ''}
                            {action.isAlert ? (<Icon size={'12'} color={action.active ? 'gd-green' : 'gd-grey'}
                                                     icon={action.active ? 'FaCheck' : 'FaTimes'}/>) : ''}
                        </div>)
                    )
                    ruleData = ruleData.concat(self.renderAction(action));
                }
            })
        }

        return (
            <div className={"details rule-page-advanced"}>
                <div className={'details-inner'} style={{'maxWidth': '650px'}}>
                    {ruleData}
                </div>
            </div>
        )
    }
}

export default RulePageAdvanced;

import React from "react";
import "./CreateRule.scss";
import CreateRulePageSelectTemplate from "./CreateRulePageSelectTemplate";
import GridDuck from "gridduck";
import EditRulePageTimeswitch from "../edit-rule/EditRulePageTimeswitch";
import EditRulePageAlert from "../edit-rule/EditRulePageAlert";
import {
	StateOptions
} from "gd-react";
import history from "../../meta/history";
import EditRulePageThermostat from "../edit-rule/EditRulePageThermostat";
import EditRulePageAdvanced from "../edit-rule/EditRulePageAdvanced";

class CreateRulePage extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			allowAdvancedRules: false
		};
		this.updateRuleInProgress = this.updateRuleInProgress.bind(this);
		this.save = this.save.bind(this);
		let self = this;
		GridDuck.getAccount({id: GridDuck.userId}).then(function (account) {
			self.setState({
				allowAdvancedRules: account.features ? account.features.indexOf('ADVANCED_RULES') !== -1 : false
			});
		});
	}

	componentDidMount() {
		if (this.props && this.props.match) {
			let {template} = this.props.match.params;

			if (template) {
				this.updateRuleInProgress({
					template: template === undefined ? null : template
				});
			}
		}
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if (this.props && this.props.match) {
			let newTemplate = this.props.match.params.template;
			let oldTemplate = prevProps.match.params.template;

			if (newTemplate !== oldTemplate) {
				this.updateRuleInProgress({
					template: newTemplate === undefined ? null : newTemplate
				});
			}
		}

	}

	updateRuleInProgress(updateObj) {
		if (updateObj.template !== undefined) {
			let alerts = [];
			let triggers = [];
			if (updateObj.template === 'timeswitch') {
				triggers = [{
					details: {
						monday: false,
						tuesday: false,
						wednesday: false,
						thursday: false,
						friday: false,
						saturday: false,
						sunday: false,
						state: StateOptions.map(s => s.options).flat()[1].state
			}
				}];
				alerts = [{
					type: 'ON',
					assets: []
				}]
			}
			if (updateObj.template === 'alert') {
				triggers = [{
					assets: [],
					dataType: 'CONSUMPTION',
					thresholdType: 'above',
					instantaneous: true,
					requireAll: true
				}];
				alerts = [{
					type: 'EMAIL'
				}]
			}
			if (updateObj.template === 'thermostat') {
				triggers = [{
					assets: [],
					dataType: 'TEMPERATURE',
					thresholdType: 'below',
					instantaneous: true,
					requireAll: true,
					isSymmetric: true
				}];
				alerts = [{
					type: 'STATE_CHANGE',
					assets: [],
					state: 'ON',
					defaultState: 'OFF'
				}]
			}
			let rule = {
				template: updateObj.template,
				alerts: alerts,
				triggers: triggers,
				creating: true
			};
			if (updateObj.template === 'alert') {
				rule.persistent = true;
			}
			if (updateObj.template === 'thermostat') {
				rule.persistent = true;
			}
			if (updateObj.template === 'timeswitch') {
				rule.inactiveState = StateOptions.map(s => s.options).flat()[0].state
			}

			this.setState({rule: rule});
		}
	}

	save(rule) {
		return GridDuck.createRule(rule).then(function (createdRule) {
			history.push("/rule/" + createdRule.id);
			return Promise.resolve();
		});
	}

	render() {
		let template = this.state.template;
		let Component;// = CreateRulePageAdvanced;
		if (!template) {
			Component = CreateRulePageSelectTemplate;
		} else if (template === 'timeswitch') {
			Component = EditRulePageTimeswitch;
		} else if (template === 'alert') {
			Component = EditRulePageAlert;
		} else if (template === 'thermostat') {
			Component = EditRulePageThermostat;
		} else if (template === 'advanced') {
			Component = EditRulePageAdvanced;
		}
		return (<div className={"rule"}>
			<Component updateRuleInProgress={this.updateRuleInProgress} rule={this.state} save={this.save}/>
		</div>);
	}
}

export default CreateRulePage;
import GridDuck from 'gridduck';

const checkFeature = async function (featureId, _account) {
    let account = _account || await GridDuck.getAccount({id: GridDuck.userId});
    const subTierGlobalFeature = account.globalFeatures ? account.globalFeatures.find(gf => gf.id === 'DASHBOARD_TIERS') : null;
    if (subTierGlobalFeature && subTierGlobalFeature.disabled || account.subPaymentExempt) return true;
    const tier = account.allTierFeatures.find(t => t.id === account.dashboardTier);
    const featureInTier = tier.features.find(taf => taf.id === featureId);
    if (!featureInTier) return false;
    if (featureInTier && !featureInTier.cap) return true;
    if (featureId === 'SITE_GROUPS') return (account.siteGroupsCount - 1) < featureInTier.cap ? featureId : false;
    if (featureId === 'DEVICE_GROUPS') {
        const deviceGroups = await GridDuck.getGroups({items: 1});
        return deviceGroups.total < featureInTier.cap ? featureId : false;
    }
    if (featureId === 'ALERTS') {
        const alerts = await GridDuck.getRules({items: 1, filters: [
                {
                    field: 'alerts',
                    value: true
                }
            ]});
        return alerts.total < featureInTier.cap ? featureId : false;
    }
    if (featureId === 'MEMBERS') {
        const members = await GridDuck.getOrganisationMembers({items: 1});
        return members.total < featureInTier.cap ? featureId : false;
    }
    if (featureId === 'RECURRING_REPORTS') {
        const reports = await GridDuck.getReports({items: 1});
        return reports.total < featureInTier.cap ? featureId : false;
    }
    if (featureId === 'DATA_DOWNLOADS') {
        const dataDownloads = await GridDuck.getDataRequests({items: 1});
        return dataDownloads.total < featureInTier.cap ? featureId : false;
    }
    if (featureId === 'TARIFFS') {
        const dataDownloads = await GridDuck.getTariffs({items: 1});
        return dataDownloads.total < featureInTier.cap ? featureId : false;
    }
    if (featureId === 'CUSTOM_FIELDS') {
        const customFields = await GridDuck.getCustomFields({items: 1});
        return customFields.total < featureInTier.cap ? featureId : false;
    }
    if (featureId === 'CUSTOM_DEVICE_CATEGORIES') {
        const customFields = await GridDuck.getDeviceCategorys({items: 1, filters: [
                {
                    field: 'customOnly',
                    value: true
                }
            ]});
        return customFields.total < featureInTier.cap ? featureId : false;
    }
}

export default checkFeature;
import React from 'react';
import GridDuck from 'gridduck'
import './MainProductListPage.scss';
import {Button, Dropdown, GdAutocomplete, Icon, Input} from "gd-react";
import * as _ from 'underscore'
import GenericLoader from "../../components/GenericLoader";
import ProductList from "./ProductList";
import getFormat from "../../services/formatter";
import OrderCheckoutPage from "./OrderCheckoutPage";
import cy from "react-timeago/lib/language-strings/cy";

class MainProductListPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            itemsInBasket: {},
            categoryClosed: {},
            session: {},
            checkingOut: false,
            currency: 'gbp',
            update: 0,
            vat: 'yes',
            discount: ''
        };
        this.currencies = [{
            id: 1, title: '£', value: 'gbp'
        }, {id: 2, title: '€', value: 'eur'}, {id: 3, title: '$', value: 'usd'}];
        this.editBasket = this.editBasket.bind(this);
        this.checkout = this.checkout.bind(this);
        this.emptyCart = this.emptyCart.bind(this);
        this.selectOrg = this.selectOrg.bind(this);
        this.searchOrg = this.searchOrg.bind(this);
        this.resetPriceData = this.resetPriceData.bind(this);
    }

    emptyCart(reload) {
        let self = this;
        this.session.delete().then(function () {
            let orderDetails = {
                itemsInBasket: {},
                totalInBasket: 0,
                session: {},
                checkingOut: false,
                totalPhysicalCost: null,
                totalSubscriptionCost: null
            };

            self.setState(orderDetails);
            // if (reload) window.location.reload();
        });
    }

    searchOrg(string) {
        let self = this;
        return GridDuck.getOrganisationManagements({
            filters: [{
                field: 'query', value: string
            }]
        }).then(function (results) {

            if (!self.state.orgs) self.setState({orgs: results.list});
            let fixedResults = results.list.map(function (r) {
                
                
                return {
                    id: r.id, title: r.name, subTitle: r.free, icon: 'GoPerson'
                }
            }).filter((r) => r.id && r.title)

            return Promise.resolve(fixedResults)
        });
    }

    checkout() {
        this.setState({checkingOut: true});
        // history.push('/order-hardware/checkout/' + this.state.session.id);
    }

    async editBasket(product, number, addingRemoving, firstTime) {
        let self = this;

        this.state.itemsInBasket[product.id] = this.state.itemsInBasket[product.id] ? this.state.itemsInBasket[product.id] : 0;
        if (addingRemoving) {
            this.state.itemsInBasket[product.id] += number;
            if (this.state.itemsInBasket[product.id] === 0) this.state.itemsInBasket[product.id] = null;
        } else {
            this.state.itemsInBasket[product.id] = number;
            if (!number && number !== 0) this.state.itemsInBasket[product.id] = '';
        }
        let totalItemsInBasket = 0;
        for (const [key, value] of Object.entries(this.state.itemsInBasket)) {
            totalItemsInBasket += value;
        }

        let physicalItems = [];
        let subscriptionItems = [];
        let totalPhysicalCost = 0;
        let totalSubscriptionCost = 0;

        for (const [key, value] of Object.entries(this.state.itemsInBasket)) {
            let product = _.find(this.state.unGroupedProducts, (p) => p.id === key)
            if (value) {
                if (product.selectedOneTimePrice) {
                    totalPhysicalCost += (product.prices.find((price) => price.id === product.selectedOneTimePrice).unitAmount * value);
                    physicalItems.push({
                        product: key,
                        price: product.selectedOneTimePrice,
                        quantity: value
                    });
                }
                if (product.selectedRecurringPrice) {
                    totalSubscriptionCost += (product.prices.find((price) => price.id === product.selectedRecurringPrice).unitAmount * value);
                    subscriptionItems.push({
                        product: key,
                        price: product.selectedRecurringPrice,
                        quantity: value
                    });
                }
            }
        }

        let session = {
            id: this.state.session ? this.state.session.id : null,
            cancelled: this.state.session ? this.state.session.cancelled : null,
            completed: this.state.session ? this.state.session.completed : null,
            physicalItems: physicalItems,
            subscriptionItems: subscriptionItems
        };

        if (!firstTime) {
            if (!self.session || !self.session.id) {
                let createNewSession = await GridDuck.createSession({
                    physicalItems: physicalItems, subscriptionItems: subscriptionItems
                })
                let newSession = await GridDuck.getSession({id: createNewSession.id})
                self.session = newSession;
                session = newSession;

            } else {
                await self.session.set({
                    physicalItems: physicalItems, subscriptionItems: subscriptionItems
                })
            }
        }

        let orderDetails = {
            itemsInBasket: this.state.itemsInBasket,
            totalInBasket: totalItemsInBasket,
            session: session,
            totalPhysicalCost: totalPhysicalCost,
            totalSubscriptionCost: totalSubscriptionCost
        };

        this.setState(orderDetails);
    }

    convertKeyToTitle(key) {
        switch (key) {
            case 'hub':
                return 'Hubs'
            case 'hubsub':
                return 'Hubs are required to connect the devices to the GridDuck cloud. One hub can connect to up to 30 devices each.'
            case 'monitoring_only':
                return 'Monitoring Only'
            case 'monitoring_and_control':
                return 'Monitoring and Control'
            case 'shipping':
                return 'Shipping'
            case 'sensor':
                return 'Sensors'
        }
    }

    componentDidMount() {
        let self = this;

        Promise.all([GridDuck.getProducts({getAll: true}), GridDuck.getSessions({getAll: true}), GridDuck.getDiscounts({getAll: true})]).then((res) => {


            let product_list = res[0].list, session_list = res[1].list, discounts = res[2].list;
            // GridDuck.getProducts({getAll: true}).then((products) => {
            let productsGroupedByType = _.groupBy(product_list, (p) => p.metadata.type);
            // GridDuck.getSessions({getAll: true}).then(function (sessions) {

            if (session_list && session_list.length) {
                GridDuck.getSession({id: session_list[0].id}).then(function (session) {
                    self.session = session;
                    self.setState({
                        products: productsGroupedByType,
                        loaded: true,
                        unGroupedProducts: product_list,
                        discounts: discounts,
                        session: session_list[0]
                    }, function () {
                    })
                })
            } else {
                self.setState({
                    products: productsGroupedByType, discounts: discounts, unGroupedProducts: product_list, loaded: true
                });
            }
        })
    }

    resetPriceData() {
        for (const [key, value] of Object.entries(this.state.products)) {
            value.forEach((v) => {
                v.selectedOneTimePrice = null;
                v.selectedRecurringPrice = null;
            })
        }
        this.setState({update: this.state.update++});
    }

    calculateDiscounts() {
        let hardware_discount = 0, subscription_discount = 0;
        let self = this;



        //If discount exists has applies_to
        if (this.state.discount && Object.values(this.state.itemsInBasket).length) {
            let discount = this.state.discounts.find(d => d.id === this.state.discount);
            let percentage_off = discount.percentOff;

            if (discount.appliesTo.length) {
                Object.entries(this.state.itemsInBasket).forEach((iib) => {

                    let prod_id = iib[0], prod_quantity = iib[1], prod_obj, hardware_price_obj, subscription_price_obj;
                    if (discount.appliesTo.find((dat) => dat === prod_id)) {
                        Object.values(self.state.products).forEach((p) => {

                            if (!prod_obj) prod_obj = p.find((pro) => pro.id === prod_id);

                        });

                        hardware_price_obj = prod_obj.prices.find((pr) => pr.id === prod_obj.selectedOneTimePrice);
                        subscription_price_obj = prod_obj.prices.find((pr) => pr.id === prod_obj.selectedRecurringPrice);

                        hardware_discount += ((hardware_price_obj.unitAmount * prod_quantity) / 100) * percentage_off;
                        subscription_discount += ((subscription_price_obj.unitAmount * prod_quantity) / 100) * percentage_off;
                    }

                });
            } else {


                hardware_discount = (this.state.totalPhysicalCost / 100) * percentage_off;
                subscription_discount = (this.state.totalSubscriptionCost / 100) * percentage_off;
            }
        }


        return {
            hardware: hardware_discount,
            subscription: subscription_discount
        };
    }

    async selectOrg(item, other) {
        this.resetPriceData();
        if (other && other.id) {
            this.setState({loadingOrg: true, selectedOrg: {}});
            let allOrgData = await GridDuck.getOrganisationManagement({id: other.id})
            this.setState({loadingOrg: false});

            this.setState({
                selectedOrg: allOrgData,
                discount: allOrgData.free ? "z1TFvqZz" : null,
                currency: allOrgData.currency || 'gbp'
            });
        } else {
            this.setState({
                selectedOrg: {}, discount: null,
            });
        }
    }

    render() {
        //TODO Run function to calculate discounts here
        let self = this;
        let productDoms = [];

        let discount = this.calculateDiscounts();


        let currency_obj = this.currencies.find(c => this.state.currency === c.value);
        let currency_symbol = currency_obj ? currency_obj.title : '';

        if (this.state.products) {
            for (const [key, value] of Object.entries(this.state.products)) {
                productDoms.push(<div className={'products-wrapper ' + (self.state.categoryClosed[key] ? 'closed' : '')}
                                      key={key}>
                    <div onClick={() => {
                        self.state.categoryClosed[key] = !self.state.categoryClosed[key];
                        this.setState({categoryClosed: self.state.categoryClosed})
                    }} className={'category-title'}><span className={'chevron'}><Icon size={13}
                                                                                      icon={'FaChevron' + (!self.state.categoryClosed[key] ? 'Down' : 'Up')}/></span>{this.convertKeyToTitle(key)}
                    </div>
                    {key === 'hub' && !self.state.categoryClosed[key] ?
                        <p className={'sub-title-cat'}>{this.convertKeyToTitle(key + 'sub')}</p> : ''}
                    <div className={'products'}>
                        {value.map((product) => {
                            let one_time_price_items = product.prices.filter((price) => price.type === 'one_time' && price.currency === self.state.currency && price.nickname).map((pr, i) => {
                                return {
                                    id: pr.id,
                                    title: (currency_symbol) + (pr.unitAmount / 100) + ' (' + (pr.nickname) + ')',
                                    value: pr.id
                                }
                            });
                            let recurring_price_items = product.prices.filter((price) => price.type === 'recurring' && price.currency === self.state.currency && price.nickname).map((pr) => {
                                return {
                                    id: pr.id,
                                    title: (currency_symbol) + (pr.unitAmount / 100) + ' per ' + pr.recurring.interval + ' (' + (pr.nickname) + ')',
                                    value: pr.id
                                }
                            });
                            if (!product.selectedOneTimePrice) {
                                product.selectedOneTimePrice = one_time_price_items[0] ? one_time_price_items[0].id : '';
                            }
                            if (!product.selectedRecurringPrice) {
                                product.selectedRecurringPrice = recurring_price_items[0] ? recurring_price_items[0].id : '';
                            }
                            return <div className={'product'} key={product.id}>
                                <div className={'image'}
                                     style={{backgroundImage: 'url("' + product.images[0] + '")'}}/>
                                <p className={'name'}>{product.name}</p>

                                <div className={'row'} style={{marginBottom: '10px'}}>
                                    <Dropdown style={{minWidth: '100px'}} value={product.selectedOneTimePrice}
                                              label={'One time Price'}
                                              placeholder={'Select Price'}
                                              default={one_time_price_items[0]}
                                              disabled={this.state.itemsInBasket[product.id]}
                                              fixeditems={one_time_price_items}
                                              onChange={(val) => {

                                                  product.selectedOneTimePrice = val.target.value;
                                                  this.setState({update: self.state.update++})
                                              }}/>
                                </div>

                                <p style={{color: 'grey', fontSize: '10px'}}>{product.selectedOneTimePrice}</p>
                                {key !== 'shipping' ? <div className={'row'} style={{marginBottom: '10px'}}>

                                    <Dropdown style={{minWidth: '100px'}} value={product.selectedRecurringPrice}
                                              label={'Recurring Price'}
                                              placeholder={'Select Price'}
                                              disabled={this.state.itemsInBasket[product.id]}
                                              fixeditems={recurring_price_items}
                                              onChange={(val) => {

                                                  product.selectedRecurringPrice = val.target.value;
                                                  this.setState({update: self.state.update++})
                                              }}/>
                                </div> : null}
                                <p style={{color: 'grey', fontSize: '10px'}}>{product.selectedRecurringPrice}</p>
                                <div className={'divider'}/>
                                <p className={'description'}>{product.description}</p>
                                <span style={{display: 'flex', flex: 1, marginTop: '20px'}}/>
                                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>

                                    {!this.state.itemsInBasket[product.id] && this.state.itemsInBasket[product.id] !== '' &&
                                        <Button
                                            disabled={(key !== 'shipping' && (!product.selectedRecurringPrice || !product.selectedOneTimePrice)) || (key === 'shipping' && !product.selectedOneTimePrice)}
                                            additionalclasses={'sm'}
                                            label={'+ Add'}
                                            onClick={() => self.editBasket(product, 1, true)}/>}
                                    {(!!this.state.itemsInBasket[product.id] || this.state.itemsInBasket[product.id] === '') &&
                                        <div className={'add-remove-items'}>
                                            <Icon size={12} icon={'FaMinus'}
                                                  onIconClick={() => self.editBasket(product, -1, true)}/>
                                            <Input InputProps={{step: 1, min: 0}} onBlur={(e) => {
                                                if (!e.target.value) self.editBasket(product, 0, false)
                                            }} type={'number'} value={this.state.itemsInBasket[product.id]}
                                                   onChange={(e) => self.editBasket(product, parseInt(e.target.value), false)}/>
                                            <Icon size={12} icon={'FaPlus'}
                                                  onIconClick={() => self.editBasket(product, 1, true)}/>
                                        </div>}
                                </div>
                            </div>
                        })}
                    </div>
                </div>)
            }
        }

        return <div className={'mega-prod-wrapper'} style={{overflow: 'hidden'}}>
            {/*<p className={"notice"}>*/}
            {/*    <span>Not sure what you need?</span><br/>*/}
            {/*    Book in a meeting with Miles <a className={'link'}*/}
            {/*                                    href={'https://calendly.com/miles-gridduck?embed_domain=www.gridduck.com'}*/}
            {/*                                    target={'_blank'}>here</a> , email us at <a*/}
            {/*    href={'mailto:support@gridduck.com'}*/}
            {/*    className={'link'}>support@gridduck.com</a> or give us a call at +44*/}
            {/*    (0)7305 002548*/}
            {/*</p>*/}
            <div style={{display: 'flex', borderBottom: '1px solid lightgrey', alignItems: 'center'}}>
                {this.state.loaded ? <div style={{
                    padding: '10px 30px', paddingRight: '10px', minWidth: '300px'
                }}>
                    <GdAutocomplete
                        autoFocus
                        async
                        label={'Organisation'}
                        onChange={this.selectOrg}
                        getList={this.searchOrg}
                        placeholder={'Select Organisation'}/>
                </div> : null}
                {this.state.selectedOrg ? <div style={{
                    padding: '10px 30px', paddingLeft: '0', paddingRight: '10px', minWidth: '100px'
                }}>
                    <Dropdown placeholder={'Select Currency'} value={this.state.currency}
                              disabled={this.state.selectedOrg.currency || (this.state.itemsInBasket && Object.values(this.state.itemsInBasket).length)}
                              label={'Currency'} fixeditems={self.currencies}
                              onChange={(val) => {
                                  this.resetPriceData();
                                  this.setState({currency: val.target.value})
                              }}/>
                    {this.state.selectedOrg.currency || (this.state.itemsInBasket && this.state.itemsInBasket.length) ?
                        <p style={{color: 'grey', fontSize: '10px', marginBottom: 0}}>Fixed by org currency</p> : null}
                </div> : null}
                {this.state.selectedOrg && !this.state.selectedOrg.free ? <div style={{
                    padding: '10px 30px', paddingLeft: '0', paddingRight: '10px', minWidth: '100px'
                }}>
                    <Dropdown placeholder={'Include VAT'} value={this.state.vat}
                              label={'Include VAT'} fixeditems={[{
                        id: 1, title: 'Yes', value: 'yes'
                    }, {id: 2, title: 'No', value: 'no'}]}
                              onChange={(val) => {
                                  this.setState({vat: val.target.value})
                              }}/>
                </div> : null}
                {this.state.selectedOrg ? <div style={{
                    padding: '10px 30px', paddingLeft: '0', minWidth: '100px'
                }}>
                    <Dropdown placeholder={'None'} disabled={this.state.selectedOrg.free} value={this.state.discount}
                              label={'Discount'} fixeditems={[{
                        id: null, title: 'None', value: null
                    }].concat(this.state.discounts.map((d) => {
                        return {
                            id: d.id, title: d.name + ' (' + d.percentOff + '%)', value: d.id
                        }
                    }))}
                              onChange={(val) => {
                                  this.setState({discount: val.target.value})
                              }}/>
                </div> : null}
            </div>
            {this.state.checkingOut && this.state.session && this.state.session.id ?

                <OrderCheckoutPage currencySymbol={currency_symbol} discountId={this.state.discount} discount={discount} vat={this.state.vat} selectedOrganisation={this.state.selectedOrg}
                                   currency={this.state.currency}
                                   reloadSession={() => {
                                       this.setState({checkingOut: false});
                                       this.emptyCart(true);
                                   }} editItems={() => this.setState({checkingOut: false})}
                                   sessionId={this.state.session.id}/>

                : null}

            {!this.state.checkingOut ? <div className={'row'} style={{overflow: 'hidden'}}>
                <div className={'page prdks'}>
                    <div className={'products-inner-page'}>
                        {this.state.loaded && this.state.products && this.state.currency && this.state.selectedOrg && productDoms}
                    </div>
                    {!this.state.loaded &&
                        <div style={{display: 'flex', flex: '1 1', justifyContent: 'center', alignItems: 'center'}}>
                            <GenericLoader text={'Fetching'} textLineTwo={'GridDuck Products...'}/></div>}
                </div>
                {this.state.totalInBasket ? <div style={{
                    backgroundColor: 'white',
                    maxWidth: '400px',
                    overflow: 'hidden',
                    display: 'flex',
                    flex: '1 1',
                    flexDirection: 'column',
                    borderLeft: '1px solid #ececec'
                }}>
                    <div className={'checkout-bar'} style={{borderTop: '0', borderBottom: '1px solid #ececec'}}>
                        <Button onClick={this.emptyCart} color={'gd-grey'}
                                label={'Empty Cart'}/>
                    </div>
                    <ProductList products={this.state.unGroupedProducts} session={this.state.session}/>

                    <div className={'checkout-bar totals'}>
                        <p>Total<br/>{this.state.discount ? '- Discount' : ''}<br/>{this.state.vat === 'yes' ? '+ VAT' : ''}
                        </p>
                        <p>{currency_symbol}{getFormat('comma', true)((((this.state.totalPhysicalCost - discount.hardware) * (this.state.vat === 'yes' ? 1.2 : 1)) / 100).toFixed(2))}<span><br/>one time</span>
                        </p>
                        <p>{currency_symbol}{getFormat('comma', true)((((this.state.totalSubscriptionCost - discount.subscription) * (this.state.vat === 'yes' ? 1.2 : 1)) / 100).toFixed(2))}<span><br/>per month</span>
                        </p>
                    </div>
                    <div className={'checkout-bar'}>
                        <Button disabled={!this.state.currency || !this.state.selectedOrg} onClick={this.checkout}
                                color={'gd-green'}
                                label={'Checkout'}/>
                    </div>
                </div> : null}
            </div> : null}

        </div>
    }
}

export default MainProductListPage;
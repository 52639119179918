import React from "react";
import GridDuck from "gridduck";
import history from "../meta/history";
import {GdContentLoader, List, SiteGroup, StandardListCell} from "gd-react";

import AddSiteModal from "../modals/AddSite/AddSiteModal";
import NoItems from "../components/noItems";
import AddGroupModal from "../modals/AddGroup/AddGroupModal";
import EditLocationModal from "./LocationModal";

class LocationListPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.getList = this.getList.bind(this);
        this.openLocationModal = this.openLocationModal.bind(this);
    }

    openLocationModal(location) {
        
        history.push('/settings/organisation/locations?id=' + location.id);
        this.setState({showEditReportModal: location})
    }

    getList(retrievalParams) {
        return GridDuck.getLocations(retrievalParams);
    }

    componentDidMount() {
        let self = this;
        let urlParams = new URLSearchParams(window.location.search);
        let locationId = urlParams.get('id');
        if (locationId) {
            
            GridDuck.getLocations({filters: [{field: 'id', value: locationId}]}).then((location) => {
                
                self.setState({showEditReportModal: location.list[0]});
            })
        }
    }

    render() {
        return (
            <div className={"page white"}>
                <List
                    noitemelem={<NoItems text={'You haven\'t created any locations yet'}/>}
                    columns={[
                        {component: StandardListCell, bold: true, title: 'Nickname', field: 'name'},
                        {component: StandardListCell, title: 'Timezone', field: 'timezone'},
                        {
                            component: StandardListCell,
                            title: 'Address',
                            field: 'metadata.formatted_address',
                            custom: (object) => object.metadata ? object.metadata.formatted_address : '-'
                        },
                        {component: StandardListCell, title: 'Access Level', field: '_permission', maxWidth: 'none'}
                    ]}
                    getlist={this.getList}
                    rowOnClick={this.openLocationModal}/>
                {this.state.showEditReportModal ? <EditLocationModal onClose={() => {
                    this.setState({showEditReportModal: null});
                    history.push('/settings/organisation/locations');
                }} open={!!this.state.showEditReportModal} item={this.state.showEditReportModal}/> : null}
            </div>
        )
    }
}

export default LocationListPage;

import React from "react";
import {
    GdCheckbox,
    StateOptions,
    Button,
    Dropdown,
    EditableList,
    Icon,
    Input,
    StandardListCell, ScheduleTimeline, ScheduleSetter, Tooltip,
} from "gd-react";
import {Prompt} from "react-router-dom";
import "../Rules.scss";
import GridDuck from "gridduck";
import Modal from "react-modal";
import history from "../../meta/history";
import Toggle from "../../components/Toggle/Toggle";
import DataCondition from "../../components/AdvancedRule/DataCondition";
import RuleAction from "../../components/AdvancedRule/RuleAction";
import {createPortal} from "react-dom";

class EditRulePageAdvanced extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            customThrottleDuration: (props.rule.throttleDuration !== undefined && props.rule.throttleDuration !== 86400 && props.rule.throttleDuration !== 3600 && props.rule.throttleDuration !== 60),
            page: 1,

            //new stuff:
            shownRule: 0,

            rule: {
                name: props.rule.name,
                persistent: props.rule.persistent,
                description: props.rule.description,
                id: props.rule.id,
                throttleDuration: props.rule.throttleDuration,
                actions: JSON.parse(JSON.stringify(props.rule.actions ? props.rule.actions : [{
                    id: 0,
                    inactiveAction: -1
                }])),
                creating: props.rule.creating
            }
        };
        let self = this;
        this.blockNext = this.blockNext.bind(this);

        this.hasChanged = this.hasChanged.bind(this);
        this.save = this.save.bind(this);
    }

    async save() {
        let self = this, newRule;
        if (this.props.rule.creating) {
            let newRuleProps = this.state.rule;
            newRuleProps.actions = this.state.rule.actions;
            
            newRule = await GridDuck.createRule(newRuleProps)
            let rule = self.state.rule;
            rule.id = newRule.id;
            self.setState({rule: rule});
        } else {
            await this.props.rule.set(this.state.rule);
        }
        if (this.props.onSave) this.props.onSave(newRule);
        return Promise.resolve();
    }

    hasChanged() {
        if (this.state.saved) {
            return false;
        }
        if (this.props.rule.creating) {
            return false;
        }
        let original = JSON.stringify({
            name: this.props.rule.name,
            persistent: this.props.rule.persistent,
            description: this.props.rule.description,
            id: this.props.rule.id,
            throttleDuration: this.props.rule.throttleDuration,
            alerts: JSON.parse(JSON.stringify(this.props.rule.alerts)),
            triggers: JSON.parse(JSON.stringify(this.props.rule.triggers)),
            actions: JSON.parse(JSON.stringify(this.props.rule.actions)),
            creating: this.props.rule.creating
        });
        return (original !== JSON.stringify(this.state.rule));
    }

    blockNext() {
        switch (this.state.page) {
            case 1:
                return this.state.rule.name === undefined || this.state.rule.name === null || this.state.rule.name === '';
            case 2:
                return false;
        }
        return false;
    }

    componentDidMount() {
        if (!this.drawerFooter) {
            this.drawerFooter = document.getElementById('drawer-footer');
            
            this.setState({updateRef: 1});
        }
    }

    render() {
        if (!this.drawerFooter) {
            this.drawerFooter = document.getElementById('drawer-footer');
            
        }
        let palette = [
            '#FFC6D9',
            '#FFE1C6',
            '#FFF7AE',
            '#48284A',
            '#916C80'
        ];
        let self = this;

        let saveButton;
        let content;
        let rightBar;
        switch (this.state.page) {
            case 1:
                content = (<div className={"details"}>
                        <div className={'details-inner'} style={{'maxWidth': '650px'}}>
                            <div className={'inner-header'}>
                                <p className={"title-row"}>
                                    Details
                                </p>
                                <p className={"help-row"}>
                                    First off some basic details to describe what the custom automation is for.
                                </p>
                            </div>
                            <div className={'inner-body'}>
                                <div className={"name"}>
                                    <Input label={'Name*'}
                                           ref={'name'}
                                           autoFocus
                                           value={this.state.rule.name}
                                           onChange={function (val) {
                                               let name = val.target.value;
                                               self.setState(prevState => ({
                                                   rule: {
                                                       ...prevState.rule,
                                                       name: name
                                                   }
                                               }))
                                           }}
                                    />
                                </div>
                                <div className={"description"}>
                                    <Input label={'Description'}
                                           ref={'description'}
                                           value={this.state.rule.description}
                                           multiline
                                           inputProps={{minRows: 3, maxRows: 4}}
                                           onChange={function (e) {
                                               let description = e.target.value;
                                               self.setState(prevState => ({
                                                   rule: {
                                                       ...prevState.rule,
                                                       description: description
                                                   }
                                               }))
                                           }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                break;
            case 2:
                let selectedAction;
                if (this.state.shownRule !== 0) {
                    selectedAction = this.state.rule.actions[this.state.shownRule];
                }

                let schedule = null;
                let outerRule;
                let actions = this.state.rule.actions.map(function (subRoutine, index) {
                    return (
                        <div className={'details-inner'}>
                            <p><strong>Action {(index + 1)}</strong></p>
                        </div>
                    );
                });
                let possibleActions = JSON.parse(JSON.stringify(this.state.rule.actions.filter((pA, i) => i !== this.state.shownRule)));
                possibleActions.push({
                    id: -1,
                    name: 'No Action',
                    color: '#cccccc'
                });
                if (self.state.rule.actions[0].isSchedule) {
                    schedule = (<div className={'details-inner'}>
                            <ScheduleTimeline
                                rules={
                                    self.state.rule.actions[0].schedules ? self.state.rule.actions[0].schedules : []
                                }
                                actions={possibleActions}
                                inactiveState={self.state.rule.actions[0].inactiveAction}/>
                            <ScheduleSetter
                                rules={
                                    self.state.rule.actions[0].schedules ? self.state.rule.actions[0].schedules : []
                                }
                                defaultState={-1}
                                actions={possibleActions}
                                onUpdate={function (rules) {
                                    self.state.rule.actions[0].schedules = rules;
                                    self.setState({rule: self.state.rule});
                                }}/>
                            <p>
                                <Dropdown
                                    label={'Inactive action'}
                                    onChange={function (e) {
                                        self.state.rule.actions[0].inactiveAction = e.target.value;
                                        self.setState({rule: self.state.rule});
                                    }}
                                    value={this.state.rule.actions[0].inactiveAction}
                                    fixeditems={possibleActions.map((a, id) => ({value: a.id, title: a.name}))}
                                /></p>
                        </div>
                    )
                } else {
                    outerRule = <DataCondition actions={possibleActions} action={this.state.rule.actions[0]} noName
                                               onChange={function (action) {
                                                   self.state.rule.actions[0] = action;
                                                   self.setState({rule: self.state.rule});
                                               }
                                               }/>
                }
                let activeRule;

                if (!selectedAction) {
                    activeRule = [
                        (<Toggle
                            onClick={function (val) {
                                self.state.rule.actions[0].isSchedule = val === 'true';
                                self.setState({rule: self.state.rule});
                            }}
                            inactivestatename={'Static time-based behaviour'}
                            activestatename={'Dynamic time-based behaviour'}
                            active={self.state.rule.actions[0].isSchedule ? 'true' : 'false'}
                        />),
                        (<br/>),
                        schedule,
                        outerRule
                    ];
                } else {
                    let activeRuleComponent;
                    if (selectedAction.isAlert) {
                        activeRuleComponent = (<RuleAction
                            action={selectedAction}
                            actions={this.state.rule.actions}
                            onChange={function (data) {
                                self.state.rule.actions[self.state.shownRule] = data;
                                self.setState({
                                    rule: self.state.rule
                                });
                            }}
                        />);
                    } else {
                        activeRuleComponent = (<DataCondition
                            action={selectedAction}
                            actions={possibleActions}
                            onChange={function (data) {
                                self.state.rule.actions[self.state.shownRule] = data;
                                self.setState({
                                    rule: self.state.rule
                                });
                            }}
                        />);
                    }
                    activeRule = [
                        <Toggle
                            onClick={function (val) {
                                selectedAction.isAlert = val === 'true';
                                self.setState({
                                    rule: self.state.rule
                                });
                            }}
                            inactivestatename={'Further condition checking'}
                            activestatename={'Implement action'}
                            active={selectedAction.isAlert ? 'true' : 'false'}/>,
                        activeRuleComponent
                    ]
                }
                content = (
                    <div className={"advanced-details"}>
                        <div className={'main-panel'}>
                            <div className={'details-inner'}>
                                <div className={"title-row"}>
                                    <div className={"text-column"}>
                                        <h1>{selectedAction ? selectedAction.name : 'Main Rule'}</h1>
                                    </div>
                                </div>
                                <br/>
                                {activeRule}
                            </div>
                        </div>
                    </div>)
                rightBar = (
                    <div className={'right-panel'}>
                        <div className={'actions-list'}>
                            {this.state.rule.actions.map(function (a, i) {
                                return (<div onClick={() => self.setState({shownRule: i})}
                                             className={'action ' + (self.state.shownRule === i ? 'active' : '') + (i === 0 ? ' main-rule' : '')}>
                                    {i !== 0 ? (<div className={'color'} style={{'backgroundColor': a.color}}/>) : ''}
                                    <p className={'label'}>{i === 0 ? 'Main Rule' : a.name}</p>
                                    <span style={{display: 'flex', flex: '1 1'}}/>
                                    {i !== 0 ? (
                                        <div className={'trash'}><Tooltip placement={'left'} label={'Delete Action'}
                                                                          title={'Delete Action'}><Icon color={'gd-red'}
                                                                                                        label={'Delete'}
                                                                                                        icon={'FaTrash'}
                                                                                                        size={'13'}
                                                                                                        onIconClick={function (e) {
                                                                                                            let actions = self.state.rule.actions;
                                                                                                            if (self.state.shownRule === i) {
                                                                                                                self.state.shownRule = 0;
                                                                                                            }
                                                                                                            actions.splice(i, 1);
                                                                                                            self.setState({
                                                                                                                rule: self.state.rule,
                                                                                                                shownRule: self.state.shownRule
                                                                                                            });
                                                                                                            e.stopPropagation();
                                                                                                        }
                                                                                                        }
                                        /></Tooltip></div>) : ''}
                                </div>)
                            })}
                            <div className={'new-wrapper'}>
                                <Button label={'+ New Action'} onClick={function () {
                                    let actions = self.state.rule.actions;
                                    actions.push({
                                        id: self.state.rule.actions.length,
                                        name: 'Unnamed Action',
                                        isAlert: true,
                                        color: palette[self.state.rule.actions.length % palette.length]
                                    });
                                    self.setState({rule: self.state.rule, shownRule: actions.length - 1});
                                }}/>
                            </div>
                        </div>
                    </div>
                )
                break;
        }
        // this.hasChanged()

        return (
            <div className={'advanced-wrapper'}>
                {/*<div className={"top-bar"}>*/}
                {/*    <Icon icon={'FaTimes'} color={'gd-grey'} size={'25'}*/}
                {/*          onIconClick={() => history.push(this.props.rule.creating ? '/rules/create' : ('/rule/' + this.state.rule.id))}/>*/}
                {/*    {saveButton}*/}
                {/*</div>*/}
                {rightBar}
                <div className={"inner-rule ad"}>
                    {content}
                    <Modal
                        isOpen={this.state.selectingAssets}
                        onRequestClose={this.closeAssetsModal}
                        contentLabel="Select Alert Devices">
                        <EditableList
                            isEditing={true}
                            key={1}
                            noCancel={false}
                            onCancelOnlyCallback={this.closeAssetsModal}
                            editingColumns={[
                                {component: StandardListCell, title: 'Site Name', field: 'siteName'},
                            ]}
                            availableFilters={this.state.assetListFilters}
                            getActiveList={this.getActiveList}
                            getAvailableList={this.getAvailableList}
                            save={this.setAssets}
                        />
                    </Modal>

                    {this.drawerFooter ? createPortal(<div
                        style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flex: '1 1'}}>
                        {this.state.rule.creating ?
                            <div style={{marginRight: '10px', display: 'flex'}}><Button label={"Start Again"} color={'gd-grey'} outline
                                                                                        onClick={this.props.restartTemplate}/>
                            </div> : null}
                        {!this.state.rule.creating && !this.hasChanged() ?
                            <Button label={"Cancel"} color={'gd-grey'} outline onClick={() => this.save({cancel: true})}/> : ''}
                        {!this.state.rule.creating && this.hasChanged() ?
                            <Button label={"Save and exit"} additionalclasses={'md'} color={'gd-green'} progressRes={true}
                                    onClick={this.save}/> : null}
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            {this.state.page === 1 ? <span/> : ''}
                            {this.state.page !== 1 ?
                                <div style={{marginRight: '10px'}}><Button label={"Back"} color={'gd-grey'}
                                                                           onClick={() => this.setState({page: this.state.page - 1})}/>
                                </div> : ''}
                            {this.state.page !== 2 ? <Button disabled={this.blockNext()} label={"Next"}
                                                             onClick={() => this.setState({page: this.state.page + 1})}/> : ''}
                            {this.state.page === 2 && this.state.rule.creating ?
                                <Button additionalclasses={'sm'} color={'gd-green'} label={"Save"}
                                        disabled={this.blockNext()}
                                        progressRes={true} onClick={this.save} /> : ''}
                        </div>
                    </div>, this.drawerFooter) : null}

                    {/*<Prompt*/}
                    {/*    when={this.hasChanged()}*/}
                    {/*    message="You have unsaved changes. Do you definitely want to leave?"*/}
                    {/*/>*/}
                </div>
            </div>
        )
    }
}

export default EditRulePageAdvanced;

function getLocale(currencyCode) {
    let currencyToLocale = {
        USD: "en-US",
        EUR: "de-DE",  // Germany (Euro)
        GBP: "en-GB",  // United Kingdom (Pound)
        NGN: "en-NG",
        JPY: "ja-JP",  // Japan (Yen)
        INR: "hi-IN",  // India (Rupee)
        AUD: "en-AU",  // Australia (Dollar)
        CAD: "en-CA",  // Canada (Dollar)
        CNY: "zh-CN",  // China (Yuan)
        BRL: "pt-BR",  // Brazil (Real)
    };
    return currencyToLocale[currencyCode] || undefined;
}

export {getLocale}

import React from "react";
import GridDuck from "gridduck";
import {
    Button, GdRadio, CardHeader,
    Card, EditableList, GdAutocomplete,
    Input, StandardListCell, CardBody, DateTimeRangePicker, Dropdown
} from "gd-react";
import {StateOptions} from "gd-react";

import Toggle from "../../components/Toggle/Toggle";
import {confirmAlert} from "react-confirm-alert";
import getFormat from "../../services/formatter";
import moment from "moment-timezone";

class ExistingResets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            start: moment().startOf('isoWeek').unix(),
            end: moment().unix(),
            dateSelection: 'week_so_far',
        };
        this.getList = this.getList.bind(this);
        this.getResets = this.getResets.bind(this);
        this.delete = this.delete.bind(this);
    }

    delete(reset) {
        let self = this;
        return reset.delete()
            .then(function() {
                self.getResets();
            })
    }

    getResets() {
        let self = this;
        return GridDuck.getAssetResets({
            filters: [
                {
                    field: 'assetId',
                    value: self.state.deviceId
                },
                {
                    field: 'start',
                    value: this.state.start
                },
                {
                    field: 'end',
                    value: this.state.end
                }
            ]
        })
            .then(function (resets) {
                self.setState({resets: resets.list}, self.renderGraph);
            }).catch(function (err) {
                
            })
    }

    setDateRange(rangeStr) {
        let start;
        let end;
        let granularity;
        switch (rangeStr) {
            case 'last_15_mins':
                start = moment().subtract(15, 'minutes').unix();
                end = moment().unix();
                granularity = 30;
                break;
            case 'today_so_far':
                start = moment().startOf('day').unix();
                end = moment().unix();
                granularity = 60 * 30;
                break;
            case 'yesterday':
                start = moment().subtract(1, 'day').startOf('day').unix()
                end = moment().startOf('day').unix();
                granularity = 60 * 30;
                break;
            case 'week_so_far':
                start = moment().startOf('isoWeek').unix();
                end = moment().unix();
                granularity = 60 * 60 * 24;
                break;
            case 'last_week':
                start = moment().subtract(7, 'day').startOf('isoWeek').unix();
                end = moment().startOf('isoWeek').unix();
                granularity = 60 * 60 * 24;
                break;
            case 'month_so_far':
                start = moment().startOf('month').unix();
                end = moment().unix();
                granularity = 60 * 60 * 24;
                break;
            case 'last_month':
                start = moment().subtract(1, 'month').startOf('month').unix();
                end = moment().startOf('month').unix();
                granularity = 60 * 60 * 24;
                break;
            case 'year_so_far':
                start = moment().startOf('year').unix();
                end = moment().unix();
                granularity = 'monthly';
                break;
            case 'last_year':
                start = moment().subtract(1, 'year').startOf('year').unix();
                end = moment().startOf('year').unix();
                granularity = 'monthly';
                break;
        }
        this.setState({start: start, end: end, dateSelection: rangeStr});
    }

    getList(searchTerm) {
        return GridDuck.getAssetGods({
            filters: [{
                field: 'search',
                value: searchTerm
            }]
        }).then(function (siteList) {
            return Promise.resolve(
                siteList.list.map(
                    s => ({
                        id: s.id,
                        title: s.name + ' (' + s.siteName + ')'
                    })
                )
            );
        })
    }

    render() {
        let self = this;
        let dateSelectRange = [
            {
                value: 'today_so_far',
                title: 'Today so far'
            },
            {
                value: 'yesterday',
                title: 'Yesterday'
            },
            {
                value: 'week_so_far',
                title: 'Week So Far'
            },
            {
                value: 'last_week',
                title: 'Last Week'
            },
            {
                value: 'month_so_far',
                title: 'Month So Far'
            },
            {
                value: 'last_month',
                title: 'Last Month'
            },
            {
                value: 'year_so_far',
                title: 'Year So Far'
            },
            {
                value: 'last_year',
                title: 'Last Year'
            }
        ];
        let rawData;
        let resetSummary;
        if (this.state.resets) {
            let rawDatas = [];
            if (this.state.resets.length > 0) {
                this.state.resets.forEach(function (reset) {
                    rawDatas.push(
                        <tr className={'record-row'}>
                            <td>{getFormat('date')(reset.timestamp / 1000)}</td>
                            <td>{reset.value}</td>
                            <td><Button color={'gd-red'} label={'Delete'} onClick={() => self.delete(reset)}/></td>
                        </tr>
                    )
                });
                rawData = (
                    <table className={'result-table'}>
                        <tr className={'results-header'}>
                            <th>Timestamp</th>
                            <th>Value</th>
                            <th>Delete</th>
                        </tr>
                        {rawDatas}
                    </table>
                )
            } else {
                resetSummary = (
                    <p>No Resets Found</p>
                )
            }
        }
        return (
            <div className={"page narrow not-flex grey reset-page"}>
                <div className={'column top'}>
                    <div className={'detail-content row'}>
                        <div className={'detail-content column'} style={{'width': '300px'}}>
                            <GdAutocomplete
                                async
                                placeholder={'Device'}
                                value={this.state.deviceId}
                                onChange={(ev, item) => {
                                    if (item) {
                                        this.setState({deviceId: item.id});
                                    }
                                }}
                                getList={(searchTerm) => self.getList(searchTerm)}
                                label={"Device search"}
                            />
                            <br/>
                            <div className={'date-pick'}>
                                <Dropdown key={"dropdown-date"} nolabel='true'
                                          onChange={(e) => this.setDateRange(e.target.value)}
                                          fixeditems={dateSelectRange} value={this.state.dateSelection}/>
                            </div>
                            <br/>
                            <Button label={'Query Resets'} onClick={this.getResets} progressRes/>
                        </div>
                    </div>
                    <div className={'resets'}>
                        {resetSummary}
                        <div className={"data-summary"}>
                            {rawData}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default ExistingResets;
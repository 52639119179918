import React from 'react';
import './Account.scss';
import GridDuck from 'gridduck';
import '../../styles/_layout.scss';
import {Card, CardBody, CardHeader, Button, Input} from "gd-react";

class AccountSecurity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            mfaSetup: false,
            verificationToken: ''
        }
        this.setupMFA = this.setupMFA.bind(this);
        this.onVerificationTokenChange = this.onVerificationTokenChange.bind(this);
        this.verifyToken = this.verifyToken.bind(this);
        this.disableMFA = this.disableMFA.bind(this);
        let self = this;
        GridDuck.getAccount({id: GridDuck.userId})
            .then(function (account) {
                self.account = account;
                self.setState({ready: true, mfaEnabled: account.mfaEnabled});
            });
    }

    setupMFA() {
        let self = this;
        return GridDuck.createMfaSecret({})
            .then(function (mfaSecret) {
            	
                self.setState({mfaSetupImage: mfaSecret.secretUrl});
                return Promise.resolve();
            });
    }

    disableMFA() {
        let self = this;
        return self.account.setMfaEnabled(false);
    }

    onVerificationTokenChange(e) {
        this.setState({verificationToken: e.target.value});
    }

    verifyToken() {
        let self = this;
        self.setState({error: null});
        return GridDuck.createMfaVerification({token: this.state.verificationToken}).catch(function (err) {
            self.setState({error: "Incorrect verification token"});
            return Promise.reject();
        })
    }


    render() {
        let cardContent;
        if (this.state.ready) {
            if (this.state.mfaEnabled) {
                cardContent = (
                    <CardBody>
                        <p>Multi Factor Authentication is currently enabled on this account.<br/><br/></p>
                        <Button additionalclasses={'md'} label={"Disable MFA"} onClick={this.disableMFA}
                                progressRes
                                onComplete={() => this.setState({
                                    mfaEnabled: false
                                })}/>
                    </CardBody>
                );
            }
            if (!this.state.mfaSetup && !this.state.mfaEnabled) {
                cardContent = (
                    <CardBody>
                        <p>Multi Factor Authentication is not currently set up for this account.<br/><br/></p>
                        {!window.$exampleAccount ? <Button additionalclasses={'md'} label={"Set up MFA"} onClick={this.setupMFA} progressRes
                                onComplete={() => this.setState({
                                    mfaSetup: true, verificationToken: ''
                                })}/> : ''}
                    </CardBody>
                );
            }
            if (this.state.mfaSetup) {
                cardContent = (
                    <CardBody>
                        <p>Setup MFA by creating a new authentication profile with the QR code below on your preferred
                            MFA application, such as Google Authenticator.</p>
                        <img alt={'img'} style={{"width": "200px", "height": "200px"}} src={this.state.mfaSetupImage}/>
                        <p>Then verify that you have set it up correctly by entering in the current authentication code
                            from the app below:</p>
                        <Input name={'verificationToken'} onChange={this.onVerificationTokenChange}
                               value={this.state.verificationToken}
                               label={'Authentication Code'}/>
                        <div className={'row space-between'} style={{marginTop: '30px'}}>
                            <Button color={'gd-grey'} onClick={() => this.setState({mfaSetup: false})}
                                    label={'Cancel'}/>
                            <Button additionalclasses={'md'} onClick={this.verifyToken} label={'Verify Token'}
                                    progressRes
                                    onComplete={() => this.setState({mfaEnabled: true, mfaSetup: false})}/>
                        </div>

                        {this.state.error ? (<p className={'error-text'}>{this.state.error}</p>) : ''}
                    </CardBody>
                );
            }
        }
        return (
            <div className='page narrow Account not-flex grey'>
                <div className={'column top'}>
                    <div className={'detail-content'}>
                        <Card>
                            <CardHeader>Multi Factor Authentication</CardHeader>
                            {cardContent}
                        </Card>
                    </div>
                </div>
            </div>
        );
    }
}

export default AccountSecurity;

import React from 'react';
import GridDuck from 'gridduck';
import {DemandListCell, List, LastActiveCell, StandardListCell, StateListCell, DummyListCell, SensorDataListCell} from "gd-react";
import history from "../../meta/history";
import AddGroupModal from "../../modals/AddGroup/AddGroupModal";
import NoItems from "../../components/noItems";
import AddHubModal from "../../modals/AddHub/AddHubModal";
import AddManualDeviceModal from "../../modals/AddDevice/AddManualDeviceModal";
import GenericLoader from "../../components/GenericLoader";

class SiteAssetListPage extends React.Component {
    constructor(props) {
        super(props);
        let self = this;
        this.getList = this.getList.bind(this);
        this.state = {
            updateRef: 1
        };
        this.goToAsset = this.goToAsset.bind(this);
        GridDuck.getAccount({id: GridDuck.userId}).then(function (account) {
            self.setState({
                orgPermission: account.orgPermission,
            })
        });
    }

    getList(retrievalParams) {
        return GridDuck.getAssets(retrievalParams);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        let newSiteId = this.props.item.id;
        let oldSiteId = prevProps.item.id;
        if (newSiteId && oldSiteId && newSiteId !== oldSiteId) {
            this.setState({updateRef: this.state.updateRef++})
        }
    }


    goToAsset(asset) {
        history.push("/device/" + asset.id);
    }

    render() {
        let filters;
        
        if (this.props.filterType === 'site_id') {
            filters = [
                {
                    field: 'siteId',
                    value: this.props.item.id
                },
                {
                    field: 'locationId',
                    value: this.props.item.locationId
                },
                {
                    field: 'parentAsset',
                    value: null
                },
            ];
        } else if (this.props.filterType === 'site_group_id') {
            filters = [
                {
                    field: 'siteGroupId',
                    value: this.props.item.id
                }
            ];
            
        } else if (this.props.filterType === 'org_partner_id') {
            filters = [
                {
                    field: 'orgPartnerId',
                    value: this.props.item.id
                }
            ];
            
        } else if (this.props.filterType === 'asset_id' && this.props.type === 'hub') {
            
            filters = [
                {
                    field: 'gatewayId',
                    value: this.props.item.id
                }
            ]
        }
        let assets;
        let noDevicePage;
        if (!this.props.hubs.list || !this.props.hubs.list.length) {
            noDevicePage =
                <NoItems text={'"' + this.props.item.name + '" has no Hubs'} createtext={'Add Hub'} modal={AddHubModal}
                         disabled={this.state.orgPermission !== 'admin' && this.state.orgPermission !== 'owner'}/>
        }
        if (this.props.hubs.list.length && (!this.props.dataItems.list || !this.props.dataItems.list.length)) {
            noDevicePage = <NoItems text={'"' + this.props.item.name + '" has no Devices'} createtext={'Add Device'}
                                    modal={AddManualDeviceModal}
                                    disabled={this.state.orgPermission !== 'admin' && this.state.orgPermission !== 'owner'}/>
        }
        let {siteId} = this.props.match.params;
        if (this.props.item) {
            assets = (
                <List
                    noitemelem={noDevicePage}
                    columns={[
                        {component: StandardListCell, bold: true, sortable: true, title: 'Name', field: 'name'},
                        {
                            component: StateListCell,
                            sortable: false,
                            title: 'Status',
                            field: 'currentState',
                            showLabel: true,
                            permission: this.state.orgPermission,
                            maxWidth: '300px'
                        },
                        {component: LastActiveCell, title: 'Last Active', field: 'lastContacted'},
                        {component: SensorDataListCell, title: 'Data', field: 'temperature', maxWidth: 'none'},
                    ]}
                    rowOnClick={this.goToAsset}
                    getlist={this.getList}
                    parentField={"parentAsset"}
                    childrenField={"children"}
                    filterType={this.props.type}
                    expanded={true}
                    filters={filters}
                />
            )
        }
        return (
            <div className='page'>
                {this.props.loaded ? assets : <GenericLoader/>}
            </div>
        );
    }
}

export default SiteAssetListPage;

import React from "react";
import GridDuck from "gridduck";
import {
    Button,
    Card,
    Input
} from "gd-react";
import Toggle from "../../components/Toggle/Toggle";

class ReportsTest extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            site_ids: '',
            asset_ids: '',
            site_group_ids: '',
            frequency: 'true',
            report_id: '',
            include_mega_pdf: 'false',
            per_device: 'false',
            per_site: 'false'
        }
        this.testReports = this.testReports.bind(this);
        this.processArrayString = this.processArrayString.bind(this);
    }

    async testReports() {
        let site_ids = this.state.site_ids ? this.processArrayString(this.state.site_ids) : null,
            site_group_ids = this.state.site_group_ids ? this.processArrayString(this.state.site_group_ids) : null,
            asset_ids = this.state.asset_ids ? this.processArrayString(this.state.asset_ids) : null,
            name = this.state.name || null, frequency = this.state.frequency === 'true' ? 'weekly' : 'monthly',
            include_mega_pdf = this.state.include_mega_pdf === 'true',
            per_device = this.state.per_device === 'true',
            per_site = this.state.per_site === 'true',
            report_id = this.state.report_id;

        return await GridDuck.createCreateReport({
            all: true,
            id: null,
            testing: {
                site_ids: site_ids,
                site_group_ids: site_group_ids,
                asset_ids: asset_ids,
                name: name,
                frequency: frequency,
                include_mega_pdf: include_mega_pdf,
                per_device: per_device,
                per_site: per_site,
                report_id: report_id
            }
        });
    }

    processArrayString(ArrString) {

        return ArrString.split(',');
    }

    render() {
        return (
            <div className='page narrow Account not-flex grey'>
                <div className={'column top'}>
                    <div className={'detail-content'}>
                        <Card>
                            <div className={'row'}>
                                <Input label={'Name'}
                                       onChange={(e) => this.setState({name: e.target.value})}
                                       value={this.state.name}/>
                            </div>
                            <div className={'row'} style={{marginTop: '20px'}}>
                                <Toggle
                                    onClick={(e) => {
                                        this.setState({frequency: e})

                                    }}
                                    inactivestatename={'For Last Month'} activestatename={'For Last Week'}
                                    active={this.state.frequency}/>
                            </div>
                            <div className={'row'} style={{marginTop: '20px'}}>
                                <Toggle
                                    onClick={(e) => {
                                        this.setState({include_mega_pdf: e})

                                    }}
                                    inactivestatename={'Include Mega PDF'} activestatename={'Include Mega PDF'}
                                    active={this.state.include_mega_pdf}/>
                            </div>
                            <div className={'row'} style={{marginTop: '20px'}}>
                                <Toggle
                                    onClick={(e) => {
                                        this.setState({per_device: e})

                                    }}
                                    inactivestatename={'Per Device'} activestatename={'Per Device'}
                                    active={this.state.per_device}/>
                            </div>
                            <div className={'row'} style={{marginTop: '20px'}}>
                                <Toggle
                                    onClick={(e) => {
                                        this.setState({per_site: e})

                                    }}
                                    inactivestatename={'Per Site'} activestatename={'Per Site'}
                                    active={this.state.per_site}/>
                            </div>
                            <div className={'row'}>
                                <Input label={'Report ID'}
                                       onChange={(e) => this.setState({report_id: e.target.value})}
                                       value={this.state.report_id}/>
                            </div>
                            <div className={'row'}>
                                <Input label={'Site IDs (Comma separated)'}
                                       onChange={(e) => this.setState({site_ids: e.target.value})}
                                       value={this.state.site_ids}/>
                            </div>
                            <div className={'row'}>
                                <Input label={'Site Group IDs (Comma separated)'}
                                       onChange={(e) => this.setState({site_group_ids: e.target.value})}
                                       value={this.state.site_group_ids}/>
                            </div>
                            <div className={'row'}>
                                <Input label={'Asset IDs (Comma separated)'}
                                       onChange={(e) => this.setState({asset_ids: e.target.value})}
                                       value={this.state.asset_ids}/>
                            </div>
                            <div className={'row reverse'}>
                                <Button progressRes style={{marginTop: '30px', marginBottom: '10px'}} additionalclasses={'sm'}
                                        color={'gd-green'}
                                        onClick={this.testReports}
                                        label={'Run Test'}/>
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
        )
    }
}

export default ReportsTest;
import React, {Component} from "react";
import "./LocationModal.scss";
import '../styles/_layout.scss';
import {
    Button,
    CardBody,
    Input,
    Tabs, Toast
} from "gd-react";
import GridDuck from "gridduck";
import LocationForm from "../components/LocationForm";
import GdModal from "../components/GdModal/GdModal";

class EditLocationModal extends Component {

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.moveToTab = this.moveToTab.bind(this);
        this.saveChanges = this.saveChanges.bind(this);
        this.onTabClick = this.onTabClick.bind(this);
        this.deleteLocation = this.deleteLocation.bind(this);
        this.errors = {};
        this.changed = false;
        this.locationFormRef = React.createRef();
        

        this.state = {
            wide: false,
            deleteConfirmation: '',
            tabs: [[
                {
                    id: 1,
                    title: 'Details',
                    onTabClick: this.onTabClick,
                    selected: (this.props.openOnTab === 1 || !this.props.openOnTab)
                }
            ]]
        }

        if (this.props.item._permission !== 'view') {
            this.state.tabs[0].push({
                id: 2,
                title: 'Delete',
                onTabClick: this.onTabClick,
                selected: (this.props.openOnTab === 2)
            })
        }

        this.modalClosed = false;
    }

    async deleteLocation() {
        await this.props.item.delete();
        this.setState({showToast: 'Address ' + this.props.item.name + ' Deleted'})
        this.closeModal();
    }

    async saveChanges() {
        let locationErrors = this.locationFormRef.current.checkErrors(true);
        if (Object.keys(locationErrors).length > 0) return Promise.reject();
        await this.locationFormRef.current.createNewLocation();
        this.setState({changed: false});
        return Promise.resolve();
    }

    onTabClick(ev, tab) {
        this.state.tabs.forEach(function (tabArr) {
            tabArr.forEach(function (t) {
                t.selected = (tab.id === t.id);
            })
        })
        this.setState(this.state);
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    moveToTab() {
        this.setState({triedToNext: true})
        if (Object.keys(this.errors).length > 0) return;
        this.onTabClick(null, {id: 2});
    }

    render() {

        let footer;
        if (this.state.changed && this.state.tabs[0][0] && this.state.tabs[0][0].selected) footer =
            <Button progressRes disabled={Object.keys(this.errors).length > 0} additionalclasses={'sm'} label={'Save'}
                    color={'gd-green'}
                    onClick={this.saveChanges}/>
        if (this.state.tabs[0][1] && this.state.tabs[0][1].selected) footer =
            <Button progressRes disabled={this.state.deleteConfirmation !== 'DELETE'} additionalclasses={'md'}
                    label={'Permanently Delete'} color={'gd-red'}
                    onClick={this.deleteLocation}/>

        return (
            <GdModal
                title={this.props.item.name + ' Address'}
                open={this.props.open}
                contentLabel={'Edit Address'}
                footer={footer}
                onClose={this.closeModal}>
                {this.state.tabs ? <Tabs tabData={this.state.tabs}/> : null}

                {this.state.tabs[0][0] && this.state.tabs[0][0].selected ?
                    <CardBody>
                        <LocationForm disabled={this.props.item._permission === 'view'} ref={this.locationFormRef}
                                      editingLocked={this.props.item}
                                      onLocationIdChange={(val) => this.setState({
                                          changed: true
                                      })}
                                      onNewLocationNameChange={(val) => this.setState({
                                          changed: true
                                      })}
                                      onNewLocationTimezoneChange={(val) => this.setState({
                                          changed: true
                                      })}
                                      onNewLocationMetadataChange={(val) => this.setState({
                                          changed: true
                                      })}/>
                    </CardBody>
                    : ''}
                {this.state.tabs[0][1] && this.state.tabs[0][1].selected ?
                    <CardBody>
                        <div className={'row'}>
                            <Input required error={this.errors['delete'] && this.state.triedToDelete} top='true'
                                   onChange={(e) => this.setState({deleteConfirmation: e.target.value})}
                                   placeholder={'Type DELETE here'}
                                   name={'delete'} value={this.state.deleteConfirmation}
                                   label={'Enter DELETE (case sensitive) in the input and then click the button bellow'}/>
                        </div>
                    </CardBody> : ''}
                <Toast onClose={() => this.setState({showToast: null})} message={this.state.showToast}
                       open={this.state.showToast}
                       severity="error"
                       anchorOrigin={{
                           vertical: 'bottom',
                           horizontal: 'left',
                       }}/>
            </GdModal>
        )
    }

}

export default EditLocationModal;

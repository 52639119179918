import React from "react";
import {Button, Icon, Input} from "gd-react";
import Popover from '@material-ui/core/Popover';
import {Portal} from "@material-ui/core";
import './IconSearch.scss'
import Icons from './Icons.json'

const suggestedIcons = [
    'FaBuilding',
    'FaCity',
    'AiOutlineShop',
    'FaHamburger',
    'GiHamburger',
    'FaGlobeEurope',
    'FiGlobe',
    'IoIosGlobe',
    'FaPencilRuler',
    'FaRulerVertical',
    'GiChicken',
    'FaCaravan',
    'GiCaravan',
    'FaSolarPanel',
    'FaChargingStation',
    'AiFillThunderbolt',
    'IoIosFlame',
    'IoIosWater',
    'FaSnowflake',
    'FaThermometerHalf',
    'MdPerson',
    'FaAddressBook',
    'GiComputerFan',
    'GiDuck'
]

const colours = [
    '#595959',
    '#a9a9a9',
    '#7025b5',
    '#9472cb',
    '#ae2ec1',
    '#dc4f83',
    '#ec5252',
    '#ee7351',
    '#efa24a',
    '#ffef00',
    '#85c72f',
    '#a8f184',
    '#61c68f',
    '#5cb4d2',
    '#4d89c5',
    '#444ec3',
]

class IconSearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            icons: suggestedIcons,
            colour: this.props.colour || colours[0],
            searchText: ''
        }
        // this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.searchIcons = this.searchIcons.bind(this);
    }

    handleClick(e) {
        
        this.setState({anchorEl: e.currentTarget});
    }

    handleClose() {
        this.setState({anchorEl: null, icons: suggestedIcons, searchText: ''});
        if (this.props.onClose) this.props.onClose();
    }

    async searchIcons(searchText) {
        if (!searchText.length || searchText.length < 3) {
            this.setState({
                icons: suggestedIcons,
                searchText: searchText
            });
            return;
        }
        let icons = Icons.icons.filter((ii) => ii.toLowerCase().indexOf(searchText.toLowerCase()) !== -1);

        this.setState({
            icons: icons,
            searchText: searchText
        });
    }

    render() {
        let open = Boolean(this.state.anchorEl || this.props.anchorEl);
        
        const id = open ? 'simple-popover' : undefined;
        
        
        return (
            <Popover
                id={id}
                disablePortal
                open={open}
                anchorEl={this.state.anchorEl || this.props.anchorEl}
                onClose={() => this.handleClose()}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                <div className={'Icon-Search'}>
                    <p className={'suggested-title'}>Icon Colour</p>
                    <div className={'icon-grid'}>
                        {colours.map((i) => {
                            return <div onClick={() => {
                                if (this.props.onColourChange) this.props.onColourChange(i);
                                this.setState({colour: i})
                            }} key={i}
                                        className={'icon-wrapper color'} style={{backgroundColor: i}}>
                                {this.state.colour === i ?
                                    <Icon icon={'FaCheck'} size={'11'} color={'white'}/> : null}
                            </div>
                        })}
                    </div>
                    <div className={'icon-search-input'}>
                        <Input placeholder={'Search icons'} nolabel='true' value={this.state.searchText}
                               onChange={(e) => this.searchIcons(e.target.value)}/>
                    </div>
                    {!this.state.searchText || !this.state.searchText.length || this.state.searchText.length < 3 ?
                        <p className={'suggested-title'}>Frequently Used</p> : null}
                    <div className={'icon-grid'}>
                        {this.state.icons.map((i) => {
                            return <div key={i + 'w'} className={'icon-wrapper'}><Icon color={this.state.colour}
                                                                                       onIconClick={() => {
                                                                                           if (this.props.onIconChange) this.props.onIconChange(i);
                                                                                           this.setState({icon: i})
                                                                                       }
                                                                                       }
                                                                                       key={i}
                                                                                       icon={i} size={'20'}/></div>
                        })}
                    </div>
                    <div className={'row reverse'} style={{marginTop: '15px'}}>
                        <Button label={'Done'} onClick={this.handleClose}/>
                    </div>
                </div>
            </Popover>
            // <Popover
            //     id={id}
            //     open={open}
            //     anchorEl={this.state.anchorEl || this.props.anchorEl}
            //     onClose={() => this.handleClose()}
            //     anchorOrigin={{
            //         vertical: 'bottom',
            //         horizontal: 'left',
            //     }}>
            //     <div className={'Icon-Search'}>
            //         <p className={'suggested-title'}>Icon Colour</p>
            //         <div className={'icon-grid'}>
            //             {colours.map((i) => {
            //                 return <div onClick={() => {
            //                     
            //                     if (this.props.onColourChange) this.props.onColourChange(i);
            //                     this.setState({colour: i})
            //                 }} key={i}
            //                             className={'icon-wrapper color'} style={{backgroundColor: i}}>
            //                     {this.state.colour === i ? <Icon icon={'FaCheck'} size={'11'} color={'white'}/> : null}
            //                 </div>
            //             })}
            //         </div>
            //         <div className={'icon-search-input'}>
            //             <Input placeholder={'Search icons'}
            //                    nolabel='true'
            //                    autoFocus
            //                    value={this.state.searchText}
            //                    onChange={(e) => this.searchIcons(e.target.value)}/>
            //         </div>
            //         {!this.state.searchText || !this.state.searchText.length || this.state.searchText.length < 3 ?
            //             <p className={'suggested-title'}>Frequently Used</p> : null}
            //         <div className={'icon-grid'}>
            //             {this.state.icons.map((i) => {
            //                 return <div key={i + 'w'} className={'icon-wrapper'}><Icon color={this.state.colour}
            //                                                                            onIconClick={() => {
            //                                                                                if (this.props.onIconChange) this.props.onIconChange(i);
            //                                                                                this.setState({icon: i})
            //                                                                            }}
            //                                                                            key={i}
            //                                                                            icon={i} size={'20'}/></div>
            //             })}
            //         </div>
            //         <div className={'row reverse'} style={{marginTop: '15px'}}>
            //             <Button label={'Done'} onClick={this.handleClose}/>
            //         </div>
            //     </div>
            // </Popover>
        )
    }
}

export default IconSearch;
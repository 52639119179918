import React from "react";
import "./AnomalyDetection.scss";
import {Button, Icon, Menu, Tabs} from "gd-react";
import {Tooltip} from "@material-ui/core";
import GDGraphs from "gd-graphs";
import GridDuck from "gridduck";
import getFormat from "../../services/formatter";
import GenericLoader from "../../components/GenericLoader";

class AnomalyPanel extends React.Component {
    constructor(props) {
        super(props);
        GDGraphs.config({
            gridduckJS: GridDuck
        });
        this.state = {
            notes: this.props.anomaly.notes,
            graphLoaded: false
        };
        this.onTabClick = this.onTabClick.bind(this);
        this.tabs = [[
            {
                id: 1,
                title: 'Details',
                default: true,
                onTabClick: this.onTabClick,
                selected: !this.state.tabId || this.state.tabId === 1
            },
            {
                id: 2,
                title: 'Model Analysis',
                onTabClick: this.onTabClick,
                selected: this.state.tabId === 2
            },
        ]]
        this.updatedExternally = this.updatedExternally.bind(this);
        this.saveNotes = this.saveNotes.bind(this);
        this.updateStatus = this.updateStatus.bind(this);
        this.updateValue = this.updateValue.bind(this);


        this.createGraph = this.createGraph.bind(this);
        this.createModelGraph = this.createModelGraph.bind(this);

        // progressRes
        // progressState={this.state.progressState}
        // onComplete={this.loggedIn}
    }

    componentDidMount() {
        this.props.anomaly.on('updated', this.updatedExternally);
        this.createGraph();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

        if (this.props && prevProps.anomaly && this.props.anomaly.id !== prevProps.anomaly.id) {
            this.tabs[0][0].selected = true;
            this.tabs[0][1].selected = false;
            this.setState({tabId: 1});
            this.props.anomaly.off('updated', this.updatedExternally);
            this.props.anomaly.on('updated', this.updatedExternally);
            this.createGraph();
            if ((this.props.anomaly.notes !== prevProps.anomaly.notes)) {
                this.setState({notes: this.props.anomaly.notes || ''});
            }
        }
    }

    async createGraph() {
        let self = this;

        if (this.graph) {

            this.graph.remove();
        }
        let modelData = await GridDuck.getAnomalyModel({id: this.props.anomaly.modelId});
        this.setState({
            modelData: modelData
        });
        let graphParams = {
            element: '#anomaly-graph',
            anomaly: this.props.anomaly,
            invertModelBand: true,
            modelData: modelData,
            widget: true,
            onReady: function () {
                self.setState({graphLoaded: true});
                
            }
        };
        this.graph = GDGraphs.anomaly(graphParams);
    }

    async createModelGraph() {
        if (this.graph) {
            this.graph.remove();
        }
        let modelData = await GridDuck.getAnomalyModel({id: this.props.anomaly.modelId});


        let graphParams = {
            element: '#anomaly-graph',
            modelData: modelData,
            modelOnly: true,
            anomalyTs: this.props.anomaly.startTs,
            anomalyValue: this.props.anomaly.entryValue,
            widget: true
        };
        this.graph = GDGraphs.anomaly(graphParams);
    }


    updatedExternally() {
        this.props.onUpdate(() => {
            this.setState({notes: this.props.anomaly.notes || ''});
        });
    }

    onTabClick(ev, tab) {
        this.tabs.forEach(function (tabArr) {
            tabArr.forEach(function (t) {
                t.selected = (tab.id === t.id);
            })
        })
        this.state.tabId = tab.id;
        this.setState(this.state);
        if (tab.id === 1) {
            this.createGraph();
        }
        if (tab.id === 2) {
            this.createModelGraph();
        }
    }

    componentWillUnmount() {
        this.props.anomaly.off('updated', this.updatedExternally);
    }

    updateStatus(status) {
        this.props.anomaly.set({userStatus: status});
    }

    updateNotes(notes) {
        this.setState({notes: notes || '', notesUpdated: true});
    }

    updateValue(key) {
        let updateObj = {};
        updateObj[key] = true;
        this.props.anomaly.set(updateObj);
    }

    async saveNotes() {
        let self = this;
        this.props.anomaly.set({notes: this.state.notes});
        window.setTimeout(() => {
            self.setState({notesUpdated: false});
        }, 1000);
        return Promise.resolve();
    }

    render() {
        let self = this;
        let anomaly = this.props.anomaly;

        return (
            <div className={'anomaly-panel'}>
                {this.tabs ? <Tabs tabData={this.tabs}/> : null}
                {this.tabs[0][0] && this.tabs[0][0].selected ? <div className={'anomaly-details'}>
                    <div className={'close-anomaly'}>
                        <Icon icon={'FaTimes'} size={'16'} onIconClick={this.props.onClose}/>
                    </div>
                    {!anomaly.invalid && !anomaly.dismissed ? <div className={'keep-detecting'}>
                        <p>Keep detecting anomalies like this?</p>
                        <div className={'yes-no'}>
                            <div className={'choice'} onClick={() => self.updateValue('dismissed')}>
                                <p>Yes</p>
                                <Icon icon={'FiThumbsUp'}/>
                            </div>
                            <div className={'choice'} onClick={() => self.updateValue('invalid')}>
                                <p>No</p>
                                <Icon icon={'FiThumbsDown'}/>
                            </div>
                        </div>
                    </div> : null}
                    <div className={'anomaly-graph-wrapper'}>
                        <GenericLoader/>
                        <div id={'anomaly-graph'} style={{"height": "200px", "width": "100%"}}>
                        </div>
                    </div>
                    <div className={'anomaly-details-lower-section'}>
                        <div className={'anomaly-info-item'}>
                            <p className={'anomaly-info-label'}>Status</p>
                            {this.props.anomaly.endTs ? <Menu PopoverClasses={['menu-icon-primary-override']}
                                                              controlFromElement={<Tooltip title={'Change Status'}>
                                                                  <div
                                                                      className={'anomaly-status dd ' + (anomaly.userStatus)}>{anomaly.userStatus}
                                                                      <div className={'dd-icon'}><Icon size={'10'}
                                                                                                       icon={'FaChevronDown'}/>
                                                                      </div>
                                                                  </div>
                                                              </Tooltip>}
                                                              menuItems={[
                                                                  {
                                                                      icon: {
                                                                          color: '#49c570',
                                                                          name: 'FaCircle',
                                                                          size: '8'
                                                                      },
                                                                      label: 'new',
                                                                      onMenuItemClick: function () {
                                                                          self.updateStatus('new')
                                                                      }
                                                                  },
                                                                  {
                                                                      icon: {
                                                                          color: '#e74e0c',
                                                                          name: 'FaCircle',
                                                                          size: '8'
                                                                      },
                                                                      label: 'flagged',
                                                                      onMenuItemClick: function () {
                                                                          self.updateStatus('flagged')
                                                                      }
                                                                  },
                                                                  {
                                                                      icon: {
                                                                          color: '#9b9b9b',
                                                                          name: 'FaCircle',
                                                                          size: '8'
                                                                      },
                                                                      label: 'resolved',
                                                                      onMenuItemClick: function () {
                                                                          self.updateStatus('resolved')
                                                                      }
                                                                  }
                                                              ].filter(state => state.label.toLowerCase() !== anomaly.userStatus.toLowerCase())}/> :
                                <div className={'anomaly-status ongoing'}>ongoing</div>}


                        </div>
                        <div className={'anomaly-info-item'}>
                            <p className={'anomaly-info-label'}>Device</p>
                            <p className={'anomaly-info-text'}>{anomaly.deviceName}</p>
                        </div>
                        <div className={'anomaly-info-item'}>
                            <p className={'anomaly-info-label'}>Site</p>
                            <p className={'anomaly-info-text'}>{anomaly.siteName}</p>
                        </div>
                        <div className={'anomaly-info-item'}>
                            <p className={'anomaly-info-label'}>Entering Anomaly</p>
                            {anomaly.entryValue !== null ? (<p className={'anomaly-info-text'}
                                                                    style={{whiteSpace: "pre-line"}}>{getFormat('dateUtc')(anomaly.startTs)} {anomaly.entryValue}W ({anomaly.entryLowerThreshold}W - {anomaly.entryUpperThreshold}W)</p>) : ''}

                        </div>
                        <div className={'anomaly-info-item'}>
                            <p className={'anomaly-info-label'}>Exiting Anomaly</p>
                            {anomaly.exitValue !== null ? (<p className={'anomaly-info-text'}
                                                                   style={{whiteSpace: "pre-line"}}>{getFormat('dateUtc')(anomaly.endTs)} {anomaly.exitValue}W ({anomaly.exitLowerThreshold}W - {anomaly.exitUpperThreshold}W)</p>) : (<p className={'anomaly-info-text'}
                                                                                                                                                                                                                                             style={{whiteSpace: "pre-line"}}>Ongoing</p>)}
                        </div>
                        <div className={'anomaly-info-item'}>
                            <p className={'anomaly-info-label'}>Notes</p>
                            <textarea
                                placeholder={"Enter any notes on the anomaly here"}
                                onChange={(e) => self.updateNotes(e.target.value)} value={this.state.notes}/>
                        </div>
                        {this.state.notesUpdated ? <div className={'row reverse'}>
                            <div className={'save-button-wrapper'}>
                                <Button progressRes label={'Save'} color={'gd-brand'} onClick={this.saveNotes}/>
                            </div>
                        </div> : null}
                    </div>
                </div> : null}
                {this.tabs[0][1] && this.tabs[0][1].selected ? <div className={'anomaly-details'}>
                    <div className={'anomaly-graph-wrapper'}>
                        <GenericLoader/>
                        <div id={'anomaly-graph'} style={{"height": "200px", "width": "100%"}}>
                        </div>
                    </div>
                    <div className={'anomaly-details-lower-section'}>
                        <div className={'anomaly-info-item'}>
                            <div className={'anomaly-info-item'}>
                                <p className={'anomaly-info-label'}>Trained Date</p>
                                <p className={'anomaly-info-text'}>{getFormat('dateUtc')(this.state.modelData.trained)}</p>
                            </div>
                            <div className={'anomaly-info-item'}>
                                <p className={'anomaly-info-label'}>Training Period</p>
                                <p className={'anomaly-info-text'}>{getFormat('dateUtc')(this.state.modelData.startTs)} - {getFormat('dateUtc')(this.state.modelData.endTs)}</p>
                            </div>
                        </div>
                    </div>
                </div> : null}
            </div>
        )
    }

}

export default AnomalyPanel;
import React from "react";
import "./DataPage.scss";
import {Toast, Icon, Dropdown, GdCheckbox, Button, GdAutocomplete, DataTypes, StateOptions, DataCategories} from "gd-react";
import Legend from "../../components/Legend";
import KPI from "../../components/KPI";
import GenericLoader from "../../components/GenericLoader";
import DataPage from "./DataPage";
import GraphTypePicker from "../../components/GraphTypePicker";
import GridDuck from 'gridduck';
import * as _ from 'underscore';
import LegendRange from "../../components/LegendRange";
import EditLocationModal from "../LocationModal";
import OccupancyScheduleSetterMenu from "../../components/OccupancyScheduleSetterMenu";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Navigation} from "swiper";
import {isMobile} from "react-device-detect";
import checkFeature from "../../services/SubFeatureCheckService";
import SubTierModal from "../../modals/SubTier/SubTierModal";

const detachedLimit = 4;

class DesktopDataPage extends DataPage {
    constructor(props) {
        super(props);
        this.searchDataType = this.searchDataType.bind(this);
        this.searchDevices = this.searchDevices.bind(this);
        this.addCustomOverlay = this.addCustomOverlay.bind(this);
        this.getSite = this.getSite.bind(this);
        this.updateOccupancy = this.updateOccupancy.bind(this);
        this.openOverlays = this.openOverlays.bind(this);
        this.deviceSelectRef = React.createRef();
    }

    async openOverlays (e) {
        const featureAvailable = await checkFeature('DATA_OVERLAYS');
        if (!featureAvailable) {
            // Stop opening and show sub tier modal
            this.setState({openSubTierModal: 'DATA_OVERLAYS', overlaysDDOpen: false, tierUpdated: this.openOverlays});
        } else {
            this.setState({overlaysDDOpen: true});
        }
    }

    getDataType(object) {
        let dataType;
        if (!object) return;
        if (object.dataType) {
            dataType = DataTypes.find(dt => dt.type === object.dataType);
        } else {
            if (object.sku) {
                dataType = StateOptions.find((so) => so.type === object.sku).dataTypes[0]
            }
        }
        return dataType;
    }

    async searchDevices(searchTerm, reset, offset) {
        let site = await this.getSite();
        let deviceList = await GridDuck.getAssets({
            getAll: true,
            offset: offset,
            filters: [
                {
                    field: "siteId",
                    value: site.id
                },
                {
                    field: "dataType",
                    value: this.state.deviceOverlayDataType
                }
            ]
        });
        let recordList = deviceList.list
            .filter(d => d.name.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1)
            .map(d => {
                let dt = this.getDataType(d);
                return {
                    id: d.id,
                    title: d.name,
                    icon: d.deviceCategoryIcon || dt.categoryDetails?.icon,
                    color: d.deviceCategoryColor || dt.categoryDetails?.color,
                    subtitle: d.deviceCategory
                }
            });
        return {items: recordList, offset: offset + deviceList.list.length, count: deviceList.count};
    }

    async getSite() {
        if (this.props.type === 'site') {
            return this.props.item;
        } else {
            return await GridDuck.getSite({id: this.props.item.siteId});
        }
    }

    async searchDataType(searchTerm, reset, offset) {
        let site = await this.getSite(searchTerm);
        let availableDataTypes = site.dataTypes;
        let results = [];
        if (availableDataTypes) {
            availableDataTypes.sort((a, b) => {
                let _a = DataTypes.find(dT => dT.type === a);
                let _b = DataTypes.find(dT => dT.type === b);
                return _a.categoryDetails.position - _b.categoryDetails.position
            }).forEach(function (dataType) {
                let dataTypeMeta = DataTypes.find(dT => dT.type === dataType);
                if (dataTypeMeta) {
                    let title = dataTypeMeta.name;
                    if (title.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1 && (dataTypeMeta.dataType === 'categorical' || dataTypeMeta.dataType === 'instantaneous')) {
                        results.push({
                            title: title,
                            id: dataTypeMeta.id,
                            icon: dataTypeMeta.categoryDetails.icon,
                            color: dataTypeMeta.categoryDetails.color,
                            subtitle: dataTypeMeta.categoryDetails.name
                        });
                    }
                }
            })
        }
        return Promise.resolve({
            items: results,
            offset: offset + availableDataTypes.length,
            count: availableDataTypes.length
        });
    }

    addCustomOverlay() {
        if (this.state.overlays.find(o => o.type === 'CUSTOM')) {
            this.state.overlays.splice(this.state.overlays.indexOf(this.state.overlays.find(o => o.type === 'CUSTOM')), 1);
        }
        this.state.overlays.push({
            type: 'CUSTOM',
            dataType: this.state.deviceOverlayDataType,
            deviceId: this.state.deviceOverlayDeviceId
        });
        // this.setState({overlays: this.state.overlays});
        this.setOverlays(this.state.overlays);
    }

    updateOccupancy(e, stateOnly) {
        let self = this;
        if (!stateOnly) {
            if (self.state.overlays.find(o => o.dataType === 'OCCUPANCY') && !e.target.checked) {
                self.state.overlays.splice(self.state.overlays.indexOf(self.state.overlays.find(o => o.dataType === 'OCCUPANCY')), 1);
            } else if (e.target.checked && !self.state.overlays.find(o => o.dataType === 'OCCUPANCY')) {
                self.state.overlays.push({dataType: 'OCCUPANCY'});
            }
        }
        self.setOverlays(self.state.overlays);
    }


    render() {
        let self = this;
        let loader;
        let legend;
        let timezone = this.props.item.timezone || this.props.item.siteTimezone || 'Europe/London';
        let isAll = this.props.item.id.indexOf('all') !== -1;

        let _legendRange = <LegendRange
            summaryMode
            detachedLimit={detachedLimit}
            withGranularity
            onIsComparingChange={() => {
            }}
            onSelectionTypeChange={() => {
            }}
            // disabled={!this.state.ready}
            onHover={this.onHighlight}
            overlays={this.state.overlays}
            setOverlays={this.setOverlays}
            onHide={this.onHide}
            resetZoom={this.resetZoom}
            zoomData={this.state.zoomData}
            defaultSelected={this.state.defaultSelected}
            category={self.props.tab.tab.dataType.category}
            graph={this.state.graph}
            dRString={this.dRString}
            cRString={this.cRString}
            granularity={this.granularity}
            graphType={this.state.type}
            start={this.start}
            timezone={timezone}
            time={this.state.time}
            end={this.end}
            set={this.set}
            isAll={isAll}
            combined={this.state.combined}
            compareStart={this.compareTo ? this.compareTo.start : null}
            compareEnd={this.compareTo ? this.compareTo.end : null}
            displayName={this.props.item?.name}
            item={this.props.item}
            site={this.props.site}
            disabled={!this.state.ready}
            highlighted={this.state.highlighted}
            items={this.state.visibleItems}
            data={this.state.data}
            locked={this.state.data ? this.state.data.locked : false}
        />

        legend = (<Legend
            parentRef={this.parentRef}
            showDetached={this.state.showDetached}
            tooltipRef={this.tooltipRef}
            mx={this.state.m_x}
            my={this.state.m_y}
            detachedLimit={detachedLimit}
            displayName={this.props.item?.name}
            item={this.props.item}
            site={this.props.site}
            disabled={!this.state.ready}
            onHover={this.onHighlight}
            overlays={this.state.overlays}
            setOverlays={this.setOverlays}
            onHide={this.onHide}
            highlighted={this.state.highlighted}
            resetZoom={this.resetZoom}
            zoomData={this.state.zoomData}
            items={this.state.visibleItems}
            data={this.state.data}
            defaultSelected={this.state.defaultSelected}
            locked={this.state.data ? this.state.data.locked : false}
            category={self.props.tab.tab.dataType.category}
            graph={this.state.graph}
            dRString={this.dRString}
            cRString={this.cRString}
            granularity={this.granularity}
            graphType={this.state.type}
            start={this.start}
            timezone={timezone}
            time={this.state.time}
            end={this.end}
            set={this.set}
            isAll={isAll}
            combined={this.state.combined}
            compareStart={this.compareTo ? this.compareTo.start : null}
            compareEnd={this.compareTo ? this.compareTo.end : null}
        />);
        let graphClass = '';
        if (!this.state.ready) {
            loader = (
                <GenericLoader id={'graph-loader'} text={'Fetching Data...'} textLineTwo={'This may take a while'}/>);
            graphClass += 'loading';
        }
        if (this.state.zooming) {
            loader = (<GenericLoader id={'graph-loader'} text={'Applying Changes...'}
                                     textLineTwo={'Should only take a moment...'}/>);
            graphClass += 'loading';
        }
        let noData;
        if (this.state.noData) {
            noData = (<div className={"no-data"}>
                <Icon icon={'MdInsertChart'} size={'40'}/>
                <p className={'no-data-title'}>No data</p>
                <p>There are no records in this time range.</p>
            </div>)
        }
        if (this.state.error) {
            noData = (<div className={"no-data"}>
                <Icon icon={'MdInsertChart'} size={'40'}/>
                <p className={'no-data-title'}>Something went wrong</p>
                <p>There was an issue whilst retrieving your data.</p>
            </div>)
        }
        let granularityOptions = ['today_so_far', 'yesterday', 'last_7_days', 'last_30_days', 'this_month', 'last_month', 'this_year', 'last_year', 'custom'];
        if (this.props.tab.tab.dataType.type === 'CONSUMPTION') {
            granularityOptions = [
                '15_mins', 'last_hour', 'last_24_hours', 'today_so_far', 'yesterday', 'last_7_days', 'last_30_days', 'this_month', 'last_month', 'this_year', 'last_year', 'custom'
            ];
        }
        let kpiHeight;
        kpiHeight = this.graphKPIRef?.current?.clientHeight || 0
        kpiHeight += 'px'
        let dataTabs = this.props.tabs.find(t => t.title === 'Data');
        let picker = [(
            <GraphTypePicker onChange={this.graphTypeChange}
                             type={this.state.type}
                             dataType={this.props.tab.tab.dataType}
                             key={'gtp'}
                             dRString={this.dRString}
                             combined={this.state.combined}
                             graph={this.state.graph}
                             isAll={isAll}
                             time={this.state.time}
                             filterType={this.props.filterType}
                             filterId={this.props.filterId}
                             average={this.state.average}
                             dataTabs={dataTabs.tabs}
                             category={this.state.category}/>
        )];



        let _dataCategories = Object.entries(_.groupBy(dataTabs.tabs, tab => tab.dataType.category)).map(([key, value]) => {
            let actualDC = DataCategories.find(dc => dc.name === key);
            return {
                id: key,
                icon: actualDC.icon,
                color: actualDC.color,
                title: key,
                value: key.toLowerCase()
            }
        });
        let _dataTypes = dataTabs.tabs.map((dt, i) => {
            return {
                id: dt.title,
                title: dt.title,
                value: dt.dataType.type.toLowerCase(),
                icon: dt.subCategory?.icon || 'FaTachometerAlt',
                color: dt.subCategory?.color || dt.color,
                category: dt.dataType.category,
                dataType: dt.dataType
            }
        });
        let filteredTypes;
        if (_dataTypes) {
            filteredTypes = _dataTypes.filter(dt => {
                let sameCat = (dt.category === this.props.tab.tab.dataType.category);
                let notOnlyValueIsCat = (dt.id !== this.props.tab.tab.dataType.category);
                let sibling = dataTabs.tabs.find(_dtab => _dtab.dataType.type === dt.dataType.dataTypeSibling);
                let noSiblingOrAggregateWithSibling = (!dt.dataType.dataTypeSibling || (sibling && sibling.dataType.dataType === 'instantaneous'));
                return sameCat && notOnlyValueIsCat && noSiblingOrAggregateWithSibling;
            })
        }

        let tabDt = this.props.tab.tab.dataType;
        let selectedDt = this.props.tab.tab.dataType.type.toLowerCase();
        if (tabDt.dataType === 'instantaneous' && tabDt.dataTypeSibling) {
            selectedDt = tabDt.dataTypeSibling.toLowerCase();
        }

        let _site = this.props.site || this.props.item;

        let desktop_settings_bar = <div className={'TabRow with-dd'}>
            <div className={'no-border-dropdown'}>
                {dataTabs && dataTabs.tabs.length ?
                    <Dropdown value={this.props.tab.tab.dataType.category.toLowerCase()}
                              borderless
                              label={''} nolabel={'true'}
                              fixeditems={_dataCategories}
                              onChange={(val) => {
                                  self.props.changeTab(['data', _dataTypes.find(dt => dt.category.toLowerCase() === val.target.value).value])()
                              }}/> : null}
                {filteredTypes && filteredTypes.length ?
                    <Dropdown value={selectedDt}
                              borderless
                              label={''} nolabel={'true'}
                              fixeditems={filteredTypes}
                              onChange={(val) => {
                                  self.props.changeTab(['data', val.target.value])()
                              }}/> : null}
            </div>
            {_legendRange}
        </div>

        let settings = []

        if (dataTabs && dataTabs.tabs.length) {
            settings.push(<SwiperSlide key={'categories'} style={{width: 'auto'}}>
                <Dropdown value={this.props.tab.tab.dataType.category.toLowerCase()}
                          borderless
                          label={''} nolabel={'true'}
                          fixeditems={_dataCategories}
                          onChange={(val) => {
                              self.props.changeTab(['data', _dataTypes.find(dt => dt.category.toLowerCase() === val.target.value).value])()
                          }}/>
            </SwiperSlide>)
        }

        if (filteredTypes && filteredTypes.length) {
            settings.push(<SwiperSlide key={'types'} style={{width: 'auto'}}>
                <Dropdown value={selectedDt}
                          borderless
                          label={''} nolabel={'true'}
                          fixeditems={filteredTypes}
                          onChange={(val) => {
                              self.props.changeTab(['data', val.target.value])()
                          }}/>
            </SwiperSlide>)
        }

        let deviceSelection;

        if (this.state.deviceOverlay) {
            deviceSelection = [];
            deviceSelection.push(
                <div className={'row additional-device-overlay'}>
                    <GdAutocomplete
                        async
                        lazyload
                        subtitle
                        icon
                        startAdornment={this.state.deviceOverlayDataObj ? <div style={{
                            height: '100%',
                            display: 'flex',
                            justifyContent: 'flex-start',
                            alignItems: 'center',
                            marginRight: '5px'
                        }}><Icon icon={this.state.deviceOverlayDataObj.icon}
                                 color={this.state.deviceOverlayDataObj.color ? this.state.deviceOverlayDataObj.color : this.state.deviceOverlayDataObj.type ? '#49b9c4' : '#a7a7a7'}/>
                        </div> : null}
                        name={'dataType'}
                        label={'Data Type'}
                        value={this.state.deviceOverlayDataName}
                        getOptionSelected={function (option, value) {
                            if (option && value) return option.id === value.id;
                        }}
                        placeholder={'Select a data type'}
                        getListPaginated={this.searchDataType}
                        onChange={(e, obj) => {
                            if (obj && obj.id) {
                                if (this.state.overlays.indexOf(this.state.overlays.find(o => o.type === 'CUSTOM') !== -1)) {
                                    this.state.overlays.splice(this.state.overlays.indexOf(this.state.overlays.find(o => o.type === 'CUSTOM')), 1);
                                }
                                this.setState({
                                        overlays: this.state.overlays,
                                        deviceSelectKey: Math.random(),
                                        deviceOverlayDeviceName: undefined,
                                        deviceOverlayDeviceId: undefined,
                                        deviceOverlayDataType: obj.id,
                                        deviceOverlayDataObj: obj,
                                        deviceOverlayDataName: e.target.value,
                                        deviceOverlayObj: null
                                    },
                                    () => self.setOverlays(self.state.overlays)
                                );
                            }
                        }}/>
                </div>
            )
            if (this.state.deviceOverlayDataType) {
                deviceSelection.push(
                    <div className={'row additional-device-overlay'}>
                        <GdAutocomplete
                            key={this.state.deviceSelectKey}
                            async
                            startAdornment={this.state.deviceOverlayObj ? <div style={{
                                height: '100%',
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                marginRight: '5px'
                            }}><Icon icon={this.state.deviceOverlayObj.icon}
                                     color={this.state.deviceOverlayObj.color ? this.state.deviceOverlayObj.color : this.state.deviceOverlayObj.type ? '#49b9c4' : '#a7a7a7'}/>
                            </div> : null}
                            lazyload
                            icon
                            subtitle
                            name={'overlayDevice'}
                            label={'Device'}
                            value={this.state.deviceOverlayDeviceName}
                            getOptionSelected={function (option, value) {
                                if (option && value) return option.id === value.id;
                            }}
                            placeholder={'Select a device'}
                            getListPaginated={this.searchDevices}
                            onChange={(e, obj) => {
                                if (obj && obj.id) {
                                    this.setState({
                                        deviceOverlayDeviceName: e.target.value,
                                        deviceOverlayDeviceId: obj.id,
                                        deviceOverlayObj: obj
                                    }, this.addCustomOverlay);
                                }
                            }}/>
                    </div>
                )
            }
        }

        settings.push(<SwiperSlide key={'legend'} style={{width: 'auto'}}>
            {_legendRange}
        </SwiperSlide>)

        let mobile_settings_bar = <div className={'TabRow with-dd'}><Swiper spaceBetween={0}
                                                                            slidesPerView={'auto'}
                                                                            touchStartPreventDefault={false}
                                                                            navigation={true} modules={[Navigation]}
                                                                            id={Math.random()}
        >
            {settings}
        </Swiper></div>

        return (
            <div className={"data-graphs"}>
                <Toast onClose={() => this.setState({showNoTempData: null})}
                       message={<div>
                           <p style={{color: 'orange'}}>No MET temperature data available.</p>
                           <p style={{color: 'orange'}}>This could be for a few reasons:</p>
                           <ul>
                               <li><p style={{margin: 0}}>Not enough time has passed since creating the site</p></li>
                               <li><p style={{margin: 0}}>There is no weather station within range of your address</p>
                               </li>
                               <li><p style={{margin: 0}}>You haven't yet added a postcode to your address</p>
                               </li>
                           </ul>
                           <div className={'row reverse'} style={{marginBottom: '5px', marginTop: '15px'}}>
                               <Button additionalclasses={'sm'} label={'Manage address'} onClick={async () => {
                                   let siteId = this.props.item.objectTypeName === 'site' ? this.props.item.id : this.props.item.siteId;
                                   let site = await GridDuck.getSite({id: siteId});
                                   if (site.locationId) {
                                       let location = await GridDuck.getLocations({
                                           filters: [{
                                               field: 'id',
                                               value: site.locationId
                                           }]
                                       })
                                       self.setState({showEditReportModal: location.list[0]});
                                       self.setState({showNoTempData: null})
                                   }
                               }}/>
                           </div>
                       </div>}
                       open={!!(this.state.showNoTempData)}
                       severity="warning"
                       anchorOrigin={{
                           vertical: 'center',
                           horizontal: 'end',
                       }}/>
                {this.state.showEditReportModal ? <EditLocationModal onClose={() => {
                    this.setState({showEditReportModal: null});
                }} open={!!this.state.showEditReportModal} item={this.state.showEditReportModal}/> : null}
                <div className={'graph-kpi-legend-wrapper'}>
                    <div ref={this.graphKPIRef} className={'graph-kpi-wrapper'}>
                        {isMobile ? mobile_settings_bar : desktop_settings_bar}
                        {/*{desktop_settings_bar}*/}
                        <div className={'TabRow with-dd'}>
                            <div>
                                {picker}
                            </div>
                            {(this.props.tab.tab.dataType.dataType === 'instantaneous' || this.props.tab.tab.dataType.dataType === 'categorical') && this.state.graph === 'line' && (this.props.item.objectTypeName === 'site' || this.props.item.objectTypeName === 'asset') ?
                                <div>
                                    {this.state.openSubTierModal ? <SubTierModal open={this.state.openSubTierModal}
                                                                                 tierUpdated={this.state.tierUpdated}
                                                                                 onClose={() => this.setState({openSubTierModal: false})}/> : ''}

                                    <Dropdown nolabel='true'
                                              key={'ag-dd'}
                                              faked
                                              disableEnforceFocus
                                              count={this.state.overlays ? this.state.overlays.length : 0}
                                              id={'overlays'}
                                              borderless
                                              open={this.state.overlaysDDOpen}
                                              onOpen={this.openOverlays}
                                              onClose={(e) => {
                                                  if (!this.state.deviceOverlayDeviceId && this.state.deviceOverlay) {
                                                      this.setState({
                                                          count: this.state.count++,
                                                          deviceOverlay: false,
                                                          deviceOverlayDataType: null,
                                                          deviceOverlayDataName: null,
                                                          deviceOverlayDevice: null,
                                                          deviceOverlayDeviceId: null,
                                                          deviceOverlayDeviceName: null,
                                                          deviceOverlayDataObj: null,
                                                          deviceOverlayObj: null, overlaysDDOpen: false
                                                      });
                                                  } else {
                                                      this.setState({count: this.state.count++, overlaysDDOpen: false});
                                                  }
                                              }}
                                              icon={'FaLayerGroup'}
                                              placeholder={'Overlays'}
                                              onChange={(e) => {
                                              }}
                                              overrideItems
                                              fixeditems={[
                                                  <div className={'menu-divider overlay-selection'} onClick={(e) => {
                                                      if (e) e.stopPropagation();
                                                  }}>
                                                      Overlays
                                                  </div>,
                                                  <div className={'fake-menu-body'} onClick={(e) => {
                                                      if (e) e.stopPropagation();
                                                  }}>
                                                      <div className={'menu-checkbox'}>
                                                          <div className={'row'}>
                                                              {(_site.occupancyScheduleId || _site.defaultOccupancyScheduleId) ?
                                                                  [(<GdCheckbox
                                                                      checked={self.state.occupancyShowing}
                                                                      name={'overlayOccupancy'}
                                                                      onChange={function (e) {
                                                                          if (e) e.stopPropagation();
                                                                          self.updateOccupancy(e);
                                                                      }}
                                                                      label={'Occupancy Hours'}/>), (
                                                                      <div>
                                                                          <OccupancyScheduleSetterMenu site={_site}
                                                                                                       key={'occupancy-schedule-menu'}
                                                                                                       onComplete={(res) => {
                                                                                                           self.updateOccupancy(null, true);
                                                                                                       }}>
                                                                              <div><Icon color={'grey'} size={'9px'}
                                                                                         onIconClick={(e) => {
                                                                                         }}
                                                                                         icon={'FaCog'}/></div>
                                                                          </OccupancyScheduleSetterMenu>
                                                                      </div>)] : _site._permission !== 'view' ?
                                                                      <OccupancyScheduleSetterMenu
                                                                          ref={this.customKpiMenuRef}
                                                                          site={_site}
                                                                          key={'occupancy-schedule-menu-2'}
                                                                          onComplete={(res) => {
                                                                              if (self.state.overlays.find(o => o.dataType === 'OCCUPANCY')) {
                                                                                  self.state.overlays.push({dataType: 'OCCUPANCY'});
                                                                              }
                                                                              self.setOverlays(self.state.overlays);
                                                                          }}>
                                                                          <GdCheckbox
                                                                              checked={self.state.overlays.find(o => o.dataType === 'OCCUPANCY')}
                                                                              name={'overlayOccupancy'}
                                                                              label={'Occupancy Hours'}/>
                                                                      </OccupancyScheduleSetterMenu> : null}
                                                          </div>
                                                          <div className={'row'}>
                                                              <GdCheckbox
                                                                  checked={this.state.overlays.find(o => o.dataType === 'EXTERNAL_TEMPERATURE') !== undefined}
                                                                  name={'overlayTemperature'}
                                                                  onChange={(e) => {
                                                                      if (this.state.overlays.find(o => o.dataType === 'EXTERNAL_TEMPERATURE')) {
                                                                          this.state.overlays.splice(this.state.overlays.indexOf(this.state.overlays.find(o => o.dataType === 'EXTERNAL_TEMPERATURE')), 1);
                                                                      } else {
                                                                          //Can't have external temp and custom at the same time
                                                                          this.setState({
                                                                              deviceOverlay: false,
                                                                              deviceOverlayDataType: null,
                                                                              deviceOverlayDataName: null,
                                                                              deviceOverlayDevice: null,
                                                                              deviceOverlayDeviceId: null,
                                                                              deviceOverlayDeviceName: null,
                                                                              deviceOverlayDataObj: null,
                                                                              deviceOverlayObj: null
                                                                          });
                                                                          if (this.state.overlays.find(o => o.type === 'CUSTOM')) {
                                                                              this.state.overlays.splice(this.state.overlays.indexOf(this.state.overlays.find(o => o.type === 'CUSTOM')), 1);
                                                                          }
                                                                          this.state.overlays.push({dataType: 'EXTERNAL_TEMPERATURE'});
                                                                      }
                                                                      this.setOverlays(this.state.overlays)
                                                                  }}
                                                                  label={'External Temperature'}/>
                                                          </div>

                                                          <div className={'row'}>
                                                              <GdCheckbox
                                                                  checked={this.state.deviceOverlay}
                                                                  name={'deviceOverlay'}
                                                                  onChange={(e) => {
                                                                      if (this.state.deviceOverlay) {
                                                                          if (this.state.overlays.find(o => o.type === 'CUSTOM')) {
                                                                              this.state.overlays.splice(this.state.overlays.indexOf(this.state.overlays.find(o => o.type === 'CUSTOM')), 1);
                                                                              this.setState({
                                                                                  deviceOverlay: false,
                                                                                  overlays: this.state.overlays,
                                                                                  deviceOverlayDataType: null,
                                                                                  deviceOverlayDataName: null,
                                                                                  deviceOverlayDevice: null,
                                                                                  deviceOverlayDeviceName: null,
                                                                                  deviceOverlayDeviceId: null,
                                                                                  deviceOverlayDataObj: null,
                                                                                  deviceOverlayObj: null
                                                                              }, () => this.setOverlays(this.state.overlays));
                                                                          }
                                                                          this.setState({deviceOverlay: false})
                                                                      } else {
                                                                          this.setState({deviceOverlay: true});
                                                                          if (this.state.overlays.find(o => o.dataType === 'EXTERNAL_TEMPERATURE')) {
                                                                              this.state.overlays.splice(this.state.overlays.indexOf(this.state.overlays.find(o => o.dataType === 'EXTERNAL_TEMPERATURE')), 1);
                                                                              this.setState({overlays: this.state.overlays});
                                                                              this.setOverlays(this.state.overlays);
                                                                          }
                                                                      }
                                                                  }}
                                                                  label={'Device Data'}/>
                                                          </div>
                                                          {deviceSelection}
                                                      </div>
                                                  </div>
                                              ]}
                                              fakeValue={'Overlays'}/>


                                </div> : null}
                        </div>
                        {this.state.type && !this.state.noData && !this.state.error ?
                            <div className={'graph-top-wrapper'}>
                                {!this.state.noData ?
                                    <KPI type={this.props.type} hideCustomKpis={this.state.hideCustomKpis}
                                         item={this.props.item}
                                         isMobile={isMobile}
                                         data={this.state.ready && this.state.kpiData ? this.state.kpiData : null}/> :
                                    <p/>}
                                {/*<div className={'row reverse'} style={{alignItems: 'center', flex: '0'}}>*/}

                                {/*</div>*/}
                            </div> : null}
                        {this.state.ready ? noData : ''}
                        <div ref={this.parentRef}
                             className={'graph-holder ' + (this.state.visibleItems?.length && this.state.visibleItems?.length < detachedLimit ? ' detached' : '')}
                             onMouseLeave={this.handleMouseOut}
                             onMouseMove={this.state.visibleItems?.length && this.state.visibleItems?.length < detachedLimit ? this.handleMouseMove : null}>
                            {loader && <div className={'loader-wrapper'}>
                                {loader}
                            </div>}
                            <div
                                className={'graph ' + graphClass + (this.state.ready && this.props.loaded ? '' : ' inactive')}
                                id={"graph"}
                            />
                            {this.state.graph === 'line' && this.state.type === 'historic' && this.state.zoomData ?
                                <div onClick={this.resetZoom}
                                     className={'zoom-reset-button'}>
                                    <Icon size={11}
                                          icon={'FaRedo'}/>
                                    <div className={'text'}>Reset
                                        Zoom
                                    </div>

                                </div> : null}
                        </div>
                    </div>
                    {legend}
                </div>
            </div>
        )
    }
}

export default DesktopDataPage;

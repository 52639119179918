import React from "react";
import {Button, Card, CardBody, CardHeader, Dropdown, GdCheckbox, Input, Loader, Toast} from "gd-react";
import GridDuck from "gridduck";
import cookie from "react-cookies";
import SaveBar from "../../components/SaveBar/SaveBar";

class GatewayOps extends React.Component {

	constructor(props) {
		super(props);
		let self = this;
		this.state = {
			updateRef: 0,
			staging: this.props.item.staging,
			beta: this.props.item.beta,
			debug: this.props.item.debugMode,
			enhancedLogging: this.props.item.enhancedLogging,
			zigbeeDisabled: this.props.item.zigbeeDisabled,
			scanning: this.props.item.scanning
		};
		this.stagingChange = this.stagingChange.bind(this);
		this.betaChange = this.betaChange.bind(this);
		this.enhancedLoggingChange = this.enhancedLoggingChange.bind(this);
		this.debugChange = this.debugChange.bind(this);
		this.zigbeeDisabledChange = this.zigbeeDisabledChange.bind(this);
		this.scanningChange = this.scanningChange.bind(this);
		this.confirmRebootChange = this.confirmRebootChange.bind(this);
		this.confirmZigbeeResetChange = this.confirmZigbeeResetChange.bind(this);
		this.updatedExternally = this.updatedExternally.bind(this);
		this.saveChanges = this.saveChanges.bind(this);
		this.cancelChanges = this.cancelChanges.bind(this);
		this.hasChanged = this.hasChanged.bind(this);
		this.reboot = this.reboot.bind(this);
		this.resetOwners = this.resetOwners.bind(this);
		this.downloadLogs = this.downloadLogs.bind(this);
		this.downloadEnhancedLogs = this.downloadEnhancedLogs.bind(this);
		this.activateSSHTunnel = this.activateSSHTunnel.bind(this);
		this.zigbeeReset = this.zigbeeReset.bind(this);
		this.openLogFile = this.openLogFile.bind(this);
		if (props.item) {
			props.item.on('updated', this.updatedExternally);
			this.updatedExternally();
		}
	}

	reboot() {
		return this.props.item.set({
			doReboot: true,
		});
	}

	zigbeeReset() {
		return this.props.item.set({
			doZigbeeReset: true,
		});
	}

	hasChanged() {
		return this.state.beta !== this.props.item.beta || this.state.staging !== this.props.item.staging || this.state.enhancedLogging !== this.props.item.enhancedLogging || this.state.debug !== this.props.item.debugMode || this.state.zigbeeDisabled !== this.props.item.zigbeeDisabled || this.state.scanning !== this.props.item.scanning;
	}

	confirmRebootChange(val) {
		this.setState({confirmReboot: val.target.checked})
	}

	confirmZigbeeResetChange(val) {
		this.setState({confirmZigbeeReset: val.target.checked})
	}

	stagingChange(val) {
		this.setState({staging: val.target.checked})
	}

	betaChange(val) {
		this.setState({beta: val.target.checked});
	}

	scanningChange(val) {
		this.setState({scanning: val.target.checked})
	}

	debugChange(val) {
		this.setState({debug: val.target.checked})
	}

	enhancedLoggingChange(val) {
		this.setState({enhancedLogging: val.target.checked})
	}

	zigbeeDisabledChange(val) {
		this.setState({zigbeeDisabled: val.target.checked})
	}

	updatedExternally(field) {
		let self = this;
		this.setState({
			updateRef: this.state.updateRef++,
			staging: this.props.item.staging,
			beta: this.props.item.beta,
			debug: this.props.item.debugMode,
			enhancedLogging: this.props.item.enhancedLogging,
			scanning: this.props.item.scanning,
			zigbeeDisabled: this.props.item.zigbeeDisabled
		});
	}

	componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>, snapshot: SS) {
		let self = this;
		if (prevProps !== this.props || this.props.item.id !== prevProps.item.id) {
			if (prevProps.item) {
				prevProps.item.off('updated', this.updatedExternally);
			}
			this.props.item.on('updated', this.updatedExternally);
			this.updatedExternally();
		}
	}

	componentWillUnmount() {
		if (this.props.item && this.props.item.off) {
			this.props.item.off('updated', this.updatedExternally);
		}
	}

	cancelChanges() {
		this.setState({
			staging: this.props.item.staging,
			beta: this.props.item.beta,
			debug: this.props.item.debugMode,
			enhancedLogging: this.props.item.enhancedLogging,
			scanning: this.props.item.scanning,
			zigbeeDisabled: this.props.item.zigbeeDisabled
		});
	}

	saveChanges() {
		return this.props.item.set({
			staging: this.state.staging,
			debugMode: this.state.debug,
			enhancedLogging: this.state.enhancedLogging,
			beta: this.state.beta,
			scanning: this.state.scanning,
			zigbeeDisabled: this.state.zigbeeDisabled
		});
	}

	async openLogFile(id, resolve, reject, attempts) {
		
		if (!attempts) {
			attempts = 0;
		}
		if (attempts > 11) {
			return reject();
		}
		let url = 'https://s3-eu-west-1.amazonaws.com/gridduck-gateway-logs/' + id;
		try {
			const result = await fetch(url, {method: 'GET'});
			if (result.status !== 404) {
				
				window.open(url, '_blank');
				resolve();
			} else {
				setTimeout(() => this.openLogFile(id, resolve, reject, ++attempts),5000)
			}
		} catch (e) {
			setTimeout(() => this.openLogFile(id, resolve, reject, ++attempts),5000);
		}
	}

	downloadLogs() {
		let gateway = this.props.item;
		let hub = {
			gatewayId: gateway.id,
		}
		let self = this;
		return GridDuck.createGatewayLogDownloadRequest(hub)
			.then((res) => {
				let promise = new Promise(function (resolve, reject) {
					self.openLogFile(res.id, resolve, reject);
				})
				return promise;
			})
			.catch((err) => Promise.reject(err))

	}

	activateSSHTunnel() {
		function pad(num, size) {
			num = num.toString();
			while (num.length < size) num = "0" + num;
			return num;
		}
		let gateway = this.props.item;
		let hub = {
			gatewayId: gateway.id,
		}
		let self = this;
		return GridDuck.createGatewayEnhancedLogDownloadRequest(hub)
			.then((res) => {
				let num = parseInt(gateway.serialNumber.substr(gateway.serialNumber.length-2), 16);
				self.setState({
					tunnelPort: "ssh -t ubuntu@debug.gridduck.com -i ~/.ssh/debug_key.pem 'ssh -i keys/"+gateway.serialNumber+" root@localhost -p22" + pad(num,3)+ "; bash -l'"
				});
			})
			.catch(function(err) {
				
			})

	}

	downloadEnhancedLogs() {
		let gateway = this.props.item;
		let hub = {
			gatewayId: gateway.id,
		}
		let self = this;
		return GridDuck.createGatewayEnhancedLogDownloadRequest(hub)
			.then((res) => {
				let promise = new Promise(function (resolve, reject) {
					self.openLogFile(res.id, resolve, reject);
				})
				return promise;
			})
			.catch((err) => Promise.reject(err))

	}

	resetOwners() {
		let gateway = this.props.item;
		let hub = {
			serialNumber: gateway.serialNumber,
			id: gateway.physicalGatewayId,
			resetOwners: true
		}
		
		return GridDuck.createPhysicalGateway(hub)
			.then((res) => {
				
				return Promise.resolve(true)
			})
			.catch((err) => Promise.reject(err))
		
	}

	render() {
		
		let self = this;
		let footerSaveBar = this.hasChanged() ?
			<SaveBar onSaveClick={this.saveChanges} onCancelClick={this.cancelChanges}/> : '';
		return (
			<div className={"page grey narrow not-flex"}>
				<div className={'column top'}>
					<div className={'detail-content'}>
						<Card>
							<CardHeader title={'Ops Settings'}/>
							<CardBody>
								<GdCheckbox checked={this.state.staging} name={'staging'} onChange={this.stagingChange}
											label={'Staging API Mode'}/>
								<br/>
								<GdCheckbox checked={this.state.beta} name={'beta'} onChange={this.betaChange}
											label={'Beta API Mode'}/>
								<br/>
								<GdCheckbox checked={this.state.debug} name={'debug'} onChange={this.debugChange}
											label={'Debug Mode'}/>
								<br/>
								<GdCheckbox checked={this.state.enhancedLogging} name={'enhancedLogging'} onChange={this.enhancedLoggingChange}
											label={'Enhanced Logging Mode'}/>
								<br/>
								<GdCheckbox checked={this.state.zigbeeDisabled} name={'zigbeeDisabled'}
											onChange={this.zigbeeDisabledChange} label={'Zigbee Disabled'}/>
								<br/>
								<GdCheckbox checked={this.state.scanning} name={'scanning'} label={'Scanning'}
											onChange={this.scanningChange}/>
							</CardBody>
						</Card>
					</div>
					<div className={'detail-content'}>
						<Card>
							<CardHeader title={'Reboot'}/>
							<CardBody>
								<GdCheckbox checked={this.state.confirmReboot} name={'confirmReboot'}
											onChange={this.confirmRebootChange} label={'Confirm Reboot'}/>
								<br/>
								<br/>
								{this.state.confirmReboot ?
									<Button onClick={self.reboot} progressRes additionalclasses={'sm'} color={'gd-red'}
											label={'Reboot'}/> : ''}
							</CardBody>
						</Card>
					</div>
					<div className={'detail-content'}>
						<Card>
							<CardHeader title={'Reset Zigbee'}/>
							<CardBody>
								<GdCheckbox checked={this.state.confirmZigbeeReset} name={'confirmZigbeeReset'}
											onChange={this.confirmZigbeeResetChange} label={'Confirm Reset Zigbee'}/>
								<br/>
								<p>
									Caution: This will reboot the gateway and require all connected zigbee devices to be
									physically reset.
								</p>
								<br/>
								{this.state.confirmZigbeeReset ?
									<Button onClick={self.zigbeeReset} progressRes additionalclasses={'sm'}
											color={'gd-red'}
											label={'Reset Zigbee'}/> : ''}
							</CardBody>
						</Card>
					</div>
					<div className={'detail-content'}>
						<Card>
							<CardHeader title={'Download Logs'}/>
							<CardBody>
								<div style={{"width": "200px"}}>
									<Button
										progressRes
										color={'gd-blue'}
										label={'Download Logs'}
										onClick={this.downloadLogs}/>
								</div>

								<br/>
							</CardBody>
						</Card>
					</div>
					<div className={'detail-content'}>
						<Card>
							<CardHeader title={'Download Enhanced Logs'}/>
							<CardBody>
								<div style={{"width": "200px"}}>
									<Button
										progressRes
										color={'gd-blue'}
										label={'Download Enhanced Logs'}
										onClick={this.downloadEnhancedLogs}/>
								</div>

								<br/>
							</CardBody>
						</Card>
					</div>
					<div className={'detail-content'}>
						<Card>
							<CardHeader title={'SSH Tunnel'}/>
							<CardBody>
								<div style={{"width": "200px"}}>
									<Button
										progressRes
										color={'gd-blue'}
										label={'Activate SSH Tunnel'}
										onClick={this.activateSSHTunnel}/>
								</div>
								{!this.state.tunnelPort ? '' :
									(
										<p>{this.state.tunnelPort}</p>
									)

								}

								<br/>
							</CardBody>
						</Card>
					</div>
					<div className={'detail-content'}>
						<Card>
							<CardHeader title={'Reset Owners'}/>
							<CardBody>
								<div style={{"width": "200px"}}>
									<Button
										progressRes
										color={'gd-green'}
										label={'Reset Owners'}
										onClick={this.resetOwners}/>
								</div>

								<br/>
								<p>
									Caution: This will remove all owner organisations from the hub with the exception of
									GridDuck. For hub resale / reassignment.
								</p>
							</CardBody>
						</Card>
					</div>
					<div className={'detail-content'}>
						{footerSaveBar}
					</div>
				</div>
			</div>
		)
	}

}

export default GatewayOps;

import React from 'react';
import './Account.scss';
import GridDuck from 'gridduck';
import {Card, CardBody, CardHeader, Button, Icon, Tooltip} from "gd-react";
import GenericLoader from "../../components/GenericLoader";
import moment from "moment-timezone";
import SubTiers from "../../modals/SubTier/SubTiers";

class AccountSubscriptions extends React.Component {
    constructor(props) {
        super(props);
        const self = this;
        this.state = {};
        this.formatter = new Intl.NumberFormat('en-UK', {
            style: 'currency',
            currency: 'GBP',
        });
        this.getSubs = this.getSubs.bind(this);
    }

    componentDidMount() {
        this.getSubs();
    }

    getSubs() {
        let self = this;
        this.setState({loaded: false}, () => {
            GridDuck.getGridduckSubscriptions({
                getAll: true,
                filters: [{field: 'reset', value: Math.random()}]
            }).then(function (subs) {
                self.setState({subscriptions: subs.list, loaded: true});
            });
        });
    }

    render() {
        if (this.state.subscriptions) {
            let self = this;
            let total = 0;

            return (
                <div className='page narrow Account not-flex grey'>
                    <div className={'column top'}>
                        <div className={'detail-content column'} style={{backgroundColor: 'transparent'}}>
                            {this.state.subscriptions.map((_sub) => {
                                let subs = _sub.items;

                                let subTotal = 0;
                                subs.forEach((sub) => total += (sub.plan.amount * sub.quantity));
                                subs.forEach((sub) => subTotal += (sub.plan.amount * sub.quantity));
                                return <Card className={'separated-card'}>
                                    <CardHeader>
                                        <div style={{display: 'flex', justifyContent: 'space-between'}}><p
                                            style={{textAlign: 'right', margin: '0'}}>Next charge
                                            date {moment.unix(_sub.billingCycleAnchor).format('DD/MM/YYYY')}</p>
                                        </div>
                                    </CardHeader>
                                    <table className={'invoices'}>
                                        <tbody>
                                        <tr>
                                            <th>Item</th>
                                            <th>Quantity</th>
                                            <th>Total</th>
                                        </tr>
                                        {subs.map(function (sub) {
                                            const interval = sub?.price?.recurring?.interval;
                                            return <tr key={sub.id} className={'invoice'}>
                                                <td>
                                                    <p>{sub.plan.name}<br/><span>{self.formatter.format(sub.plan.amount / 100)} per {interval}</span>
                                                    </p></td>
                                                <td>{sub.quantity}x</td>
                                                <td>{self.formatter.format((sub.plan.amount * sub.quantity) / 100)}</td>
                                            </tr>
                                        })}
                                        <tr key={'total'} className={'invoice'}>
                                            <td></td>
                                            <td></td>
                                            <td style={{fontWeight: 700}}>{self.formatter.format((subTotal) / 100)}</td>
                                        </tr>
                                        </tbody>
                                    </table>

                                </Card>
                            })}
                            <Card>
                                <table className={'invoices'}>
                                    <tbody>
                                    <tr key={'total'} className={'invoice'}>
                                        <td></td>
                                        <td></td>
                                        <td style={{
                                            fontWeight: 700,
                                            textAlign: 'end',
                                            padding: '16px 65px 7px 0'
                                        }}>Grand
                                            Total: {self.formatter.format((total) / 100)}</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </Card>
                            {!this.state.loaded ?
                                <GenericLoader text={"Fetching"} textLineTwo={"Subscriptions..."}/> : null}
                            {this.state.loaded && !this.state.subscriptions.length &&
                                <Card>
                                    <CardHeader>Subscriptions</CardHeader>
                                    <CardBody>
                                        <p>You don't have any subscriptions yet</p>
                                    </CardBody>
                                </Card>}
                        </div>
                    </div>

                </div>
            );
        } else return <div></div>
    }
}

export default AccountSubscriptions;

import React, {Component} from "react";
import {Button, CardBody, Dropdown, Input} from "gd-react";
import GridDuck from 'gridduck';
import GdModal from "../../components/GdModal/GdModal";

class ShareDataRequestModal extends Component {

    constructor(props) {
        super(props);
        this.closeModal = this.closeModal.bind(this);
        this.generateLink = this.generateLink.bind(this);
        this.clipboard = this.clipboard.bind(this);
        this.state = {
            expiryNum: 1,
            expiryPeriod: 18144000
        };
    }

    clipboard() {
        let self = this;
        
        return navigator.clipboard.writeText(this.state.url).then(function () {
            self.setState({copied: true});
        });

    }

    generateLink() {
        let self = this;
        let expiresTime = this.state.expiryNum * this.state.expiryPeriod;
        if (this.props.invoices) {
            return GridDuck.createInvoiceRequestLink({
                invoiceRequestId: this.props.dataRequestId,
                expiresTime: expiresTime
            }).then(function (dataRequestLink) {
                self.setState({url: dataRequestLink.url});
            });
        } else if (this.props.report) {
            return GridDuck.createReportFileLink({
                fileId: this.props.dataRequestId,
                expiresTime: expiresTime
            }).then(function (dataRequestLink) {
                self.setState({url: dataRequestLink.url});
            });
        } else {
            return GridDuck.createDataRequestLink({
                dataRequestId: this.props.dataRequestId,
                expiresTime: expiresTime
            }).then(function (dataRequestLink) {
                self.setState({url: dataRequestLink.url});
            });
        }
    }

    closeModal() {
        this.modalClosed = true;
        this.props.onClose();
    }

    render() {
        return (
            <GdModal
                open={this.props.open}
                contentLabel={this.props.report ? 'Share Report' : this.props.invoices ? 'Share Invoice' : 'Share Data'}
                title={this.props.report ? 'Share Report' : this.props.invoices ? 'Share Invoice' : 'Share Data'}
                footer={
                    <Button progressRes additionalclasses={'sm'} label={'Generate link'} color={'gd-green'}
                            onClick={this.generateLink}/>
                }
                onClose={this.closeModal}>
                <CardBody>
                    <div style={{display: 'flex'}}>
                        <div className={'left-input'} style={{display: 'flex', flex: '1'}}><Input type={'number'}
                                                                                                  InputProps={{inputProps: {min: 1}}}
                                                                                                  top={'true'}
                                                                                                  label={"Link will expire in"}
                                                                                                  value={this.state.expiryNum}
                                                                                                  onChange={e => this.setState({
                                                                                                      expiryNum: e.target.value ? e.target.value > 0 ? e.target.value : 1 : e.target.value,
                                                                                                      url: null
                                                                                                  })}/>
                        </div>
                        <div className={'right-input'} style={{marginTop: '16px', width: '130px'}}><Dropdown
                            onChange={e => this.setState({expiryPeriod: e.target.value, url: null})}
                            fixeditems={[
                                {
                                    id: 60,
                                    value: 60,
                                    title: 'minute' + (this.state.expiryNum > 1 ? 's' : '')
                                },
                                {
                                    id: 3600,
                                    value: 3600,
                                    title: 'hour' + (this.state.expiryNum > 1 ? 's' : '')
                                },
                                {
                                    id: 86400,
                                    value: 86400,
                                    title: 'day' + (this.state.expiryNum > 1 ? 's' : '')
                                },
                                {
                                    id: 604800,
                                    value: 604800,
                                    title: 'week' + (this.state.expiryNum > 1 ? 's' : '')
                                },
                                {
                                    id: 18144000,
                                    value: 18144000,
                                    title: 'month' + (this.state.expiryNum > 1 ? 's' : '')
                                },
                            ]}
                            value={this.state.expiryPeriod}/></div>
                    </div>
                    {this.state.url ? (<div>
                        <div className={"row"}>
                            <Input disabled value={this.state.url}/>
                        </div>
                        <div 
                             style={{alignItems: 'center', justifyContent: 'center', marginTop: '20px'}}>
                            <Button additionalclasses={'md'} progressRes color={'gd-blue'} className={"copy-link"}
                                    onClick={this.clipboard} label={'Copy Link To Clipboard'}/>
                        </div>
                    </div>) : ''}
                    {/*{this.state.copied ? (<div className={"row"}>Link copied to clipboard</div>) : ''}*/}
                </CardBody>
            </GdModal>
        )
    }
}

export default ShareDataRequestModal;

import formatValue from "./formatterV2";

function GenerateExampleUnit(item, params) {
    let md = item?.metaData || item;
    let array = params?.array;

    function val(value) {
        return formatValue({value: value, unit: item, unitOnly: true, currency: 'gbp', array: array});
    }

    if (md) {
        let valueToReturn = '';
        if (array) {
            valueToReturn = val();
        } else {
            if (item.unitType === 'metric') {
                valueToReturn = val(1) + ', ' + val(1000) + ', ' + val(1000000) + ', ' + val(1000000000) + '...';
            } else if (item.unitType === 'nonMetric' && md.rules && md.rules.length) {
                // 
                md.rules.forEach((r, i) => {
                    if (i > 0) valueToReturn += ', ';
                    if (i < 5) valueToReturn += val(r.scaleOfBase);
                });
                if (md.rules.length > 4) {
                    valueToReturn += '...';
                }
            } else if (item.unitType === 'dimensionless') {
                if (item.metaData.symbol) {
                    valueToReturn = item.metaData.symbol
                } else {
                    valueToReturn = val(1) + ', ' + val(2);
                }
            } else if (item.unitType === 'currency') {
                valueToReturn = '£, $, €...'
            } else if (item.unitType === 'time') {
                valueToReturn = 's, m, h, d, y...'
            } else if (item.unitType === 'categorical' && md.categories && md.categories.length) {
                md.categories.forEach((r, i) => {
                    if (i > 0) valueToReturn += ', ';
                    if (i < 5) valueToReturn += r.label;
                });
                if (md.categories.length > 4) {
                    valueToReturn += '...';
                }
            } else if (item.unitType === 'boolean' && md.categories && md.categories.length) {
                valueToReturn += md.categories[0].label + '/' + md.categories[1].label
            }
        }
        return valueToReturn;
    } else {
        return ''
    }
}

export default GenerateExampleUnit;
import React from "react";
import {Menu} from '@material-ui/core'
import {Icon, Dropdown, Button} from "gd-react";
import GridDuck from 'gridduck'
import GenericLoader from "./GenericLoader";
import getFormat from "../services/formatter";
import AddEditCustomFieldTypeModal from "../modals/AddEditCustomField/AddEditCustomFieldType";
import {isMobile} from "react-device-detect";
import history from "../meta/history";
import SiteOccupancyScheduleModal from "../modals/SiteOccupancyScheduleModal/SiteOccupancyScheduleModal";

class OccupancyScheduleSetterMenu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            anchorEl: null,
            loading: true,
            showOccupancyScheduleModal: false
        };
        this.handleClick = this.handleClick.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.loadOccupancies = this.loadOccupancies.bind(this);
    }

    async handleClick(e) {
        
        let self = this;
        self.setState({
            anchorEl: e ? e.currentTarget : this.state.anchorEl,
            loading: true
        });
        await this.loadOccupancies();
    }

    async loadOccupancies(reload) {
        let self = this;
        if (reload) {
            self.setState({
                loading: true
            });
        }
        // Load Menu Items
        let os = await GridDuck.getOccupancySchedules({getAll: true});
        self.setState({occupancySchedules: os.list, loading: false, allOccupancySchedules: os.list});
    }

    handleClose() {
        this.setState({anchorEl: null});
    }

    render() {

        return (
            <div className={'filter-menu'}>
                <div style={{position: 'relative', overflow: 'hidden'}} onClick={(e) => {
                    
                    e.stopPropagation();
                    return this.handleClick(e);
                    }
                }>
                    {this.props.children}
                </div>
                <Menu
                    id="filter-menu"
                    getContentAnchorEl={null}
                    anchorOrigin={{vertical: "bottom", horizontal: "left"}}
                    transformOrigin={{vertical: "top", horizontal: "left"}}
                    anchorEl={this.state.anchorEl}
                    open={!!(this.state.anchorEl)}
                    disableEnforceFocus
                    onClose={this.handleClose}>
                    <div className={'filter-menu-container kpi-tooltip'}>
                        <div className={'menu-header'}>
                            Occupancy Schedule<br/>
                            <span style={{
                                fontSize: '11px',
                                color: 'grey'
                            }}>{this.props.site.name}</span>
                        </div>

                        <div className={'filter-items'}>
                            {!this.state.loading && this.state.occupancySchedules ? this.state.occupancySchedules.map(cft => {
                                return <div key={cft.id}
                                            className={'row icon-row ' + (this.props.site.occupancyScheduleId === cft.id ? 'selected' : '')}
                                            onClick={async () => {
                                                await this.props.site.set({occupancyScheduleId: cft.id});
                                                // this.handleClose();
                                                window.setTimeout(() => {
                                                    this.props.onComplete(cft);
                                                }, 100);
                                            }}>
                                    <Icon size={'14'} color={'#49b9c4'} icon={'FaClock'}/>
                                    <p>{cft.name}</p>
                                </div>
                            }) : null}
                            {!isMobile && !this.state.loading && this.state.allOccupancySchedules && this.state.allOccupancySchedules.length ?
                                <div key={'add-new'} className={'row icon-row'}
                                     onClick={() => this.setState({showOccupancyScheduleModal: true})}>
                                    <Icon size={'14'} color={'#49b9c4'} icon={'FaPlus'}/>
                                    <p style={{color: '#49b9c4'}}>Create New</p>
                                </div> : null}
                            {!this.state.loading && (!this.state.occupancySchedules || !this.state.occupancySchedules.length) && (!this.state.allOccupancySchedules || !this.state.allOccupancySchedules.length) ?
                                <div style={{
                                    display: 'flex',
                                    'flexDirection': 'column',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    padding: '10px'
                                }}><span
                                    style={{fontSize: '12px', marginBottom: '10px'}}>You have not yet created any occupancy schedules</span><Button
                                    label={'Create Occupancy Schedule'}
                                    onClick={() => {
                                        this.setState({showOccupancyScheduleModal: true})
                                    }}
                                    style={{
                                        height: '20px',
                                        fontSize: '11px',
                                        lineHeight: '20px',
                                        marginTop: '5px'
                                    }}/></div> : null}
                            {this.state.loading ? <GenericLoader/> : null}
                            <p onClick={() => history.push('/settings/organisation/occupancy-schedules')}
                               className={'link'} style={{
                                fontSize: '11px',
                                margin: '0',
                                marginTop: '15px',
                            }}>Manage Occupancy Schedules</p>
                            <SiteOccupancyScheduleModal
                                onSave={async (msg, item, e) => {
                                    await this.props.site.set({occupancyScheduleId: item.id});
                                    await this.loadOccupancies(true);
                                    // this.handleClose();
                                    window.setTimeout(() => {
                                        this.props.onComplete(item);
                                    }, 100);
                                }}
                                onClose={() => this.setState({showOccupancyScheduleModal: false})}
                                open={this.state.showOccupancyScheduleModal}/>
                        </div>
                    </div>
                </Menu>
            </div>
        )
    }
}

export default OccupancyScheduleSetterMenu;

import React from "react";
import "./Reports.scss";
import {Input, Button, CardBody, Icon, Tooltip, Toast} from "gd-react";
import * as _ from 'underscore'
import {Document, Page} from 'react-pdf';
import Toggle from "../../components/Toggle/Toggle";
import getFormat from "../../services/formatter";
import history from "../../meta/history"
import ShareDataRequestModal from "../../modals/ShareDataRequest/ShareDataRequestModal";
import GridDuck from "gridduck";
import GenericLoader from "../../components/GenericLoader";
import EditReport from "../../modals/EditReport/EditReport";
import ReportListItem from "./ReportListItem";
import PdfListItem from "./PdfListItem";
import GdModal from "../../components/GdModal/GdModal";
import NoItems from "../../components/noItems";
import AddInvoiceModal from "../../modals/AddInvoice/AddInvoiceModal";
import CreateReportScheduleModal from "../../modals/CreateReportSchedule/CreateReportScheduleModal";

class MainReportsPage extends React.Component {
    constructor(props) {
        super(props);
        let self = this;
        this.toggleReportActive = this.toggleReportActive.bind(this);
        this.pdfLoaded = this.pdfLoaded.bind(this);
        this.urlCopied = this.urlCopied.bind(this);
        this.selectReport = this.selectReport.bind(this);
        this.selectDateRange = this.selectDateRange.bind(this);
        this.getReports = this.getReports.bind(this);
        this.searchReports = this.searchReports.bind(this);
        this.openListModal = this.openListModal.bind(this);
        this.updateReports = this.updateReports.bind(this);
        this.getAssetIdsFromSiteIds = this.getAssetIdsFromSiteIds.bind(this);
        this.sharePdf = this.sharePdf.bind(this);
        this.openPdf = this.openPdf.bind(this);
        this.generateLink = this.generateLink.bind(this);
        this.selectReportHistory = this.selectReportHistory.bind(this);
        this.routeToUrlLoc = this.routeToUrlLoc.bind(this);

        this.pdfViewRef = React.createRef();

        this.unregisterHistoryListener = history.listen((location) => {
            if (location.pathname.split('/')[1] === 'reports') {
                self.routeToUrlLoc(location.pathname);
            }
        });

        this.state = {
            selectedReport: null,
            selectedPdf: null,
            reportHistory: null,
            pageNumber: 1,
            factorIncrease: 1,
            copiedId: {},
            reportSearchFilter: '',
            showEditReportModal: null,
            retrievedAssetIds: null,
            openOnTab: null,
            loadedGroupDevices: false
        };
        this.getReports().then(function () {
            self.reports.on('updated', self.updateReports);
        })
    }

    componentDidMount() {
        let self = this;
        GridDuck.getAccount({id: GridDuck.userId}).then(function (accRes) {
            self.setState({account: accRes})
        })
    }

    routeToUrlLoc(pn) {
        let self = this;
        let reportId = pn.split('/')[2];
        let startTime = pn.split('/')[3];
        
        if (reportId && reportId !== 'create') {
            let selReport = self.state.reports.find((report) => report.id === reportId);
            
            this.selectReport(selReport, startTime).then(() => {
            });
        } else {
            self.setState({selectedReport: null});
        }
    }

    selectReportHistory(rId, startTime) {
        this.setState({rHFromClick: true}, function () {
            history.push('/reports/' + rId + '/' + startTime)
        })
    }

    updateReports() {
        
        this.setState({reports: this.reports.list});
    }

    componentWillUnmount() {
        this.unregisterHistoryListener();
        if (this.state.reports && this.state.reports.off) {
            this.reports.off('updated', this.updateReports);
        }
    }

    searchReports(e) {
        this.setState({
            reportSearchFilter: e ? e.target.value : ''
        }, () => this.getReports());
    }

    getReports() {
        let self = this;
        return GridDuck.getReports({
            getAll: true, filters: [{
                field: 'search',
                value: self.state.reportSearchFilter
            }]
        }).then(function (rreports) {
            self.reports = rreports;
            // if (!self.state.reportSearchFilter.length && !rreports.list.length) {
            //     history.push('/reports/create')
            // }
            
            
            self.setState({reports: rreports.list, loaded: true}, () => {
                self.routeToUrlLoc(history.location.pathname);
            });
        })
    }

    async getAssetIdsFromSiteIds(siteIds) {
        
        let assetsFromSiteIds = [], assetList = []
        for (const sId of siteIds) {
            let siteInfo = await GridDuck.getSite({id: sId}).catch((err) => {});
            if (siteInfo && siteInfo.id) {
                assetsFromSiteIds.push(GridDuck.getAssets({
                    getAll: true,
                    filters: [{field: 'siteId', value: sId}]
                }).then((asRes) => {
                    
                    assetList = assetList.concat(asRes.list.map((a) => a.id))
                }).catch((err) => {}));
            }
        }
        await Promise.all(assetsFromSiteIds);
        return assetList;
    }

    async selectReport(report, startTime) {
        let self = this;
        let newAssetIdList;
        
        if (report) {

            self.setState({loadingReportHistory: !this.state.rHFromClick, selectedReport: report});

            if (report.siteIds && report.siteIds.length || report.groupIds && report.groupIds.length || report.siteGroupIds && report.siteGroupIds.length) newAssetIdList = [];
            if (report.siteIds && report.siteIds.length && report._permission === 'admin') {
                let assetIdsFromSite = await self.getAssetIdsFromSiteIds(report.siteIds);
                newAssetIdList = newAssetIdList.concat(assetIdsFromSite);
            }
            if (report.groupIds && report.groupIds.length) {
                let assetsFromGroupIds = [];
                for (const gId of report.groupIds) {
                    let groupInfo = await GridDuck.getGroup({id: gId}).catch((err) => {});
                    if (groupInfo && groupInfo.id) {
                        assetsFromGroupIds.push(GridDuck.getGroup({
                            id: gId
                        }).then((asRes) => newAssetIdList = newAssetIdList.concat(asRes.assetIds)).catch((err) => {}));
                    }
                }
                await Promise.all(assetsFromGroupIds);
            }

            if (report.siteGroupIds && report.siteGroupIds.length) {
                let assetsFromSiteGroupIds = [], siteIds = [];
                for (const sgId of report.siteGroupIds) {
                    let siteGroupInfo = await GridDuck.getSiteGroup({id: sgId}).catch((err) => {});
                    if (siteGroupInfo && siteGroupInfo.id) {
                        assetsFromSiteGroupIds.push(GridDuck.getSiteGroup({
                            id: sgId
                        }).then((asRes) => siteIds = siteIds.concat(asRes.siteIds)).catch((err) => {}));
                    }
                }
                await Promise.all(assetsFromSiteGroupIds);
                let assetIdsFromSite = await self.getAssetIdsFromSiteIds(siteIds);
                newAssetIdList = newAssetIdList.concat(assetIdsFromSite);
            }

            self.setState({loadedGroupDevices: true});

            GridDuck.getReportHistorys({
                sortBy: {
                    field: 'start_time',
                    type: 'desc'
                }, filters: [
                    {
                        field: 'reportId',
                        value: report.id
                    }
                ], getAll: true
            }).then(function (rhs) {
                self.setState({
                    retrievedAssetIds: newAssetIdList,
                    reportHistory: rhs.list,
                    loadingReportHistory: false,
                    rHFromClick: false
                }, function () {
                    
                    if (startTime) {
                        
                        let rhFromList = rhs.list.find((rh) => rh.startTime.toString() === startTime.toString());
                        self.selectDateRange(report, startTime, rhFromList);
                    }
                });

            }).catch((err) => {});

        } else {
            history
                .push(
                    '/reports'
                )
        }
    }

    selectDateRange(report, startTime, rh) {
        let self = this;

        
        
        self.setState({selectedPdf: rh});
        GridDuck.getReportFiles({
            filters: [
                {
                    field: 'reportId',
                    value: report.id
                },
                {
                    field: 'startTime',
                    value: startTime
                }
            ], getAll: true
        }).then(function (rfs) {
            
            let pdfsGroupedByType = _.groupBy(rfs.list, (li) => li.type);
            
            self.setState({pdfs: pdfsGroupedByType});
        });
    }

    async openListModal(type, report) {
        let items;
        let title = report.name + ' report ' + type;
        this.setState({
            showListModal: {
                title: title,
                type: type,
                loading: true
            }
        })

        if (type === 'devices') {
            let promises = [];
            let assetsToGet;
            if (this.state.retrievedAssetIds) assetsToGet = this.state.retrievedAssetIds;
            else assetsToGet = report.assetIds;
            assetsToGet.forEach((id) => promises.push(GridDuck.getAsset({id: id}).catch((err) => {})))
            await Promise.all(promises).then(function (res) {
                items = res.map(function (item) {
                    if (item && item.name) return item.name + ' (' + item.siteName + ')';
                })
                
            })
        }
        if (type === 'recipients') items = report.recipients;

        this.setState({
            showListModal: {
                title: title,
                type: type,
                items: items,
                loading: false
            }
        })
    }

    toggleReportActive() {
        let self = this;
        this.state.selectedReport.set({active: !self.state.selectedReport.active}).then(function () {
            self.setState({report: self.state.selectedReport});
        });
    }

    pdfLoaded(pn) {
        this.setState({numberOfPages: pn._pdfInfo.numPages});
    }

    sharePdf(file) {
        this.setState({shareData: file});
    }

    downloadPdf(file) {
        this.generateLink(file).then(function (dataRequestLink) {
            window.open(dataRequestLink.url, '_blank');
        })
    }

    openPdf(file) {
        let self = this;
        this.generateLink(file).then(function (dataRequestLink) {
            self.setState({
                selectedPdfToShow: file,
                pageNumber: 1,
                factorIncrease: 1,
                url: dataRequestLink.url
            })
        })
    }

    generateLink(file) {
        
        let expiresTime = this.state.expiryNum * this.state.expiryPeriod;
        return GridDuck.createReportFileLink({
            fileId: file.id,
            expiresTime: expiresTime
        });
    }

    urlCopied(id) {
        let self = this;
        this.state.copiedId[id] = true;
        self.setState({copiedId: this.state.copiedId})
        window.setTimeout(function () {
            self.state.copiedId[id] = false;
            self.setState({copiedId: self.state.copiedId});
        }, 1500);
    }

    render() {

        let self = this, totals = [];
        let reportsDOM = this.state.reports && this.state.reports.length ? this.state.reports.map(function (report) {
            return <ReportListItem key={report.id} onClick={() => history.push('/reports/' + report.id)} item={report}/>
        }) : null;

        if (this.state.pdfs) {
            totals = [this.state.pdfs.summary[0]];
            if (this.state.pdfs.combined && this.state.pdfs.combined.length) {
                totals.push(this.state.pdfs.combined[0]);
            }
        }
        if (this.state.loaded) {

            if (this.state.reports && this.state.reports.length) {
                return (
                    <div className={'Reports'} ref={this.pdfViewRef}>
                        <div className={'left-panel ' + (this.state.selectedReport ? 'show-details' : '')}>
                            <div className={'wide-inner-panel'}>
                                <div className={'report-details report-list'}>
                                    <div className={'details-section'}>
                                        <Input value={this.state.reportSearchFilter}
                                               onChange={(e) => this.searchReports(e)}
                                               InputProps={{
                                                   startAdornment: (
                                                       <Icon color={'grey'} size={'12'} icon={'FaSearch'}/>)
                                               }} placeholder={'Search'}/>
                                        {this.state.reportSearchFilter.length ?
                                            <div onClick={() => this.searchReports()} className={'cancel-search'}><Icon
                                                color={'grey'} size={'12'} icon={'FaTimes'}/></div> : null}
                                    </div>
                                    <div className={'list-section'}>
                                        {reportsDOM && this.state.loaded ? reportsDOM : null}
                                        {!this.state.loaded ?
                                            <GenericLoader text={"Fetching"} textLineTwo={"Reports..."}/> : null}
                                        {this.state.loaded && !this.state.reports.length ? <p style={{
                                            textAlign: 'center',
                                            marginTop: '50px'
                                        }}>No reports matching "{this.state.reportSearchFilter}"</p> : null}
                                    </div>
                                </div>
                                <div className={'report-details'}>
                                    <div className={'report-header'}>
                                        <Tooltip title={'Back'} label={'Back'} position={'bottom'}>
                                            <div className={'left-arrow'} onClick={() => {
                                                history.push('/reports');
                                                this.setState({
                                                    selectedReport: null,
                                                    selectedPdf: null,
                                                    selectedPdfToShow: null,
                                                    reportHistory: null,
                                                    pageNumber: 1,
                                                    factorIncrease: 1,
                                                    loadedGroupDevices: false
                                                })
                                            }}>
                                                <Icon color={'grey'} size={'13'} icon={'FaChevronLeft'}/></div>
                                        </Tooltip>
                                        {this.state.selectedReport ? <p>{this.state.selectedReport.name}</p> : ''}
                                        <span style={{display: 'flex', flex: '1 1'}}/>
                                        {this.state.account && !window.$exampleAccount && this.state.selectedReport && (this.state.selectedReport._permission === 'owner' || this.state.selectedReport._permission === 'admin' || this.state.selectedReport._permission === 'edit') ?
                                            <Tooltip title={'Edit'} label={'Edit'} position={'bottom'}>
                                                <div className={'left-arrow thin'}
                                                     onClick={() => this.setState({showEditReportModal: true})}>
                                                    <Icon color={'grey'} size={'15'} icon={'FaCog'}/></div>
                                            </Tooltip> : null}
                                    </div>
                                    {this.state.selectedReport ?
                                        <div className={'details-section'}>
                                            {this.state.account && (this.state.selectedReport._permission === 'owner' || this.state.selectedReport._permission === 'admin' || this.state.selectedReport._permission === 'edit') ?
                                                <div className={'details-row'}><Toggle
                                                    onClick={!window.$exampleAccount && this.state.account.orgPermission !== 'view' ? () => this.setState({showToggleActiveModal: true}) : () => {
                                                    }}
                                                    inactivestatename={'Inactive'} activestatename={'Active'}
                                                    active={this.state.selectedReport.active.toString()}/>
                                                </div> : null}
                                            <div className={'details-row'}>
                                                <div className={'details-row-icon'}>
                                                    <Tooltip label={'Sent out'} position={'top'}>
                                                        <Icon color={'grey'} size={'13'}
                                                              icon={this.state.selectedReport.frequency === 'monthly' ? 'FaCalendarWeek' : 'FaCalendarDay'}/>
                                                    </Tooltip>
                                                </div>
                                                <p style={{
                                                    marginBottom: '0',
                                                    paddingTop: '6px'
                                                }}>{getFormat('titleCase')(this.state.selectedReport.frequency)}</p>
                                                {/*on {this.state.selectedReport.frequency === 'monthly' ? ('the ' + getFormat('ordinalSuffix')(this.state.selectedReport.repeatOn)) : getFormat('weekDay')(this.state.selectedReport.repeatOn)}*/}
                                            </div>
                                            <div className={'details-row'}>
                                                <div className={'details-row-icon'}>
                                                    <Icon color={'grey'}
                                                          size={'13'}
                                                          icon={'FaPlug'}/>
                                                </div>
                                                {(this.state.retrievedAssetIds || this.state.selectedReport.assetIds) &&
                                                    <p style={{marginBottom: '0', paddingTop: '5px'}}
                                                       className={'link'}
                                                       onClick={() => this.openListModal('devices', this.state.selectedReport)}>{this.state.retrievedAssetIds ? this.state.retrievedAssetIds.length : this.state.selectedReport.assetIds.length} Device{(this.state.retrievedAssetIds ? this.state.retrievedAssetIds.length : this.state.selectedReport.assetIds.length) > 1 ? 's' : null}</p>}
                                            </div>
                                            <div className={'details-row'}>
                                                <div className={'details-row-icon'}>
                                                    <Tooltip label={'Send to'} position={'top'}>
                                                        <Icon color={'grey'} size={'13'} icon={'FaShare'}/>
                                                    </Tooltip>
                                                </div>
                                                <p style={{marginBottom: '0', paddingTop: '2px'}}
                                                   className={'link'}
                                                   onClick={() => this.openListModal('recipients', this.state.selectedReport)}>{this.state.selectedReport.recipients.length} Recipient{this.state.selectedReport.recipients.length > 1 ? 's' : null}</p>
                                            </div>
                                        </div> : null}
                                    {this.state.loadingReportHistory ?
                                        <GenericLoader text={'Fetching'} textLineTwo={"Historic Reports"}/> : null}
                                    {!this.state.loadingReportHistory && (!this.state.reportHistory || !this.state.reportHistory.length) ?
                                        <div className={'list-section'}><p style={{
                                            textAlign: 'center',
                                            marginTop: '50px'
                                        }}>No reports generated yet</p></div> : null}

                                    {this.state.selectedReport ?
                                        <div
                                            className={'wide-inner-panel-inner ' + (this.state.selectedPdf ? 'showing-pdfs' : '')}>
                                            <div className={'list-section'}>
                                                {this.state.reportHistory && this.state.reportHistory.length && !this.state.loadingReportHistory ?
                                                    <div className={'list-section'}>
                                                        {this.state.reportHistory.map(function (pdf) {
                                                            return <div
                                                                key={pdf.startTime + 'pdf'}
                                                                className={'report-list-item pdf'}
                                                                onClick={() => self.selectReportHistory(self.state.selectedReport.id, pdf.startTime)}>
                                                                <p>{pdf.rangeName}</p>
                                                                <div className={'right-arrow'}>
                                                                    <Icon color={'grey'} size={'13'}
                                                                          icon={'FaChevronRight'}/>
                                                                </div>
                                                            </div>
                                                        })}
                                                    </div> :
                                                    null}
                                            </div>
                                            <div className={'report-details'}>
                                                <div className={'report-header'}>
                                                    <Tooltip label={'Back'} position={'bottom'}>
                                                        <div className={'left-arrow'} onClick={() => {
                                                            history.push('/reports/' + this.state.selectedReport.id)
                                                            this.setState({
                                                                selectedPdf: null,
                                                                selectedPdfToShow: null,
                                                                pageNumber: 1,
                                                                factorIncrease: 1
                                                            })
                                                        }}>
                                                            <Icon color={'grey'} size={'13'} icon={'FaChevronLeft'}/>
                                                        </div>
                                                    </Tooltip>
                                                    {this.state.selectedPdf ?
                                                        <p>{this.state.selectedPdf.rangeName}</p> : ''}
                                                    <span style={{display: 'flex', flex: '1 1'}}/>
                                                    {/*{this.state.selectedPdf ?*/}
                                                    {/*    <Tooltip label={'Share all PDFs'} position={'bottom'}>*/}
                                                    {/*        <div className={'left-arrow thin'}*/}
                                                    {/*             onClick={() => this.setState({shareData: true})}>*/}
                                                    {/*            <Icon color={'grey'} size={'15'} icon={'FaShare'}/>*/}
                                                    {/*        </div>*/}
                                                    {/*    </Tooltip> : null}*/}
                                                    {/*<Tooltip label={'Download zip of all PDFs'} position={'bottom'}>*/}
                                                    {/*    <div className={'left-arrow thin'}*/}
                                                    {/*         onClick={() => window.open(self.state.selectedPdf.url, '_blank')}>*/}
                                                    {/*        <Icon color={'grey'} size={'15'} icon={'FaDownload'}/></div>*/}
                                                    {/*</Tooltip>*/}
                                                </div>

                                                {this.state.selectedPdf && (!this.state.pdfs || !this.state.pdfs.summary) &&
                                                    <GenericLoader text={"Fetching"} textLineTwo={"PDFs..."}/>}
                                                {/*<div className={'details-section no-padding'}>*/}
                                                {/*    {this.state.selectedPdf && this.state.pdfs && <p className={'divider-text'}>Total</p>}*/}
                                                {/*    {this.state.selectedPdf && this.state.pdfs && [this.state.pdfs.summary[0], this.state.pdfs.combined[0]].map(function (pdf) {*/}
                                                {/*            return <PdfListItem downloadPdf={() => {*/}
                                                {/*            }} sharePdf={() => {*/}
                                                {/*            }} selectedPdfToShow={self.state.selectedPdfToShow} pdf={pdf}*/}
                                                {/*                                onClick={(ppdf) => self.setState({*/}
                                                {/*                                    selectedPdfToShow: ppdf,*/}
                                                {/*                                    pageNumber: 1,*/}
                                                {/*                                    factorIncrease: 1*/}
                                                {/*                                })}/>*/}
                                                {/*        })}*/}
                                                {/*    {this.state.selectedPdf && this.state.pdfs && <p className={'divider-text'}>Individual Device</p>}*/}
                                                {/*</div>*/}
                                                <div className={'list-section'}>
                                                    {this.state.selectedPdf && this.state.pdfs &&
                                                        <p className={'divider-text'}>Total</p>}
                                                    {this.state.selectedPdf && this.state.pdfs && this.state.pdfs.summary && totals.map(function (pdf) {
                                                        return <PdfListItem downloadPdf={() => self.downloadPdf(pdf)}
                                                                            sharePdf={() => self.sharePdf(pdf)}
                                                                            selectedPdfToShow={self.state.selectedPdfToShow}
                                                                            pdf={pdf}
                                                                            key={pdf.id}
                                                                            onClick={self.openPdf}/>
                                                    })}
                                                    {this.state.selectedPdf && this.state.pdfs && this.state.pdfs.device && this.state.pdfs.device.length &&
                                                        <p className={'divider-text'}>Individual Device</p>}
                                                    {this.state.selectedPdf && this.state.pdfs && this.state.pdfs.device && this.state.pdfs.device.length && this.state.pdfs.device.map(function (pdf) {
                                                        return <PdfListItem downloadPdf={() => self.downloadPdf(pdf)}
                                                                            sharePdf={(pdf) => self.sharePdf(pdf)}
                                                                            selectedPdfToShow={self.state.selectedPdfToShow}
                                                                            pdf={pdf}
                                                                            key={pdf.id}
                                                                            onClick={self.openPdf}/>
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        : null}

                                </div>
                            </div>
                        </div>
                        <div className={'pdf-view'}>
                            {!this.state.selectedPdfToShow ? <div className={'no-pdf'}>
                                <p className={'no-pdf-title'}>Report Preview</p>
                                <p className={'no-pdf-sub-title'}>Select a report on the left to preview it here</p>
                            </div> : null}
                            {this.state.selectedPdfToShow && this.state.url ? <Document
                                loading={<div style={{
                                    position: 'absolute',
                                    top: 0,
                                    left: 0,
                                    display: 'flex',
                                    flex: '1 1',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    height: '100%',
                                    width: '100%'
                                }}><GenericLoader text={'Fetching Report'}
                                                  textLineTwo={this.state.selectedPdfToShow.name}/></div>}
                                file={this.state.url}
                                onLoadError={console.error}
                                onLoadSuccess={this.pdfLoaded}>
                                <Page width={this.state.factorIncrease * (this.pdfViewRef.current.clientWidth - 440)}
                                      pageNumber={this.state.pageNumber}/>
                            </Document> : null}
                            {this.state.numberOfPages && this.state.selectedPdfToShow && this.state.url ?
                                <div className="page-controls close">
                                    <Tooltip position={'bottom'}
                                             label={'Close'}>
                                        <div
                                            className={'control-icon'}
                                            onClick={() => self.setState({
                                                numberOfPages: null,
                                                selectedPdfToShow: null,
                                                url: null
                                            })}>
                                            <Icon color={'grey'} size={'13'} icon={'FaTimes'}/></div>
                                    </Tooltip>
                                </div>
                                : ''}
                            {this.state.numberOfPages && this.state.selectedPdfToShow && this.state.url ?
                                <div className="page-controls">
                                    <Tooltip position={'top'}
                                             label={this.state.pageNumber !== 1 ? 'Previous page' : 'On first page'}>
                                        <div
                                            className={'control-icon ' + (this.state.pageNumber === 1 ? 'disabled' : '')}
                                            onClick={this.state.pageNumber !== 1 ? () => this.setState({pageNumber: this.state.pageNumber - 1}) : null}>
                                            <Icon color={'grey'} size={'13'} icon={'FaChevronLeft'}/></div>
                                    </Tooltip>
                                    <p>{this.state.pageNumber} of {this.state.numberOfPages}</p>
                                    <Tooltip position={'top'}
                                             label={this.state.pageNumber !== this.state.numberOfPages ? 'Next page' : 'On last page'}>
                                        <div
                                            className={'control-icon ' + (this.state.pageNumber === this.state.numberOfPages ? 'disabled' : '')}
                                            onClick={this.state.pageNumber !== this.state.numberOfPages ? () => this.setState({pageNumber: this.state.pageNumber + 1}) : null}>
                                            <Icon color={'grey'} size={'13'} icon={'FaChevronRight'}/></div>
                                    </Tooltip>
                                    <Tooltip position={'top'} label={'Zoom out'}>
                                        <div className={'control-icon'}
                                             onClick={() => this.setState({factorIncrease: this.state.factorIncrease - 0.2})}>
                                            <Icon
                                                color={'grey'} size={'13'} icon={'FaMinus'}/></div>
                                    </Tooltip>
                                    <Tooltip position={'top'} label={'Reset zoom'}>
                                        <div className={'control-icon'}
                                             onClick={() => this.setState({factorIncrease: 1})}>
                                            <Icon
                                                color={'grey'} size={'13'} icon={'FaRedo'}/></div>
                                    </Tooltip>
                                    <Tooltip position={'top'} label={'Zoom in'}>
                                        <div className={'control-icon'}
                                             onClick={() => this.setState({factorIncrease: this.state.factorIncrease + 0.2})}>
                                            <Icon
                                                color={'grey'} size={'13'} icon={'FaPlus'}/></div>
                                    </Tooltip>
                                </div> : null}
                        </div>
                        {this.state.showToggleActiveModal ? <GdModal
                            open={this.state.showToggleActiveModal}
                            contentLabel={this.state.selectedReport.name}
                            title={(this.state.selectedReport.active ? "Deactivate" : 'Activate') + ' report ' + this.state.selectedReport.name}
                            footer={<div style={{display: 'flex', flex: '1 1'}}>
                                <Button additionalclasses={'sm'} outline label={"Cancel"} color={'gd-grey'}
                                        onClick={() => this.setState({showToggleActiveModal: false})}/>
                                <span style={{display: 'flex', flex: '1 1'}}/>
                                <Button color={this.state.selectedReport.active ? 'gd-red' : 'gd-green'}
                                        additionalclasses={'sm'}
                                        label={this.state.selectedReport.active ? "Deactivate" : 'Activate'}
                                        onClick={() => {
                                            this.setState({showToggleActiveModal: false});
                                            this.toggleReportActive();
                                        }}/>

                            </div>}
                            onClose={() => this.setState({showToggleActiveModal: false})}>
                            <CardBody>
                                <p>Are you sure you want
                                    to {this.state.selectedReport.active ? "deactivate" : 'activate'} this
                                    report.<br/><br/>You
                                    will {this.state.selectedReport.active ? "no longer receive emails and the PDFs will no longer be generated." : 'start receiving emails and regular PDFs will be generated again.'}
                                </p>
                            </CardBody>
                        </GdModal> : null}
                        {this.state.showListModal ? <GdModal
                            open={!!(this.state.showListModal)}
                            contentLabel={this.state.showListModal.title}
                            title={this.state.showListModal.title}
                            footer={!window.$exampleAccount && this.state.showListModal.type === 'recipients' && this.state.selectedReport && (this.state.selectedReport._permission === 'owner' || this.state.selectedReport._permission === 'admin' || this.state.selectedReport._permission === 'edit') ?
                                <div style={{display: 'flex', flex: '1 1', justifyContent: 'flex-end'}}>
                                    <Button additionalclasses={'sm'} label={"Edit"} color={'gd-brand'}
                                            onClick={() => this.setState({
                                                showListModal: false,
                                                showEditReportModal: true,
                                                openOnTab: 2
                                            })}/>
                                </div> : null}
                            onClose={() => this.setState({
                                showListModal: false,
                                showEditReportModal: false,
                                openOnTab: null
                            })}>
                            <CardBody>

                                {this.state.showListModal.items ? this.state.showListModal.items.map(function (item, i) {
                                    return <div key={i + 'lis'} className={'list-item-modal'}>{item}</div>
                                }) : null}
                                {this.state.showListModal.loading ? <GenericLoader/> : null}

                            </CardBody>
                        </GdModal> : null}

                        <Toast onClose={() => this.setState({showSuccessToast: false})} message={"Save Successful"}
                               anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                               open={this.state.showSuccessToast}/>

                        <Toast onClose={() => this.setState({showDeletedToast: false})} message={"Delete Successful"}
                               anchorOrigin={{vertical: 'bottom', horizontal: 'left'}}
                               open={this.state.showDeletedToast}/>

                        {this.state.showEditReportModal && this.state.selectedReport ? <EditReport onDelete={() => {
                            this.setState({showDeletedToast: true});
                            history.push('/reports')
                        }} onSave={() => this.setState({showSuccessToast: true})} openOnTab={this.state.openOnTab}
                                                                                                   onClose={() => this.setState({
                                                                                                       showEditReportModal: null,
                                                                                                       openOnTab: null
                                                                                                   })}
                                                                                                   open={this.state.showEditReportModal}
                                                                                                   retrievedAssetIds={this.state.retrievedAssetIds}
                                                                                                   item={this.state.selectedReport}/> : null}

                        {this.state.shareData ?
                            <ShareDataRequestModal open={!!(this.state.shareData)} report={true}
                                                   dataRequestId={this.state.shareData.id}
                                                   onClose={() => this.setState({shareData: null})}/> : ''}
                    </div>
                )
            } else {
                return <div className={'page white'}><NoItems text={'You haven\'t set up any report schedules'}
                                                              createtext={'Create Report Schedule'}
                                                              modal={CreateReportScheduleModal}/></div>;
            }
        } else {
            return <GenericLoader/>
        }
    }

}

export default MainReportsPage;
import React from "react";
import "./OpeningHours.scss";
import OpeningHourDay from "./openingHourDay";

class OpeningHours extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        
        let hours = this.props.hours;
        if (this.props.gHours && !this.props.updated) {
            let GHours = this.props.gHours.periods;
            hours = hours.map((h, i) => {
                let Gh = GHours.find((gh) => gh.open.day === i);
                h.not_closed = !(Gh) ? 'false' : 'true';
                if (h.not_closed === 'true') {
                    h.open = Gh.open ? Gh.open.time.substring(0, 2) + ':' + Gh.open.time.substring(2) : '';
                    h.close = Gh.close ? Gh.close.time.substring(0, 2) + ':' + Gh.close.time.substring(2) : '';
                }
                return h;
            });
        }

        if (!this.props.updated) hours.push(hours.splice(0, 1)[0]);
        
        this.setState({hours: hours}, this.props.onUpdate(hours));
    }

    render() {
        return (
            <div className={'opening-hours'}>
                {this.state.hours && this.state.hours.map((h, i) => {
                    return <OpeningHourDay key={h.day} updateValue={(key, value) => {
                        h[key] = value;
                        this.setState({hours: this.state.hours});
                        this.props.onUpdate(this.state.hours);
                    }} day={h}/>
                })}
            </div>
        )
    }
}

export default OpeningHours;